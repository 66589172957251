import { Injectable, OnDestroy } from "@angular/core";
import { AuthService } from "./auth.service";
import { Router } from "@angular/router";
import { ApiEndpointConstant } from "../../core/api.endpoint.constant";

@Injectable({
  providedIn: "root",
})
export class WebSocketService implements OnDestroy {
  static websocket: WebSocket;
  static isConnected = false;
  e: any;
  msg: any;
  static callback = (x: any) => {};

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router
  ) {}

  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }

  connectWebSocket() {
    if (!WebSocketService.isConnected) {
      WebSocketService.websocket = new WebSocket(
        ApiEndpointConstant.WebSocketEndpoint
      );
      WebSocketService.websocket.onopen = this.onOpen;
      WebSocketService.websocket.onmessage = this.onMessage;
      WebSocketService.websocket.onerror = this.onError;
      WebSocketService.websocket.onclose = this.onClose;
    }
  }

  onOpen(evt: any) {
    let token = localStorage.getItem("appUser"); //this._authService.userToken();
    if (WebSocketService.websocket && token) {
      WebSocketService.websocket.send(
        JSON.stringify({
          action: "auth",
          value: { client: "lsm-backoffice", token: token },
        })
      );
      WebSocketService.isConnected = true;

      // let timeMillisec = 60000;
      let timeMillisec = 300000;
      setInterval(() => {
        WebSocketService.websocket.send(
          JSON.stringify({
            action: "ping",
            message: "",
          })
        );
      }, timeMillisec);
    }
  }

  //on error event
  onError(evt: any) {}

  //on close event
  onClose(evt: any) {
    // WebSocketService.isConnected = false;
    // alert('websocket closed:::::!!!')
    // window.location.reload();
  }

  onMessage(evt: any) {
    if (evt.data) {
      if (evt.data == "ok") {
      } else {
        this.msg = JSON.parse(evt.data);

        const { Action = "" } = this.msg || {};

        if (Action == "kick") {
          localStorage.clear();
          window.location.reload();
        }
        if (Action == "kick_all") {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  }
}
