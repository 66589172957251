import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameService } from '../../../pages/game/services/game.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { GameConfigConstant } from '../../../core/game.config.constant';

@Component({
  selector: 'app-modal-list-games',
  templateUrl: './modal-list-games.component.html',
  styleUrls: ['./modal-list-games.component.css'],
})
export class ModalListGamesComponent implements OnInit {
  @Input() gameListContents: any = [];
  @Output() isClickCloseModal = new EventEmitter<boolean>();
  gameAllList: any = [];
  gameHistoryList: any = [];
  deviceInfo: any;
  newTabGames: string[] = GameConfigConstant.GameInNewTab;

  constructor(
    private _gameService: GameService,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    let deviceInfo: any = localStorage.getItem('deviceInfo');
    this.deviceInfo = JSON.parse(deviceInfo);

    this.gameListContents['games'].map(async (v: any) => {
      if (v.status == 'Open') {
        await this.gameAllList.push(v);
      }
    });

    this.spinner.hide();
  }

  onClickCloseModal() {
    this.isClickCloseModal.emit(true);
  }

  onClickGameLaunch(item: any) {
    this._gameService
      .getDataProductUrlForPlayGameList(
        this.gameListContents.code,
        this.gameListContents.category,
        item.code,
        this.deviceInfo?.deviceType
      )
      .subscribe({
        next: (data) => {
          this.setHistory(item, data.value);

          this.openGame(data.value, this.newTabGames.includes(item.code));
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  setHistory(item: any, url: string) {
    let gameSlotPlayLasted = localStorage.getItem('gameSlotPlayLasted');
    if (gameSlotPlayLasted)
      this.gameHistoryList = JSON.parse(gameSlotPlayLasted);

    this.gameHistoryList = this.gameHistoryList.filter((x: any) => {
      return x.id != item.id;
    });
    this.gameHistoryList.unshift({
      brand: this.gameListContents.brand,
      category: this.gameListContents.category,
      code: item.code,
      id: item.id,
      imageUrl: item.properties.imageURL,
      name: item.name,
      linkPlayGame: url,
    });
    if (this.gameHistoryList.length > 10) this.gameHistoryList.pop();
    localStorage.setItem(
      'gameSlotPlayLasted',
      JSON.stringify(this.gameHistoryList)
    );
  }

  openGame(url: string, isNewTab: boolean = false) {
    const isSafari =
      this.deviceInfo?.browser?.toLowerCase()?.indexOf('safari') > -1;
    if (isNewTab) {
      if (isSafari) {
        setTimeout(() => {
          window.open(
            url,
            '_blank',
            'toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes'
          );
        });
        return;
      }
      window.open(url, '_blank');
      return;
    }

    if (url.indexOf('http:') != 0 && url.indexOf('hg.lsmapi.com') == -1)
      this._router.navigate(['game/player'], { queryParams: { url: url } });
    else if (!isSafari) window.open(url, '_blank');
    else
      setTimeout(() => {
        window.open(
          url,
          '_blank',
          'toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes'
        );
      });
  }
}
