<!-- <div class="-menu-bottom">
  <div class="container">
    <div class="-group-btn">
      <a
        id="wallet"
        routerLink="/wallet"
        [ngClass]="this.path == 'wallet' ? '-active' : ''"
      >
        <span class="-icon">
          <img appImgLazyLoad src="assets/img/theme/wallet.svg" alt="" />
          <img appImgLazyLoad src="assets/img/theme/wallet-active.svg" alt="" />
        </span>
        <span class="-text">กระเป๋า</span>
      </a>
      <a
        id="deposit"
        routerLink="/deposit"
        [ngClass]="this.path == 'deposit' ? '-active' : ''"
      >
        <span class="-icon">
          <img appImgLazyLoad src="assets/img/theme/deposit.svg" alt="" />
          <img appImgLazyLoad src="assets/img/theme/deposit-active.svg" alt="" />
        </span>
        <span class="-text">ฝากเงิน</span>
      </a>
      <a
        id="game"
        routerLink="/game/gameListing"
        [ngClass]="this.path == 'game' ? '-active' : ''"
      >
        <span class="-icon">
          <img appImgLazyLoad src="assets/img/theme/game.svg" alt="" />
          <img appImgLazyLoad src="assets/img/theme/game-active.svg" alt="" />
        </span>
        <span class="-text">เล่นเกมส์</span>
      </a>
      <a
        id="withdraw"
        routerLink="/withdraw"
        [ngClass]="this.path == 'withdraw' ? '-active' : ''"
      >
        <span class="-icon">
          <img appImgLazyLoad src="assets/img/theme/withdraw.svg" alt="" />
          <img appImgLazyLoad src="assets/img/theme/withdraw-active.svg" alt="" />
        </span>
        <span class="-text">ถอนเงิน</span>
      </a>
      <a
        id="contact"
        routerLink="/contact"
        [ngClass]="this.path == 'contact' ? '-active' : ''"
      >
        <span class="-icon">
          <img appImgLazyLoad src="assets/img/theme/contact.svg" alt="" />
          <img appImgLazyLoad src="assets/img/theme/contact-active.svg" alt="" />
        </span>
        <span class="-text">ติดต่อเรา</span>
      </a>
    </div>
  </div>
</div> -->

<app-frame-bg [type]="this.pathClass">
  <div class="container-menu">
    <div class="-theme-menu-bottom-wrapper">
      <div class="-theme-menu-bottom">
        <a
          id="wallet"
          routerLink="/wallet"
          routerLinkActive="active"
          class="group-btn-item"
        >
          <div class="box-icon-bt" [ngClass]="{ active: path === 'wallet' }">
            <span class="flex">
              <div
                class="bg-boot-icon"
                [ngClass]="{ active: path === 'wallet' ? 'active' : '' }"
              >
                <div *ngIf="path === 'wallet'" class="-active-wallet">
                  <video
                    autoplay
                    loop
                    poster="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da1.gif"
                  >
                    <source
                      type="video/webm"
                      src="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da1.png"
                    />
                  </video>
                </div>
                <div *ngIf="path !== 'wallet'" class="-img-wallet"></div>
              </div>
            </span>
            <span class="text-bt-x" [ngClass]="{ active: path === 'wallet' }"
              >กระเป๋า</span
            >
          </div>
        </a>

        <a id="deposit" routerLink="/deposit" class="group-btn-item">
          <div
            class="box-icon-bt"
            [ngClass]="{ active: path === 'deposit' || path === 'withdraw' }"
          >
            <span class="flex">
              <div class="bg-boot-icon">
                <div
                  [ngClass]="{
                    active: path === 'deposit' || path === 'withdraw'
                  }"
                >
                  <video
                    *ngIf="path === 'deposit' || path === 'withdraw'"
                    class="-active-deposit"
                    autoplay
                    loop
                    poster="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da2.gif"
                  >
                    <source
                      type="video/webm"
                      src="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da2.png"
                    />
                  </video>
                  <div
                    *ngIf="path !== 'deposit' && path !== 'withdraw'"
                    class="-img-deposit"
                  ></div>
                </div>
              </div>
            </span>
            <span
              class="text-bt-x"
              [ngClass]="{ active: path === 'deposit' || path === 'withdraw' }"
              >ฝาก-ถอน</span
            >
          </div>
        </a>

        <a id="affiliates" routerLink="/affiliates" class="group-btn-item">
          <div
            class="box-icon-bt"
            [ngClass]="{
              active: path === 'affiliates' || path === 'referFriends'
            }"
          >
            <span class="flex">
              <div class="bg-boot-icon">
                <div
                  [ngClass]="{
                    active: path === 'affiliates' || path === 'referFriends'
                  }"
                >
                  <video
                    *ngIf="path === 'affiliates' || path === 'referFriends'"
                    class="-active-affiliates"
                    autoplay
                    loop
                    poster="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da3.gif"
                  >
                    <source
                      type="video/webm"
                      src="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da3.png"
                    />
                  </video>
                  <div
                    *ngIf="path !== 'affiliates' && path !== 'referFriends'"
                    class="-img-affiliates"
                  ></div>
                </div>
              </div>
            </span>
            <span
              class="text-bt-x"
              [ngClass]="{
                active: path === 'affiliates' || path === 'referFriends'
              }"
              >แนะนำเพื่อน</span
            >
          </div>
        </a>

        <a
          id="promotionList"
          routerLink="/promotionList"
          class="group-btn-item"
        >
          <div
            class="box-icon-bt"
            [ngClass]="{ active: path === 'promotionList' }"
          >
            <span class="flex">
              <div
                class="bg-boot-icon"
                [ngClass]="{ active: path === 'promotionList' ? 'active' : '' }"
              >
                <div [ngClass]="this.path == 'promotionList' ? 'active' : ''">
                  <video
                    *ngIf="this.path === 'promotionList'"
                    class="-active-promotion"
                    autoplay
                    loop
                    poster="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da4.gif"
                  >
                    <source
                      type="video/webm"
                      src="https://fs.cdnxn.com/lsm99king/king/img/menu/MenuFooter/da4.png"
                    />
                  </video>
                  <div
                    *ngIf="this.path !== 'promotionList'"
                    class="-img-promotion"
                  ></div>
                </div>
              </div>
            </span>
            <span
              class="text-bt-x"
              [ngClass]="{ active: path === 'promotionList' }"
              >โปรโมชั่น</span
            >
          </div>
        </a>
      </div>
      <div class="-theme-menu-playgame">
        <a
          id="game"
          routerLink="/game/casinoListing"
          [ngClass]="this.path == 'game' ? '-active' : ''"
        >
          <span class="flex">
            <div [ngClass]="this.path == 'game' ? 'active' : ''">
              <video
                *ngIf="this.path === 'game'"
                class="-active-game"
                autoplay
                loop
                poster="https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.gif"
              >
                <source
                  type="video/webm"
                  src="https://fs.cdnxn.com/lsm99king/king/img/ico-bottom-playgame-hover.webm"
                />
              </video>
              <div *ngIf="this.path !== 'game'" class="-img-game"></div>
            </div>
          </span>
        </a>
      </div>
    </div>
  </div>
</app-frame-bg>
