import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-otp-forgotpassword',
  templateUrl: './otp-forgotpassword.component.html',
  styleUrls: ['./otp-forgotpassword.component.scss']
})
export class OtpForgotpasswordComponent implements OnInit {
  otpForgotPassword!: FormGroup

  constructor(
      private readonly _router: Router,
      private _formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initOtpForgotPasswordForm();
  }

  initOtpForgotPasswordForm() {
    this.otpForgotPassword = this._formBuilder.group({
      inputForm1: ['', Validators.required],
      inputForm2: ['', Validators.required],
      inputForm3: ['', Validators.required],
      inputForm4: ['', Validators.required],
    })
  }

  onClickSubmit() {
    this._router.navigate(['index']);
  }

}
