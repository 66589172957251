import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-menu-bottom",
  templateUrl: "./menu-bottom.component.html",
  styleUrls: ["./menu-bottom.component.scss"],
})
export class MenuBottomComponent implements OnInit {
  path: string = "";
  pathClass: any = "";

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.path = this._router.url.substring(1);
    if (this.path.indexOf("Listing") >= 0) this.path = "game";
    else if (this.path == "index") {
      this.path = "wallet";
    }

    switch (this.path) {
      case "wallet":
      case "deposit":
      case "withdraw":
      case "promotionList":
      case "game":
        this.pathClass = "bottom-menu-" + this.path;
        break;
      default:
        this.pathClass = "bottom-menu-wallet";
        break;
    }
  }

  onHover(element: string) {
    this.pathClass = "bottom-menu-" + element;
  }
}
