import { Component, OnInit } from '@angular/core';
// import { SafeService } from 'src/app/shared/services/safe.services';
import "./safe.component.scss";
import { miniGameService } from 'src/app/shared/services/minigame.service';
import {ActivatedRoute, Router} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

declare function animateGameSafety(): any;
declare function randomReward(): any;
declare function GameResultSafety(): any;
declare function animateSafeOpen(): any;


@Component({
  selector: 'app-game',
  templateUrl: './safe.component.html',
  styleUrls: ['./safe.component.scss']
})


export class SafeComponent implements OnInit {
  websiteName: string = '';
  result: any;
  checkAvailble: any;
  bonus: string = '';
  resultBonus: string = '';
  safeType: string = '';
  alertMsgContents: string = '';
  showModalAlertMessage: boolean = false;
  miniGamePromotionId: any;

  constructor(
    // private readonly _safeService: SafeService,
    private _minigameService : miniGameService,
    private _urlRoute: ActivatedRoute,
    private readonly _router: Router,
    private spinner: NgxSpinnerService,
  ) { 
    // init();
    this.alertMsgContents = JSON.parse(localStorage.getItem("alertMessage") || '""');
  }

  ngOnInit(): void {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }
    this.setSafeType();
    animateGameSafety();
  }

  onClickCloseModal(event: any) {
    this.showModalAlertMessage = !event;
    document.body.style.overflow = '';
  }

  setSafeType() {
    //get marketing channel from url link
    this._urlRoute.queryParams
    .subscribe(params => {
      if (params['type']) {
        this.safeType = params['type'];
        localStorage.setItem('safe_select', params['type']);
      }else{
        this._router.navigate(['index']);
      }
      this.miniGamePromotionId = localStorage.getItem('appMiniGamePromotionId');
    });
  }

  async safestart(): Promise<any>{
    this.spinner.show();
    this._minigameService.checkPromitionLuckyAvailable(this.miniGamePromotionId)
    .subscribe({
      next: (data) => {
        this.checkAvailble = data;
        if(this.checkAvailble.isAvailable){
          this._minigameService.userPromotionLuckyBonus(this.miniGamePromotionId)
          .subscribe({
            next: (data) => {
              animateSafeOpen();
              this.spinner.hide();
              this.result = data;
              localStorage.setItem('safe_result', JSON.stringify(data));
              this.bonus = this.result.resultDestination.text;
              this.resultBonus = this.result.resultDestination.resultText;
              // setTimeout(()=>{
                // GameResultSafety();
              // }, 6000);
              
            },
            error: (e) => {
              console.error(e)
            }
          });
        }else{
          this.spinner.hide();
          this.alertMsgContents = this.checkAvailble.reason;
          this.showModalAlertMessage = true;
        }
        
      },
      error: (e) => {
        console.error(e)
      }
    });
  }
}
