<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
		<main id="main-content" class="bg-black min-h-screen">
			<div class="-content-playing -game-play">
				<div class="container">
          <div class="-box-title">
            <h1>เปิดไพ่ลุ้นรางวัล</h1>
            <p>เลือกไพ่ที่คุณต้องการเปิด<br>เพื่อรับของรางวัลพิเศษจาก BERLIN777</p>
          </div>
          
					<div class="-list-item">
            <div class="-item">
              <a href="/card?type=heart">
                <img appImgLazyLoad src="assets/img/game-card/card-back.png" alt="card">
                <img appImgLazyLoad src="assets/img/game-card/card-heart.png" alt="card">
              </a>
            </div>
            <div class="-item">
              <a href="/card?type=club">
                <img appImgLazyLoad src="assets/img/game-card/card-back.png" alt="card">
                <img appImgLazyLoad src="assets/img/game-card/card-club.png" alt="card">
              </a>
            </div>
            <div class="-item">
              <a href="/card?type=diamond">
                <img appImgLazyLoad src="assets/img/game-card/card-back.png" alt="card">
                <img appImgLazyLoad src="assets/img/game-card/card-diamond.png" alt="card">
              </a>
            </div>
            <div class="-item">
              <a href="/card?type=spade">
                <img appImgLazyLoad src="assets/img/game-card/card-back.png" alt="card">
                <img appImgLazyLoad src="assets/img/game-card/card-spade.png" alt="card">
              </a>
            </div>
          </div>
				</div>
			</div>
		</main> <!-- end content this page -->


