import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { timer, Subscription } from 'rxjs';
import { WalletBankService } from 'src/app/pages/wallet/services/wallet-bank.service';
import { SetDisplayPromotion } from '../../services/setDisplayPromotion';
import { GameService } from 'src/app/pages/game/services/game.service';
import { miniGameService } from '../../services/minigame.service';

@Component({
  selector: 'app-theme-wallet-group',
  templateUrl: './theme-wallet-group.component.html',
  styleUrls: ['./theme-wallet-group.component.css'],
})
export class ThemeWalletGroupComponent implements OnInit {
  @Input() title = 'wallet';
  lastUpdate!: Date;
  creditBalance!: number;
  availablePromotions!: any;
  totalAvailablePromotions!: number;
  userInfo!: any;
  displayName!: string;
  memberGroupImage!: string;
  activedPromotionId!: number;
  activedPromotion: any;
  IsActiveBonusWallet!: boolean;
  promotionForSelect: any;

  constructor(
    private _walletBankService: WalletBankService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public setPromotion: SetDisplayPromotion,
    private router: Router,
    private _gameService: GameService,
    private _minigameService: miniGameService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('appUserInfo') || '{}');
  }

  ngOnInit(): void {
    this.onGetDataUserAvailablePromotion();
    this.getDataUserInfomation();
    this.onGetDataUserBalance();
  }

  getDataUserInfomation() {
    this.spinner.show();
    this._walletBankService.getDataUserInfomation().subscribe({
      next: (data) => {
        this.setDataToFront(data);
        this.getUrlUserProfileImage();
        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataToFront(data: any) {
    this.activedPromotionId = data.activedPromotionId;
    this.displayName = this.userInfo.name;
  }

  onGetDataUserAvailablePromotion() {
    this.spinner.show();
    this._walletBankService.getDataUserAvailablePromotion().subscribe({
      next: (data) => {
        if (data) {
          const promotionData = data.filter((item: any) => {
            return item.promotionBasicTypeCode != 'manual';
          });
          this.promotionForSelect = promotionData;
        } else {
          this.promotionForSelect = [];
        }
        this.availablePromotions =
          this.promotionForSelect.length > 0 ? true : false;
        this.totalAvailablePromotions =
          this.availablePromotions == true ? this.promotionForSelect.length : 0;

        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  onRefreshDataUserInfo() {
    this.getDataUserInfomation();
  }

  onRefreshDataUserBalance() {
    this.onGetDataUserBalance();
  }

  onGetDataUserBalance() {
    this.spinner.show();
    this._walletBankService.getDataUserBalance().subscribe({
      next: (data) => {
        this.handleDataUserBalance(data);
        this.spinner.hide();
        this.lastUpdate = new Date();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  handleDataUserBalance(data: any) {
    this.creditBalance = data.creditBalance;
    this.IsActiveBonusWallet = data.isActiveBonusWallet;
  }

  getUrlUserProfileImage() {
    this._walletBankService.getUrlUserProfileImage().subscribe({
      next: (data) => {
        this.memberGroupImage = data.value
          ? data.value
          : 'assets/img/icons/img-profile.png';
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onClickSelectPromotion() {
    localStorage.setItem(
      'promotionForSelect',
      JSON.stringify(this.promotionForSelect)
    );
  }
}
