import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-modal-with-rich-text",
  templateUrl: "./modal-with-rich-text.component.html",
  styleUrls: ["./modal-with-rich-text.component.css"],
})
export class ModalWithRichTextComponent implements OnInit {
  @Input() imgUrl: string = "";
  @Input() promotionContents: string = "";
  @Input() alertMsgContents: string = "";
  @Input() isReadOnly: boolean = true;
  @Output() isClickCloseModal = new EventEmitter<boolean>();
  @Output() isClickSubmitPromotion = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onClickCloseModal() {
    this.isClickCloseModal.emit(true);
  }

  onClickSubmit() {
    if (this.isReadOnly) {
      this.isClickCloseModal.emit(true);
    } else {
      this.isClickSubmitPromotion.emit(true);
    }
  }
}
