<div class="flex justify-center">
  <img
    src="https://fs.cdnxn.com/lsm99king/king/img/icon-horizontal-line.webp"
    class="max-w-sm md:hidden xl:hidden"
  />
  <img
    src="https://fs.cdnxn.com/lsm99king/king/img/icon-horizontal-line-dt.webp"
    class="sm:hidden md:block md:w-xl xl:w-xl"
  />
</div>
