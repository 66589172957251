import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MemberService} from "../../shared/services/member.service";
import {ActivatedRoute, NavigationExtras, Router} from "@angular/router";
import {LoginService} from "../../shared/services/login.service";
import {MigrationService} from "../../shared/services/migration.service";

@Component({
  selector: 'app-register-migration',
  templateUrl: './register-migration.component.html',
  styleUrls: ['./register-migration.component.css']
})
export class RegistermigrationComponent implements OnInit {
  @Input() marketingChannelCode: string = "";

  registermigrationForm!: FormGroup;
  bankId: number = 0;
  bankName: string = "";
  phoneNumberValidate: boolean = true;
  accountNoValidate: boolean = true;
  bankIdValidate: boolean = true;
  otpReference!: string;
  phoneNumber!: string;
  accountNumber!: string;
  marketingCode: string = "";
  marketingChannelId: number = 0;
  affCode: string = "0";
  isLoginFacebook: any;
  isLoginLine: any;
  externalAuthReference: string = '';
  payload: any;
  registerMessage: string = '';
  pathImage: string = '';
  websiteName: string = '';
  showModal: boolean = false;
  usernameForLogin!: string;
  passwordForLogin!: string;

  userName: any ;
  userCredit: any;
  userAgent: any;
  userType: any;


    constructor(
      private spinner: NgxSpinnerService,
      private toastr: ToastrService,
      private _formBuilder: FormBuilder,
      private _memberService: MemberService,
      private readonly _router: Router,
      private _urlRoute: ActivatedRoute,
      private _loginService: LoginService,
      private _migrationService : MigrationService,
  ) { }

  ngOnInit(): void {
      this.setName();
      this.setImage();
      this.setRegisterMessage();
      this.initRegistermigrationForm();
      this.getMarketingChannels();
      this.userName = localStorage.getItem('appUserName');
      this.userCredit = localStorage.getItem('appUserCredit');
      this.userAgent = localStorage.getItem('appUserAgent');
      this.userType = localStorage.getItem('appUserType');
  }

  getMarketingChannels() {
    this._memberService.getMarketingChanels()
    .subscribe({
      next: (data) => {
        this.setMarketingChannel(data);
      },
      error: e => {
        console.error(e);
      }
    })
  }

  setMarketingChannel(data: any[]) {
    //get marketing channel from url link
    this._urlRoute.queryParams
    .subscribe(params => {
      if (params['channel']) {
        this.marketingCode = params['channel'];
        let items = data.filter(x => x.code.toLowerCase() == this.marketingCode.toLowerCase());
        if (items.length > 0) {
          this.marketingChannelId = items[0].id;
        }
      }

      if (params['affiliatecode']) {
        this.affCode = params['affiliatecode'];
        this.onSetAffliateCountFromAffliateCode(this.affCode);
      }
    });
  }

  initRegistermigrationForm() {
    this.registermigrationForm = this._formBuilder.group({
      phoneNumberForm: ['', [Validators.required, Validators.minLength(10)]],
      accountNoForm: ['', [Validators.required, Validators.minLength(10)]]
    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onChooseBank(bankId: number) {
    this.bankId = bankId;
    switch (bankId) {
      case 1: this.bankName = 'กรุงเทพ';
          break;
      case 2: this.bankName = 'ไทยพาณิชย์';
          break;
      case 3: this.bankName = 'กสิกรไทย';
          break;
      case 4: this.bankName = 'กรุงไทย';
          break;
      case 5: this.bankName = 'กรุงศรีอยุธยา';
          break;
      case 6: this.bankName = 'เพื่อการเกษตรและสหกรณ์การเกษตร';
          break;
      case 7: this.bankName = 'ซีไอเอ็มบีไทย';
          break;
      case 8: this.bankName = 'ทิสโก้';
          break;
      case 9: this.bankName = 'อาคารสงเคราะห์';
          break;
      case 10: this.bankName = 'ออมสิน';
          break;
      case 11: this.bankName = 'ทหารไทยธนชาต';
          break;
      case 12: this.bankName = 'อิสลามแห่งประเทศไทย';
          break;
      case 13: this.bankName = 'เกียรตินาคินภัทร';
          break;
      case 14: this.bankName = 'แลนด์ แอนด์ เฮ้าส์';
          break;
      case 15: this.bankName = 'ยูโอบี';
          break;
      default: this.bankName = '';
          break;
    }
  }

  onSubmit() {
    this.phoneNumberValidate = this.registermigrationForm.controls['phoneNumberForm'].valid;
    this.accountNoValidate = this.registermigrationForm.controls['accountNoForm'].valid;
    this.bankIdValidate = this.bankId > 0;
    this.phoneNumber = this.registermigrationForm.controls['phoneNumberForm'].value;
    this.accountNumber = this.registermigrationForm.controls['accountNoForm'].value;


      let externalAuthReference: any;
      externalAuthReference = localStorage.getItem('externalAuthReference');
      this.externalAuthReference = JSON.parse(externalAuthReference);

      this.isLoginFacebook = localStorage.getItem('isLoginFacebook');
      this.isLoginFacebook = JSON.parse(this.isLoginFacebook);

      this.isLoginLine = localStorage.getItem('isLoginLine');
      this.isLoginLine = JSON.parse(this.isLoginLine);

    this.payload = {
      phoneNumber: this.phoneNumber,
      bankAccountNo: this.accountNumber,
      registBankId: this.bankId,
      fromMarketingChannelId: this.marketingChannelId,
      externalAuthReference:  this.externalAuthReference,
      registAffliateCode:  this.affCode,
    }

    if (!this.registermigrationForm.invalid && this.bankIdValidate) {
      this.spinner.show();
        this._memberService.postCheckUsernameIsAvailable(this.phoneNumber)
        .subscribe({
            next: (data) => {
              this.spinner.hide();
              this.register();
            },
            error: (e) => {
              console.error(e)
              this.spinner.hide();
              this.toastr.error('', 'เบอร์โทรศัพท์ไม่ถูกต้อง หรือ มีผู้ใช้งานเบอร์นี้แล้ว');
            }
        })
        // this.spinner.hide();
    }
  }

  register() {
    this.spinner.show();  

/* 
    this.spinner.hide();  
    this.showModal = true;
    setInterval(() => {
      // this.spinner.show();
      this.showModal = false;
      this.onNextToLogin();
    }, 20000)
 */
  
    let dataSet = {
      phoneNumber: this.phoneNumber,
      bankAccountNo: this.accountNumber,
      registBankId: this.bankId,
      fromMarketingChannelId: this.marketingChannelId,
      registAffliateCode:  this.affCode,
      Agent:  this.userAgent,
      V2User:  this.userName,
      UserType:  this.userType,
    }
    this._memberService.postMemberRegisterMigration(dataSet)
    .subscribe({
      next: (data: any) => {

        this.usernameForLogin = this.phoneNumber;
        this.passwordForLogin = data.code;

        this.spinner.hide();
        // this._router.navigate(['registerSuccess']);
        // this.toastr.success('', 'การสมัครสมาชิกสำเร็จ โปรด Login เพื่อใช้งาน');

        this.showModal = true;
        setInterval(() => {
          // this.spinner.show();
          this.showModal = false;
          this.onNextToLogin();
        }, 20000)

      },
      error: (e: any) => {
        console.error(e);
        this.toastr.clear();
        let errorCode = e.error.code;
        switch (errorCode) {
          case "105":
            this.toastr.error('เลขที่บัญชีนี้มีในระบบแล้ว กรุณาใช้เลขที่บัญชีใหม่ค่ะ', 'ทำรายการไม่สำเร็จ');
            break;
        
          default:
            this.toastr.error('สมาชิกในระบบเต็มแล้ว กรุณาสมัครใหม่ในภายหลังค่ะ', 'ทำรายการไม่สำเร็จ');
            break;
        }
        this.spinner.hide();
      }
    })
   
  }


  onSetAffliateCountFromAffliateCode(registAffliateCode: string = this.affCode){
    let payload = {
      value: registAffliateCode
    }

    this._memberService.setDataRegisterAffliateCount(payload)
    .subscribe({
      next: data => {
      },
      error: e => {
        console.error(e);
      }
    })
  }

  setRegisterMessage() {
    // this.registerMessage = JSON.parse(localStorage.getItem('registerMessage') || '');
    let registerMessage: any = localStorage.getItem('registerMessage');
    if (registerMessage) {
      this.registerMessage = JSON.parse(registerMessage);
    } else {
      this.registerMessage = "";
    }
  }

  setImage() {
    let webImage = localStorage.getItem('webImage');
    if (webImage) {
      this.pathImage = JSON.parse(webImage || '');
    } else {
      this.pathImage = 'assets/White/img/theme/logo.svg';
    }
  }

  setName() {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }
  }

  toggleModal(){
    this.showModal = !this.showModal;
    this.onNextToLogin();
  }

  onNextToLogin(){
    this.spinner.hide();
    // demo data
    // this.usernameForLogin = "0807820606";
    // this.passwordForLogin = "836224";

    const navigationExtras: NavigationExtras = {
      state: {
        username: this.usernameForLogin,
        password: this.passwordForLogin,
      }
    };
    this._router.navigate(['login'], navigationExtras);
  }
}
