export const environment = {
  production: false,
  envName: "Dev",
  backendApi: "https://api.ppnes.com",
  authApi: "https://auth.ppnes.com",
  webSocket: "wss://276yacw5ne.execute-api.ap-southeast-1.amazonaws.com/sit",
  // luckywheel_centerCircleImageUrl: "https://resource-luckydraw.s3.ap-southeast-1.amazonaws.com/logo-berlin.png",
  luckywheel_centerCircleImageUrl:
    "https://fs.cdnxn.com/lsm99king/king/img/King-HNY1.gif",
  luckywheel_wheelStrokeColor: "#ffe889",
  luckywheel_segmentStrokeColor: "#E2E2E2",
  luckywheel_colorArray: "#000000",
  shortenLinkEnable: true,
  shortenLinkApi: "https://shorten-url.azurewebsites.net",
  shortenLinkApiKey: "f8d5ce81-8163-46d1-a5c7-8aa46dfc71ec",
  shortenLinkTitle: "รับสิทธิพิเศษ",
};
