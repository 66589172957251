import { Component, OnInit } from '@angular/core';
// import { SafeService } from 'src/app/shared/services/safe.services';
import "./safe-result.component.scss";
import { miniGameService } from 'src/app/shared/services/minigame.service';
import {ActivatedRoute, Router} from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

declare function animateGameCardAnswer(): any;

@Component({
  selector: 'app-game',
  templateUrl: './safe-result.component.html',
  styleUrls: ['./safe-result.component.scss']
})


export class SafeResultComponent implements OnInit {
  websiteName: string = '';
  result: any;
  checkAvailble: any;
  bonus: string = '';
  resultBonus: string = '';
  safeType: string = '';
  alertMsgContents: string = '';
  showModalAlertMessage: boolean = false;
  miniGamePromotionId: any;
  safe_result: any;
  safe_select: any;
  safe_display: any;

  constructor(
    // private readonly _safeService: SafeService,
    private _minigameService : miniGameService,
    private _urlRoute: ActivatedRoute,
    private readonly _router: Router,
    private spinner: NgxSpinnerService,
  ) { 
    // init();
    this.alertMsgContents = JSON.parse(localStorage.getItem("alertMessage") || '""');
  }

  ngOnInit(): void {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }
    this.safe_result = localStorage.getItem('safe_result');
    this.safe_select = localStorage.getItem('safe_select');

    var Safe:Array<object>  = [
      { type: "bb", data :"" },
      { type: "rb", data :"" },
      { type: "br", data :"" },
      { type: "rr", data :"" },
    ];

    var Safe_prepare:Array<object>  = [];

    var safe_data = JSON.parse(this.safe_result);
    safe_data.segmentValues.forEach((e: any) => {
      if(e.value != safe_data.resultDestination.value){
        Safe_prepare.push(e);
      }
    });

    var i = 0;
    Safe.forEach((e: any) => {
      if(e.type == this.safe_select){
        e.data = JSON.parse(this.safe_result).resultDestination;
      }else{
        e.data = Safe_prepare[i];
        i++;
      }
    });

    this.safe_display = Safe;

    // this.setSafeType();
    animateGameCardAnswer();
    //  setTimeout(()=>{
      
    // }, 5000);
  }

  onClickCloseModal(event: any) {
    this.showModalAlertMessage = !event;
    document.body.style.overflow = '';
  }

  setSafeType() {
    //get marketing channel from url link
    // this._urlRoute.queryParams
    // .subscribe(params => {
    //   if (params['type']) {
    //     this.safeType = params['type'];
    //   }else{
    //     this._router.navigate(['index']);
    //   }
    //   this.miniGamePromotionId = localStorage.getItem('appMiniGamePromotionId');
    // });
  }

  async safestart(): Promise<any>{
    this.spinner.show();
    this._minigameService.checkPromitionLuckyAvailable(this.miniGamePromotionId)
    .subscribe({
      next: (data) => {
        this.checkAvailble = data;
        if(this.checkAvailble.isAvailable){
          this._minigameService.userPromotionLuckyBonus(this.miniGamePromotionId)
          .subscribe({
            next: (data) => {
              this.spinner.hide();
              this.result = data;
              this.bonus = this.result.resultDestination.text;
              this.resultBonus = this.result.resultDestination.resultText;
              // GameResultSafety();
            },
            error: (e) => {
              console.error(e)
            }
          });
        }else{
          this.alertMsgContents = this.checkAvailble.reason;
          this.showModalAlertMessage = true;
        }
        
      },
      error: (e) => {
        console.error(e)
      }
    });
  }
}
