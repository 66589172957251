<!-- Set Header -->
<div class="-header">
  <h2 class="-text-header">สมัครสมาชิก</h2>

  <a routerLink="/" class="-btn-close">
    <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
  </a>
</div>
<!-- End Set Header --> 

<div class="-content-register -step-01">
  <div class="container">
    <div class="-section-top">
      <div class="-box-step">
        <div class="-group-step">
          <div class="-col -active">
            <div class="-icon">
              <img appImgLazyLoad src="assets/img/theme/register-step-01.svg" alt="">
              <img appImgLazyLoad src="assets/img/theme/register-step-01-active.svg" alt="">
            </div>
            <p>ยืนยันตัวตน</p>
          </div>
          <div class="-col">
            <div class="-icon">
              <img appImgLazyLoad src="assets/img/theme/register-step-02.svg" alt="">
              <img appImgLazyLoad src="assets/img/theme/register-step-02-active.svg" alt="">
            </div>
            <p>ข้อมูลผู้ใช้</p>
          </div>
          <div class="-col">
            <div class="-icon">
              <img appImgLazyLoad src="assets/img/theme/register-step-03.svg" alt="">
              <img appImgLazyLoad src="assets/img/theme/register-step-03-active.svg" alt="">
            </div>
            <p>บัญชีธนาคาร</p>
          </div>
          <div class="-col">
            <div class="-icon">
              <img appImgLazyLoad src="assets/img/theme/register-step-04.svg" alt="">
              <img appImgLazyLoad src="assets/img/theme/register-step-04-active.svg" alt="">
            </div>
            <p>ยืนยันข้อมูล</p>
          </div>
        </div>

        <div class="-progress">
          <div class="-bar"></div>
        </div>
      </div>

      <h2 class="-title">ยืนยันตัวตน</h2>
      <p>ระบุหมายเลขโทรศัพท์ของคุณและข้อมูลให้ครบถ้วน
        <br>เพื่อรับรหัส OTP เพื่อยืนยันตัวตน สำหรับสมัครสมาชิก</p>
      <div class="-form-style">
        <form>
          <div class="-row -required">
            <label>หมายเลขโทรศัพท์</label>
            <input type="tel" class="-form-input">
            <p class="-text-validate">Validate</p>
          </div>
          <div class="-row -required">
            <label>ช่องทางที่คุณรู้จักเรา</label>
            <select class="-form-input">
              <option value="01">ช่องทางที่ 1</option>
              <option value="02">ช่องทางที่ 2</option>
            </select>
            <p class="-text-validate">Validate</p>
          </div>
          <div class="-row -required">
            <label>กรอกรหัสแนะนำพัธมิตร</label>
            <input type="text" class="-form-input">
            <p class="-text-validate">Validate</p>
          </div>
          <div class="-row">
            <!-- <button type="submit" class="-btn-01"><span>ขอรับรหัสยืนยันตัวตน</span></button> -->
            <!-- Mock Up Next Step -->
            <a routerLink="/otp" class="-btn-01"><span>ขอรับรหัสยืนยันตัวตน</span></a><!-- End Mock Up Next Step -->
          </div>
        </form>
      </div>
    </div>

    <div class="-section-bottom">
      <p>เคยสมัครสมาชิกกับ Berlin777 แล้ว</p>
      <a routerLink="/login" class="-btn-02"><span>เข้าสู่ระบบ</span></a>
    </div>
  </div>
</div>

