import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiEndpointConstant } from "src/app/core/api.endpoint.constant";
import { UserDepositInfoModel } from "src/app/models/user-deposit-info.model";
import { UserDepositUploadSlipModel } from "src/app/models/user-deposit-upload-slip.model";
import { UserInfomationModel } from "src/app/models/user-infomation.model";
import { UserWithdrawBankModel } from "src/app/models/user-withdraw-bank.model";
import { UserDepositUploadQrDepositModel } from "src/app/models/user-deposit-upload-qrCode.model";

const getUserDepositInfoBaseUrl = ApiEndpointConstant.GetUserDepositInfo;
const getWithdrawBankListBaseUrl = ApiEndpointConstant.GetWithdrawBankList;
const getDecimalDepositRequestBaseUrl =
  ApiEndpointConstant.GetDecimalDepositRequest;
const getLastestUserRequestDecimalDepositTimeoutBaseUrl =
  ApiEndpointConstant.GetLastestUserRequestDecimalDepositTimeout;
const postUploadSlipDecimalDepositTimeoutBaseUrl =
  ApiEndpointConstant.PostUploadSlipDecimalDepositTimeout;
const getLastestUserRequestDecimalDepositBaseUrl =
  ApiEndpointConstant.GetLastestUserRequestDecimalDeposit;
const getUserInfomationBaseUrl = ApiEndpointConstant.GetUserInfomation;
const postDataUploadSlipQrDepositBaseUrl =
  ApiEndpointConstant.PostDataUploadSlipQrDeposit;
const postDepositRequestPgwPaymentUrl =
  ApiEndpointConstant.PostDepositRequestPgwPayment;

@Injectable({
  providedIn: "root",
})
export class DepositService {
  constructor(private _httpClient: HttpClient) {}

  getDataUserDepositInfo() {
    return this._httpClient.get<UserDepositInfoModel>(
      `${getUserDepositInfoBaseUrl}`
    );
  }

  getDataWithdrawBankList() {
    return this._httpClient.get<UserWithdrawBankModel>(
      `${getWithdrawBankListBaseUrl}`
    );
  }

  getDataDecimalDepositRequest(dataSet: any) {
    return this._httpClient.get<any>(
      `${getDecimalDepositRequestBaseUrl}systemBankId=${dataSet.systemBankId}&amount=${dataSet.amount}`,
      {}
    );
  }

  getDataLastestUserRequestDecimalDepositTimeout(systemBankId: number) {
    return this._httpClient.get<any>(
      `${getLastestUserRequestDecimalDepositTimeoutBaseUrl}systembankId=${systemBankId}`
    );
  }

  postDataUploadSlipDecimalDepositTimeout(dataSet: any) {
    return this._httpClient.post<UserDepositUploadSlipModel>(
      `${postUploadSlipDecimalDepositTimeoutBaseUrl}`,
      dataSet
    );
  }

  getDataLastestUserRequestDecimalDeposit() {
    return this._httpClient.get<any>(
      `${getLastestUserRequestDecimalDepositBaseUrl}`
    );
  }

  getDataUserInfomation() {
    return this._httpClient.get<UserInfomationModel>(
      `${getUserInfomationBaseUrl}`
    );
    // return this._httpClient.get<any>(`${getUserInfomationBaseUrl}`);
  }

  postDataUploadQrDeposit(dataSet: any) {
    return this._httpClient.post<UserDepositUploadQrDepositModel>(
      `${postDataUploadSlipQrDepositBaseUrl}`,
      dataSet
    );
  }
  postRequestDepositQrPaymentGateway(dataSet: any) {
    return this._httpClient.post<any>(
      `${postDepositRequestPgwPaymentUrl}`,
      dataSet
    );
  }
}
