<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing">
    <div class="container">
      <div class="-title">
        <h2>กงล้อเสี่ยงโชค</h2>
        <!-- <p>คุณมีสิทธิ์{{this.websiteName}}</p> -->
        <br/>
        
      </div>

      <div class="wheelContainer">
          <svg class="wheelSVG" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" text-rendering="optimizeSpeed" preserveAspectRatio="xMidYMin meet">
              <defs>
                  <filter id="shadow" x="-100%" y="-100%" width="550%" height="550%">
                      <feOffset in="SourceAlpha" dx="0" dy="0" result="offsetOut"></feOffset>
                      <feGaussianBlur stdDeviation="9" in="offsetOut" result="drop" />
                      <feColorMatrix in="drop" result="color-out" type="matrix" values="0 0 0 0   0
                      0 0 0 0   0 
                      0 0 0 0   0 
                      0 0 0 .3 0" />
                      <feBlend in="SourceGraphic" in2="color-out" mode="normal" />
                  </filter>
              </defs>
              <g class="mainContainer">
                  <g class="wheel">
                  </g>
              </g>
              <g class="centerCircle" />
              <g class="wheelOutline" />
              <g class="pegContainer" opacity="1">
                  <path class="peg" fill="#E10210" d="M22.139,0C5.623,0-1.523,15.572,0.269,27.037c3.392,21.707,21.87,42.232,21.87,42.232 s18.478-20.525,21.87-42.232C45.801,15.572,38.623,0,22.139,0z" />
              </g>
              <g class="valueContainer" />
              <g class="centerCircleImageContainer" />
          </svg>
          <div class="toast">
              <p></p>
          </div>
          <p><a routerLink="/luckywheel-result"><button>กดเพื่อหมุน!</button></a></p>
          <p class="spinBtn"></p>
      </div>
        
      
    </div>
  </div>
  
</main> <!-- end content this page -->


