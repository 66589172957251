import { Injectable } from '@angular/core';
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";
import {HttpClient} from "@angular/common/http";

const GetWebInfoBaseUrl = ApiEndpointConstant.GetWebInfo;

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  isUpload!: boolean;

  constructor(
      private _httpClient: HttpClient
  ) { }

  getWebInfo() {
    return this._httpClient.get<any>(GetWebInfoBaseUrl);
  }

  
  updateGetWebInfo() {
    this.getWebInfo().subscribe({
      next: data => {
        let customSetting = JSON.parse(data.customSetting);
        this.isUpload = customSetting?.isUpload;

        document.body.className = 'loaded';
      },
      error: e => {
        console.error(e);
      }
    })
  }
}
