import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { miniGameService } from '../../shared/services/minigame.service';
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";
import { environment } from "src/environments/environment";

declare const Spin2WinWheel: any;
declare function spinWheel(): any;
const getPromotionGameResultUrl = ApiEndpointConstant.getPromotionGameResult;

@Injectable({
  providedIn: 'root'
})
export class LuckywheelService {
  config : any ;
  jsonData: any = 
  {
      "colorArray":[environment.luckywheel_colorArray],
      "svgWidth": 1024,
      "svgHeight": 1024,
      "wheelStrokeColor": environment.luckywheel_wheelStrokeColor,
      "wheelStrokeWidth": 20,
      "wheelSize": 600,
      "wheelTextOffsetY": 80,
      "wheelTextColor": "#EDEDED",  
      "wheelTextSize": "2.3em",
      "wheelImageOffsetY": -20,
      "wheelImageSize": 271,
      "centerCircleSize": 191,
      "centerCircleStrokeColor": "#F1DC15",
      "centerCircleStrokeWidth": 12,
      "centerCircleFillColor": "#000",
      "centerCircleImageUrl": environment.luckywheel_centerCircleImageUrl,
      "centerCircleImageWidth":250,
      "centerCircleImageHeight":250,  
      "segmentStrokeColor": environment.luckywheel_segmentStrokeColor,
      "segmentStrokeWidth": 4,
      "centerX": 512,
      "centerY": 512,  
      "hasShadows": false,
      "numSpins": -1,
      "minSpinDuration":2,
      "maxSpinDuration":5,
      "gameOverText":'ท่านสามารถรับโปรโมชั่นนี้ใน <br> "โปรโมชั่นที่สามารถรับได้" </br> <div class="-box-btn"><a href="/" class="-btn-02"><span>กลับสู่กระเป๋า</span></a></div>',
      "invalidSpinText":"การหมุนผิดพลาด. กรุณาหมุนใหม่อีกครั้ง",
      "introText":"ข้อมูลผิดพลาด",
      "hasSound":true,
      "clickToSpin":true,
      "spinDirection": "ccw",
      "disabledText":"คุณไม่สามารถหมุนได้ขณะนี้" 
    };
  constructor(
    private _httpClient: HttpClient,
    private _minigameService : miniGameService,
    ) { }

  private async getWheelData(promotionId : any): Promise<any> {
    this._minigameService.getPromotionLuckyBonus(promotionId)
    .subscribe({
      next: (data) => {
        this.config = data;
        this.jsonData.segmentValuesArray = this.config.segmentValues.map( (x: { value: any; resultText: any; imageSrc: any;}) => {
          return {
              "type": "image",
              "value": x.imageSrc,
              "resultText": x.resultText,
              "userData": {
                  "value": x.value
              }
          };
        });
        let spinDestination : string[] = [this.config.resultDestinationIndex + 1 ];
        this.jsonData.spinDestinationArray = spinDestination;
        this.jsonData.gameId = this.config.gameId;

        var mySpinBtn = document.querySelector('.spinBtn');
        var myWheel = new Spin2WinWheel();
        myWheel.init({data:this.jsonData, onResult:this.myResult, onGameEnd:this.myGameEnd, onError:this.myError, spinTrigger: mySpinBtn});
      },
      error: (e) => {
        console.error(e)
      }
    });
  }

  private async getWheelDataResult(promotionId : any): Promise<any> {
    this._minigameService.userPromotionLuckyBonus(promotionId)
    .subscribe({
      next: (data) => {
        this.config = data;
        this.jsonData.segmentValuesArray = this.config.segmentValues.map( (x: { value: any; resultText: any; imageSrc: any;}) => {
          return {
              "type": "image",
              "value": x.imageSrc,
              "resultText": x.resultText,
              "userData": {
                  "value": x.value
              }
          };
        });
        let spinDestination : string[] = [this.config.resultDestinationIndex + 1 ];
        this.jsonData.spinDestinationArray = spinDestination;
        this.jsonData.gameId = this.config.gameId;

        var mySpinBtn = document.querySelector('.spinBtn');
        var myWheel = new Spin2WinWheel();
        myWheel.init({data:this.jsonData, onResult:this.myResult, onGameEnd:this.myGameEnd, onError:this.myError, spinTrigger: mySpinBtn});

        spinWheel();

      },
      error: (e) => {
        console.error(e)
      }
    });
  }

  async initwheelpreview() {
    let miniGamePromotionId = localStorage.getItem('appMiniGamePromotionId');
    await this.getWheelData(miniGamePromotionId);
  }

  async initwheel() {
    let miniGamePromotionId = localStorage.getItem('appMiniGamePromotionId');
    await this.getWheelDataResult(miniGamePromotionId);
  }


  myResult(e : any) {
    //e is the result object
    let _userKey = localStorage.getItem("appUser");

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", getPromotionGameResultUrl + '/'+ e.gameId, false ); // false for synchronous request
    xmlHttp.setRequestHeader("Authorization", `Bearer ` + _userKey)
    xmlHttp.send( null );
    var result = JSON.parse(xmlHttp.responseText);

    if(result.resultValue == e.userData.value){

    }else{
      e.target.restart();
    }  
  /*  if(e.spinCount == 3){
      show the game progress when the spinCount is 3

      restart it if you like
      e.target.restart();
    }*/  
  
  }

  //your own function to capture any errors
  myError(e  : any) {
    //e is error object


  }

  myGameEnd(e  : any) {
    //e is gameResultsArray
    //reset the wheel at the end of the game after 5 seconds
    /* TweenMax.delayedCall(5, function(){
      
      Spin2WinWheel.reset();

    })*/
  }
}
