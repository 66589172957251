import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { data } from 'autoprefixer';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';

const getAffliateInfoBaseUrl = ApiEndpointConstant.GetAffliateInfo;
const getAffliateRegisterTypeBaseUrl = ApiEndpointConstant.GetAffliateRegisterType;
const setRegisterUserAffliateBaseUrl = ApiEndpointConstant.SetRegisterUserAffliate;
const setWithdrawAffliateCommissionBaseUrl = ApiEndpointConstant.SetWithdrawAffliateCommission;
const getAffliateOverviewInfoBaseUrl = ApiEndpointConstant.GetAffliateOverviewInfo;
const getAffiliateShortenLinkUrl = ApiEndpointConstant.GetAffiliateShortenLink;

@Injectable({
  providedIn: 'root'
})
export class AffliateService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  getDataAffliateInfo() {
    return this._httpClient.get<any>(`${getAffliateInfoBaseUrl}`);
  } 
  
  getDataAffliateRegisterType() {
    return this._httpClient.get<any>(`${getAffliateRegisterTypeBaseUrl}`);
  } 
  
  setRegisterUserAffliate(dataSet: any) {
    return this._httpClient.post<any>(`${setRegisterUserAffliateBaseUrl}`, dataSet);
  } 

  setDataWithdrawAffliateCommission() {
    return this._httpClient.post<any>(`${setWithdrawAffliateCommissionBaseUrl}`, {});
  }

  getDataAffliateOverviewInfo(year: string, month: string) {
    return this._httpClient.get<any>(`${getAffliateOverviewInfoBaseUrl}year=${year}&month=${month}`);
  } 

  getShortenUrl(url: string, tags: string[]) {
    return this._httpClient.post<any>(`${getAffiliateShortenLinkUrl}`, {
      url: url,
      title: ApiEndpointConstant.ShortedLinkTitle,
      tags: tags
    }, {
      headers: {
        'x-api-key': ApiEndpointConstant.ShortenLinkApiKey
      }
    });
  }
}
