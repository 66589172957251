<div class="-content-data w-sm mx-auto">
  <div class="-box-content -content-01 active">
    <div class="-list-history">
      <app-frame-bg
        [type]="'history-player'"
        *ngFor="let item of dataHistoryList"
      >
        <!-- <div class="-item -card">
                    <header>
                        <p>เวลา {{item.requestTime}}</p>
                        <div class="-group-status">
                            <p class="Success" *ngIf="item.betStatus=='Success'">
                                <span>
                                    <img appImgLazyLoad src="assets/img/icons/correct.svg" alt="">
                                    สำเร็จ
                                </span>
                            </p>
                            <p class="Fail" *ngIf="item.betStatus!='Success'">
                                <span>
                                    <img appImgLazyLoad src="assets/img/icons/incorrect.svg" alt="">
                                    ไม่สำเร็จ
                                </span>
                            </p>
                        </div>
                    </header>
                    <div class="mt-3">
                        <p class="detail"><strong>ค่ายเกม :</strong></p>
                        <p class="data">{{item.brand}}</p>
                    </div>
                    <div>
                        <p class="detail"><strong>ชื่อเกม :</strong></p>
                        <p class="data">{{item.gameName}}</p>
                    </div>
                    <div>
                        <p class="detail"><strong>รอบที่ :</strong></p>
                        <p class="data">{{item.round}}</p>
                    </div>
                    <div class="-box-data">
                        <div class="-col">
                            <p>ยอดเล่น</p>
                            <p><strong>{{item.betAmount | number}}</strong></p>
                        </div>
                        <div class="-col">
                            <p>แพ้/ชนะ</p>
                            <p [ngStyle]="{'color': item.winlose >= 0 ? '#27e389' : 'red' }"><strong>{{item.winlose | number}}</strong></p>
                        </div>            
                    </div>
                </div> -->
        <div class="relative w-full h-full">
          <div class="absolute top-[-0.25rem] left-[-2rem]">
            <!-- <img
              src="assets/img/brand/{{ item.gameName }}.png"
              class="w-[50px]"
            /> -->
          </div>

          <div class="absolute w-[20px] top-[.5rem] right-[.5rem]">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti-success.webp"
              *ngIf="item.betStatus === 'Success'"
            />
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti-success.webp"
              *ngIf="item.betStatus === 'Fail'"
            />
          </div>

          <div class="absolute top-[2.5rem] right-[.5rem]">
            <span *ngIf="item.betStatus === 'Success'" class="text-[#35CEA2]"
              >เดิมพันสำเร็จ</span
            >
            <span *ngIf="item.betStatus === 'Fail'" class="text-[#FF8074]"
              >ยกเลิกการเดิมพัน</span
            >
          </div>

          <div class="pt-2 pl-6">
            <p class="text-xl text-white">
              {{ item.brand }}
            </p>
          </div>

          <div class="mt-[.5rem] ml-[1rem] space-x-3">
            <span class="text-gray-400">เกมส์</span>
            <span>{{ item.gameName | uppercase }}</span>
          </div>

          <div class="mt-[1rem] mx-[.75rem] space-x-3 flex justify-between">
            <div class="flex-1">
              <div class="space-x-3">
                <span class="text-gray-400">รอบที่</span>
                <span>{{ item.round }}</span>
              </div>
              <div class="space-x-3">
                <span class="text-gray-400">วันที่</span>
                <span style="font-size: 11px">{{
                  item.requestTime | date : "dd/MM/yyyy : HH:mm:ss"
                }}</span>
              </div>
            </div>
            <div class="flex-1">
              <div class="space-x-3">
                <span class="text-gray-400">ยอดเล่น</span>
                <span>{{ item.betAmount | number : "1.2-2" }}</span>
              </div>
              <div class="space-x-3">
                <span class="text-gray-400">แพ้ชนะ</span>
                <span
                  [ngStyle]="{ color: item.winlose >= 0 ? '#35CEA2' : '#fff' }"
                  ><span *ngIf="item.winlose >= 0">+</span
                  >{{ item.winlose | number : "1.2-2" }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </app-frame-bg>
    </div>
  </div>
</div>
<div class="flex justify-center" *ngIf="this.totalPage > 1">
  <nav aria-label="Page navigation example">
    <ul class="flex list-style-none">
      <li class="page-item is-link">
        <a
          class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          (click)="paginateEvent(0)"
          ><</a
        >
      </li>

      <li
        *ngFor="let page of totalPageList; let i = index"
        class="page-item is-link"
      >
        <a
          [ngClass]="
            page == currentPage
              ? 'border-0 bg-gray-200 page-link relative block py-1.5\n' +
                '               px-3 border-0 bg-transparent\n' +
                '               outline-none transition-all\n' +
                '               duration-300 rounded-full text-gray-800\n' +
                '               hover:text-gray-800 hover:bg-gray-200\n' +
                '               focus:shadow-none'
              : 'page-link relative block py-1.5\n' +
                '               px-3 border-0 bg-transparent\n' +
                '               outline-none transition-all\n' +
                '               duration-300 rounded-full text-gray-800\n' +
                '               hover:text-gray-800 hover:bg-gray-200\n' +
                '               focus:shadow-none'
          "
          (click)="paginateEvent(page + 1)"
          >{{ page + 1 }}</a
        >
      </li>

      <li class="page-item is-link">
        <a
          class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          (click)="paginateEvent(this.totalPage)"
          >></a
        >
      </li>
    </ul>
  </nav>
</div>
