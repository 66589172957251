<!-- Set Header -->
<div class="-header">
    <a routerLink="register-step03" class="-btn-back">
        <img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="">
    </a>
    
    <h2 class="-text-header">สมัครสมาชิก</h2>

    <a routerLink="/" class="-btn-close">
        <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
    </a>
</div>

<app-main-menu></app-main-menu>

<!-- start content this page -->
<main id="main-content">
    <div class="-content-register -step-04">
        <div class="container">
            <div class="-section-top">
                <div class="-box-step">
                    <div class="-group-step">
                        <div class="-col -done">
                            <div class="-icon">
                                <img appImgLazyLoad src="assets/img/theme/register-step-01.svg" alt="">
                                <img appImgLazyLoad src="assets/img/theme/register-step-01-active.svg" alt="">
                            </div>
                            <p>ยืนยันตัวตน</p>
                        </div>
                        <div class="-col -done">
                            <div class="-icon">
                                <img appImgLazyLoad src="assets/img/theme/register-step-02.svg" alt="">
                                <img appImgLazyLoad src="assets/img/theme/register-step-02-active.svg" alt="">
                            </div>
                            <p>ข้อมูลผู้ใช้</p>
                        </div>
                        <div class="-col -done">
                            <div class="-icon">
                                <img appImgLazyLoad src="assets/img/theme/register-step-03.svg" alt="">
                                <img appImgLazyLoad src="assets/img/theme/register-step-03-active.svg" alt="">
                            </div>
                            <p>บัญชีธนาคาร</p>
                        </div>
                        <div class="-col -active">
                            <div class="-icon">
                                <img appImgLazyLoad src="assets/img/theme/register-step-04.svg" alt="">
                                <img appImgLazyLoad src="assets/img/theme/register-step-04-active.svg" alt="">
                            </div>
                            <p>ยืนยันข้อมูล</p>
                        </div>
                    </div>

                    <div class="-progress">
                        <div class="-bar"></div>
                    </div>
                </div>
                
                <h2 class="-title">ยืนยันข้อมูล</h2>
                <p>กรุณาตรวจสอบข้อมูลของคุณให้ถูกต้อง
                <br>ก่อนการยืนยันการสมัคร</p>

                <div class="-form-style -form-summary mt-4">
                    <form>
                        <div class="-row">
                            <div class="-form-input">
                            <p>ชื่อยูสเซอร์</p>
                            <p>จ๋า ชรินทร์ทิพย์</p>
                            </div>
                        </div>
                        <div class="-row">
                            <div class="-form-input">
                            <p>หมายเลขโทรศัพท์</p>
                            <p>0985657687</p>
                            </div>
                        </div>
                        <div class="-row">
                            <div class="-form-input">
                            <p>ชื่อบัญชี</p>
                            <p>ชรินทร์ทิพย์ บำรุงศักดิ์</p>
                            </div>
                        </div>
                        <div class="-row">
                            <div class="-form-input">
                            <p>ธนาคาร</p>
                            <p>กสิกรไทย</p>
                            </div>
                        </div>
                        <div class="-row">
                            <div class="-form-input">
                            <p>หมายเลขบัญชี</p>
                            <p>644-209878-4</p>
                            </div>
                        </div>
                        <div class="-row">
                            <div class="-form-input">
                            <p>รู้จักเราจาก</p>
                            <p>เพจมวยหูหนุ่มบางกระดี่</p>
                            </div>
                        </div>
                        <div class="-row mt-5">
                            <!-- <button type="submit" class="-btn-01"><span>ยืนยันข้อมูล</span></button> -->
                            <!-- Mock Up Next Step -->
                            <a routerLink="/registerSuccess" class="-btn-01"><span>ยืนยันข้อมูล</span></a>
                            <!-- End Mock Up Next Step -->
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</main> <!-- end content this page -->
