import { Component, OnInit } from '@angular/core';
import { WalletBankService } from '../wallet/services/wallet-bank.service';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  bankList: any;
  withdrawBankList: any[] | undefined;
  userInfo: any;
  displayName: string = "";
  memberGroupImage: string = "";
  memberRole: string = "";
  telephoneNumber: any;
  username: string = "";

  constructor(
    private _profileService: ProfileService,
    private _walletBankService: WalletBankService,
  ) { }

  ngOnInit(): void {
    this.bankList = JSON.parse(localStorage.getItem('bankList') || '{}');
    this.userInfo = JSON.parse(localStorage.getItem('appUserInfo') || '{}');

    this.getMemberWithdrawBankList();
    this.getUrlUserProfileImage();
  }

  getMemberWithdrawBankList() {
    this._profileService.getDataWithdrawBankList()
      .subscribe({
        next: (data) => {
          this.setDataMemberWithdrawBankList(data.collection);
        },
        error: (e) => {
          console.error(e)
        }
      })
  }

  setDataMemberWithdrawBankList(sourceData: any) {
    if (sourceData) {
      let packData: any[] = [];
      sourceData.forEach((e: any) => {
        let bankId = Number(e.registBankId) - 1;
        e.bankAccountName = this.bankList[bankId].name;
        let bCode = this.bankList[bankId].code
        e.bankAccountCode = bCode.toUpperCase();
        e.registBankLogoUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${e.bankAccountCode}.png`;

        // bankAccountStatusClass
        if (e.isApproved == true) {
          e.bankAccountStatusClass = "use";
          e.bankAccountStatusName = "ตรวจสอบบัญชีสำเร็จแล้ว";
        } else if (e.isApproved == false && e.isRejected == false) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = 'กรุณารอตรวจสอบบัญชีสักครู่ค่ะ';
        } else if (e.isRejected == true) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "บัญชีที่ไม่ได้ใช้งาน";
        } else {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = 'ไม่ทราบ ติดต่อผู้ดูแลระบบ';
        }

        packData.push(e);
      });
      this.withdrawBankList = packData;
      this.telephoneNumber = packData[0].phoneNumber;
      this.displayName = this.userInfo.name;
      this.memberRole = this.userInfo.role;
      this.telephoneNumber = this.userInfo.phoneNumber ? this.userInfo.phoneNumber : this.telephoneNumber;
      this.username = this.userInfo.preferred_username;

    }
  }

  getUrlUserProfileImage() {
    this._walletBankService.getUrlUserProfileImage()
      .subscribe({
        next: data => {
          this.memberGroupImage = data.value ? data.value : 'assets/img/icons/img-profile.png';
        },
        error: e => {
          console.error(e);
        }
      })
  }

}
