<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<!-- End Set Header -->
		
<!-- start content this page -->
<main id="main-content">
	<div class="-content-register">
		<div class="container">
			<div class="-section-top">
				<img appImgLazyLoad src="assets/img/theme/withdraw-success.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
				<h2 class="-title">ถอนเงินสำเร็จ</h2>
				<p>รายการถอนเงินของคุณสำเร็จแล้ว ระบบกำลังทำการโอนเงินให้ในไม่ช้า ยอดเงินจะเข้าบัญชีของคุณภายใน 3 นาที</p>
				<a href="./" class="-btn-01 mt-8"><span>ตกลง</span></a>
			</div>
		</div>
	</div>
</main> 
<!-- end content this page -->