<app-frame-bg [type]="'popular-list'">
  <div class="relative -section-games-popular pt-[1.5rem] pb-[1rem] px-[1rem]">
    <app-theme-label [label]="'ค่ายเกมส์ยอดนิยม'"></app-theme-label>

    <div class="absolute right-[1rem] top-[2rem]">
      <a routerLink="/game/gameListing" class="!no-underline text-white">ดูทั้งหมด</a>
    </div>

    <splide
      class="mt-3 -list-game-category"
      [options]="{
        type: 'loop',
        gap: 5,
        autoplay: true,
        perPage: this.size === 'sm' ? 4 : 5,
        keyboard: false,
        pagination: false
      }"
    >
      <splide-slide *ngFor="let item of games">
        <div class="flex justify-center">
          <div class="rounded-lg shadow-lg max-w-sm">
            <a
              [routerLink]="item.targetLink"
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
            >
              <img
                class="rounded-lg"
                style="height: 80px; width: 80px; background-color: transparent"
                [src]="item.imageUrl"
                alt=""
              />
            </a>
          </div>
        </div>
      </splide-slide>
    </splide>
  </div>
</app-frame-bg>
