import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-bank-success',
  templateUrl: './add-bank-success.component.html',
  styleUrls: ['./add-bank-success.component.scss']
})
export class AddBankSuccessComponent implements OnInit {

  constructor(
    private _activateRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this._activateRoute.queryParams
    .subscribe(params => {
      let data: any = params;   
    });
  }

}
