import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';
import { UserInfomationModel } from 'src/app/models/user-infomation.model';
import { UserWithdrawBankModel } from 'src/app/models/user-withdraw-bank.model';
import { UserWithdrawInfoModel } from 'src/app/models/user-withdraw-info.model';


const getUserInfomationBaseUrl = ApiEndpointConstant.GetUserInfomation;
const getUserWithdrawInfoBaseUrl = ApiEndpointConstant.GetUserWithdrawInfo;
const getWithdrawBankListBaseUrl = ApiEndpointConstant.GetWithdrawBankList;
const postRequestToWithdrawBaseUrl = ApiEndpointConstant.PostRequestToWithdraw;
const getAllPromotionBaseUrl = ApiEndpointConstant.GetAllPromotion;
const getPromotionDisplayCategoryBaseUrl = ApiEndpointConstant.GetPromotionDisplayCategorie;
const deleteUserPromotionBaseUrl = ApiEndpointConstant.DeleteUserPromotion;
const getUserBalanceBaseUrl = ApiEndpointConstant.GetUserBalance;
const getAllProductsBaseUrl = ApiEndpointConstant.GetAllProducts;
const getUserAvailablePromotionBaseUrl = ApiEndpointConstant.GetUserAvailablePromotion;
const getUserProfileImageBaseUrl = ApiEndpointConstant.GetUserProfileImage;

@Injectable({
  providedIn: 'root'
})

export class WalletBankService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getDataUserInfomation() {
    return this._httpClient.get<UserInfomationModel>(`${getUserInfomationBaseUrl}`);
  } 
  
  getDataUserWithdrawInfo() {
    return this._httpClient.get<UserWithdrawInfoModel>(`${getUserWithdrawInfoBaseUrl}`);
  } 

  getDataWithdrawBankList() {
    return this._httpClient.get<UserWithdrawBankModel>(`${getWithdrawBankListBaseUrl}`);
  } 

  postDataRequestToWithdraw(dataSet: any) {
    return this._httpClient.post<any>(`${postRequestToWithdrawBaseUrl}`, dataSet);
  } 

  getDataAllPromotion() {
    return this._httpClient.get<any>(`${getAllPromotionBaseUrl}`);
  } 

  getDataPromotionDisplayCategory() {
    return this._httpClient.get<any>(`${getPromotionDisplayCategoryBaseUrl}`);
  } 

  getDataUserBalance() {
    return this._httpClient.get<any>(`${getUserBalanceBaseUrl}`);
  } 

  deleteUserPromotion() {
    return this._httpClient.delete<any>(`${deleteUserPromotionBaseUrl}`);
  }

  getAllProducts() {
    return this._httpClient.get<any>(`${getAllProductsBaseUrl}`);
  }
  
  getDataUserAvailablePromotion() {
    return this._httpClient.get<any>(`${getUserAvailablePromotionBaseUrl}`);
  }

  getUrlUserProfileImage() {
    return this._httpClient.get<any>(`${getUserProfileImageBaseUrl}`);
  }



}
