<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
<main id="main-content" class="min-h-screen">
  <div class="-content-playing -game-play">
    <div class="container">
      <div class="-box-title">
        <h1>เปิดตู้เซฟลุ้นรางวัล</h1>
        <p class="text-black">เลือกตู้เซฟที่คุณต้องการเปิด<br>เพื่อรับของรางวัลพิเศษจาก BERLIN777</p>
      </div>
      
      <div class="-list-item gap-2 mt-7">
        <div class="-item">
          <a href="/safe?type=bb">
            <img appImgLazyLoad src="assets/img/game-safety/bb-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/bb-open.png" alt="safety">
          </a>
        </div>
        <div class="-item">
          <a href="/safe?type=rb">
            <img appImgLazyLoad src="assets/img/game-safety/rb-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/rb-open.png" alt="safety">
          </a>
        </div>
        <div class="-item">
          <a href="/safe?type=br">
            <img appImgLazyLoad src="assets/img/game-safety/br-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/br-open.png" alt="safety">
          </a>
        </div>
        <div class="-item">
          <a href="/safe?type=rr">
            <img appImgLazyLoad src="assets/img/game-safety/rr-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/rr-open.png" alt="safety">
          </a>
        </div>
      </div>
    </div>
  </div>
</main> <!-- end content this page -->


