<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<app-snackbar-running-message></app-snackbar-running-message>

<!-- start content this page -->
<app-register-affliate
  *ngIf="!this.isUserAffliate && !this.isAffliateApproved"
></app-register-affliate>

<!--  *ngIf="this.isUserAffliate" -->
<!-- <main id="main-content">
  <div class="-content-playing">
    <div class="container">
      <div class="-box-affiliates">
        <div class="-inner">
          <p class="text-xl font-normal">ยอดเงินทั้งหมด</p>
          <a
            class="-btn-refresh btn-link"
            (click)="onClickRefresh()"
            style="margin-top: -6px; margin-right: -6px"
          >
            <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt="" />
          </a>
          <p class="my-2">
            <strong>฿ {{ this.credit | number }}</strong>
          </p>
          <a
            (click)="onClickWithdraw()"
            class="-btn-01 mt-4 btn-link"
            *ngIf="this.credit > 0"
            ><span>รับผลตอบแทน</span></a
          >
        </div>
      </div>

      <div class="-list-web">
        <div class="-item">
          <div class="-text">
            <p>เว็บลงทะเบียนสมาชิก</p>
            <a href="{{ this.registerAffliateLink }}" target="_blank">{{
              this.registerAffliateLink
            }}</a>
          </div>
          <a
            (click)="copyText(this.registerAffliateLink)"
            class="-btn-copy btn-link"
          >
            <img appImgLazyLoad src="assets/img/icons/copy.svg" alt="" />
          </a>
        </div>
      </div>

      <div class="-box-summary-member">
        <header>
          <h3>สรุปภาพรวมสมาชิก</h3>

          <div class="-form-style">
            <form [formGroup]="this.affliateFormGroup">
              <select
                class="-form-input btn-link"
                (change)="onChangeMonthYear($event)"
                formControlName="selectMonthForm"
              >
                <option *ngFor="let item of monthList; index as i" [value]="i">
                  {{ item.nameTh }} {{ item.year + 543 }}
                </option>
              </select>
            </form>
          </div>
        </header>

        <div class="-box-data">
          <div class="-row">
            <p>สมาชิกทั้งหมด</p>
            <p>
              <strong>{{ this.totalMembers | number }}</strong>
            </p>
          </div>
          <div class="-row">
            <p>จำนวนคลิก</p>
            <p>
              <strong>{{ this.clickCount | number }}</strong>
            </p>
          </div>
          <div class="-row">
            <p>สมาชิกใหม่</p>
            <p>
              <strong>{{ this.newMembers | number }}</strong>
            </p>
          </div>
          <div class="-row">
            <p>ฝากครั้งแรก</p>
            <p>
              <strong>{{ this.newDeposit | number }}</strong>
            </p>
          </div>
          <div class="-row">
            <p>สมาชิกที่เดิมพัน</p>
            <p>
              <strong>{{ this.totalPlayer | number }}</strong>
            </p>
          </div>
        </div>
      </div>

      <div class="-group-data-money">
        <div class="-item">
          <div class="-icon">
            <img appImgLazyLoad src="assets/img/theme/return.svg" alt="" />
          </div>
          <div class="-text">
            <p>ผลตอบแทน <br />ของคุณ</p>
            <p class="mt-1">
              <strong>฿ {{ this.totalBenefit | number }}</strong>
            </p>
          </div>
        </div>
        <div class="-item">
          <div class="-icon">
            <img appImgLazyLoad src="assets/img/theme/peak.svg" alt="" />
          </div>
          <div class="-text">
            <p>ยอดได้เสีย <br />รวม</p>
            <p class="mt-1">
              <strong>฿ {{ this.totalCompanyWinlose | number }}</strong>
            </p>
          </div>
        </div>
        <div class="-item">
          <div class="-icon">
            <img appImgLazyLoad src="assets/img/theme/turn-over.svg" alt="" />
          </div>
          <div class="-text">
            <p>เทิร์นโอเวอร์ <br />รวม</p>
            <p class="mt-1">
              <strong>฿ {{ this.totalTurnOver | number }}</strong>
            </p>
          </div>
        </div>
      </div>

      <div class="-box-summary-data">
        <div class="-content-data">
          <div class="-box-content -content-01 active">
            <div class="-box-table">
              <h4>สรุปข้อมูลการฝากถอน</h4>
              <div class="-table-style">
                <div class="-scroll table-scoll">
                  <table>
                    <thead>
                      <tr>
                        <th>ยูเซอร์</th>
                        <th>จำนวนครั้งที่ฝากทั้งหมด</th>
                        <th>ยอดเงินที่ฝากทั้งหมด</th>
                        <th>จำนวนครั้งที่ถอนทั้งหมด</th>
                        <th>ยอดเงินที่ถอนทั้งหมด</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let item of affliateInfoSummaryMemberDepositWithdrawal
                        "
                      >
                        <td>{{ item.username }}</td>
                        <td>{{ item.totalDepositCount | number }}</td>
                        <td>{{ item.sumDeposit | number }}</td>
                        <td>{{ item.totalWithdrawCount | number }}</td>
                        <td>{{ item.sumWithdraw | number }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="-box-table">
              <h4>สรุปข้อมูลการเดิมพัน</h4>
              <div class="-table-style">
                <div class="-scroll table-scoll">
                  <table>
                    <thead>
                      <tr>
                        <th>ยูเซอร์</th>
                        <th>จำนวนครั้งที่เดิมพันทั้งหมด</th>
                        <th>ยอดได้/เสีย ทั้งหมด</th>
                        <th>ยอดทำเทิร์นโอเวอร์ทั้งหมด</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of affliateInfoSummaryMemberBets">
                        <td>{{ item.username }}</td>
                        <td>{{ item.totalBetCount | number }}</td>
                        <td>{{ item.sumTotalWinlose | number }}</td>
                        <td>{{ item.sumTurnover | number }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main> -->
<div class="box-frame-top">
  <div class="box-frame">
    <div class="tab-bar-ac">
      <a routerLink="/affiliates" style="text-decoration: none">
        <span class="act1" style="color: #eeaf4a">ภาพรวม</span>
      </a>
      <a routerLink="/referFriends" style="text-decoration: none">
        <span class="act2">วิธีแนะนำเพื่อน</span>
      </a>
    </div>
  </div>
</div>
<div class="mt-4 mb-[10rem] flex justify-center" *ngIf="this.isUserAffliate">
  <div class="md:w-md md:mx-4">
    <div
      class="w-full flex flex-col justify-center items-center md:flex-row md:flex-start md:space-x-4"
    >
      <div class="relative w-sm md:flex-none">
        <div class="pl-2">
          <app-theme-label [label]="'ข้อมูลพันธมิตร'"></app-theme-label>
        </div>
        <app-frame-bg [type]="'affiliates'">
          <div class="w-full p-5 absolute top-[4.7rem]">
            <app-frame-bg [type]="'withdraw-wallet'">
              <div class="pt-2 mx-4 flex justify-between items-center">
                <div class="w-[30px] flex-none">
                  <img
                    src="https://fs.cdnxn.com/lsm99king/king/img/icon-refresh.webp"
                    class="w-[20px] cursor-pointer"
                    (click)="onClickRefresh()"
                  />
                </div>
                <span class="text-black text-xl font-bold">
                  ฿ {{ this.credit | number : "1.2-2" }}
                </span>
              </div>
            </app-frame-bg>
            <div class="flex justify-between items-center">
              <a
                (click)="onClickWithdraw()"
                class="-btn-01 btn-link w-[40%] h-[1.5rem]"
                *ngIf="this.credit > 0"
                ><span>รับผลตอบแทน</span></a
              >
              <p class="w-full text-right text-sm py-1 text-gray-400">
                อัพเดทยอดล่าสุด {{ this.lastUpdate | date : "dd/MM HH:mm" }}
              </p>
            </div>
          </div>
          <div class="w-full p-5 absolute top-[12rem]">
            <div class="flex justify-between items-center space-x-1">
              <app-frame-bg [type]="'affiliates-link'">
                <div class="w-[170px] h-[30px] pt-1">
                  <a
                    href="{{
                      this.shortenAffliateLink || this.registerAffliateLink
                    }}"
                    target="_blank"
                  >
                    <p class="text-black truncate w-100">
                      {{
                        this.shortenAffliateLink || this.registerAffliateLink
                      }}
                    </p>
                  </a>
                </div>
              </app-frame-bg>
              <div
                class=""
                (click)="
                  copyText(
                    this.shortenAffliateLink || this.registerAffliateLink
                  )
                "
              >
                <img
                  appImgLazyLoad
                  src="https://fs.cdnxn.com/lsm99king/king/img/btn-copy.webp"
                />
              </div>
            </div>
          </div>
        </app-frame-bg>
        <div class="mt-4">
          <app-frame-bg [type]="'main'">
            <div class="p-2 flex justify-between items-center space-x-1">
              <app-theme-label [label]="'สรุปภาพรวมพันธมิตร'"></app-theme-label>
              <div class="-form-style h-[1.5rem]">
                <form [formGroup]="this.affliateFormGroup">
                  <app-theme-text-input>
                    <select
                      class="-form-input btn-link h-[1.5rem]"
                      (change)="onChangeMonthYear($event)"
                      formControlName="selectMonthForm"
                      style="
                        background-color: transparent !important;
                        background: url('https://fs.cdnxn.com/lsm99king/king/img/bg-input.webp');
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: center center;
                      "
                    >
                      <option
                        *ngFor="let item of monthList; index as i"
                        [value]="i"
                      >
                        {{ item.nameTh }} {{ item.year + 543 }}
                      </option>
                    </select>
                  </app-theme-text-input>
                </form>
              </div>
            </div>
            <div class="flex justify-between px-3 mt-1">
              <div class="-label space-y-3">
                <p>พันธมิตรทั้งหมด</p>
                <p>จำนวนคลิก</p>
                <p>พันธมิตรใหม่</p>
                <p>ฝากเงินครั้งแรก</p>
                <p>พันธมิตรที่เดิมพัน</p>
              </div>
              <div class="-value space-y-2">
                <p>
                  <span class="text-[#27FFB1] text-xl">{{
                    this.totalMembers | number
                  }}</span>
                  คน
                </p>
                <p>
                  <span class="text-[#27FFB1] text-xl">{{
                    this.clickCount | number
                  }}</span>
                  คลิก
                </p>
                <p>
                  <span class="text-[#27FFB1] text-xl"
                    >+{{ this.newMembers | number }}</span
                  >
                  คน
                </p>
                <p>
                  <span class="text-[#27FFB1] text-xl">{{
                    this.newDeposit | number
                  }}</span>
                  คน
                </p>
                <p>
                  <span class="text-[#27FFB1] text-xl"
                    >{{ this.totalPlayer | number }}
                  </span>
                  คน
                </p>
              </div>
            </div>
            <div class="px-3 mt-3">
              <p>ผลตอบแทนของคุณ</p>
              <app-frame-bg [type]="'withdraw-wallet'">
                <div class="pt-2 mx-4 flex justify-center items-center">
                  <span class="text-black text-xl font-bold">
                    ฿ {{ this.totalBenefit | number : "1.2-2" }}
                  </span>
                </div>
              </app-frame-bg>
            </div>
            <div class="flex justify-between px-3 mt-3">
              <div class="-label space-y-3">
                <p>ยอดได้เสียรวม</p>
                <p>เทิร์นโอเวอร์รวม</p>
              </div>
              <div class="-value space-y-1">
                <p>
                  <span class="text-[#27FFB1] text-xl"
                    >+{{ this.totalCompanyWinlose | number }}</span
                  >
                </p>
                <p>
                  <span class="text-[#27FFB1] text-xl"
                    >+{{ this.totalTurnOver | number }}</span
                  >
                </p>
              </div>
            </div>
          </app-frame-bg>
        </div>
      </div>
      <div class="relative w-sm xl:w-xl">
        <div class="mt-4">
          <app-frame-bg [type]="'main-resp'">
            <div class="p-2 flex justify-between items-center space-x-1">
              <app-theme-label
                [label]="'สรุปข้อมูลการฝาก - ถอน'"
              ></app-theme-label>
            </div>
            <div class="">
              <div class="-box-summary-data mt-0">
                <div class="-content-data mt-0">
                  <div class="-box-content -content-01 active">
                    <div class="-box-table">
                      <div class="-table-style">
                        <div class="-scroll table-scoll">
                          <table>
                            <thead>
                              <tr>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยูเซอร์
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  จำนวนฝาก
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยอดเงินฝาก
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  จำนวนถอน
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยอดเงินทอน
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let item of affliateInfoSummaryMemberDepositWithdrawal
                                "
                              >
                                <td>{{ item.username }}</td>
                                <td>{{ item.totalDepositCount | number }}</td>
                                <td>{{ item.sumDeposit | number }}</td>
                                <td>{{ item.totalWithdrawCount | number }}</td>
                                <td>{{ item.sumWithdraw | number }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-frame-bg>
        </div>

        <div class="mt-4">
          <app-frame-bg [type]="'main-resp'">
            <div class="p-2 flex justify-between items-center space-x-1">
              <app-theme-label
                [label]="'สรุปข้อมูลการเดิมพัน'"
              ></app-theme-label>
            </div>
            <div class="">
              <div class="-box-summary-data mt-0">
                <div class="-content-data mt-0">
                  <div class="-box-content -content-01 active">
                    <div class="-box-table">
                      <div class="-table-style">
                        <div class="-scroll table-scoll">
                          <table>
                            <thead>
                              <tr>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยูเซอร์
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  จำนวนเดิมพัน
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยอดได้เสีย
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยอดเทิร์นโอเวอร์
                                </th>
                                <th class="whitespace-nowrap bg-transparent">
                                  ยอดเงินทอน
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let item of affliateInfoSummaryMemberDepositWithdrawal
                                "
                              >
                                <td>{{ item.username }}</td>
                                <td>{{ item.totalBetCount | number }}</td>
                                <td>{{ item.sumTotalWinlose | number }}</td>
                                <td>{{ item.sumTurnover | number }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-frame-bg>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end content this page -->
