<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>
<!-- start content this page -->

<div class="mt-4">
  <app-frame-bg [type]="'change-password'">
    <main id="main-content">
      <div class="-content-register">
        <div class="container w-sm mt-[8rem]">
          <div class="-section-top">
            <!-- <h2 class="-title">เปลี่ยนรหัสผ่าน</h2>
            <p>
              ระบุรหัสผ่านเดิม พร้อมสร้างรหัสผ่านใหม่
              <br />และยืนยันรหัสผ่านใหม่อีกครั้ง
            </p> -->
            <div class="-form-style pt-[2rem] px-[3rem] md:pt-[4rem]">
              <form [formGroup]="changePasswordForm">
                <div class="-required">
                  <app-theme-text-input>
                    <input type="password" class="-form-input" formControlName="oldPassword"
                      placeholder="รหัสผ่านเดิม" /></app-theme-text-input>
                </div>
                <div class="mt-3 -required">
                  <app-theme-text-input>
                    <input type="password" class="-form-input" formControlName="newPassword"
                      placeholder="รหัสผ่านใหม่" />
                  </app-theme-text-input>
                </div>
                <div class="mt-3 -required">
                  <app-theme-text-input>
                    <input type="password" class="-form-input" formControlName="confirmPassword"
                      placeholder="รหัสผ่านใหม่อีกครั้ง" />
                  </app-theme-text-input>
                </div>
                <div class="-row">
                  <!-- <button type="submit" class="-btn-01"><span>ตกลง</span></button> -->
                  <!-- Mock Up Next Step -->
                  <app-theme-button>
                    <a (click)="onSubmit()" class="-btn-01 btn-link"><span>ยืนยัน</span></a>
                  </app-theme-button>
                  <!-- End Mock Up Next Step -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </app-frame-bg>
</div>
<!-- end content this page -->