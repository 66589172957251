import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
  url: SafeResourceUrl = '';

  constructor(
    private _urlRoute: ActivatedRoute,
    public _anitizer: DomSanitizer,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._urlRoute.queryParams
    .subscribe(params => {
      if (params['url']) {
        this.url = this._anitizer.bypassSecurityTrustResourceUrl(params['url']);;
      } 
      else {
        this._router.navigate(['wallet']);
      }
    });
    document.body.style.overflow = '';
  }
}
