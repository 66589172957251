import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterStep01Component } from './pages/register/register-step01/register-step01.component';
import { RegisterStep02Component } from './pages/register/register-step02/register-step02.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainMenuComponent } from './shared/components/main-menu/main-menu.component';
import { MenuBottomComponent } from './shared/components/menu-bottom/menu-bottom.component';
import { AddBankSuccessComponent } from './pages/add-bank-success/add-bank-success.component';
import { AddBankComponent } from './pages/add-bank/add-bank.component';
import { AffiliatesComponent } from './pages/affiliates/affiliates.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ChangeTelComponent } from './pages/change-tel/change-tel.component';
import { ChangeUsernameComponent } from './pages/change-username/change-username.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { GameComponent } from './pages/game/game.component';
import { GetPromotionComponent } from './pages/get-promotion/get-promotion.component';
import { HistoryComponent } from './pages/history/history.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { OtpForgotpasswordComponent } from './pages/otp-forgotpassword/otp-forgotpassword.component';
import { OtpComponent } from './pages/otp/otp.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RebateComponent } from './pages/rebate/rebate.component';
import { RegisterSuccessComponent } from './pages/register-success/register-success.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { WithdrawSuccessComponent } from './pages/withdraw-success/withdraw-success.component';
import { WithdrawComponent } from './pages/withdraw/withdraw.component';
import { RegisterStep03Component } from './pages/register/register-step03/register-step03.component';
import { RegisterStep04Component } from './pages/register/register-step04/register-step04.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'; // add this line
import { CachingInterceptor } from './core/interceptors/caching.interceptor';
import { AuthenInterceptor } from './core/interceptors/authen.interceptor';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { NotificationBarComponent } from './shared/components/notification-bar/notification-bar.component';
import { FormsModule } from '@angular/forms';
import { RegisterEasyComponent } from './pages/register/register-easy/register-easy.component';
import { NgxSplideModule } from 'ngx-splide';
import { CustomTimerPipe } from './shared/pipe/custom-timer.pipe';
import { DepositUploadSlipComponent } from './pages/deposit/deposit-upload-slip/deposit-upload-slip.component';
import { HistoryDepositWithdrawComponent } from './pages/history/history-deposit-withdraw/history-deposit-withdraw.component';
import { HistoryPlayerComponent } from './pages/history/history-player/history-player.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PromotionListComponent } from './pages/get-promotion/promotion-list/promotion-list.component';
import { SafeHtmlPipe } from './shared/pipe/safe-html.pipe';
import { ModalWithRichTextComponent } from './shared/components/modal-with-rich-text/modal-with-rich-text.component';
import { CasinoListComponent } from './pages/game/casino-list/casino-list.component';
import { GameListComponent } from './pages/game/game-list/game-list.component';
import { SportListComponent } from './pages/game/sport-list/sport-list.component';
import { LottoListComponent } from './pages/game/lotto-list/lotto-list.component';
import { PlayerComponent } from './pages/game/player/player.component';
import { RegisterAffliateComponent } from './pages/affiliates/register-affliate/register-affliate.component';
import { ModalListGamesComponent } from './shared/components/modal-list-games/modal-list-games.component';
import { ModalPromotionCompletedComponent } from './shared/components/modal-promotion-completed/modal-promotion-completed.component';
import { SnackbarRunningMessageComponent } from './shared/components/snackbar-running-message/snackbar-running-message.component';
import { ModalAlertMessageComponent } from './shared/components/modal-alert-message/modal-alert-message.component';
import { LoginMigrationComponent } from './pages/loginmigration/loginmigration.component';
import { RegistermigrationComponent } from './pages/register-migration/register-migration.component';
import { LuckywheelComponent } from './pages/luckywheel/luckywheel.component';
import { LuckywheelResultComponent } from './pages/luckywheel-result/luckywheel-result.component';
import { SafeComponent } from './pages/safe/safe.component';
import { SafeListComponent } from './pages/safe-list/safe-list.component';
import { SafeResultComponent } from './pages/safe-result/safe-result.component';
import { CardComponent } from './pages/card/card.component';
import { CardListComponent } from './pages/card-list/card-list.component';
import { ShowcasesComponent } from './dev/showcases/showcases.component';
import { FrameBgComponent } from './shared/components/frame-bg/frame-bg.component';
import { ThemeTextInputComponent } from './shared/components/theme-text-input/theme-text-input.component';
import { ThemeButtonComponent } from './shared/components/theme-button/theme-button.component';
import { ThemeWalletGroupComponent } from './shared/components/theme-wallet-group/theme-wallet-group.component';
import { ThemeProfileGroupComponent } from './shared/components/theme-profile-group/theme-profile-group.component';
import { ThemeLineComponent } from './shared/components/theme-line/theme-line.component';
import { ThemeLabelComponent } from './shared/components/theme-label/theme-label.component';
import { BankListComponent } from './shared/components/bank-list/bank-list.component';
import { GamePopularListComponent } from './shared/components/game-popular-list/game-popular-list.component';
import { RecommendPromotionsComponent } from './shared/components/recommend-promotions/recommend-promotions.component';
import { ContactBoxComponent } from './shared/components/contact-box/contact-box.component';
import { environment } from 'src/environments/environment.prod';
import { GroupGameFilterComponent } from './shared/components/group-game-filter/group-game-filter.component';
import { GroupPromotionsComponent } from './shared/components/group-promotions/group-promotions.component';
import { DemoComponent } from './pages/demo/demo.component';
import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { ImgLazyLoadModule } from './core/img-lazy-load/img-lazy-load.module';
import { ReferFriendsComponent } from './pages/refer-friends/refer-friends.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    RegisterComponent,
    RegisterStep01Component,
    RegisterStep02Component,
    MainMenuComponent,
    MenuBottomComponent,
    AddBankSuccessComponent,
    AddBankComponent,
    AffiliatesComponent,
    ChangePasswordComponent,
    ChangeTelComponent,
    ChangeUsernameComponent,
    DepositComponent,
    ForgotpasswordComponent,
    GameListComponent,
    GameComponent,
    GetPromotionComponent,
    HistoryComponent,
    NewPasswordComponent,
    OtpForgotpasswordComponent,
    OtpComponent,
    ProfileComponent,
    RebateComponent,
    RegisterSuccessComponent,
    WalletComponent,
    WithdrawSuccessComponent,
    WithdrawComponent,
    RegisterStep03Component,
    RegisterStep04Component,
    NotificationBarComponent,
    RegisterEasyComponent,
    CustomTimerPipe,
    DepositUploadSlipComponent,
    HistoryDepositWithdrawComponent,
    HistoryPlayerComponent,
    TermConditionComponent,
    PolicyComponent,
    ContactUsComponent,
    PromotionListComponent,
    SafeHtmlPipe,
    ModalWithRichTextComponent,
    CasinoListComponent,
    SportListComponent,
    LottoListComponent,
    RegisterAffliateComponent,
    ModalListGamesComponent,
    ModalPromotionCompletedComponent,
    SnackbarRunningMessageComponent,
    ModalAlertMessageComponent,
    LoginMigrationComponent,
    RegistermigrationComponent,
    LuckywheelComponent,
    LuckywheelResultComponent,
    SafeComponent,
    SafeListComponent,
    SafeResultComponent,
    CardComponent,
    CardListComponent,
    PlayerComponent,
    ShowcasesComponent,
    FrameBgComponent,
    ThemeTextInputComponent,
    ThemeButtonComponent,
    ThemeWalletGroupComponent,
    ThemeProfileGroupComponent,
    ThemeLineComponent,
    ThemeLabelComponent,
    BankListComponent,
    GamePopularListComponent,
    RecommendPromotionsComponent,
    ContactBoxComponent,
    GroupGameFilterComponent,
    GroupPromotionsComponent,
    ReferFriendsComponent,
    DemoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxSplideModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
    }),
    ToastContainerModule,
    DragDropModule,
    ImgLazyLoadModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
