import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LoginService} from "../../shared/services/login.service";

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  phoneNo!: string;
  formValid: boolean = true;

  constructor(
      private spinner: NgxSpinnerService,
      private toastr: ToastrService,
      private readonly _router: Router,
      private _formBuilder: FormBuilder,
      private _loginService: LoginService,
  ) { }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  ngOnInit(): void {
    this.initForgotPasswordForm();
  }

  initForgotPasswordForm() {
    this.forgotPasswordForm = this._formBuilder.group({
      phoneNoForm: ['', [Validators.required, Validators.minLength(10)]]
    })
  }

  onClickSubmit() {
    this.spinner.show();
    this.phoneNo = this.forgotPasswordForm.controls['phoneNoForm'].value;
    this.formValid = this.forgotPasswordForm.valid;
    if (this.formValid) {
      this._loginService.postResetPasswordByUserName(this.phoneNo)
          .subscribe({
            next: (data) => {
              this.spinner.hide();
              this.toastr.success('โปรดรอรับ OTP','สำเร็จ');
              this._router.navigate(['index']);
            },
            error: e => {
              this.spinner.hide();
              this.toastr.error('', 'เบอร์โทรศัพท์ไม่ถูกต้อง');
            }
          })
    }
  }

}
