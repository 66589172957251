import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-button',
  templateUrl: './theme-button.component.html',
  styleUrls: ['./theme-button.component.css'],
})
export class ThemeButtonComponent implements OnInit {
  @Input() type: 'primary' | 'secondary' | 'facebook' | 'line' = 'primary';
  constructor() {}

  ngOnInit(): void {}
}
