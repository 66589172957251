<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover">
<meta http-equiv="X-UA-Compatible" content="ie=edge">
<meta name="apple-mobile-web-app-capable" content="yes" />

<title>Berlin777</title>
<meta name="description" content="description_site">
<meta name="keywords" content="">

<!-- Meta Open Graph (FB) -->
<meta property="og:type" content="website">
<meta property="og:url" content="http://url.com">
<meta property="og:title" content="Berlin777">
<meta property="og:description" content="description_site">
<meta property="og:image" content="assets/img/share-img/og-1200x630.jpg">
<meta property="og:site_name" content="Berlin777">

<!-- Twitter Card -->
<meta name="twitter:card" content="summary_large_image">
<meta name="twitter:title" content="Berlin777">
<meta name="twitter:description" content="description_site">
<meta name="twitter:url" content="http://url.com">
<meta name="twitter:image:src" content="assets/img/share-img/og-630x630.jpg">

<link rel="icon" type="image/png" href="assets/img/share/favicon-192x192.png">
<link rel="apple-touch-icon" type="image/png" href="assets/img/share/favicon-180x180.png">

