import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AddBankService } from './service/add-bank.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss'],
})
export class AddBankComponent implements OnInit {
  bankList: any = [];
  addBankForm!: FormGroup;
  bankAccountNo!: string;
  memberBankAccountId!: number;
  bankId: number = 0;
  bankIdValidate: boolean = true;
  bankName: string = '';
  hideBankContainer: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private _addBankService: AddBankService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private readonly _router: Router
  ) {}

  ngOnInit(): void {
    this.bankList = JSON.parse(localStorage.getItem('bankList') || '{}');
    this.onInitForm();
  }

  onInitForm() {
    this.addBankForm = this.formBuilder.group({
      // selectBankForm: ['', [Validators.required]],
      bankAccountNoForm: [
        '',
        [Validators.required, Validators.pattern(/^-?(0|[0-9]\d*)?$/)],
      ],
    });
  }

  onSubmit() {
    if (this.addBankForm.status == 'INVALID') {
      this.toastr.clear();
      if (this.addBankForm.value.bankAccountNoForm == undefined) {
        this.toastr.error('กรุณากรอกเลขบัญชีธนาคารให้ถูกต้อง', 'ข้อมูลไม่ครบ');
      } else if (!this.bankId) {
        this.toastr.error('กรุณาเลือกธนาคาร', 'ข้อมูลไม่ครบ');
      }
      // else if (this.addBankForm.value.selectBankForm == "") {
      //   this.toastr.error('กรุณาเลือกธนาคาร', 'ข้อมูลไม่ครบ');
      // }
      else {
        this.toastr.error('กรุณากรอกข้อมูลให้ถูกต้อง', 'ข้อมูลไม่ถูกต้อง');
      }
    } else {
      this.spinner.show();

      let bankId: any;
      // bankId = this.addBankForm.value.selectBankForm;
      bankId = this.bankId;
      bankId = Number(bankId);

      let bankAccountNo: any;
      bankAccountNo = this.addBankForm.value.bankAccountNoForm;
      bankAccountNo = bankAccountNo.toString();

      let payload = {
        bankId: bankId,
        bankAccountNo: bankAccountNo,
      };

      this._addBankService.addMemberNewBankAccount(payload).subscribe({
        next: (data) => {
          this.spinner.hide();
          this._router.navigate(['/addBankSuccess'], {
            queryParams: {
              status: 'success',
            },
          });
          this.toastr.success('ทำรายการสำเร็จ', 'สำเร็จ');
        },
        error: (e) => {
          this.toastr.clear();
          console.error(e);
          let errorCode = e.error.code;
          switch (errorCode) {
            case '105':
              this.toastr.error(
                'เลขที่บัญชีนี้มีในระบบแล้ว กรุณาใช้เลขที่บัญชีใหม่ค่ะ',
                'ไม่สำเร็จ'
              );
              break;

            default:
              this.toastr.error(
                'ทำรายการไม่สำเร็จ กรุณาติดต่อแอดมินค่ะ',
                'ไม่สำเร็จ'
              );
              break;
          }
          this.spinner.hide();
          // this.toastr.error('ทำรายการไม่สำเร็จ', 'ไม่สำเร็จ');
        },
      });

      // this.spinner.hide();
    }
  }

  addMemberBankAccount(event: any) {
    this.memberBankAccountId = event;
  }

  checkBankAccountInvalid(event: any) {}

  onChooseBank(bankId: number) {
    this.bankId = bankId;
    switch (bankId) {
      case 1:
        this.bankName = 'กรุงเทพ';
        break;
      case 2:
        this.bankName = 'ไทยพาณิชย์';
        break;
      case 3:
        this.bankName = 'กสิกรไทย';
        break;
      case 4:
        this.bankName = 'กรุงไทย';
        break;
      case 5:
        this.bankName = 'กรุงศรีอยุธยา';
        break;
      case 6:
        this.bankName = 'เพื่อการเกษตรและสหกรณ์การเกษตร';
        break;
      case 7:
        this.bankName = 'ซีไอเอ็มบีไทย';
        break;
      case 8:
        this.bankName = 'ทิสโก้';
        break;
      case 9:
        this.bankName = 'อาคารสงเคราะห์';
        break;
      case 10:
        this.bankName = 'ออมสิน';
        break;
      case 11:
        this.bankName = 'ทหารไทยธนชาต';
        break;
      case 12:
        this.bankName = 'อิสลามแห่งประเทศไทย';
        break;
      case 13:
        this.bankName = 'เกียรตินาคินภัทร';
        break;
      case 14:
        this.bankName = 'แลนด์ แอนด์ เฮ้าส์';
        break;
      case 15:
        this.bankName = 'ยูโอบี';
        break;
      default:
        this.bankName = '';
        break;
    }

    this.hideBankContainer = !this.hideBankContainer;
  }
}
