import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-promotion-completed',
  templateUrl: './modal-promotion-completed.component.html',
  styleUrls: ['./modal-promotion-completed.component.css']
})
export class ModalPromotionCompletedComponent implements OnInit {
  @Input() rewardedBonusAmount: number = 0;
  @Output() isClickCloseModal = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickCloseModal() {
    this.isClickCloseModal.emit(true);
  }

}
