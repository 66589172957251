<div
  class="fixed top-0 w-full border-b-2 border-gray-300 z-20 inset-0 overflow-y-auto"
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom bg-theme rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-sm md:w-md sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="absolute top-0 right-0">
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
          class="w-[25px] cursor-pointer"
          (click)="onClickCloseModal()"
        />
      </div>

      <div class="">
        <!-- <div class="flex flex-col justify-center xl:flex-row"> -->
        <div class="mt-6 xl:ml-4 xl:w-[96%] text-center">
          <!-- xl:w-[60%] -->
          <img appImgLazyLoad src="{{ imgUrl }}" />
        </div>

        <div class="xl:w-[100%]">
          <!-- xl:w-[40%] -->
          <div class="bg-theme px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  กรุณาอ่านเงื่อนไข
                </h3>
                <div class="mt-2">
                  <p
                    class="text-sm leading-5 text-gray-500"
                    [innerHtml]="promotionContents | safeHtml"
                    style="font-size: 16px"
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="bg-theme px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center"
          >
            <span
              class="w-[200px] shadow-sm sm:ml-4 text-center cursor-pointer"
              (click)="onClickSubmit()"
            >
              <app-theme-button>
                <div class="md:pt-[.5rem] xl:pt-0">
                  <a type="button" class="-btn- text-white">
                    <span class="font-bold md:mb-0">ยอมรับเงื่อนไข</span>
                  </a>
                </div>
              </app-theme-button>
            </span>

            <span
              class="w-[200px] shadow-sm sm:mt-0 text-center cursor-pointer"
              (click)="onClickCloseModal()"
            >
              <app-theme-button
                ><!-- <app-theme-button [type]="'secondary'"> -->
                <div class="md:pt-[.5rem] xl:pt-0">
                  <a type="button" class="-btn- text-white"
                    ><!-- text-black -->
                    <span class="font-bold md:mb-0">ปิด</span>
                    <!-- text-black font-bold md:mb-0 -->
                  </a>
                </div>
              </app-theme-button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
