import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-group-game-filter',
  templateUrl: './group-game-filter.component.html',
  styleUrls: ['./group-game-filter.component.css'],
})
export class GroupGameFilterComponent implements OnInit {
  path: string = '';

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.path = this._router.url.split('/')[2];
  }
}
