
<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing">
    <div class="container">
      <div class="-content-profile">
        <div class="-box-profile">
          <div class="-inner -card">
            <div class="-img-profile">
              <div class="-box-img">
                <img appImgLazyLoad [src]="this.memberGroupImage" alt="" class="-img" style="min-height: 50px;">  
              </div>
            </div>
            <div class="-profile-name">
              <p><strong>{{this.displayName}}</strong></p>
              <p><small>สมาชิก {{this.memberRole}}</small></p>
            </div>
            <div class="-items">
              <div class="-item">
                <p>ชื่อยูสเซอร์</p>
                <p><strong>{{this.username}}</strong></p>    
              </div>
              <div class="-item">
                <p>รหัสผ่าน</p>
                <p class="relative top-[5px] text-2xl"><strong>*********</strong></p>
                <a routerLink="/changePassword" class="-btn-edit">
                  <img appImgLazyLoad src="assets/img/icons/edit.svg" alt="">
                </a>
              </div>
              <div class="-item">
                <p>หมายเลขโทรศัพท์</p>
                <p><strong>{{this.telephoneNumber}}</strong></p>            
              </div>
            </div>
          </div>
        </div>

        <div class="-content-bank">
          <div class="-inner">
            <div class="-your-account text-lg">
              <div class="-head">
                <h2>บัญชีธนาคาร</h2>
                <div>
                  <a routerLink="/addBank" class="-btn">
                    <img appImgLazyLoad src="assets/img/icons/plus.svg" alt="" class="mr-3">
                    <span>เพิ่มบัญชี</span>
                  </a>
                </div>
              </div>
              
              <div class="-items">
                <splide [options]="{ type: 'loop', perPage: 1, keyboard: false }">
                  <splide-slide *ngFor="let item of withdrawBankList">
                    <div class="-item">
                      <div class="-inner">            
                        <div class="-img">
                          <img appImgLazyLoad [src]="item.registBankLogoUrl" alt="">
                        </div>
                        <div class="-text">
                          <p><small>{{item.bankAccountName}}</small></p>
                          <p><strong>{{item.bankAccountNo}}</strong></p>
                          <p><small>{{item.actualBankAccountName}}</small></p>
                          <span style="width: 150px;display:none" 
                            [ngClass]="(item.bankAccountStatusClass === 'use' 
                                      ? '-status -used' 
                                      : '-status -wait-check')"
                          >{{item.bankAccountStatusName}}</span>
                        </div>
                      </div>
                    </div>
                  </splide-slide>
                </splide>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</main> <!-- end content this page -->
