import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-popular-list',
  templateUrl: './game-popular-list.component.html',
  styleUrls: ['./game-popular-list.component.css'],
})
export class GamePopularListComponent implements OnInit {
  @Input() games: any;
  @Input() size: 'sm' | 'md' | 'xl' = 'sm';
  constructor() {}

  ngOnInit(): void {}
}
