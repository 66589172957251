import { Component, OnInit } from '@angular/core';
import { LuckywheelService } from 'src/app/shared/services/luckywheel.services';

declare function greet(): void;

@Component({
  selector: 'app-game',
  templateUrl: './luckywheel.component.html',
  styleUrls: ['./luckywheel.component.scss']
})


export class LuckywheelComponent implements OnInit {
  websiteName: string = '';

  constructor(
    private readonly _luckyWheelService: LuckywheelService
  ) { 
    // init();
  }

  ngOnInit(): void {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }

    this._luckyWheelService.initwheelpreview();
  }

}
