import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';
import { UserInfomationModel } from 'src/app/models/user-infomation.model';

const getUserInfomationBaseUrl = ApiEndpointConstant.GetUserInfomation;
const getAllPromotionBaseUrl = ApiEndpointConstant.GetAllPromotion;
const getPromotionDisplayCategoryBaseUrl = ApiEndpointConstant.GetPromotionDisplayCategorie;
const postSelectPromotionBaseUrl = ApiEndpointConstant.PostSelectPromotion;
const getUserBalanceBaseUrl = ApiEndpointConstant.GetUserBalance;
const getUserAvailablePromotionBaseUrl = ApiEndpointConstant.GetUserAvailablePromotion;

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getDataUserInfomation() {
    return this._httpClient.get<UserInfomationModel>(`${getUserInfomationBaseUrl}`);
  } 
  
  getDataAllPromotion() {
    return this._httpClient.get<any>(`${getAllPromotionBaseUrl}`);
  } 
  
  getDataPromotionDisplayCategory() {
    return this._httpClient.get<any>(`${getPromotionDisplayCategoryBaseUrl}`);
  } 
  
  postDataSelectPromotion(dataSet: any) {
    return this._httpClient.post<any>(`${postSelectPromotionBaseUrl}`, dataSet);
  } 

  getDataUserBalance() {
    return this._httpClient.get<any>(`${getUserBalanceBaseUrl}`);
  } 

  getDataUserAvailablePromotion() {
    return this._httpClient.get<any>(`${getUserAvailablePromotionBaseUrl}`);
  }

}
