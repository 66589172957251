<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<!-- End Set Header -->

<!-- Alert message -->
<app-modal-alert-message
  *ngIf="showModalAlertMessage"
  [alertMsgContents]="alertMsgContents"
  (isClickCloseModal)="onClickCloseModal($event)"
>
</app-modal-alert-message>

<app-modal-with-rich-text
  *ngIf="showModalPromotion"
  [promotionContents]="promotionContents"
  (isClickCloseModal)="onClickCloseModal($event)"
  [isReadOnly]="true"
>
</app-modal-with-rich-text>

<!-- Call Modal promotion completed-->
<app-modal-promotion-completed
  *ngIf="showModalPromotionCompleted"
  [rewardedBonusAmount]="rewardedBonusAmount"
  (isClickCloseModal)="onClickCloseModal($event)"
>
</app-modal-promotion-completed>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing">
    <div class="container w-sm md:w-md md:mt-3 2xl:w-xl">
      <div class="md:flex md:justify-center md:space-x-2 2xl:space-x-10">
        <div class="w-sm xl:w-[50%]">
          <div class="-section-promotions">
            <splide
              class="mt-3 -list-game-category"
              [options]="{
                type: 'loop',
                gap: 0,
                autoplay: true,
                perPage: 1,
                keyboard: false,
                pagination: false
              }"
            >
              <splide-slide *ngFor="let item of allPromotions">
                <div class="flex justify-center">
                  <div class="w-[328px] h-[152px] xl:w-[530px] xl:h-[100%]">
                    <a>
                      <img
                        appImgLazyLoad
                        src="{{ item.imgUrlDesktop }}"
                        alt=""
                        style="cursor: pointer"
                      />
                    </a>
                  </div>
                </div>
              </splide-slide>
              <!--
            <splide-slide *ngFor="let item of minigamemenu">
              <div class="flex justify-center">
                <div class="max-w-sm">
                  <a>
                    <img appImgLazyLoad (click)="onClickPromotion(item)" [src]="item.imgUrlDesktop" alt="" style="cursor:pointer"/>
                  </a>
                </div>
              </div>
            </splide-slide>
            -->
            </splide>
          </div>

          <div class="hidden mt-4 md:block">
            <app-contact-box></app-contact-box>
          </div>
        </div>
        <div class="xl:w-[50%] flex justify-center">
          <div class="-section-games-category mt-4 md:mt-[2rem]">
            <div class="-section-games-category-inner">
              <!-- <div class="mb-2">
                <a routerLink="/game/gameListing">
                  <img
                    src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-all-game.webp"
                    class="sm:block md:hidden" />
                  <img
                    src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-all-game-dt.webp"
                    class="hidden md:block w-[511px]" />
                </a>
              </div> -->
              <div
                class="-category-items flex justify-center items-center space-x-2"
              >
                <div>
                  <a routerLink="/game/casinoListing">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-casino.webp"
                      class="sm:block md:hidden"
                    />
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-casino-dt.webp"
                      class="hidden md:block"
                    />
                  </a>
                  <a routerLink="/game/sportListing">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-sport.webp"
                      class="sm:block md:hidden"
                    />
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-sport-dt.webp"
                      class="hidden md:block"
                    />
                  </a>
                  <a routerLink="/game/gameListing">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-slot.webp"
                      class="sm:block md:hidden"
                    />
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-slot-dt.webp"
                      class="hidden md:block"
                    />
                  </a>
                  <a routerLink="/game/lottoListing">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-lotto.webp"
                      class="sm:block md:hidden"
                    />
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-filter-lotto-dt.webp"
                      class="hidden md:block"
                    />
                  </a>
                </div>
                <div
                  class="flex-auto relative md:flex-none md:max-w-[60%] 2xl:w-[50%]"
                >
                  <app-frame-bg [type]="'popular-game'">
                    <div
                      class="w-[185px] h-[167px] mr-[-9rem] pb-[-2rem] absolute top-[2.5rem] overflow-y-scroll no-scrollbar md:w-full md:h-[250px] 2xl:top-[3rem] 2xl:right-[-4.3rem] 2xl:w-[17rem] 2xl:h-[23rem] 2xl:mx-auto"
                    >
                      <div class="no-scrollbar">
                        <div class="grid grid-cols-2 gap-1">
                          <div *ngFor="let item of gameHitPlayList">
                            <div class="flex justify-center">
                              <div class="w-[80px] 2xl:w-[120px]">
                                <a
                                  [routerLink]="item.targetLink"
                                  data-mdb-ripple="true"
                                  data-mdb-ripple-color="light"
                                >
                                  <img
                                    appImgLazyLoad
                                    [src]="item.imageUrl"
                                    alt=""
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="absolute bottom-3 left-3 2xl:left-[2rem]">
                      <a routerLink="/game/gameListing" class="-btn- ml-2">
                        ดูทั้งหมด
                      </a>
                    </div>
                  </app-frame-bg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 md:hidden">
        <app-contact-box></app-contact-box>
      </div>
    </div>
  </div>
</main>
<!-- end content this page -->

<div class="fixed z-20 inset-0 overflow-y-auto" *ngIf="this.showModal">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              กรุณาอ่านเงื่อนไข
            </h3>
            <div class="mt-2">
              <p
                class="text-sm leading-5 text-gray-500"
                style="font-size: 16px"
              >
                คุณสามารถขอยกเลิกโปรโมชั่นปัจจุบัน
                และขอรับเงินต้นคืนได้ก็ต่อเมื่อ
                คุณยังไม่ได้ใช้เครดิตในกระเป๋าโบนัสหลังจากกดรับโปรโมชั่นเท่านั้น
                <span class="warning"
                  >หากคุณขอยกเลิกโปรโมชั่นปัจจุบันหลังจากได้ใช้เครดิตในกระเป๋าโบนัสไปแล้ว
                  เครดิตที่เหลืออยู่ในกระเป๋าโบนัสทั้งหมดจะถูกริบคืนทันที</span
                >
                คุณยืนยันที่จะขอยกเลิกโปรโมชั่นปัจจุบันหรือไม่?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-4">
          <button
            (click)="onCleanBonusWallet()"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            ยืนยัน
          </button>
        </span>

        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
          <button
            (click)="toggleModal()"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            ยกเลิก
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
