import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';

const getAllPromotionBaseUrl = ApiEndpointConstant.GetAllPromotion;
const getPromotionDisplayCategoryBaseUrl = ApiEndpointConstant.GetPromotionDisplayCategorie;
const getUserRebateInfoBaseUrl = ApiEndpointConstant.GetUserRebateInfo;
const postRewardUserRebateBaseUrl = ApiEndpointConstant.PostRewardUserRebate;

@Injectable({
  providedIn: 'root'
})
export class RebateService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getDataAllPromotion() {
    return this._httpClient.get<any>(`${getAllPromotionBaseUrl}`);
  }

  getDataPromotionDisplayCategory() {
    return this._httpClient.get<any>(`${getPromotionDisplayCategoryBaseUrl}`);
  } 

  getDataUserRebateInfoBaseUrl() {
    return this._httpClient.get<any>(`${getUserRebateInfoBaseUrl}`);
  } 
  
  postDataRewardUserRebate() {
    return this._httpClient.post<any>(`${postRewardUserRebateBaseUrl}`, {});
  } 
  
}
