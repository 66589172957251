import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WalletBankService } from "../wallet/services/wallet-bank.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-withdraw",
  templateUrl: "./withdraw.component.html",
  styleUrls: ["./withdraw.component.scss"],
})
export class WithdrawComponent implements OnInit {
  @Output() onSplide = new EventEmitter<any>();
  creditAmount: number = 0;
  limitWithdrawAmountPerDay: number = 0;
  limitWithdrawTimePerDay: number = 0;
  maxWithdrawAmount: number = 0;
  minWithdrawAmount: number = 0;
  totalWithdrawAmountToday: number = 0;
  totalWithdrawTimesToday: number = 0;
  displayWithdrawTime: string = "";
  withdrawAmountList: any = [100, 300, 500, 1000, 2000, 3000, 5000, 10000];
  withdrawAmountListM: any = [100, 300, 500, 1000];
  withdrawAmount: any;
  withdrawForm!: FormGroup;
  bankList: any = [];
  userWithdrawBankList: any = [];
  selectBankWithdrawIndex: number = 0;
  selectBankWithdrawInfo: any;
  checkWithdrawAmountAndCreditAmountStatus: boolean = false;
  checkWithdrawAmountAndMinMaxWithdrawAmountStatus: boolean = false;
  checkWithdrawAmountFormatStatus: boolean = false;
  checkDataStatusList: any = [];
  msgErrorList: any = [];
  conditonPack: any;
  duringPromotionCanWithdraw!: boolean;
  showModalPromotionCompleted: boolean = false;
  rewardedBonusAmount: number = 0;
  isActiveWithdraw: boolean = true;
  isWithdrawLimit!: boolean;
  isDisabled: boolean = false;
  lastUpdate!: Date;
  indexSelected: number = 0;

  constructor(
    private _walletBankService: WalletBankService,
    private formBuilder: FormBuilder,
    private readonly _router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.bankList = JSON.parse(localStorage.getItem("bankList") || "{}");

    this.initForm();
    this.getDataUserWithdrawInfo();
    this.getDataUserWithdrawBank();
    this.onGetDataUserBalance();
  }

  getDataUserWithdrawInfo() {
    this._walletBankService.getDataUserWithdrawInfo().subscribe({
      next: (data) => {
        this.setDataToFront(data);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataToFront(data: any) {
    this.limitWithdrawAmountPerDay = data.limitWithdrawAmountPerDay;
    this.limitWithdrawTimePerDay = data.limitWithdrawTimePerDay;
    this.maxWithdrawAmount = data.maxWithdrawAmount;
    this.minWithdrawAmount = data.minWithdrawAmount;
    this.totalWithdrawAmountToday = data.totalWithdrawAmountToday;
    this.totalWithdrawTimesToday = data.totalWithdrawTimesToday;
    this.duringPromotionCanWithdraw = data.duringPromotionCanWithdraw;
    this.isWithdrawLimit = data.isWithdrawLimit;

    this.conditonPack = {
      limitWithdrawAmountPerDay: data.limitWithdrawAmountPerDay,
      limitWithdrawTimePerDay: data.limitWithdrawTimePerDay,
      maxWithdrawAmount: data.maxWithdrawAmount,
      minWithdrawAmount: data.minWithdrawAmount,
      totalWithdrawAmountToday: data.totalWithdrawAmountToday,
      totalWithdrawTimesToday: data.totalWithdrawTimesToday,
    };

    // check condition for withdraw
    if (
      this.isWithdrawLimit &&
      (this.limitWithdrawAmountPerDay <= this.totalWithdrawAmountToday ||
        (this.limitWithdrawTimePerDay > 0 &&
          this.limitWithdrawTimePerDay <= this.totalWithdrawTimesToday))
    ) {
      // this.isActiveWithdraw = false;
      // this.toastr.clear();
      // let msg = "คุณถอนเงินเต็มลิมิตของวันนี้แล้วค่ะ";
      // this.toastr.error(msg, 'ไม่สามารถทำรายการได้');
    }
    if (!this.duringPromotionCanWithdraw) {
      this.isActiveWithdraw = false;

      this.toastr.clear();
      let msg = "คุณไม่สามารถถอนเงินได้ในระหว่างที่รับโปรโมชั่นนี้";
      this.toastr.error(msg, "ไม่สามารถทำรายการได้");
    }

    if (this.limitWithdrawTimePerDay == 0) {
      this.displayWithdrawTime = "ไม่จำกัด";
    } else {
      this.displayWithdrawTime = ` ${this.totalWithdrawTimesToday}/${this.limitWithdrawTimePerDay}`;
    }
  }

  onRefreshData() {
    this.getDataUserWithdrawInfo();
  }

  initForm() {
    this.withdrawForm = this.formBuilder.group({
      withdrawAmountForm: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(15),
          Validators.pattern(/^\d+(\.\d{0,2})?$/),
          Validators.min(this.minWithdrawAmount),
        ],
      ],
    });
  }

  getDataUserWithdrawBank() {
    this._walletBankService.getDataWithdrawBankList().subscribe({
      next: (data) => {
        this.setDataBankForWithdraw(data);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataBankForWithdraw(data: any) {
    data.collection.forEach((e: any) => {
      let bankId = Number(e.registBankId) - 1;
      let bankCode = this.bankList[bankId].code;
      let bankName = this.bankList[bankId].name;
      let bankCodeUpper = bankCode.toUpperCase();
      let bankImageUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${bankCodeUpper}.png`;

      let dataInfo = {
        id: e.id,
        bankAccountName: e.bankAccountName,
        bankAccountNo: e.bankAccountNo,
        bankName: bankName,
        bankImageUrl: bankImageUrl,
      };
      if (
        e.isApproved == true &&
        e.isDeleted == false &&
        e.isRejected == false
      ) {
        this.userWithdrawBankList.push(dataInfo);
      }
    });
  }

  onSplideActive(index: any) {
    this.selectBankWithdrawIndex = index;
    this.indexSelected = index;
    this.onSplide.emit(index);
  }

  confirmWithdraw(amount: string) {
    this.checkDataConditonStatus(amount);

    let checkFullStatusValid = true;
    this.checkDataStatusList.forEach((e: any) => {
      if (e.status == false) {
        checkFullStatusValid = false;
      }
    });

    if (checkFullStatusValid == true) {
      this.isDisabled = true;
      this.withDrawProcess();
    }
  }

  checkDataConditonStatus(amount: string) {
    this.checkDataStatusList = [];
    let aNumber = Number(amount);
    if (amount != undefined && amount != null)
      this.withdrawForm.get("withdrawAmountForm")?.setValue(aNumber);
    this.checkWithdrawAmountFormat();
  }

  checkWithdrawAmountFormat() {
    this.toastr.clear();

    if (!this.withdrawForm.valid || this.withdrawAmount == 0) {
      this.withdrawAmount = this.minWithdrawAmount;
      this.toastr.error("กรุณาใส่จำนวนเงินให้ถูกต้อง", "ข้อมูลไม่ถูกต้อง");
      this.checkWithdrawAmountFormatStatus = false;
    } else {
      this.checkWithdrawAmountFormatStatus = true;
      this.checkWithdrawAmountAndMinMaxWithdrawAmount();
    }

    this.checkDataStatusList.push({
      name: "checkWithdrawAmountFormatStatus",
      status: this.checkWithdrawAmountFormatStatus,
    });
  }

  checkWithdrawAmountAndMinMaxWithdrawAmount() {
    this.toastr.clear();

    if (
      this.withdrawAmount < this.minWithdrawAmount &&
      this.minWithdrawAmount !== 0
    ) {
      this.withdrawAmount = this.minWithdrawAmount;
      this.toastr.error(
        `ยอดถอนขั้นต่ำ ${this.minWithdrawAmount} บาท`,
        "ข้อมูลไม่ถูกต้อง"
      );
      this.checkWithdrawAmountAndMinMaxWithdrawAmountStatus = false;
    } else if (
      this.withdrawAmount > this.maxWithdrawAmount &&
      this.maxWithdrawAmount !== 0
    ) {
      this.withdrawAmount = this.maxWithdrawAmount;
      this.toastr.error(
        `ยอดถอนสูงสุด ${this.maxWithdrawAmount} บาท`,
        "ข้อมูลไม่ถูกต้อง"
      );
      this.checkWithdrawAmountAndMinMaxWithdrawAmountStatus = false;
    } else {
      this.checkWithdrawAmountAndMinMaxWithdrawAmountStatus = true;
      this.checkWithdrawAmountAndCreditAmount();
    }

    this.checkDataStatusList.push({
      name: "checkWithdrawAmountAndMinMaxWithdrawAmountStatus",
      status: this.checkWithdrawAmountAndMinMaxWithdrawAmountStatus,
    });
  }

  checkWithdrawAmountAndCreditAmount() {
    this.toastr.clear();

    if (this.withdrawAmount > this.creditAmount) {
      this.withdrawAmount = this.creditAmount;
      this.toastr.error("ยอดถอนมากกว่าจำนวนเงินในกระเป๋า", "ข้อมูลไม่ถูกต้อง");
      this.checkWithdrawAmountAndCreditAmountStatus = false;
    } else {
      this.checkWithdrawAmountAndCreditAmountStatus = true;
    }

    this.checkDataStatusList.push({
      name: "checkWithdrawAmountAndCreditAmountStatus",
      status: this.checkWithdrawAmountAndCreditAmountStatus,
    });
  }

  withDrawProcess() {
    this.spinner.show();

    this.selectBankWithdrawInfo =
      this.userWithdrawBankList[this.selectBankWithdrawIndex];

    let payload = {
      amount: this.withdrawAmount,
      memberBankAccountId: this.selectBankWithdrawInfo.id,
    };

    this._walletBankService.postDataRequestToWithdraw(payload).subscribe({
      next: (data) => {
        if (!data.success) {
          switch (data?.code) {
            case '109':
              this.toastr.error(
                `โปรดทำอีก ${data?.messageHint ?? ''} เพื่อถอนเงินค่ะ`,
                'คุณทำเทิร์นโอเวอร์ไม่ครบตามกำหนด'
              );
              break;
            default:
              this.toastr.error(data?.message, 'ทำรายการไม่สำเร็จ');
          }
          return;
        }
        this.spinner.hide();
        this.toastr.success("ทำรายการสำเร็จ", "สำเร็จ");
        this._router.navigate(["/withdrawSuccess"]);
      },
      error: (e) => {
        console.error(e);
        this.toastr.clear();
        let errorCode = e.error.code;
        let errorMessage = e.error.message;
        let errorMessageHint = e.error.messageHint;
        switch (errorCode) {
          case "101":
            this.toastr.error(
              "ยอดถอนมากกว่าจำนวนเครดิตในกระเป๋า",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          case "102":
            this.toastr.error(
              "ยอดถอนมากกว่าจำนวนเครดิตในกระเป๋า",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          case "103":
            this.toastr.error(
              "ยอดถอนมากกว่าจำนวนเครดิตในกระเป๋า",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          case "104":
            this.toastr.error(
              "ถอนเกินจำนวนครั้งของวันนี้แล้วค่ะ",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          case "105":
            this.toastr.error(
              "เลขที่บัญชีนี้มีในระบบแล้ว กรุณาใช้เลขที่บัญชีใหม่ค่ะ",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          case "400":
            // this.toastr.error('ยอดการถอนเกินกำหนดแล้วในวันนี้', 'ทำรายการไม่สำเร็จ');
            this.toastr.error(
              "คุณมีรายการถอนที่คงค้างอยู่ กรุณาลองใหม่อีกครั้งค่ะ",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          default:
            this.toastr.error(errorMessage, "ทำรายการไม่สำเร็จ");
            break;
        }
        this.spinner.hide();
      },
    });

    this.spinner.hide();
  }

  onGetDataUserBalance() {
    this._walletBankService.getDataUserBalance().subscribe({
      next: (data) => {
        this.creditAmount = data.creditBalance;
        this.showModalPromotionCompleted = data.isPromotionCompleted;
        this.rewardedBonusAmount = data.rewardedBonusAmount;
        this.spinner.hide();
        this.lastUpdate = new Date();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  onClickCloseModal(event: any) {
    this.showModalPromotionCompleted = !event;
    document.body.style.overflow = "";
  }
}
