import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";
import {Register3rdModel} from "../../models/register3rd.model";
// import {ApiEndpointConstant} from "./core/api.endpoint.constant";
// import {LoginRegister3rdModel} from "./models/login-register3rd.model";
// import {LoginAuthExternalProviderModel} from "./models/login-auth-external-provider.model";

const PostFacebookInspectCodeBaseUrl = ApiEndpointConstant.PostFacebookInspectCode;
const PostLineInspectCodeBaseUrl = ApiEndpointConstant.PostLineInspectCode;
const PostMemberRegister3rdBaseUrl = ApiEndpointConstant.PostMemberRegister3rd;
const PostAuthExternalProviderBaseUrl = ApiEndpointConstant.PostAuthExternalProvider;
const PostResetPasswordByUserNameBaseUrl = ApiEndpointConstant.PostResetPasswordByUserName;
const getClientStatusBaseUrl = ApiEndpointConstant.GetClientStatus;

const checkClientCheckloginV2Url = ApiEndpointConstant.checkClientCheckloginV2;
const memberVerifyUrl = ApiEndpointConstant.memberVerify;

@Injectable({
  providedIn: 'root'
})
export class MigrationService {
  constructor(
    private _httpClient: HttpClient
  ) { }

  postMemberRegister3rd(params: Register3rdModel) {
    return this._httpClient.post<any>(`${PostMemberRegister3rdBaseUrl}`, params);
  }

  postAuthExternalProvider(params: any) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    return this._httpClient.post<any>(`${PostAuthExternalProviderBaseUrl}`, params.toString(), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')});
  }

  postResetPasswordByUserName(phoneNo: string) {
    return this._httpClient.post<any>(`${PostResetPasswordByUserNameBaseUrl}?username=${phoneNo}`, {});
  }

  getClientStatusBaseUrl() {
    return this._httpClient.get<any>(`${getClientStatusBaseUrl}`);
  }

  checkClientCheckloginV2(username: string, password : string) {
    return this._httpClient.get<any>(`${checkClientCheckloginV2Url}?username=${username}&password=${password}`, {});
  }

  memberVerify(dataSet: any) {
    return this._httpClient.post(`${memberVerifyUrl}`, dataSet)
  }

}
