<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- Set Header -->
<div class="-header">
  <!-- <h2 class="-text-header">ลืมรหัสผ่าน</h2> -->
</div>

<!-- <app-main-menu></app-main-menu> -->

<!-- start content this page -->
<main id="main-content" class="-forgot-password">
  <a routerLink="/" class="-btn-close -btn-close-forgot-password">
    <img
      appImgLazyLoad
      src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
      class="w-[30px]"
    />
  </a>

  <app-frame-bg [type]="'contact-box'">
    <div class="-content-register pt-4">
      <div class="container w-sm">
        <div class="-section-top">
          <h2 class="text-xl text-white">ลืมรหัสผ่าน</h2>

          <p class="text-gray-400 mt-3">
            กรอกเบอร์โทรศัพท์ที่ลงทะเบียนไว้กับระบบ
          </p>

          <div class="-form-style px-8 mt-[1rem]">
            <form [formGroup]="forgotPasswordForm">
              <div class="-row -required -error px-3">
                <app-theme-text-input>
                  <input
                    (keypress)="numberOnly($event)"
                    maxlength="10"
                    formControlName="phoneNoForm"
                    type="tel"
                    class="-form-input"
                    placeholder="เบอร์โทรศัพท์"
                  />
                </app-theme-text-input>
                <p *ngIf="!formValid" class="-text-validate">
                  โปรดใส่เลขโทรศัพท์ 10 หลัก
                </p>
              </div>
              <div class="-row mt-[1rem]">
                <app-theme-button>
                  <a
                    style="cursor: pointer"
                    (click)="onClickSubmit()"
                    class="-btn-01"
                    ><span class="text-xl mt-2">ยืนยัน</span></a
                  >
                </app-theme-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </app-frame-bg>
</main>
<!-- end content this page -->
