<!DOCTYPE html>
<html lang="en">

<head>
	<app-header></app-header>
</head>

<body>
	<!--#wrapper-->
	<div id="wrapper">

		<!-- Set Header -->
		<div class="-header">
      <a href="profile.php" class="-btn-back">
        <img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="">
      </a>
      
			<h2 class="-text-header">ข้อมูลส่วนตัว</h2>
		</div>

		<app-main-menu></app-main-menu>
		
		<!-- start content this page -->
		<main id="main-content">
			<div class="-content-register">
				<div class="container">
					<div class="-section-top">
            <img appImgLazyLoad src="assets/img/theme/tel.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
						<h2 class="-title">เปลี่ยนหมายเลขโทรศัพท์</h2>

            <div class="-form-style">
              <form>
                <div class="-row -required">
									<label>หมายเลขโทรศัพท์</label>
									<input type="tel" class="-form-input">
									<p class="-text-validate">Validate</p>
								</div>
                <div class="-row">
									<!-- <button type="submit" class="-btn-01"><span>ตกลง</span></button> -->
									<!-- Mock Up Next Step -->
									<a href="profile.php" class="-btn-01 mt-8"><span>ตกลง</span></a><!-- End Mock Up Next Step -->
								</div>
              </form>
            </div>
					</div>
				</div>
			</div>
		</main> <!-- end content this page -->
	</div> <!--end #wrapper-->

</body>

</html>