import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";
// import { ApiEndpointConstant } from "./core/api.endpoint.constant";
// import { UserDepositInfoModel } from "./models/user-deposit-info.model";

const getBankListBaseUrl = ApiEndpointConstant.GetBankList;
const getMarketingChannelBaseUrl = ApiEndpointConstant.GetMarketingChanels;
const postOtpForRegisterBaseUrl = ApiEndpointConstant.PostOtpForRegister;
const postCheckUsernameIsAvailableBaseUrl = ApiEndpointConstant.CheckUsernameIsAvailable;
const getWithdrawBankListBaseUrl = ApiEndpointConstant.GetWithdrawBankList;
const setMemberNewBankAccountBaseUrl = ApiEndpointConstant.SetMemberNewBankAccount;
const postRequestToWithdrawBaseUrl = ApiEndpointConstant.PostRequestToWithdraw;
const getUserWithdrawInfoBaseUrl = ApiEndpointConstant.GetUserWithdrawInfo;
const getSystemBankAccountTypeListBaseUrl = ApiEndpointConstant.GetSystemBankAccountTypeList;
const getUserDepositInfoBaseUrl = ApiEndpointConstant.GetUserDepositInfo;
const postMemberRegisterBaseUrl = ApiEndpointConstant.PostMemberRegister;
const postMemberRegisterMigrationBaseUrl = ApiEndpointConstant.PostMemberRegisterMigration;

const setRegisterAffliateCountBaseUrl = ApiEndpointConstant.SetRegisterAffliateCount;


@Injectable({
  providedIn: 'root'
})
export class MemberService {

  // BackOfficeApiEndPoint1: any = "https://www.localhost:7001";
  // GetBankList: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/Banks`;

  constructor(
    private _httpClient: HttpClient
  ) { }

  // getBankList() {
  //   return this._httpClient.get<any>(this.GetBankList);
  // }

  getBankList() {
    return this._httpClient.get<any>(getBankListBaseUrl);
  }

  getMarketingChanels() {
    return this._httpClient.get<any>(getMarketingChannelBaseUrl);
  }

  getOtpForRegister(phoneNumber: string) {
    return this._httpClient.post(`${postOtpForRegisterBaseUrl}phoneNo=`+phoneNumber, phoneNumber, {responseType: 'text'});
  }

  postMemberRegister(dataSet: any) {
    return this._httpClient.post(`${postMemberRegisterBaseUrl}`, dataSet)
  }

  postMemberRegisterMigration(dataSet: any) {
    return this._httpClient.post(`${postMemberRegisterMigrationBaseUrl}`, dataSet)
  }

  postCheckUsernameIsAvailable(userName: string) {
    return this._httpClient.post(`${postCheckUsernameIsAvailableBaseUrl}?username=${userName}`, '');
  }

  getWithdrawBankList() {
    return this._httpClient.get<any>(getWithdrawBankListBaseUrl);
  }

  setMemberNewBankAccount(dataSet: any) {
    return this._httpClient.post(`${setMemberNewBankAccountBaseUrl}`, dataSet);
  }

  postRequestToWithdraw(dataSet: any) {
    return this._httpClient.post(`${postRequestToWithdrawBaseUrl}`, dataSet);
  }

  getDataUserWithdrawInfo() {
    return this._httpClient.get(`${getUserWithdrawInfoBaseUrl}`);
  }

  getSystemBankAccountTypeList(lang: string = "th") {
    return this._httpClient.get(`${getSystemBankAccountTypeListBaseUrl}lang=${lang}`);
  }

  setDataRegisterAffliateCount(dataSet: any) {
    return this._httpClient.post<any>(`${setRegisterAffliateCountBaseUrl}`, dataSet);
  }

  // getDataUserDepositInfo() {
  //   return this._httpClient.get<UserDepositInfoModel>(`${getUserDepositInfoBaseUrl}`);
  // }


}
