import { ShowcasesComponent } from './dev/showcases/showcases.component';
import { NgModule, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { InSessionGuard } from './core/guards/in-session.guard';
import { AddBankSuccessComponent } from './pages/add-bank-success/add-bank-success.component';
import { AddBankComponent } from './pages/add-bank/add-bank.component';
import { AffiliatesComponent } from './pages/affiliates/affiliates.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ChangeTelComponent } from './pages/change-tel/change-tel.component';
import { ChangeUsernameComponent } from './pages/change-username/change-username.component';
import { DepositUploadSlipComponent } from './pages/deposit/deposit-upload-slip/deposit-upload-slip.component';
import { DepositComponent } from './pages/deposit/deposit.component';
import { ForgotpasswordComponent } from './pages/forgotpassword/forgotpassword.component';
import { GameComponent } from './pages/game/game.component';
import { GetPromotionComponent } from './pages/get-promotion/get-promotion.component';
import { HistoryComponent } from './pages/history/history.component';
import { LoginComponent } from './pages/login/login.component';
import { NewPasswordComponent } from './pages/new-password/new-password.component';
import { OtpForgotpasswordComponent } from './pages/otp-forgotpassword/otp-forgotpassword.component';
import { OtpComponent } from './pages/otp/otp.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RebateComponent } from './pages/rebate/rebate.component';
import { RegisterSuccessComponent } from './pages/register-success/register-success.component';
import { RegisterComponent } from './pages/register/register.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { WithdrawSuccessComponent } from './pages/withdraw-success/withdraw-success.component';
import { WithdrawComponent } from './pages/withdraw/withdraw.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PromotionListComponent } from './pages/get-promotion/promotion-list/promotion-list.component';
import { GameListComponent } from './pages/game/game-list/game-list.component';
import { CasinoListComponent } from './pages/game/casino-list/casino-list.component';
import { SportListComponent } from './pages/game/sport-list/sport-list.component';
import { LottoListComponent } from './pages/game/lotto-list/lotto-list.component';
import { PlayerComponent } from './pages/game/player/player.component';
import { LoginMigrationComponent } from './pages/loginmigration/loginmigration.component';
import { RegistermigrationComponent } from './pages/register-migration/register-migration.component';
import { LuckywheelComponent } from './pages/luckywheel/luckywheel.component';
import { LuckywheelResultComponent } from './pages/luckywheel-result/luckywheel-result.component';
import { SafeComponent } from './pages/safe/safe.component';
import { SafeListComponent } from './pages/safe-list/safe-list.component';
import { SafeResultComponent } from './pages/safe-result/safe-result.component';
import { CardComponent } from './pages/card/card.component';
import { CardListComponent } from './pages/card-list/card-list.component';
import { environment } from 'src/environments/environment';
import { DemoComponent } from './pages/demo/demo.component';
import { ReferFriendsComponent } from './pages/refer-friends/refer-friends.component';

const routes: Routes = [
  { path: 'demo', component: DemoComponent },
  { path: 'login', component: LoginComponent, canActivate: [InSessionGuard] },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [InSessionGuard],
  },
  { path: 'addBank', component: AddBankComponent, canActivate: [AuthGuard] },
  {
    path: 'addBankSuccess',
    component: AddBankSuccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'affiliates',
    component: AffiliatesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'referFriends',
    component: ReferFriendsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changePassword',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changeTel',
    component: ChangeTelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'changeUsername',
    component: ChangeUsernameComponent,
    canActivate: [AuthGuard],
  },
  { path: 'deposit', component: DepositComponent, canActivate: [AuthGuard] },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'game', component: GameComponent, canActivate: [AuthGuard] },
  {
    path: 'game/gameListing',
    component: GameListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'game/casinoListing',
    component: CasinoListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'game/sportListing',
    component: SportListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'game/lottoListing',
    component: LottoListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'game/player', component: PlayerComponent, canActivate: [AuthGuard] },
  {
    path: 'getPromotion',
    component: GetPromotionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'promotionList',
    component: PromotionListComponent,
    canActivate: [AuthGuard],
  },
  { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
  {
    path: 'newPassword',
    component: NewPasswordComponent,
    canActivate: [AuthGuard],
  },
  { path: 'otp', component: OtpComponent, canActivate: [AuthGuard] },
  {
    path: 'otpForgotpassword',
    component: OtpForgotpasswordComponent,
    canActivate: [InSessionGuard],
  },
  { path: 'profile', redirectTo: 'wallet' },
  { path: 'rebate', component: RebateComponent, canActivate: [AuthGuard] },
  {
    path: 'registerSuccess',
    component: RegisterSuccessComponent,
    canActivate: [InSessionGuard],
  },
  { path: 'index', component: WalletComponent, canActivate: [AuthGuard] },
  { path: 'withdraw', component: WithdrawComponent, canActivate: [AuthGuard] },
  {
    path: 'withdrawSuccess',
    component: WithdrawSuccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'depositUploadSlip',
    component: DepositUploadSlipComponent,
    canActivate: [AuthGuard],
  },
  { path: 'termCondition', component: TermConditionComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'contact', component: ContactUsComponent },
  { path: 'login-migration', component: LoginMigrationComponent },
  { path: 'register-migration', component: RegistermigrationComponent },
  {
    path: 'luckywheel',
    component: LuckywheelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'luckywheel-result',
    component: LuckywheelResultComponent,
    canActivate: [AuthGuard],
  },
  { path: 'safe', component: SafeComponent, canActivate: [AuthGuard] },
  { path: 'safe-list', component: SafeListComponent, canActivate: [AuthGuard] },
  {
    path: 'safe-result',
    component: SafeResultComponent,
    canActivate: [AuthGuard],
  },
  { path: 'card', component: CardComponent, canActivate: [AuthGuard] },
  { path: 'card-list', component: CardListComponent, canActivate: [AuthGuard] },
  {
    path: '**',
    component: LoginComponent,
    canActivate: [InSessionGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
