import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-step01',
  templateUrl: './register-step01.component.html',
  styleUrls: ['./register-step01.component.css']
})
export class RegisterStep01Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
