<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<app-modal-with-rich-text
  *ngIf="showModalPromotion"
  (isClickCloseModal)="onClickCloseModal($event)"
  [promotionContents]="promotionContents"
  [imgUrl]="imgUrlDesktop"
  [isReadOnly]="true"
>
</app-modal-with-rich-text>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing">
    <div class="container w-sm md:w-md xl:w-xl">
      <!-- <div class="-box-rebate -card">
        <div class="-inner">
          <a
            class="-btn-refresh"
            (click)="refreshRebateInfo()"
            style="margin-top: -8px; margin-right: -8px; cursor: pointer"
          >
            <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt="" />
          </a>
        </div>

        <div class="-inner">
          <p style="margin-top: -20px">
            คุณมีสิทธิ์รับเงินคืนทั้งหมด<br />จากวันที่
            {{ fromDateFormatted }} - {{ this.toDateFormatted }}
          </p>
          <p class="my-3">
            <strong>฿ {{ this.amount | number }}</strong>
          </p>
          <span *ngIf="this.isAllowedToRebate">
            <a class="-btn-01 btn-link" (click)="confirmRebateAmount()">
              <span>รับเงินคืน</span>
            </a>
          </span>
        </div>
      </div> -->
      <div class="relative w-sm mx-auto md:w-md md:px-[5rem]">
        <div class="mt-4">
          <app-theme-line></app-theme-line>
          <div class="my-3 md:relative">
            <app-theme-label [label]="'รับเงินคืน'"></app-theme-label>
            <p
              class="hidden md:block md:absolute top-[5px] left-[8rem] text-gray-400"
            >
              รวดเร็ว ปลอดภัย ถอนไว ภายใน 1 นาที
            </p>
          </div>
          <app-theme-line></app-theme-line>
          <div class="w-full mt-2">
            <p>คุณมีสิทธิ์รับเงินคืนทั้งหมด</p>
            <app-frame-bg [type]="'rebate-wallet'">
              <div class="pt-2 mx-4 flex justify-between items-center">
                <div class="w-[30px] flex-none">
                  <img
                    src="https://fs.cdnxn.com/lsm99king/king/img/icon-refresh.webp"
                    class="w-[20px] cursor-pointer"
                    (click)="refreshRebateInfo()"
                  />
                </div>
                <span class="text-black text-xl font-bold">
                  ฿ {{ this.amount | number : "1.2-2" }}
                </span>
              </div>
            </app-frame-bg>
            <p class="w-full text-right text-sm py-1 text-gray-400">
              อัพเดทยอดล่าสุด {{ this.lastUpdate | date : "dd/MM HH:mm" }}
            </p>
            <span *ngIf="this.isAllowedToRebate">
              <a class="-btn-01 btn-link" (click)="confirmRebateAmount()">
                <span>รับเงินคืน</span>
              </a>
            </span>
            <app-theme-line></app-theme-line>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <!-- <app-recommend-promotions></app-recommend-promotions> -->

        <app-recommend-promotions
          *ngIf="this.promotionList.length > 0"
          [promotions]="this.promotionList"
          (handleClickPromotion)="this.onClickPromotion($event)"
          [single]="false"
        ></app-recommend-promotions>
      </div>
      <!-- <div class="-section-recommended-promotion">
        <h2>โปรโมชั่นแนะนำ</h2>
        <div *ngFor="let item of promotionDisplayCategory">      
          <div *ngIf="item.id===1 && sportCategoryData.length>0">
            <div class="flex items-center justify-between">
              <h2>{{item.name}}</h2>
            </div>
            <div class="-list-img" *ngFor="let c of sportCategoryData" style="margin-bottom: 10px;">
              <a>
                <img appImgLazyLoad (click)="onClickPromotion(c)" [src]="c.imgUrlDesktop ? c.imgUrlDesktop : 'assets/img/uploads/promotion.jpg'" alt="{{c.name}}">
              </a>
            </div>
          </div>

          <div *ngIf="item.id===2 && casinoCategoryData.length>0">
            <div class="flex items-center justify-between">
              <h2>{{item.name}}</h2>
            </div>
            <div class="-list-img" *ngFor="let c of casinoCategoryData" style="margin-bottom: 10px;">
              <a>
                <img appImgLazyLoad (click)="onClickPromotion(c)" [src]="c.imgUrlDesktop ? c.imgUrlDesktop : 'assets/img/uploads/promotion.jpg'" alt="{{c.name}}">
              </a>
            </div>
          </div>

          <div *ngIf="item.id===3 && slotCategoryData.length>0">
            <div class="flex items-center justify-between">
              <h2>{{item.name}}</h2>
            </div>
            <div class="-list-img" *ngFor="let c of slotCategoryData" style="margin-bottom: 10px;">
              <a>
                <img appImgLazyLoad (click)="onClickPromotion(c)" [src]="c.imgUrlDesktop ? c.imgUrlDesktop : 'assets/img/uploads/promotion.jpg'" alt="{{c.name}}">
              </a>
            </div>
          </div>

          <div *ngIf="item.id===4 && lottoCategoryData.length>0">
            <div class="flex items-center justify-between">
              <h2>{{item.name}}</h2>
            </div>
            <div class="-list-img" *ngFor="let c of lottoCategoryData" style="margin-bottom: 10px;">
              <a>
                <img appImgLazyLoad (click)="onClickPromotion(c)" [src]="c.imgUrlDesktop ? c.imgUrlDesktop : 'assets/img/uploads/promotion.jpg'" alt="{{c.name}}">
              </a>
            </div>
          </div>

        </div>

        <div class="-box-btn">
          <a routerLink="/promotionList" class="-btn-02">
            <span>ดูโปรโมชั่นทั้งหมด</span>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</main>
<!-- end content this page -->
