import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../services/history.service';

@Component({
  selector: 'app-history-deposit-withdraw',
  templateUrl: './history-deposit-withdraw.component.html',
  styleUrls: ['./history-deposit-withdraw.component.css'],
})
export class HistoryDepositWithdrawComponent implements OnInit {
  isActiveTab: number = 1;

  take: number = 10;
  skip: number = 0;

  dataHistoryList: any = [];
  total!: number;
  limitPerPage: number = 10;
  totalPage: number = 0;
  totalPageList: any = [];
  currentPage: number = 0;

  constructor(private _historyService: HistoryService) {}

  ngOnInit(): void {
    this.getDataDepositWithdrawAllHistory();
  }

  getDataDepositWithdrawAllHistory() {
    this._historyService
      .getDataDepositWithdrawAllHistory(this.take, this.skip)
      .subscribe({
        next: (data) => {
          this.onSetdataList(data.collection);
          this.total = data.total;
          this.onPaginator(this.currentPage);
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  onPaginator(index: number) {
    this.totalPage = Math.ceil(this.total / this.limitPerPage);
    this.totalPageList = [];

    if (index == 0) {
      this.currentPage = 0;
    }

    let beforeCurrentPage = 0;
    let afterCurrentPage = 0;
    if (this.currentPage == 0) {
      beforeCurrentPage = this.currentPage;
      afterCurrentPage = this.currentPage + 2;
    } else {
      beforeCurrentPage = this.currentPage - 1;
      afterCurrentPage = this.currentPage + 2;
      if (afterCurrentPage > this.totalPage) {
        afterCurrentPage = this.totalPage;
      }
    }

    for (let i = beforeCurrentPage; i < afterCurrentPage; i++) {
      this.totalPageList.push(i);
    }
  }

  onSetdataList(dataList: any) {
    this.dataHistoryList = [];
    dataList.forEach((e: any) => {
      switch (e.transactionStatusType) {
        case 'pending':
          e.transactionStatusName = 'รอดำเนินการ';
          break;
        case 'success':
          e.transactionStatusName = 'สำเร็จ';
          break;
        case 'cancel':
          e.transactionStatusName = 'ไม่สำเร็จ';
          break;
        case 'processing':
          e.transactionStatusName = 'ประมวลผล';
          break;
        default:
          e.transactionStatusName = 'ไม่ทราบสถานะ';
          break;
      }

      switch (e.transactionType) {
        case 'withdraw':
          e.transactionTypeName = 'ถอนเงิน';
          break;
        case 'deposit':
          e.transactionTypeName = 'ฝากเงิน';
          break;
        default:
          break;
      }

      const createdDates = new Date(e.createdDate);
      createdDates.setHours(createdDates.getHours() + 7);

      let data = {
        afterAmount: e.afterAmount,
        afterAmountTxt: e.afterAmountTxt,
        amount: e.amount,
        amountTxt: e.amountTxt,
        beforeAmount: e.beforeAmount,
        beforeAmountTxt: e.beforeAmountTxt,
        // createdDate: e.createdDate,
        createdDate: createdDates.toISOString(),
        requestBy: e.requestBy,
        transactionStatusType: e.transactionStatusType,
        transactionType: e.transactionType,
        transactionStatusName: e.transactionStatusName,
        transactionTypeName: e.transactionTypeName,
        frameType:
          e.transactionType === 'deposit'
            ? 'history-deposit'
            : 'history-withdraw',
      };

      this.dataHistoryList.push(data);
    });

    // //TODO: just for demo
    // if (dataList.length === 0)
    //   this.dataHistoryList = [
    //     {
    //       transactionType: 'withdraw',
    //       transactionStatusType: 'success',
    //       createdDate: new Date(),
    //       transactionTypeName: 'ถอนเงิน',
    //       transactionStatusTypeName: 'สำเร็จ',
    //       beforeAmount: 6000000,
    //       beforeAmountTxt: '6000000',
    //       amount: '-1000000',
    //       afterAmount: 5000000,
    //       afterAmountTxt: '5000000',
    //     },
    //     {
    //       transactionType: 'deposit',
    //       transactionStatusType: 'success',
    //       createdDate: new Date(),
    //       transactionTypeName: 'ฝากเงิน',
    //       transactionStatusTypeName: 'สำเร็จ',
    //       beforeAmount: 5000000,
    //       beforeAmountTxt: '5000000',
    //       amount: '+1000000',
    //       afterAmount: 6000000,
    //       afterAmountTxt: '6000000',
    //     },
    //     {
    //       transactionType: 'deposit',
    //       transactionStatusType: 'cancel',
    //       createdDate: new Date(),
    //       transactionTypeName: 'ฝากเงิน',
    //       transactionStatusTypeName: 'ไม่สำเร็จ',
    //       beforeAmount: 5000000,
    //       beforeAmountTxt: '5000000',
    //       amount: '+1000000',
    //       afterAmount: 6000000,
    //       afterAmountTxt: '6000000',
    //     },
    //     {
    //       transactionType: 'deposit',
    //       transactionStatusType: 'processing',
    //       createdDate: new Date(),
    //       transactionTypeName: 'ฝากเงิน',
    //       transactionStatusTypeName: 'ประมวลผล',
    //       beforeAmount: 5000000,
    //       beforeAmountTxt: '5000000',
    //       amount: '+1000000',
    //       afterAmount: 6000000,
    //       afterAmountTxt: '6000000',
    //     },
    //   ];
  }

  paginateEvent(page: any) {
    if (page == 0) {
      this.currentPage = 0;
      this.skip = 0;
      this.take = 10;
    } else {
      this.currentPage = page - 1;
      this.skip = this.currentPage * this.take;
    }

    this.getDataDepositWithdrawAllHistory();
  }
}
