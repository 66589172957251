<!-- Set Header -->
<div class="-header">
  <a routerLink="/register" class="-btn-back">
    <img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="">
  </a>

  <h2 class="-text-header">สมัครสมาชิก</h2>

  <a routerLink="/" class="-btn-close">
    <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
  </a>
</div>
<!-- End Set Header --> 

<!-- start content this page -->
<main id="main-content">
  <div class="-content-register -step-02">
    <div class="container">
      <div class="-section-top">
        <div class="-box-step">
          <div class="-group-step">
            <div class="-col -done">
              <div class="-icon">
                <img appImgLazyLoad src="assets/img/theme/register-step-01.svg" alt="">
                <img appImgLazyLoad src="assets/img/theme/register-step-01-active.svg" alt="">
              </div>
              <p>ยืนยันตัวตน</p>
            </div>
            <div class="-col -active">
              <div class="-icon">
                <img appImgLazyLoad src="assets/img/theme/register-step-02.svg" alt="">
                <img appImgLazyLoad src="assets/img/theme/register-step-02-active.svg" alt="">
              </div>
              <p>ข้อมูลผู้ใช้</p>
            </div>
            <div class="-col">
              <div class="-icon">
                <img appImgLazyLoad src="assets/img/theme/register-step-03.svg" alt="">
                <img appImgLazyLoad src="assets/img/theme/register-step-03-active.svg" alt="">
              </div>
              <p>บัญชีธนาคาร</p>
            </div>
            <div class="-col">
              <div class="-icon">
                <img appImgLazyLoad src="assets/img/theme/register-step-04.svg" alt="">
                <img appImgLazyLoad src="assets/img/theme/register-step-04-active.svg" alt="">
              </div>
              <p>ยืนยันข้อมูล</p>
            </div>
          </div>

          <div class="-progress">
            <div class="-bar"></div>
          </div>
        </div>

        <h2 class="-title">ข้อมูลผู้ใช้</h2>
        <div class="-form-style mt-4">
          <form>
            <div class="-row -required">
              <label>ชื่อยูสเซอร์</label>
              <input type="text" class="-form-input">
              <p class="-text-validate">Validate</p>
            </div>
            <div class="-row -required">
              <label>รหัสผ่าน</label>
              <input type="password" class="-form-input">
              <p class="-text-validate">Validate</p>
            </div>
            <div class="-row -required">
              <label>ยืนยันรหัสผ่าน</label>
              <input type="password" class="-form-input">
              <p class="-text-validate">Validate</p>
            </div>
            <div class="-row">
              <!-- <button type="submit" class="-btn-01"><span>ตกลง</span></button> -->
              <!-- Mock Up Next Step -->
              <a routerLink="/register-step03" class="-btn-01"><span>ตกลง</span></a><!-- End Mock Up Next Step -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main> <!-- end content this page -->
