import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProfileService } from '../profile/services/profile.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private _profileService: ProfileService,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
  }

  initFormGroup(){
    this.changePasswordForm = this._formBuilder.group({
      oldPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
      newPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(30)]],
    })
  }

  onSubmit(){
    if (this.changePasswordForm.status=="VALID") {
      let newPasswd = this.changePasswordForm.value.newPassword.trim();
      let confirmPasswd = this.changePasswordForm.value.confirmPassword.trim();
      let oldPasswd = this.changePasswordForm.value.oldPassword.trim();  

      if (newPasswd == confirmPasswd) {
        
        let payload:any = {
          password: newPasswd,
          oldPassword: oldPasswd,
        }

        this._profileService.setDataChangeMemberUserPassowrd(payload)
        .subscribe({
          next: data => {
            let msg = "ทำรายการสำเร็จ";
            this.toastr.clear();
            this.toastr.success(msg, 'สำเร็จ');

            let url = "/profile";
            this._router.navigate([url]);
          },
          error: e => {
            let msg = "ทำรายการไม่สำเร็จ หรือ รหัสผ่านเก่าไม่ถูกต้อง"; 
            this.toastr.clear();
            this.toastr.error(msg, 'ไม่สำเร็จ');
          }
        })

      } else {
        let msg = "รหัสผ่านใหม่ และ ยืนยันรหัสผ่านใหม่ ไม่ตรงกัน";
        this.toastr.clear();
        this.toastr.error(msg, 'ข้อมูลไม่ถูกต้อง');
      }
      
    } else {
      let msg = "กรุณาใส่รหัสผ่านอย่างน้อย 6 ตัวค่ะ";
      this.toastr.clear();
      this.toastr.error(msg, 'ข้อมูลไม่ถูกต้อง');
    }
    
  }

}
