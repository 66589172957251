<app-frame-bg
  [type]="this.frame === 'bank-list' ? 'bank-list' : 'bank-list-resp'"
>
  <div class="-content-bank pt-3">
    <div class="-inner my-2 mx-3">
      <div class="-your-account mt-0 text-lg">
        <div
          class="flex justify-end"
          [ngClass]="{
            'xl:mt-[1.5rem] xl:mr-[1rem]': this.frame === 'bank-list-resp'
          }"
        >
          <a routerLink="/addBank"
            ><img
              src="https://fs.cdnxn.com/lsm99king/king/img/btn-bank-account-add.webp"
              class="mr-[1rem] mt-[.2rem] w-28 cursor-pointer"
          /></a>
        </div>

        <div
          class="-items m-2 overflow-y-scroll no-scrollbar max-h-[130px] grid grid-cols-2 gap-1 xl:px-[.5rem] xl:mt-[1.7rem] xl:max-h-[12rem] xl:h-[20rem] xl:gap-2"
        >
          <div *ngFor="let item of withdrawBankList">
            <div class="flex justify-start items-center space-x-2">
              <div class="-img flex-none w-[32px]">
                <img appImgLazyLoad [src]="item.registBankLogoUrl" alt="" />
              </div>
              <div class="-text whitespace-nowrap">
                <p class="text-sm leading-[14px]">
                  {{ item.bankAccountName }}
                </p>
                <p class="font-bold">
                  {{ item.bankAccountNo }}
                </p>
                <p class="text-sm leading-[14px]">
                  {{ item.actualBankAccountName ?? item.bankAccountStatusName }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="flex justify-end pt-[1.2rem] pr-[1.2rem]">
    <a routerLink="/addBank"
      ><img
        src="https://fs.cdnxn.com/lsm99king/king/img/btn-bank-account-add.webp"
        class="mr-[1rem] mt-[.2rem] w-28 cursor-pointer"
    /></a>
  </div>
  <div
    class="h-[140px] pt-[.5rem] pb-[1rem] px-[1.5rem] grid grid-cols-2 gap-[5px]"
  >
    <div
      *ngFor="let item of withdrawBankList"
      class="flex justify-start items-center space-x-4 max-w-[127px] h-[42px]"
    >
      <div class="flex-none">
        <img appImgLazyLoad [src]="item.registBankLogoUrl" alt="" class="w-[32px]" />
      </div>
      <div class="flex">
        <p>{{ item.bankAccountName }}</p>
        <p class="text-xl font-bold">
          <strong>{{ item.bankAccountNo }}</strong>
        </p>
        <p class="whitespace-nowrap">
          {{ item.actualBankAccountName ?? item.bankAccountStatusName }}
        </p>
      </div>
    </div>
  </div> -->
</app-frame-bg>
