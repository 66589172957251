import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-alert-message',
  templateUrl: './modal-alert-message.component.html',
  styleUrls: ['./modal-alert-message.component.css']
})
export class ModalAlertMessageComponent implements OnInit {
  @Input() alertMsgContents: string = "";
  @Output() isClickCloseModal = new EventEmitter<boolean>();

  constructor( ) { }

  ngOnInit(): void { }

  onClickCloseModal() {
    localStorage.setItem('lastAlertMessage', JSON.stringify(this.alertMsgContents));
    document.body.style.overflow = '';
    this.isClickCloseModal.emit(true);
  }
}
