import { Component, HostListener, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { WalletBankService } from "src/app/pages/wallet/services/wallet-bank.service";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit {
  userInfo: any;
  displayName: string = "";
  memberGroupImage: string = "";
  memberRole: string = "";

  deferredPrompt: any;
  showModalAlertMessage = false;
  iosPwaImg = '<img appImgLazyLoad src="assets/img/pwa-ios-setup-guide.png" />';

  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private _walletBankService: WalletBankService
  ) {}

  @HostListener("window:beforeinstallprompt", ["$event"])
  onbeforeinstallprompt(e: Event) {
    console.log("onbeforeinstallprompt", e);

    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();

    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("appUserInfo") || "{}");
    this.displayName = this.userInfo.name;
    this.memberRole = this.userInfo.role;

    this.getUrlUserProfileImage();
  }

  getMobileOS() {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      return "iOS";
    }
    return "Other";
  }

  onClickLogOut() {
    this._authService.logout$().subscribe((x) => {
      this._router.navigate(["login"]);
    });
  }

  onClickPWA() {
    if (this.getMobileOS() === "iOS") {
      this.showModalAlertMessage = true;
      this.closeMenu();
      return;
    }

    if (this.deferredPrompt) {
      // Show the prompt
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        this.deferredPrompt = null;
      });
    }
  }

  onCloseModal() {
    this.showModalAlertMessage = false;
  }

  closeMenu() {
    document.body.classList.toggle("-open-menu-hamburger");
    document.getElementById("hamburger")?.classList.remove("-active");
    document.body.style.overflow = "";
  }

  getUrlUserProfileImage() {
    this._walletBankService.getUrlUserProfileImage().subscribe({
      next: (data) => {
        this.memberGroupImage = data.value
          ? data.value
          : "assets/img/icons/img-profile.png";
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
