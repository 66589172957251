import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-frame-bg",
  templateUrl: "./frame-bg.component.html",
  styleUrls: ["./frame-bg.component.css"],
})
export class FrameBgComponent implements OnInit {
  @Input() type:
    | "login"
    | "login-2"
    | "register-2"
    | "main"
    | "main-resp"
    | "modal-register"
    | "header"
    | "bottom-menu-wallet"
    | "bottom-menu-deposit"
    | "bottom-menu-withdraw"
    | "bottom-menu-promotionList"
    | "bottom-menu-game"
    | "snackbar"
    | "wallet-group"
    | "profile-img"
    | "label"
    | "bank-list"
    | "bank-list-resp"
    | "popular-list"
    | "contact-box"
    | "change-password"
    | "add-bank"
    | "add-bank-success"
    | "withdraw-wallet"
    | "withdraw-bank"
    | "popular-game"
    | "affiliates"
    | "affiliates-link"
    | "rebate-wallet"
    | "noti-withdraw"
    | "noti-deposit"
    | "noti-bonus"
    | "history-deposit"
    | "history-withdraw"
    | "history-player"
    | "new-member"
    | "contact-tel"
    | "contact-us" = "main";

  constructor() {}

  ngOnInit(): void {}
}
