import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as introJs from 'intro.js';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TourService {
  private introJS: any;

  constructor(
    private _httpClient: HttpClient) { }

  private async getTour(tourName: string): Promise<any> {

    return this._httpClient.get<any>(`./assets/tours/tour.json`).pipe(
      map(data => {
        return data[tourName];
      }))
      .toPromise();
  }

  async tourLoginFeature() {
    this.introJS = introJs();
    this.introJS.start();
    this.introJS.setOptions(await this.getTour("login.tour")).start();
  }
}