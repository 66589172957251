<!DOCTYPE html>
<html lang="en">

<head>
	<?php include('inc/head.php'); ?>
</head>

<body>
	<!--#wrapper-->
	<div id="wrapper">

		<!-- Set Header -->
		<div class="-header">
			<h2 class="-text-header">ลืมรหัสผ่าน</h2>

      <a href="./" class="-btn-close">
        <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
      </a>
		</div>

		<?php include('inc/main-menu.php'); ?> <!-- End Set Header -->
		
		<!-- start content this page -->
		<main id="main-content">
			<div class="-content-register">
				<div class="container">
					<div class="-section-top">
            <img appImgLazyLoad src="assets/img/theme/new-password.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
						<h2 class="-title">สร้างรหัสผ่านใหม่</h2>
            <p>สร้างรหัสผ่านใหม่และยืนยันรหัสผ่านใหม่อีกครั้ง</p>

            <div class="-form-style">
              <form>
                <div class="-row -required">
									<label>รหัสผ่าน</label>
									<input type="password" class="-form-input">
									<p class="-text-validate">Validate</p>
								</div>
								<div class="-row -required">
									<label>ยืนยันรหัสผ่าน</label>
									<input type="password" class="-form-input">
									<p class="-text-validate">Validate</p>
								</div>
                <div class="-row">
									<!-- <button type="submit" class="-btn-01"><span>ตกลง</span></button> -->
									<!-- Mock Up Next Step -->
									<a href="./" class="-btn-01 mt-8"><span>ตกลง</span></a><!-- End Mock Up Next Step -->
								</div>
              </form>
            </div>
					</div>
				</div>
			</div>
		</main> <!-- end content this page -->
	</div> <!--end #wrapper-->

	<!-- javascript => inc all js -->
	<?php include('inc/javascript.php'); ?>
</body>

</html>