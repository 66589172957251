export class GameConfigConstant {
  static GameInNewTab: string[] = [
    'F-SF01',
    'F-SF02',
    'newsportbook',
    'lobby_pg',
    'muaypukyok',
    'sexy',
  ];
  static GenNewToken: string[] = ['lobby_jili'];
}
