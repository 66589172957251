import { Component, OnInit } from "@angular/core";
import { WebSocketService } from "../../services/web-socket.service";
import { HandleWebSocketService } from "../../services/handle-web-socket.service";
import { HistoryService } from "src/app/pages/history/services/history.service";
import { WalletBankService } from "src/app/pages/wallet/services/wallet-bank.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-notification-bar",
  templateUrl: "./notification-bar.component.html",
  styleUrls: ["./notification-bar.component.css"],
})
export class NotificationBarComponent implements OnInit {
  userInfo: any;
  creditBalance!: number;
  displayName!: string;

  isHidden: boolean = false;
  deposit_success: any = false;
  // pathImage: string = 'https://fs.cdnxn.com/lsm99king/king/img/King-HNY1.gif';
  pathImage: string =
    "https://fs.cdnxn.com/lsm99king/king/img/bg-logo/header-logo.webp";
  msg: any;
  take: number = 10;
  skip: number = 0;
  total: number = 0;
  limitPerPage: number = 10;
  totalPage: number = 0;
  totalPageList: any = [];
  currentPage: number = 0;
  dataCollection: any[] = [];
  activeNotiTab: string = "";
  hasGameUrl: any;
  isDisabledDrag = false;
  isDragging = false;

  constructor(
    private readonly historyService: HistoryService,
    private _walletBankService: WalletBankService,
    private _router: Router
  ) {
    const userInfo = JSON.parse(localStorage.getItem("appUserInfo") || "{}");
    this.displayName = userInfo.name;

    const pathUrl = this._router.parseUrl(this._router.url);
    this.hasGameUrl = pathUrl.queryParamMap.get("url");
  }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("appUserInfo") || "{}");
    WebSocketService.websocket.onmessage = this.onMessage;
    this.setImage();
    this.loadUserNotification();
    this.onGetDataUserBalance();
  }

  onMessage(evt: any) {
    // Ex. for kick all user
    const handleWebSocketService = new HandleWebSocketService();
    let response = handleWebSocketService.onAction(evt);
    this.deposit_success = localStorage.getItem("deposit_success");
    if (evt.data) {
      if (evt.data == "ok") {
      } else {
        this.msg = JSON.parse(evt.data);

        const { Action = "" } = this.msg || {};

        if (Action == "kick") {
          localStorage.clear();
          window.location.reload();
        }
        if (Action == "kick_all") {
          localStorage.clear();
          window.location.reload();
        }
      }
    }
  }

  onSelectNoticategory(category: string) {
    if (this.activeNotiTab != category) {
      this.activeNotiTab = category;
      this.take = 10;
      this.skip = 0;
    }
    this.loadUserNotification(category);
  }

  toggleMenuHamburger() {
    this.isHidden = !this.isHidden;
    document.body.classList.toggle("-open-menu-hamburger");
    document.body.style.overflow = this.isHidden ? "hidden" : "";
  }

  setImage() {
    // let webImage = localStorage.getItem('webImage');
    // if (webImage) {
    //   this.pathImage = JSON.parse(webImage || '');
    // }
    this.pathImage =
      "https://fs.cdnxn.com/lsm99king/king/img/bg-logo/header-logo.webp";
    // this.pathImage = 'https://fs.cdnxn.com/lsm99king/king/img/King-HNY1.gif';
  }

  loadUserNotification(category: string = "") {
    this.historyService
      .getUserNotifications(this.take, this.skip, category)
      .subscribe({
        next: (data) => {
          this.onSetdataList(data.collection);
          this.total = data.total;
          this.onPaginator(this.currentPage);
        },
        error: (e) => {
          console.error(e);
        },
      });
  }

  onSetdataList(dataList: any) {
    this.dataCollection = [];
    dataList.forEach((e: any) => {
      console.log("กุหล่อมาก", e);
      let frameType = "noti-bonus";

      switch (e.transactionStatusType) {
        case "pending":
          e.transactionStatusName = "รอดำเนินการ";
          break;
        case "success":
          e.transactionStatusName = "สำเร็จ";
          break;
        case "cancel":
          e.transactionStatusName = "ไม่สำเร็จ";
          break;
        case "processing":
          e.transactionStatusName = "ประมวลผล";
          break;
        default:
          e.transactionStatusName = "ไม่ทราบสถานะ";
          break;
      }

      switch (e.transactionType) {
        case "withdraw":
          e.transactionTypeName = "หักเครดิต";
          break;
        case "deposit":
          e.transactionTypeName = "ได้รับเครดิต";
          break;
        case "info":
          e.transactionTypeName = "";
          break;
        default:
          break;
      }

      switch (e.discriminator) {
        case "deposit-withdraw":
          {
            e.transactionName =
              e.transactionType == "deposit"
                ? "ฝาก"
                : e.transactionType == "withdraw"
                ? "ถอน"
                : "";

            frameType = `noti-${e.transactionType}`;
          }
          break;
        case "rejected-withdraw":
          e.transactionName = "ยกเลิกการถอนเงิน";
          break;
        case "affiliate-commission":
          e.transactionName = "คอมมิชชั่น พันธมิตร";
          break;
        case "activate-promotion":
          e.transactionName = "เริ่มภาระกิจ โปรโมชั่น";
          break;
        case "promotion-givefirst":
          e.transactionName = "รับโปรโมชั่น";
          break;
        case "promotion-completed":
          e.transactionName = "ทำโปรโมชั่นสำเร็จ";
          break;
        case "promotion-cancelled":
          e.transactionName = "ยกเลิกโปรโมชั่น";
          break;
        case "promotion-admin-approved":
          e.transactionName = "โปรโมชั่น";
          break;
        case "promotion-admin-rejected":
          e.transactionName = "ยกเลิกโปรโมชั่น";
          break;
        case "rebate-completed":
          e.transactionName = "เงินคืน";
          break;
        case "rebate-admin-approved":
          e.transactionName = "เงินคืน";
          break;
        case "rebate-user-self-rebate":
          e.transactionName = "เงินคืน";
          break;
        default:
          e.transactionName = "ไม่ทราบ";
          break;
      }

      let data = {
        afterAmount: e.afterAmount,
        afterAmountTxt: e.afterAmountTxt,
        amount: e.amount,
        amountTxt: e.amountTxt,
        beforeAmount: e.beforeAmount,
        beforeAmountTxt: e.beforeAmountTxt,
        createdDate: e.createdDate,
        requestBy: e.requestBy,
        transactionStatusType: e.transactionStatusType,
        transactionType: e.transactionType,
        transactionStatusName: e.transactionStatusName,
        transactionTypeName: e.transactionTypeName,
        frameType,
        transactionName: e.transactionName,
        remark: e.remark,
      };
      this.dataCollection.push(data);
    });
  }

  onPaginator(index: number) {
    this.totalPage = Math.ceil(this.total / this.limitPerPage);
    this.totalPageList = [];

    if (index == 0) {
      this.currentPage = 0;
    }

    let beforeCurrentPage = 0;
    let afterCurrentPage = 0;
    if (this.currentPage == 0) {
      beforeCurrentPage = this.currentPage;
      afterCurrentPage = this.currentPage + 2;
    } else {
      beforeCurrentPage = this.currentPage - 1;
      afterCurrentPage = this.currentPage + 2;
      if (afterCurrentPage > this.totalPage) {
        afterCurrentPage = this.totalPage;
      }
    }

    for (let i = beforeCurrentPage; i < afterCurrentPage; i++) {
      this.totalPageList.push(i);
    }
  }

  paginateEvent(page: any) {
    if (page == 0) {
      this.currentPage = 0;
      this.skip = 0;
      this.take = 10;
    } else {
      this.currentPage = page - 1;
      this.skip = this.currentPage * this.take;
    }

    this.loadUserNotification();
  }

  onGetDataUserBalance() {
    this._walletBankService.getDataUserBalance().subscribe({
      next: (data) => {
        this.creditBalance = data.creditBalance;
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onDragStart(event: any) {
    this.isDragging = true;
    console.log("drag start");
  }

  onDragEnd(event: any) {
    this.isDragging = false;
    console.log("drag end");
  }
}
