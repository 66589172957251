import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-snackbar-running-message',
  templateUrl: './snackbar-running-message.component.html',
  styleUrls: ['./snackbar-running-message.component.css'],
})
export class SnackbarRunningMessageComponent implements OnInit {
  isShowRunningMsg: boolean = false;
  runningMsg: string = '';
  hasGameUrl: any;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    const pathUrl = this._router.parseUrl(this._router.url);
    this.hasGameUrl = pathUrl.queryParamMap.get('url');

    if (!this.hasGameUrl) {
      this.runningMsg = JSON.parse(
        localStorage.getItem('runningMessage') ?? '""'
      );

      if (
        localStorage.getItem('runningMessage') !=
          localStorage.getItem('lastRunningMessage') &&
        this.runningMsg.trim() != ''
      )
        this.isShowRunningMsg = true;
    }
  }

  onCloseRunningMsg() {
    // localStorage.setItem("lastRunningMessage", localStorage.getItem('runningMessage') ?? '""');

    this.isShowRunningMsg = false;
  }
}
