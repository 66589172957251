import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { GameConfigConstant } from '../../../core/game.config.constant';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss'],
})
export class GameListComponent implements OnInit {
  gameList: any = [];
  gameDisplayList: any = [];
  gameAllList: any = [];
  gameHitsList: any = [];
  gameNewList: any = [];
  gameHotList: any = [];
  gameHistoryList: any = [];
  gameGroupList: any = [
    {
      name: 'คาสิโน',
      key: 'casino',
      imageUrl: 'assets/img/uploads/game-category-01.png',
      targetLink: '/game/casinoListing',
      detail: '',
    },
    {
      name: 'เกมส์',
      key: 'game',
      imageUrl: 'assets/img/uploads/game-category-02.png',
      targetLink: '/game/gameListing',
      detail: '',
    },
    {
      name: 'กีฬา',
      key: 'sport',
      imageUrl: 'assets/img/uploads/game-category-03.png',
      targetLink: '/game/sportListing',
      detail: '',
    },
    {
      name: 'หวย',
      key: 'lotto',
      imageUrl: 'assets/img/uploads/game-category-04.png',
      targetLink: '/game/lottoListing',
      detail: '',
    },
  ];
  promoteList: any = [
    'sportOnly',
    'muaypukyok',
    'lottoexit',
    'ab',
    'pg',
    'sexy',
    'ps',
    'sa',
    'hg',
    'dg',
    'pp',
  ];
  isActiveGameBtn: string = 'all';
  showModalGameList: boolean = false;
  dataModalGameList: any;
  deviceInfo!: any;
  newTabGames: string[] = GameConfigConstant.GameInNewTab;

  constructor(
    private _gameService: GameService,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.deviceInfo = JSON.parse(localStorage.getItem('deviceInfo') || '{}');

    this.onGetGameList();
    this.onGetHistory();
  }

  onGetGameList() {
    this.spinner.show();
    let allGameList = localStorage.getItem('allGameList');
    if (allGameList) {
      this.gameList = JSON.parse(allGameList);
      this.onGetGameAllList();
      this.onGetDataGameHitList();
    } else {
      this._gameService.getDataAllProductsV3().subscribe({
        next: async (games) => {
          const product = await games.data.filter((item: any) => {
            return item.status == 'Active';
          });

          await product.sort((a: any, b: any) => {
            return a.displayOrder - b.displayOrder;
          });

          this.gameList = await product;
          this.onGetGameAllList();
          this.onGetDataGameHitList();
          localStorage.setItem('allGameList', JSON.stringify(this.gameList));
        },
        error: (e) => {
          console.error(e);
          this.spinner.hide();
        },
      });
    }
    this.spinner.hide();
  }

  onGetHistory() {
    let gameSlotPlayLasted = localStorage.getItem('gameSlotPlayLasted');
    if (gameSlotPlayLasted)
      this.gameHistoryList = JSON.parse(gameSlotPlayLasted);
  }

  onGetGameAllList() {
    if (this.gameAllList.length == 0) {
      this.gameAllList = this.gameList.filter((item: any) => {
        item.imageUrl = 'assets/img/brand/' + item.code + '.png';
        item.brand = item.code;
        item.category = 'game';

        return item.tags.find((x: any) => x.slug == 'game');
      });
    }

    this.gameDisplayList = this.gameAllList;
  }

  onGetDataGameHitList() {
    this.gameHitsList = this.gameList.filter((item: any) => {
      switch (item.tags[0].slug) {
        case 'game':
          item.targetLink = '/game/gameListing';
          break;
        case 'casino':
          item.targetLink = '/game/casinoListing';
          break;
        case 'sport':
          item.targetLink = '/game/sportListing';
          break;
        case 'lotto':
          item.targetLink = '/game/lottoListing';
          break;
      }
      return this.promoteList.find((x: any) => x == item.code);
    });
  }

  onGetGameNewList() {
    if (this.gameNewList.length == 0) {
      this.spinner.show();
      this.gameList.forEach((item: any) => {
        if (!item.maintenance && item.tags.find((x: any) => x.slug == 'game')) {
          item.games.forEach((game: any) => {
            if (
              game.status == 'Open' &&
              game.tags.find((x: any) => x.slug == 'new')
            ) {
              game.brand = item.brand;
              game.category = item.category;
              game.imageUrl = game.properties.imageURL;

              this.gameNewList.push(game);
            }
          });
        }
      });

      this.gameNewList.sort((a: any, b: any) => {
        return a.displayOrder - b.displayOrder;
      });

      this.spinner.hide();
    }

    this.gameDisplayList = this.gameNewList;
  }

  onGetGameHotList() {
    if (this.gameHotList.length == 0) {
      this.spinner.show();
      this.gameList.forEach((item: any) => {
        if (!item.maintenance && item.tags.find((x: any) => x.slug == 'game')) {
          item.games.forEach((game: any) => {
            if (
              game.status == 'Open' &&
              game.tags.find((x: any) => x.slug == 'hot')
            ) {
              game.brand = item.brand;
              game.category = item.category;
              game.imageUrl = game.properties.imageURL;

              this.gameHotList.push(game);
            }
          });
        }
      });

      this.gameNewList.sort((a: any, b: any) => {
        return a.displayOrder - b.displayOrder;
      });

      this.spinner.hide();
    }

    this.gameDisplayList = this.gameHotList;
  }

  onGetGameLastList() {
    this.onGetHistory();
    this.gameDisplayList = this.gameHistoryList;
  }

  onActiveGameBtn(key: string) {
    this.isActiveGameBtn = key;

    switch (key) {
      case 'all':
        this.onGetGameAllList();
        break;
      case 'new':
        this.onGetGameNewList();
        break;
      case 'popular':
        this.onGetGameHotList();
        break;
      case 'last':
        this.onGetGameLastList();
        break;
      default:
        this.onGetGameAllList();
        break;
    }
  }

  onClickCloseModal(event: any) {
    this.showModalGameList = !event;
    document.body.style.overflow = '';
  }

  onClickGameLaunch(item: any) {
    if (item.games != null) {
      if (item.games.length == 1) {
        this.spinner.show();
        this._gameService
          .getDataProductUrlForPlayGameList(
            item.brand,
            item.category,
            item.games[0].code,
            this.deviceInfo.deviceType
          )
          .subscribe({
            next: (data) => {
              this.setHistory({
                brand: item.brand,
                category: item.category,
                code: item.games[0].code,
                id: item.games[0].id,
                imageUrl: 'assets/img/brand/' + item.code + '.png',
                name: item.name,
                linkPlayGame: data.value,
              });
              this.spinner.hide();
              this.openGame(
                data.value,
                this.newTabGames.includes(item.games[0].code)
              );
            },
            error: (e) => {
              console.error(e);
              this.spinner.hide();
            },
          });
      } else {
        this.showModalGameList = true;
        this.dataModalGameList = item;
        document.body.style.overflow = 'hidden';
      }
    } else if (item.linkPlayGame == null) {
      this.spinner.show();
      this._gameService
        .getDataProductUrlForPlayGameList(
          item.brand,
          item.category,
          item.code,
          this.deviceInfo.deviceType
        )
        .subscribe({
          next: (data) => {
            this.setHistory({
              brand: item.brand,
              category: item.category,
              code: item.code,
              id: item.id,
              imageUrl: item.imageUrl,
              name: item.name,
              linkPlayGame: data.value,
            });
            this.spinner.hide();
            this.openGame(data.value);
          },
          error: (e) => {
            console.error(e);
            this.spinner.hide();
          },
        });
    } else {
      this.setHistory(item);
      this.openGame(item.linkPlayGame);
    }
  }

  setHistory(item: any) {
    this.onGetHistory();
    this.gameHistoryList = this.gameHistoryList.filter((x: any) => {
      return x.id != item.id;
    });
    this.gameHistoryList.unshift(item);
    if (this.gameHistoryList.length > 10) this.gameHistoryList.pop();
    localStorage.setItem(
      'gameSlotPlayLasted',
      JSON.stringify(this.gameHistoryList)
    );
  }

  openGame(url: string, isNewTab: boolean = false) {

    const isSafari = this.deviceInfo?.browser?.toLowerCase()?.indexOf('safari') > -1;

    if (isNewTab) {
      if (isSafari) {
        setTimeout(() => {
          var newTab = window.open(url, '_blank','toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }
        });
        return;
      }
      var newTab = window.open(url, '_blank');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }
      return;
    }

    if (url.indexOf('http:') != 0 && url.indexOf('hg.lsmapi.com') == -1) {
      this._router.navigate(['game/player'], { queryParams: { url: url } });
    } else if (!isSafari) {
      
      var newTab = window.open(url, '_blank');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }

    } else {
      setTimeout(() => {
        var newTab = window.open(url, '_blank','toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes');
        if (newTab === null) {
          // New tab was blocked
          var link = document.createElement('a');
          link.target = '_blank';
          link.href = url;
          document.body.appendChild(link);
          link.click();
        }
      });
    }
      
  }
}
