<!--#wrapper-->
<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>

  <!-- start content this page -->
  <main id="main-content">
    <div class="-content-login">
      <div class="container">
        <div class="-section-top">
          <img appImgLazyLoad [src]="this.pathImage" alt="" class="-logo">
          <h1 id="login-title" class="-title">การย้ายระบบ</h1>
          <p>กรุณากรอกข้อมูล ยูสเซอร์ ปัจจุบันของท่าน</p>
          <h1 style="color: red;">ต้องทำการถอนเงินออกจาก "กระเป๋าเกมส์" ทั้งหมดก่อนทำการย้าย เพื่อป้องกันยอดเงินมาไม่ครบ</h1>
          <div class="-form-style">
            <form [formGroup]="loginMigratiomForm">
              <div class="-row -required -error">
                <label>ยูสเซอร์</label>
                <input
                  id="login-username"
                  type="text"
                  class="-form-input"
                  formControlName="userForm"
                >
                <div *ngIf="isLoginFormNotValid" class="-text-validate">โปรดใส่ยูสเซอร์ให้ถูกต้อง</div>
              </div>
              <div class="-row -required -error">
                <label class="flex justify-between">รหัสผ่าน </label>
                <input
                  	id="login-password"
                  type="password"
                  class="-form-input"
                  formControlName="passwordForm"
                >
                <div *ngIf="isLoginFormNotValid" class="-text-validate">โปรดใส่รหัสผ่านให้ถูกต้อง</div>
              </div>
              <div class="-row">
                <button
                        type="submit"
                        (click)="onClickBtnLogin()"
                        class="-btn-01">
                  <span>เข้าสู่ระบบ</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- end content this page -->
  