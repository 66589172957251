import { Injectable } from '@angular/core';
import { delay, Observable, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly _userKey: string = "appUser";
  private readonly _userInfoKey: string = "appUserInfo";

  public redirectUrl?: string = '';

  constructor() { }

  get isLoggedIn(): boolean {
    return !!localStorage.getItem(this._userKey) && !!localStorage.getItem(this._userInfoKey);
    //return !!localStorage.getItem(this._userKey);
  }

  //member_group_img
  getUserInfo() : any{
    const userInfo = localStorage.getItem(this._userInfoKey);
    if (userInfo){
      return JSON.parse(userInfo!);
    }
    return "";
  }
  
  logout$ = (): Observable<boolean> => {
    this.redirectUrl = "";
    return of(true).pipe(
      delay(500),
      tap(() => localStorage.removeItem(this._userKey)),
      tap(() => localStorage.removeItem(this._userInfoKey)),
      tap(() => localStorage.removeItem("lastAlertMessage")),
      tap(() => localStorage.removeItem("lastRunningMessage")),
      tap(() => localStorage.removeItem("allGameList")),
      tap(() => localStorage.removeItem("deviceInfo")),
      tap(() => localStorage.removeItem("apiDataAllPromotion")),
      tap(() => localStorage.removeItem("apiDataPromotionDisplayCategory"))
    );
  }

  userToken(): any {
    return localStorage.getItem(this._userKey);
  }
}
