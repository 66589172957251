import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';

const getAllProductsBaseUrl = ApiEndpointConstant.GetAllProducts;
const getProductUrlForPlayBaseUrl = ApiEndpointConstant.GetProductUrlForPlay;
const getAllProductsV3BaseUrl = ApiEndpointConstant.GetAllProductsV3;

@Injectable({
  providedIn: 'root'
})
export class GameService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  getDataAllProduct() {
    return this._httpClient.get<any>(`${getAllProductsBaseUrl}`);
  }

  getDataProductUrlForPlay(brand: string, category: string, device: string="desktop") {
    return this._httpClient.get<any>(`${getProductUrlForPlayBaseUrl}/${brand}/${category}?device=${device}`);
  }

  getDataAllProductsV3() {
    return this._httpClient.get<any>(`${getAllProductsV3BaseUrl}`);
  }

  getDataProductUrlForPlayGameList(brand: string, category: string, gameId: number, device: string="desktop") {
    return this._httpClient.get<any>(`${getProductUrlForPlayBaseUrl}/${brand}/${category}/${gameId}?device=${device}`);
  }

}
