<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<app-snackbar-running-message></app-snackbar-running-message>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing -game-listing-container">
    <div class="container">
      <!-- <div class="-title">
        <h2>{{ this.gameGroupList[1].name }}</h2>
        <p>{{ this.gameGroupList[1].detail }}</p>
      </div> -->

      <div class="md:flex md:justify-center md:space-x-12">
        <div class="">
          <!-- <div class="mt-4 w-[120px]">
            <a routerLink="/index" class="-btn">
              <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/btn-back.webp" />
            </a>
          </div> -->

          <app-group-game-filter></app-group-game-filter>

          <!-- <div class="my-2">
            <app-theme-line></app-theme-line>
            <div class="-btn-filter py-2 m-0">
              <a (click)="onActiveGameBtn('all')" class="-btn-all is-link"
                [ngClass]="this.isActiveGameBtn == 'all' ? 'active' : ''">
                <span class="-text">ทั้งหมด</span>
              </a>
              <a (click)="onActiveGameBtn('new')" class="-btn-new is-link"
                [ngClass]="this.isActiveGameBtn == 'new' ? 'active' : ''">
                <span class="-text">มาใหม่</span>
              </a>
              <a (click)="onActiveGameBtn('popular')" class="-btn-popular is-link"
                [ngClass]="this.isActiveGameBtn == 'popular' ? 'active' : ''">
                <span class="-text">ยอดนิยม</span>
              </a>
              <a (click)="onActiveGameBtn('last')" class="-btn-latest is-link"
                [ngClass]="this.isActiveGameBtn == 'last' ? 'active' : ''">
                <span class="-text">เล่นล่าสุด</span>
              </a>
            </div>
            <app-theme-line></app-theme-line>
          </div> -->

          <div id="filter-all" class="-list-game -filter-item">
            <div *ngFor="let item of gameDisplayList" class="relative">
              <div *ngIf="item.maintenance" class="maintenance">
                ปิดปรับปรุงระบบชั่วคราว
              </div>
              <a (click)="onClickGameLaunch(item)">
                <img
                  appImgLazyLoad
                  style="height: 100px; width: 100px"
                  [src]="item.imageUrl"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
        <div class="">
          <div class="mt-4">
            <!-- <app-recommend-promotions></app-recommend-promotions> -->
            <app-group-promotions
              [promotionCategoryId]="2"
              (handleClickPromotion)="this.onClickPromotion($event)"
            ></app-group-promotions>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- end content this page -->
