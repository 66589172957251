<!--#wrapper-->
<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-login">
    <div
      class="container p-0 md:flex md:flex-row-reverse md:justify-around md:items-center md:w-md xl:w-xl"
    >
      <div class="md:mx-4">
        <div class="z-[100]">
          <a href="{{ this.logoLink }}">
            <img
              appImgLazyLoad
              [src]="this.pathImage"
              alt=""
              class="-logo pb-[10px]"
            />
          </a>
        </div>
        <app-frame-bg [type]="'login'">
          <div
            class="-section-top mt-[-4rem] px-[1.5rem] pt-[3.5rem] md:mt-[-2rem] md:pt-[0.2rem]"
          >
            <!-- <h1 id="login-title" class="-title" title="{{ envName }}">
              เข้าสู่ระบบ
            </h1>
            <p>{{ this.welcomeMessage }}</p> -->
            <div class="-form-style md:mt-3">
              <form [formGroup]="loginForm" class="px-[1rem] pt-[10px]">
                <div class="-row -required -error">
                  <app-theme-text-input>
                    <input
                      id="login-username"
                      type="tel"
                      class="-form-input"
                      formControlName="phoneForm"
                      placeholder="Username/mobile number"
                    />
                    <div *ngIf="isLoginFormNotValid" class="-text-validate">
                      โปรดใส่เบอร์โทรศัพท์ให้ถูกต้อง
                    </div>
                  </app-theme-text-input>
                </div>
                <div class="mt-2 -required -error">
                  <app-theme-text-input>
                    <input
                      id="login-password"
                      type="password"
                      class="-form-input"
                      formControlName="passwordForm"
                      placeholder="Password"
                    />
                  </app-theme-text-input>
                  <div *ngIf="isLoginFormNotValid" class="-text-validate">
                    โปรดใส่รหัสผ่านให้ถูกต้อง
                  </div>
                </div>
                <div class="mt-2">
                  <a
                    routerLink="/forgotpassword"
                    class="-btn- text-[#E4AB3C] underline"
                    >ลืมรหัสผ่าน?</a
                  >
                </div>
                <div class="mt-2">
                  <app-theme-button>
                    <button
                      type="submit"
                      (click)="onClickBtnLogin()"
                      class="-btn-01"
                    >
                      <span class="text-2xl font-bold">เข้าสู่ระบบ</span>
                    </button>
                  </app-theme-button>
                </div>
                <!-- <p class="text-sm flex justify-center pb-3">
                  หรือเข้าสู่ระบบด้วย
                </p> -->
                <div class="flex justify-center">
                  <div class="flex space-x-4 justify-center">
                    <a
                      (click)="onClickLoginFaceBook()"
                      href="{{ urlLoginWithFacebook }}"
                      class="cursor-pointer w-[100px]"
                    >
                      <!-- <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/btn-facebook.webp" /> -->
                    </a>
                    <a
                      (click)="onClickLoginLine()"
                      href="{{ urlLoginWithLine }}"
                      class="cursor-pointer w-[100px]"
                    >
                      <!-- <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/btn-line.webp" /> -->
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </app-frame-bg>
        <div class="-mobile">
          <div class="my-4" [ngClass]="this.isLinkLine ? '' : 'display-none'">
            <div>
              <a
                href="{{ this.isLinkLine }}"
                target="_blank"
                class="row flex justify-center isShowLinkLineLogin"
              >
                <p style="color: #fff; font-weight: 600; margin-bottom: 0">
                  ติดต่อเรา
                </p>
              </a>
            </div>
          </div>
        </div>
        <app-frame-bg [type]="'login-2'">
          <div class="-section-bottom px-[1.5rem] mt-[1rem] pt-[4rem] my-2">
            <!-- <p>ยังไม่ได้เป็นสมาชิกกับ {{ this.websiteName }}</p> -->
            <div *ngIf="isRegister">
              <app-theme-button [type]="'secondary'">
                <a routerLink="/register" (click)="onRemoveLocalStorage()">
                  <span class="text-3xl font-bold justify-center items-center"
                    >ลงทะเบียน</span
                  >
                </a>
              </app-theme-button>
            </div>

            <div class="-desktop">
              <div
                class="my-2"
                [ngClass]="this.isLinkLine ? '' : 'display-none'"
              >
                <div>
                  <a
                    href="{{ this.isLinkLine }}"
                    target="_blank"
                    class="row flex justify-center isShowLinkLineLogin"
                  >
                    <p style="color: #fff; font-weight: 600; margin-bottom: 0">
                      ติดต่อเรา
                    </p>
                  </a>
                </div>
              </div>
            </div>

            <small>{{ version }}</small>
          </div>
        </app-frame-bg>
        <div class="flex justify-center mx-auto mt-[-2rem] md:hidden">
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/character.webp"
            class="w-[200px]"
          />
        </div>
      </div>

      <div class="hidden flex justify-center mx-auto mt-[-2rem] md:block">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/character-dt.webp"
          class="min-w-[15rem] -king-left"
        />
      </div>
    </div>
  </div>
</main>
<!-- end content this page -->
