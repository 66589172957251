import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})

export class SetDisplayPromotion {
    memberGroupId: number = 0;
    constructor() {
        let id: any;
        id = localStorage.getItem('appUserInfo');
        id = JSON.parse(id);
        this.memberGroupId = id.member_group_id;
    }

    canDisplayPromotion(timeSet: any[]) {

       let listDisplay: any = [];
       timeSet.map((val, index) => {
           let startDate = new Date(val.startDate);
           startDate.setHours(startDate.getHours() + 7)

           let endDate = new Date(val.endDate);
           endDate.setHours(endDate.getHours() + 7)

           if (val.displayScheduleId == 1 && val.isDisplayImage) {

               if (this.handleDaily(startDate, endDate)) {
                   listDisplay.push(val.id);
               }
           } else if (val.displayScheduleId == 2 && val.isDisplayImage) {
               let startDate = new Date(val.startDate);
               startDate.setHours(startDate.getHours() + 7)

               let endDate = new Date(val.endDate);
               endDate.setHours(endDate.getHours() + 7)

               if (this.handleWeekly(val.scheduleDay, startDate, endDate)) {
                   listDisplay.push(val.id);
               }
           } else if (val.displayScheduleId == 3 && val.isDisplayImage) {
               let startDate = new Date(val.startDate);
               startDate.setHours(startDate.getHours() + 7)

               let endDate = new Date(val.endDate);
               endDate.setHours(endDate.getHours() + 7)

               if (this.handleMonthly(val.scheduleDay, startDate, endDate)) {
                   listDisplay.push(val.id);
               }
           }
        })
        let newList: any;
        newList = timeSet.filter(x => listDisplay.indexOf(x.id) > -1);

        //Filter by memberGroupIds
        newList = newList.filter((x: { memberGroupIds: number[]; }) => x.memberGroupIds.some((memberGroupIds: number) => memberGroupIds == this.memberGroupId))

        return newList;
    }

    handleDaily(startDate: Date, endDate: Date) {
        let dateNow = new Date();
        return (dateNow.getTime() >= startDate.getTime() && dateNow.getTime() <= endDate.getTime());
    }

    handleWeekly(dayId: number, startDate: Date, endDate: Date) {
        let dateNow = new Date();
        return (
            dayId == dateNow.getDay() &&
            dateNow.getTime() >= startDate.getTime() &&
            dateNow.getTime() <= endDate.getTime()
        );
    }

    handleMonthly(monthId: number, startDate: Date, endDate: Date) {
        let dateNow = new Date();
        return (
            monthId == (dateNow.getMonth() + 1) &&
            dateNow.getTime() >= startDate.getTime() &&
            dateNow.getTime() <= endDate.getTime()
        );
    }
}