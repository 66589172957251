<main id="main-content">
  <div class="-content-playing mt-[7rem]">
    <div class="container">
      <div class="my-2">
        <app-theme-label [label]="'โปรโมชั่นล่าสุด'"></app-theme-label>
      </div>

      <app-theme-line></app-theme-line>
      <div class="my-2 flex justify-around">
        <span (click)="onSelectCategory(0)" [ngClass]="this.onSelectCategoryId == 0 ? 'active' : ''">
          <span class="cursor-pointer">ทั้งหมด</span>
        </span>
        <span (click)="onSelectCategory(1)" [ngClass]="this.onSelectCategoryId == 1 ? 'active' : ''">
          <span class="cursor-pointer">กีฬา</span>
        </span>
        <span (click)="onSelectCategory(2)" [ngClass]="this.onSelectCategoryId == 2 ? 'active' : ''">
          <span class="cursor-pointer">คาสิโน</span>
        </span>
        <span (click)="onSelectCategory(3)" [ngClass]="this.onSelectCategoryId == 3 ? 'active' : ''">
          <span class="cursor-pointer">สล็อต</span>
        </span>
        <span (click)="onSelectCategory(4)" [ngClass]="this.onSelectCategoryId == 4 ? 'active' : ''">
          <span class="cursor-pointer">หวย</span>
        </span>
      </div>
      <app-theme-line></app-theme-line>
      <div class="-section-recommended-promotion" [ngClass]="{
          'md:grid md:grid-cols-2 md:gap-4 xl:grid-cols-3':
            this.single === false
        }">
        <div class="-list-img" *ngFor="let item of allPromotions" style="margin-bottom: 10px">
          <a>
            <img (click)="onClickPromotion(item)" [src]="
                item.imgUrlDesktop
                  ? item.imgUrlDesktop
                  : 'assets/img/uploads/promotion.jpg'
              " alt="" />
          </a>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <app-theme-button>
          <a class="-btn-01" routerLink="/promotionList">
            <span class="text-xl font-bold w-[200px] text-center mb-2">ดูทั้งหมด</span>
          </a>
        </app-theme-button>
      </div>
    </div>
  </div>
</main>