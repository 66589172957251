import { Component, OnInit } from "@angular/core";
import { HistoryService } from "./services/history.service";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  isActiveTab: number = 1;
  take: number = 5;
  skip: number = 0;

  index0!: number;
  index1: number = 1;
  index2: number = 2;
  index3: number = 3;
  index4!: number;
  dataHistoryList: any = [];
  total: number = 0;

  constructor(private _historyService: HistoryService) {}

  ngOnInit(): void {}

  onActiveHistory(tab: number) {
    switch (tab) {
      case 1:
        this.isActiveTab = 1;
        break;
      case 2:
        this.isActiveTab = 2;
        break;
      default:
        this.isActiveTab = 1;
        break;
    }
  }
}
