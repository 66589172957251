import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.css']
})
export class DemoComponent implements OnInit {

  username = '0922896445';
  password = '0922896445';

  constructor(private _router: Router) { }

  ngOnInit(): void {
    this._router.navigate(['login'], { state: { username: this.username, password: this.password }})
  }

}
