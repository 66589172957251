import { Component, OnInit } from '@angular/core';
import { PromotionService } from '../services/promotion.service';
import {SetDisplayPromotion} from "../../../shared/services/setDisplayPromotion";

@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.css']
})
export class PromotionListComponent implements OnInit {
  creditBalance: any;
  bonusWallet: any;
  turnoverRemain: any;
  availablePromotions: any;
  totalAvailablePromotions: any;
  promotionList: any;
  promotionDisplayCategory: any;
  sportCategoryData!: any[];
  casinoCategoryData!: any[];
  slotCategoryData!: any[];
  lottoCategoryData!: any[];
  promotionAllList!: any;
  onSelectCategoryName!: string;

  imgUrlDesktop: string = '';
  promotionContents: string = '';
  showModalPromotion: boolean = false;
  isClickCloseModal: boolean = false;

  constructor(
    private _promotionService: PromotionService,
    public setPromotion: SetDisplayPromotion
  ) { }

  ngOnInit(): void {
    this.onSelectCategoryName = "all";
    this.getDataAllPromotion();
  }

  onSelectCategory(categoryName: string){
    this.onSelectCategoryName = categoryName;
    
    switch (categoryName) {
      case 'all':
        this.promotionList = this.promotionAllList;
        break;
    
      case 'casino':
        this.promotionList = this.casinoCategoryData;
        break;
    
      case 'sport':
        this.promotionList = this.sportCategoryData;
        break;
    
      case 'slot':
        this.promotionList = this.slotCategoryData;
        break;
    
      case 'lotto':
        this.promotionList = this.lottoCategoryData;
        break;
    
      default:
        break;
    }

  }

  getDataAllPromotion(){
    let cacheApiDataAllPromotion: any = localStorage.getItem('apiDataAllPromotion');
    cacheApiDataAllPromotion = cacheApiDataAllPromotion ? JSON.parse(cacheApiDataAllPromotion) : null;

    let cacheDataPromotionDisplayCategory: any = localStorage.getItem('apiDataPromotionDisplayCategory');
    cacheDataPromotionDisplayCategory = cacheDataPromotionDisplayCategory ? JSON.parse(cacheDataPromotionDisplayCategory) : null;

    if (cacheDataPromotionDisplayCategory === null) {
      this._promotionService.getDataPromotionDisplayCategory().subscribe({
        next: (data) => {
          this.promotionDisplayCategory = data;
          localStorage.setItem('apiDataPromotionDisplayCategory', JSON.stringify(data))
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.promotionDisplayCategory = cacheDataPromotionDisplayCategory;
    }

    if (cacheApiDataAllPromotion === null) {
      this._promotionService.getDataAllPromotion().subscribe({
        next: (data) => {
          let newData = this.setPromotion.canDisplayPromotion(data);
          localStorage.setItem('apiDataAllPromotion', JSON.stringify(newData))
          this.onSetDataToGroup(newData);
          this.promotionAllList = newData;
          this.promotionList = newData;
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.onSetDataToGroup(cacheApiDataAllPromotion);
      this.promotionAllList = cacheApiDataAllPromotion;
      this.promotionList = cacheApiDataAllPromotion;
    }
  }

  onSetDataToGroup(dataSource: any){

    let sportCategoryData: any[] = [];
    let casinoCategoryData: any[] = [];
    let slotCategoryData: any[] = [];
    let lottoCategoryData: any[] = [];

    dataSource.forEach((e: any) => {
      switch (e.promotionDisplayCategoryId) {
        case 1:
          sportCategoryData.push(e);
          break;
      
        case 2:
          casinoCategoryData.push(e);
          break;
      
        case 3:
          slotCategoryData.push(e);
          break;
      
        case 4:
          lottoCategoryData.push(e);
          break;
      
        default:
          break;
      }
    });

    this.sportCategoryData = sportCategoryData;
    this.casinoCategoryData = casinoCategoryData;
    this.slotCategoryData = slotCategoryData;
    this.lottoCategoryData = lottoCategoryData;
  }

  onClickPromotion(dataPromotion: any) {
    this.showModalPromotion = true;
    this.imgUrlDesktop = dataPromotion.imgUrlDesktop;
    this.promotionContents = dataPromotion.promotionRichText;
    console.log(dataPromotion);
    document.body.style.overflow = 'hidden';
  }

  onClickCloseModal(event: any) {
    this.showModalPromotion = !event;
    document.body.style.overflow = '';
  }

}
