<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>
<div toastContainer></div>
<!-- Set Header -->
<!-- <div class="-header">
  <img appImgLazyLoad [src]="this.pathImage" alt="" class="-logo" />
</div> -->
<!-- End Set Header -->

<main id="main-content">
  <div class="-content-login">
    <div class="container p-0 md:flex md:flex-row-reverse md:justify-around md:items-center md:w-wd xl:w-xl">
      <div class="md:mx-4">
        <div class="z-[100]">
          <a href="/">
            <img appImgLazyLoad [src]="this.pathImage" alt="" class="-logo" />
          </a>
        </div>
        <app-frame-bg [type]="'login'">
          <div class="-section-top mt-[-4rem] px-[2rem] pt-[1.2rem] md:mt-[-2rem] md:pt-[0.2rem] w-sm mx-auto">
            <div class="-form-style mt-[3rem] md:mt-[2rem]">
              <form [formGroup]="registerEasyForm" action="" id="form-register" class="px-[1rem]">
                <div class="-row -required -error">
                  <app-theme-text-input>
                    <input type="tel" maxlength="10" formControlName="phoneNumberForm" (keypress)="numberOnly($event)"
                      class="-form-input" placeholder="เบอร์โทรศัพท์" />
                    <p *ngIf="!phoneNumberValidate" class="-text-validate">
                      โปรดใส่หมายเลขโทรศัพท์
                    </p>
                  </app-theme-text-input>
                </div>
                <div class="-required -error">
                  <div class="justify-center -required -error">
                    <div class="dropdownWrapper">
                      <app-theme-text-input>
                        <button id="dropdownUsersButton" data-dropdown-toggle="dropdownUsers"
                          data-dropdown-placement="bottom"
                          class="-form-style -form-input bg-white-700 hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-2"
                          type="button" (click)="hideBankContainer = !hideBankContainer">
                          <div class="button-dropdown w-full flex justify-between items-center">
                            <div>
                              <span class="text-base text-[#4f4f4f] -text-placeholder">{{
                                bankName === ""
                                ? "เลือกธนาคาร"
                                : "ธนาคาร" + bankName
                                }}</span>
                            </div>
                            <div class="w-3">
                              <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/icon-dropdown.webp" />
                            </div>
                          </div>
                        </button></app-theme-text-input>
                    </div>
                  </div>

                  <div id="dropdownBanks" [ngClass]="{ hidden: hideBankContainer }"
                    class="hidden z-10 mt-2 w-100 bg-white rounded shadow dark:bg-gray-700">
                    <ul class="overflow-y-auto w-100 py-1 h-48 text-black absolute bg-white w-[232px] cursor-pointer"
                      aria-labelledby="dropdownUsersButton">
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(3)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KBANK.png"
                              alt="ธนาคารกสิกรไทย" width="30" />
                          </div>
                          <span>ธนาคารกสิกรไทย</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(2)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/SCB.png"
                              alt="ธนาคารไทยพาณิชย์" width="30" />
                          </div>
                          <span>ธนาคารไทยพาณิชย์</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(4)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KTB.png"
                              alt="ธนาคารกรุงไทย" width="30" />
                          </div>
                          <span>ธนาคารกรุงไทย</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(1)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BBL.png"
                              alt="ธนาคารกรุงเทพ" width="30" />
                          </div>
                          <span>ธนาคารกรุงเทพ</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(11)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/TTB.png"
                              alt="ธนาคารทหารไทยธนชาต" width="30" />
                          </div>
                          <span>ธนาคารทหารไทยธนชาต</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(5)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BAY.png"
                              alt="ธนาคารกรุงศรีอยุธยา" width="30" />
                          </div>
                          <span>ธนาคารกรุงศรีอยุธยา</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(10)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/GSB.png"
                              alt="ธนาคารออมสิน" width="30" />
                          </div>
                          <span>ธนาคารออมสิน</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(9)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/GHB.png"
                              alt="ธนาคารอาคารสงเคราะห์" width="30" />
                          </div>
                          <span>ธนาคารอาคารสงเคราะห์</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(6)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BAAC.png"
                              alt="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" width="30" />
                          </div>
                          <span>ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(12)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/ISBT.png"
                              alt="ธนาคารอิสลามแห่งประเทศไทย" width="30" />
                          </div>
                          <span>ธนาคารอิสลามแห่งประเทศไทย</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(13)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KKP.png"
                              alt="ธนาคารเกียรตินาคิน" width="30" />
                          </div>
                          <span>ธนาคารเกียรตินาคิน</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(7)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/CIMB.png"
                              alt="ธนาคารซีไอเอ็มบี" width="30" />
                          </div>
                          <span>ธนาคารซีไอเอ็มบี</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(14)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/LHBANK.png"
                              alt="ธนาคารแลนด์ แอนด์ เฮ้าส์" width="30" />
                          </div>
                          <span>ธนาคารแลนด์ แอนด์ เฮ้าส์</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(8)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/TISCO.png"
                              alt="ธนาคารทิสโก้" width="30" />
                          </div>
                          <span>ธนาคารทิสโก้</span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          (click)="onChooseBank(15)">
                          <div class="pr-2">
                            <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/UOB.png"
                              alt="ธนาคารยูโอบี" width="30" />
                          </div>
                          <span>ธนาคารยูโอบี</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p *ngIf="!bankIdValidate" class="-text-validate">
                    โปรดเลือกธนาคาร
                  </p>
                </div>
                <div class="mt-2 -required -error">
                  <app-theme-text-input>
                    <input type="tel" maxlength="20" formControlName="accountNoForm" (keypress)="numberOnly($event)"
                      class="-form-input" placeholder="หมายเลขบัญชีธนาคาร{{ this.bankName }}" />
                  </app-theme-text-input>
                  <p *ngIf="!accountNoValidate" class="-text-validate">
                    กรุณากรอกหมายเลขบัญชีธนาคาร
                  </p>
                </div>
                <div class="mt-2 pt-[1rem]">
                  <app-theme-button>
                    <button class="-btn-01" (click)="onSubmit()" [disabled]="this.isDisabled">
                      <span class="text-xl">สมัครสมาชิก</span>
                    </button>
                  </app-theme-button>
                </div>
              </form>
            </div>
          </div>
        </app-frame-bg>
        <div class="-mobile">
          <div class="my-4" [ngClass]="this.isLinkLine ? '' : 'display-none'">
            <div>
              <a href="{{ this.isLinkLine }}" target="_blank" class="row flex justify-center isShowLinkLineLogin">
                <p style="color: #fff; font-weight: 600; margin-bottom: 0">
                  พบปัญหาติดต่อเรา
                </p>
              </a>
            </div>
          </div>
        </div>

        <app-frame-bg [type]="'register-2'">
          <div class="-section-bottom px-[1.5rem] pt-[3.5rem] my-2 md:pt-[4rem] md:px-[3.5rem]">
            <div class="w-[232px] mx-auto">
              <app-theme-button [type]="'secondary'">
                <a routerLink="/login" class="-btn-01"><span class="text-xl font-bold text-black">เข้าสู่ระบบ</span></a>
              </app-theme-button>

              <div class="-desktop">
                <div class="my-2" [ngClass]="this.isLinkLine ? '' : 'display-none'">
                  <div>
                    <a href="{{ this.isLinkLine }}" target="_blank" class="row flex justify-center isShowLinkLineLogin">
                      <p style="color: #fff; font-weight: 600; margin-bottom: 0">
                        พบปัญหาติดต่อเรา
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-frame-bg>

        <div class="flex justify-center mx-auto mt-[-2rem] md:hidden">
          <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/character.webp" class="w-[200px]" />
        </div>
      </div>

      <div class="hidden flex justify-center mx-auto mt-[-2rem] md:block">
        <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/character-dt.webp" class="min-w-[15rem]" />
      </div>
    </div>
  </div>
</main>

<!-- <div class="-content-register -step-01">
  <div class="container">
    <div class="-section-top">
      <div style="padding-top: 30px"></div>
      <h2 class="-title">สมัครสมาชิก</h2>
      <p>{{ this.registerMessage }}</p>
      <div class="-form-style">
        <form [formGroup]="registerEasyForm" action="" id="form-register">
          <div class="-row -required -error">
            <label>หมายเลขโทรศัพท์</label>
            <input
              type="tel"
              maxlength="10"
              formControlName="phoneNumberForm"
              (keypress)="numberOnly($event)"
              class="-form-input"
            />
            <p *ngIf="!phoneNumberValidate" class="-text-validate">
              โปรดใส่หมายเลขโทรศัพท์
            </p>
          </div>
          <div class="-row justify-center -required -error">
            <label>กรุณาเลือกธนาคาร</label>
            <div class="block rounded-lg max-w-sm text-center">
              <div class="p-1 bank-container">
                <img
                  class="rounded-full"
                  (click)="onChooseBank(3)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KBANK.png"
                  alt="ธนาคารกสิกรไทย"
                  [ngClass]="this.bankId == 3 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(2)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/SCB.png"
                  alt="ธนาคารไทยพาณิชย์"
                  [ngClass]="this.bankId == 2 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(4)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KTB.png"
                  alt="ธนาคารกรุงไทย"
                  [ngClass]="this.bankId == 4 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(1)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BBL.png"
                  alt="ธนาคารกรุงเทพ"
                  [ngClass]="this.bankId == 1 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(11)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/TTB.png"
                  alt="ธนาคารทหารไทยธนชาต"
                  [ngClass]="this.bankId == 11 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(5)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BAY.png"
                  alt="ธนาคารกรุงศรีอยุธยา"
                  [ngClass]="this.bankId == 5 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(10)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/GSB.png"
                  alt="ธนาคารออมสิน"
                  [ngClass]="this.bankId == 10 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(9)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/GHB.png"
                  alt="ธนาคารอาคารสงเคราะห์"
                  [ngClass]="this.bankId == 9 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(6)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BAAC.png"
                  alt="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
                  [ngClass]="this.bankId == 6 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(12)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/ISBT.png"
                  alt="ธนาคารอิสลามแห่งประเทศไทย"
                  [ngClass]="this.bankId == 12 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(13)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KKP.png"
                  alt="ธนาคารเกียรตินาคิน"
                  [ngClass]="this.bankId == 13 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(7)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/CIMB.png"
                  alt="ธนาคารซีไอเอ็มบี"
                  [ngClass]="this.bankId == 7 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(14)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/LHBANK.png"
                  alt="ธนาคารแลนด์ แอนด์ เฮ้าส์"
                  [ngClass]="this.bankId == 14 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(8)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/TISCO.png"
                  alt="ธนาคารทิสโก้"
                  [ngClass]="this.bankId == 8 ? 'selected' : ''"
                />
                <img
                  class="rounded-full"
                  (click)="onChooseBank(15)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/UOB.png"
                  alt="ธนาคารยูโอบี"
                  [ngClass]="this.bankId == 15 ? 'selected' : ''"
                />
              </div>
            </div>
            <p *ngIf="!bankIdValidate" class="-text-validate">
              โปรดเลือกธนาคาร
            </p>
          </div>
          <div class="-row -required -error">
            <label>หมายเลขบัญชีธนาคาร{{ bankName }}</label>
            <input
              type="tel"
              maxlength="20"
              formControlName="accountNoForm"
              (keypress)="numberOnly($event)"
              class="-form-input"
            />
            <p *ngIf="!accountNoValidate" class="-text-validate">
              กรุณากรอกหมายเลขบัญชีธนาคาร
            </p>
          </div>
          <div class="-row">
            <button
              class="-btn-01"
              (click)="onSubmit()"
              [disabled]="this.isDisabled"
            >
              สมัครสมาชิก
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="-section-bottom">
      <p>เคยสมัครสมาชิกกับ {{ this.websiteName }} แล้ว</p>
      <a routerLink="/login" class="-btn-02"><span>เข้าสู่ระบบ</span></a>
    </div>
  </div>
</div> -->

<div class="fixed z-20 inset-0 overflow-y-auto" *ngIf="showModal">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="h-[250px] md:h-[400px] inline-block align-bottom bg-transparent rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <app-frame-bg [type]="'modal-register'">
        <div class="relative bg-transparent px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="absolute w-[30px] bottom-[-2rem] md:bottom-[-4rem]"
            style="left: 50%; transform: translate(-50%, -50%)">
            <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp" />
          </div>
          <div class="mt-[3rem] text-center">
            <p class="text-xl md:text-[40px] font-bold md:mt-[7rem]" id="modal-headline">
              {{ this.passwordForLogin ?? 0 }}
            </p>
            <div class="mt-[3rem] w-[256px] mx-auto md:mt-[7rem]">
              <app-theme-button [type]="'secondary'">
                <button (click)="toggleModal()" type="button" class="inline-flex justify-center w-full h-full">
                  <span class="text-xl font-bold mt-[.5rem]">เข้าสู่ระบบ </span>
                </button>
              </app-theme-button>
            </div>
          </div>
        </div>
      </app-frame-bg>
    </div>
  </div>
</div>