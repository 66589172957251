<app-modal-alert-message
  *ngIf="showModalAlertMessage"
  [alertMsgContents]="iosPwaImg"
  (isClickCloseModal)="onCloseModal()"
>
</app-modal-alert-message>

<div class="-main-menu">
  <!-- <div class="absolute top-0 right-0 mt-4 mr-4">
    <img appImgLazyLoad src="./assets/img/icons/close.svg" class="w-[1rem] cursor-pointer" (click)="closeMenu()" />
  </div> -->
  <div class="-group-main-menu relative h-full">
    <div class="-menu-item">
      <a routerLink="/game/casinoListing" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-playgame.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/rebate" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-rebate.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/history" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-history.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/promotionList" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-promotion.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/deposit" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-deposit.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/index" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-wallet.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/affiliates" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-affiliate.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a (click)="onClickPWA()" class="cursor-pointer">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-install.webp"
          alt=""
        />
      </a>
    </div>
    <div class="-menu-item">
      <a routerLink="/contact" (click)="closeMenu()">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/btn-menu-contact.webp"
          alt=""
        />
      </a>
    </div>

    <div class="mt-[18%] w-full flex justify-center">
      <app-theme-button>
        <a style="cursor: pointer" (click)="onClickLogOut()" class="-btn-02">
          <span class="text-xl text-center w-[200px] mb-2">ออกจากระบบ</span>
        </a>
      </app-theme-button>
    </div>
  </div>

  <!-- <div class="-box-content container">
    <div class="-inner">
      <div class="-img-profile">
        <div class="-box-img">
          <img appImgLazyLoad [src]="this.memberGroupImage" alt="" class="-img" />
        </div>
      </div>
      <div class="-profile-name">
        <p class="text-2xl font-medium">{{ this.displayName }}</p>
        <p class="text-sm">สมาชิก {{ this.memberRole }}</p>
      </div>

      <div class="-group-link">
        <a routerLink="/wallet" (click)="closeMenu()">
          <span class="-img">
            <img appImgLazyLoad src="assets/img/theme/wallet.svg" alt="" />
          </span>
          <span class="-text">กระเป๋าเงิน</span>
        </a>
        <a routerLink="/deposit" (click)="closeMenu()">
          <span class="-img">
            <img appImgLazyLoad src="assets/img/theme/deposit.svg" alt="" />
          </span>
          <span class="-text">ฝากเงิน</span>
        </a>
        <a routerLink="/withdraw" (click)="closeMenu()">
          <span class="-img">
            <img appImgLazyLoad src="assets/img/theme/withdraw.svg" alt="" />
          </span>
          <span class="-text">ถอนเงิน</span>
        </a>
        <a routerLink="/profile" (click)="closeMenu()">
          <span class="-img">
            <img appImgLazyLoad src="assets/img/theme/profile.svg" alt="" />
          </span>
          <span class="-text">ข้อมูลส่วนตัว</span>
        </a>
      </div>

      <nav class="-menu">
        <a routerLink="/game/gameListing" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/game.svg" alt="" />
          <span>เล่นเกมส์</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
        <a routerLink="/rebate" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/cash-back.svg" alt="" />
          <span>รับเงินคืน</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
        <a routerLink="/history" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/history.svg" alt="" />
          <span>ประวัติทำรายการ</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
        <a routerLink="/promotionList" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/promotion.svg" alt="" />
          <span>โปรโมชั่น</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
        <a routerLink="/affiliates" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/achievement.svg" alt="" />
          <span>ข้อมูลพันธมิตร</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
        <a routerLink="/contact" (click)="closeMenu()">
          <img appImgLazyLoad src="assets/img/theme/contact.svg" alt="" />
          <span>ติดต่อเรา</span>
          <img appImgLazyLoad src="assets/img/icons/angle-right-white.svg" alt="" />
        </a>
      </nav>

      <app-theme-button>
        <a style="cursor: pointer" (click)="onClickLogOut()" class="-logout">
          <span>ออกจากระบบ</span>
        </a>
      </app-theme-button>
    </div>
  </div> -->
</div>
