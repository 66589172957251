<!-- Set Header -->

<app-main-menu></app-main-menu>
<!-- <app-menu-bottom></app-menu-bottom> -->
<!-- End Set Header -->

<!-- start content this page -->
<div class="mt-4">
  <app-frame-bg [type]="'add-bank-success'">
    <main id="main-content">
      <div class="-content-register">
        <div class="container w-sm">
          <div class="-row w-[200px] mt-[14rem] mx-auto">
            <app-theme-button>
              <a routerLink="/index" class="-btn-01 is-link"
                ><span class="text-xl">กลับไปหน้ากระเป๋า</span></a
              >
            </app-theme-button>
          </div>
        </div>
      </div>
    </main>
  </app-frame-bg>
</div>
<!-- end content this page -->
