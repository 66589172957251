import { Component, OnInit } from '@angular/core';
// import { SafeService } from 'src/app/shared/services/safe.services';
import "./card.component.scss";
import { miniGameService } from 'src/app/shared/services/minigame.service';
import {ActivatedRoute, Router} from "@angular/router";

declare function animateGameCardOpen(): any;
declare function randomReward(): any;
declare function GameResultSafety(): any;

@Component({
  selector: 'app-game',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})


export class CardComponent implements OnInit {
  websiteName: string = '';
  result: any;
  bonus: string = '';
  resultBonus: string = '';
  cardType: string = '';

  constructor(
    // private readonly _safeService: SafeService,
    private _minigameService : miniGameService,
    private _urlRoute: ActivatedRoute,
  ) { 
    // init();
  }

  ngOnInit(): void {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }
    this.setSafeType();
    animateGameCardOpen();
  }

  setSafeType() {
    //get marketing channel from url link
    this._urlRoute.queryParams
    .subscribe(params => {
      if (params['type']) {
        this.cardType = params['type'];
      }
    });
  }

  async safestart(): Promise<any>{
    this._minigameService.checkPromitionLuckyAvailable(58)
    .subscribe({
      next: (data) => {
        this._minigameService.userPromotionLuckyBonus(58)
        .subscribe({
          next: (data) => {
            this.result = data;
            this.bonus = this.result.resultDestination.text;
            this.resultBonus = this.result.resultDestination.resultText;
            GameResultSafety();
          },
          error: (e) => {
            console.error(e)
          }
        });
      },
      error: (e) => {
        console.error(e)
      }
    });
    }
  }
