import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HandleWebSocketService {
    msg!: any;
    constructor(
    ) {}

    onAction(evt: any) {

        if (evt.data) {
            if (evt.data=="ok") {

            } else {                
                this.msg = JSON.parse(evt.data);

                const { Action = '' } = this.msg || {};

                if (Action == "kick_all") {
                    localStorage.clear();
                    window.location.reload();
                }
    
                if (Action=="fetch-deposit") {
                    return Action;
                }
    
                if (Action == "deposit-success") {
                    let element: any = document.getElementById('toast-container');
                    element.style.visibility = "visible"
                    setTimeout(() => {
                        element.style.visibility = "hidden"
                    }, 6000)
                    return Action;
                }
    
                if (Action == "verifyaccount-success") {
                    let element: any = document.getElementById('toast-container-verification-bank');
                    element.style.visibility = "visible"
                    setTimeout(() => {
                        element.style.visibility = "hidden"
                    }, 6000)
                    return Action;
                }
    
                if (Action == "invalid-deposit-condition") {
                    let element: any = document.getElementById('toast-container-deposit-fail');
                    element.style.visibility = "visible"
                    setTimeout(() => {
                        element.style.visibility = "hidden"
                    }, 6000)
                    return Action;
                }
    
                if (Action == "rejected-mem-bank-account") {
                    let element: any = document.getElementById('toast-container-verification-reject');
                    element.style.visibility = "visible"
                    setTimeout(() => {
                        element.style.visibility = "hidden"
                    }, 6000)
                    return Action;
                }
            }

        }
    }

}
