import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refer-friends',
  templateUrl: './refer-friends.component.html',
  styleUrls: ['./refer-friends.component.scss']
})
export class ReferFriendsComponent implements OnInit {
  tab1:boolean=false;
  tab2:boolean=false;
  tab3:boolean=false;
  tab4:boolean=false;
  tab5:boolean=false;
    
  constructor() { }

  ngOnInit(): void {
  }
  onSelectQuestiona() {
    if (this.tab1==false){
      this.tab1 = true;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;
    }
    else{
      this.tab1 = false;
    }
  }
  onSelectQuestionb() {
    if (this.tab2==false){
      this.tab1 = false;
      this.tab2 = true;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = false;     
    }
    else{
      this.tab2 = false;
    }
  }
  onSelectQuestionc() {
    if (this.tab3==false){
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = true;
      this.tab4 = false;
      this.tab5 = false;
    }
    else{
      this.tab3 = false;
    }
  }
  onSelectQuestiond() {
    if (this.tab4==false){
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = true;
      this.tab5 = false;
    }
    else{
      this.tab4 = false;
    }
  }
  onSelectQuestione() {
    if (this.tab5==false){
      this.tab1 = false;
      this.tab2 = false;
      this.tab3 = false;
      this.tab4 = false;
      this.tab5 = true;
    }
    else{
      this.tab5 = false;
    }
  }  
}
