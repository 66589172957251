<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<!-- <app-menu-bottom></app-menu-bottom> -->
<!-- 
<main id="main-content">
  <div class="-content-playing">
    <div class="container">
      <div class="-title">
        <h2>ติดต่อเรา</h2>
        <p>
          พวกเราเพียบพร้อมไปด้วยทีมงานมืออาชีพที่ทำงานกันอย่างไม่หยุดพัก
          พร้อมที่จะช่วยเหลือและบริการลูกค้าทุกๆ ท่าน ทุกวัน 7 วัน ตลอด 24
          ชั่วโมง ไม่มีวันหยุด ติดต่อเราได้เลยตามช่องทางด้านล่างนี้
        </p>
      </div>

      <div class="-list-history" *ngIf="phoneNumbers.length > 0 || linkLine">
        <div class="-item">
          <div *ngIf="phoneNumbers.length > 0">
            <span *ngFor="let item of phoneNumbers; index as i">
              <a href="tel:{{ item.trim() }}" target="_blank">
                <img appImgLazyLoad *ngIf="i == 0" src="assets/img/icons/call-center.jpg" />
                <h2 *ngIf="i > 0">หรือ</h2>
                <h2>โทรศัพท์: {{ item.trim() }}</h2>
              </a>
            </span>
          </div>
          <div *ngIf="linkLine">
            <a href="{{ linkLine }}" target="_blank">
              <img appImgLazyLoad src="assets/img/icons/line-icon.png" />
              <h2>ไลน์แชท: {{ lineId }}</h2>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</main> -->

<div class="mt-[7rem]">
  <main id="main-content">
    <app-frame-bg [type]="'contact-us'">
      <div class="-content-playing p-3">
        <div class="container w-sm md:w-[528px]">
          <div class="ml-3">
            <app-theme-label [label]="'ติดต่อเรา'"></app-theme-label>
          </div>

          <div class="block md:hidden">
            <div class="mt-4 px-6 text-center">
              <p class="font-bold">แสกน QR Code หรือกดปุ่มแอดไลน์</p>
              <p class="text-sm text-[#B5B5B5]">
                พวกเราเพียบพร้อมไปด้วยทีมงานมืออาชีพที่ทำงานกัน <br />
                อย่างไม่หยุดพักติดต่อเราได้เลยตามช่องทางด้านล่างนี้
              </p>
            </div>

            <div class="mt-2">
              <div class="px-4 space-x-4 flex justify-around">
                <div class="flex-none">
                  <img
                    appImgLazyLoad
                    src="https://fs.cdnxn.com/lsm99king/king/img/qrcode.webp"
                    class="w-[100px]"
                  />
                </div>
                <div class="flex-1 flex flex-col justify-between items-center">
                  <a href="{{ this.linkLine }}" target="_blank">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/line.webp"
                      class="w-[271px]"
                    />
                  </a>
                  <div
                    class="flex flex-col justify-center items-center w-full"
                    *ngIf="this.phoneNumbers && this.phoneNumbers.length > 0"
                  >
                    <p class="py-2">หรือโทร</p>
                    <div class="w-full text-center">
                      <app-frame-bg [type]="'contact-tel'">
                        <a
                          href="tel:{{ this.phoneNumbers[0] }}"
                          target="_blank"
                          class="font-bold"
                          >{{ this.phoneNumbers[0] }}</a
                        >
                      </app-frame-bg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="hidden md:block">
            <div class="mt-[2rem] mx-[1rem] flex justify-between items-center">
              <div class="">
                <img
                  appImgLazyLoad
                  src="https://fs.cdnxn.com/lsm99king/king/img/qrcode.webp"
                  class="w-[150px]"
                />
              </div>
              <div class="flex-1">
                <div class="px-4 flex flex-col">
                  <div class="px-6 text-center">
                    <p class="font-bold">แสกน QR Code หรือกดปุ่มแอดไลน์</p>
                    <p class="text-sm text-[#B5B5B5]">
                      พวกเราเพียบพร้อมไปด้วยทีมงานมืออาชีพที่ทำงานกัน <br />
                      อย่างไม่หยุดพักติดต่อเราได้เลยตามช่องทางด้านล่างนี้
                    </p>
                  </div>
                  <div
                    class="flex-1 mt-3 flex flex-col justify-between items-center"
                  >
                    <a
                      href="{{ linkLine }}"
                      class="btn-line mt-[1.3px]"
                      target="_blank"
                    >
                      <img
                        appImgLazyLoad
                        src="https://fs.cdnxn.com/lsm99king/king/img/icon-line.png"
                        style="width: 35px"
                      />ADD Line ID : {{ lineId }}
                    </a>
                    <div
                      class="mt-3 flex justify-between items-center space-x-4 w-full px-6"
                      *ngIf="this.phoneNumbers && this.phoneNumbers.length > 0"
                    >
                      <p class="whitespace-nowrap">หรือโทร</p>
                      <div class="w-full text-center">
                        <app-frame-bg [type]="'contact-tel'">
                          <a
                            href="tel:{{ this.phoneNumbers[0] }}"
                            target="_blank"
                            class="font-bold"
                            >{{ this.phoneNumbers[0] }}</a
                          >
                        </app-frame-bg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-frame-bg>
  </main>
</div>
