<div class="-section-wallet mt-2">
  <app-frame-bg [type]="'wallet-group'">
    <app-theme-line></app-theme-line>
    <div class="container">
      <div class="my-2 p-2 flex justify-between">
        <div class="flex-none w-20">
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/icon-wallet.webp"
          />
        </div>
        <div class="mx-2 flex-auto flex flex-col justify-between">
          <h1 class="text-xl font-bold whitespace-nowrap">ยอดเงินในกระเป๋า</h1>
          <div class="flex justify-start items-center space-x-4">
            <div>
              <img
                src="https://fs.cdnxn.com/lsm99king/king/img/icon-verical-line.webp"
                class="h-[2.5rem] w-[.5rem]"
              />
            </div>
            <div class="flex flex-col justify-around">
              <p class="-color-02 text-base whitespace-nowrap">
                อัพเดทยอดล่าสุด
              </p>
              <p class="text-sm">
                {{ this.lastUpdate | date : "dd/MM HH:mm" }}
              </p>
            </div>
            <div>
              <img
                src="https://fs.cdnxn.com/lsm99king/king/img/btn-refresh.webp"
                class="w-6 cursor-pointer"
                (click)="onRefreshDataUserBalance()"
              />
            </div>
          </div>
          <h1 class="text-xl font-bold space-x-1">
            <b>฿</b
            ><span class="text-credit-balance">{{
              this.creditBalance | number : "1.2-2"
            }}</span>
          </h1>
        </div>
        <div class="flex-none">
          <app-frame-bg [type]="'profile-img'">
            <img
              [src]="this.memberGroupImage"
              class="w-[63px] py-[0.5rem] px-[0.3rem] rounded-2xl"
            />
            <p class="text-sm whitespace-nowrap">{{ this.displayName }}</p>
          </app-frame-bg>
        </div>
      </div>
    </div>

    <app-theme-line></app-theme-line>

    <div class="mb-2">
      <app-theme-label [label]="'ข้อมูลส่วนตัว'"></app-theme-label>
      <div class="flex justify-start items-center space-x-10 mt-2">
        <div class="flex-none space-y-2">
          <p>ยูสเซอร์เนม</p>
          <p>รหัสผ่าน</p>
          <p>เบอร์โทรศัพท์</p>
        </div>
        <div class="flex-auto space-y-2">
          <p>{{ this.displayName }}</p>
          <p>******</p>
          <p>{{ this.displayName }}</p>
        </div>
        <div class="w-28">
          <a [routerLink]="'/changePassword'"
            ><img
              src="https://fs.cdnxn.com/lsm99king/king/img/btn-change-pass.webp"
              class="cursor-pointer"
          /></a>
        </div>
      </div>
    </div>

    <app-theme-line></app-theme-line>
  </app-frame-bg>

  <div
    class="-list-btn"
    *ngIf="
      (!this.activedPromotionId && this.availablePromotions) ||
      (!this.IsActiveBonusWallet && this.availablePromotions)
    "
  >
    <a
      routerLink="/getPromotion"
      class="-active mt-2"
      (click)="onClickSelectPromotion()"
    >
      <span>
        <img
          appImgLazyLoad
          src="assets/img/icons/star.png"
          alt=""
          class="-icon"
        />
        <em>โปรโมชั่นที่คุณรับได้</em>
        <strong>{{ this.totalAvailablePromotions | number }} โปรโมชั่น</strong>
        <img
          src="assets/img/icons/angle-right-white.svg"
          alt=""
          class="-arrow"
        />
      </span>
    </a>
  </div>
</div>
