
<!-- Set Header -->
<div class="-header">
	<h2 class="-text-header">สมัครสมาชิก</h2>
</div>

<!-- <app-main-menu></app-main-menu> -->
<!-- End Set Header -->

<!-- start content this page -->
<main id="main-content">
	<div class="-content-register">
		<div class="container">
			<div class="-section-top">
				<img appImgLazyLoad src="assets/img/theme/register-done.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
				<h2 class="-title">สมัครสมาชิก สำเร็จแล้ว</h2>
				<p>กรุณาตรวจสอบข้อมูลของคุณให้ถูกต้อง
				<br>ก่อนการยืนยันการสมัคร</p>
				<a routerLink="/login" class="-btn-01 mt-8"><span>เข้าเล่นเลย</span></a>
			</div>
		</div>
	</div>
</main> 
<!-- end content this page -->
