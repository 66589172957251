<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>

<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- Alert message -->
<app-modal-alert-message
        *ngIf="showModalAlertMessage"
        [alertMsgContents]="alertMsgContents"
        (isClickCloseModal)="onClickCloseModal($event)">
</app-modal-alert-message>

<!-- start content this page -->
<main id="main-content" class="min-h-screen">
  <div class="-content-playing -game-play -answer">
    <div class="container">
      <div class="-box-title">
        <h1>ผลลัพท์เกมตู้เซฟ</h1>
      </div>
      
      <div class="-list-item gap-2 mt-7">

        <ng-container *ngFor="let item of this.safe_display">
          <!-- <div *ngIf="item.promotionCalValueTypeCode == 'luckybonus-wheel'">
            <a (click)="onClickMiniGameWheel(item)" >
              <span class="-icon">
                <img appImgLazyLoad src="assets/media/game-wheel.png" alt="">
              </span>
              <span class="-text">{{item.name}}</span>
            </a>
          </div> -->

          <div class="-item selected" style="transform: translate(0px, 1px);opacity: 1;filter: grayscale(1);" [ngStyle]="{'filter':item.type === safe_select ? 'grayscale(0)' : 'grayscale(1)'}">
            <a>
              <img appImgLazyLoad src="assets/img/game-safety/{{item.type}}-close.png" alt="safety">
              <img appImgLazyLoad src="assets/img/game-safety/{{item.type}}-open.png" alt="safety">
            </a>
            <div class="-light">
              <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/light.png" alt="light" class="!h-[150%] !w-[150%]"></div>
            </div>
            <div class="-detail">
              <p class="text-[20px] font-medium">โบนัส</p>
              <p class="text-reward text-[25px] font-medium text-[#AF010B] mt-2">{{item.data.text}}</p>
            </div>
          </div>

        </ng-container>


        
        <!-- <div class="-item selected" >
          <a>
            <img appImgLazyLoad src="assets/img/game-safety/rb-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/rb-open.png" alt="safety">
          </a>
          <div class="-light">
            <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/light.png" alt="light" class="!h-[150%] !w-[150%]"></div>
          </div>
          <div class="-detail">
            <p class="text-[20px] font-medium">โบนัส</p>
            <p class="text-reward text-[25px] font-medium text-[#AF010B] mt-2">฿77</p>
          </div>
        </div>
        <div class="-item selected" style="transform: translate(0px, 1px);opacity: 1;filter: grayscale(1);">
          <a>
            <img appImgLazyLoad src="assets/img/game-safety/br-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/br-open.png" alt="safety">
          </a>
          <div class="-light">
            <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/light.png" alt="light" class="!h-[150%] !w-[150%]"></div>
          </div>
          <div class="-detail">
            <p class="text-[20px] font-medium">โบนัส</p>
            <p class="text-reward text-[25px] font-medium text-[#AF010B] mt-2">฿777</p>
          </div>
        </div>
        <div class="-item selected" style="transform: translate(0px, 1px);opacity: 1;filter: grayscale(1);">
          <a>
            <img appImgLazyLoad src="assets/img/game-safety/rr-close.png" alt="safety">
            <img appImgLazyLoad src="assets/img/game-safety/rr-open.png" alt="safety">
          </a>
          <div class="-light">
            <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/light.png" alt="light" class="!h-[150%] !w-[150%]"></div>
          </div>
          <div class="-detail">
            <p class="text-[20px] font-medium">โบนัส</p>
            <p class="text-reward text-[25px] font-medium text-[#AF010B] mt-2">฿7</p>
          </div>
        </div> -->
      </div>

      <div class="container">
          <div class="-detail text-black">
            <a href="game-safety.php" class="-btn-01">กลับหน้าหลัก</a>
          </div>
        </div>
    </div>

    
  </div>
</main> <!-- end content this page -->


