import { environment } from "src/environments/environment";

export class ApiEndpointConstant {
  static AppVersion: string = "1.0.2";

  static BackOfficeApiEndPoint1: string = environment.backendApi;
  static AuthenApiEndPoint: string = environment.authApi;
  static WebSocketEndpoint: string = environment.webSocket;
  static ShortenLinkEndpoint: string = environment.shortenLinkApi;
  static ShortenLinkApiKey: string = environment.shortenLinkApiKey;
  static ShortedLinkTitle: string = environment.shortenLinkTitle;

  static GetBankList: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/Banks?lang=th`;
  static GetMarketingChanels: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/MarketingChannels?lang=th`;
  static PostOtpForRegister: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/RequestOtpForRegister?`;
  static VerifyOtpReference: any = `${this.BackOfficeApiEndPoint1}/Test/TestVerifyOtpSms`;
  static GetNewRegistrationBankAccountName: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/GetNewRegistrationBankAccountName`;
  static GetUrlLoginWithFacebook: any = `${this.AuthenApiEndPoint}/ExternalAuthProvider/facebooklogin`;
  static GetUrlLoginWithLine: any = `${this.AuthenApiEndPoint}/ExternalAuthProvider/linelogin`;
  static PostFacebookInspectCode: any = `${this.AuthenApiEndPoint}/ExternalAuthProvider/FacebookInspectCode`;
  static PostLineInspectCode: any = `${this.AuthenApiEndPoint}/ExternalAuthProvider/LineInspectCode`;
  static PostMemberRegister: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/Register`;
  static PostMemberRegisterMigration: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/RegisterMigration`;
  static PostMemberRegister3rd: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/Register3rd`;
  static PostResetPasswordByUserName: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/ResetPassword`;
  static PostChangeMemberUserPassowrd: any = `${this.BackOfficeApiEndPoint1}/api/user/ChangeMemberUserPassowrd`;
  static GetWithdrawBankList: any = `${this.BackOfficeApiEndPoint1}/api/User/GetWithdrawBankList`;
  static SetMemberNewBankAccount: any = `${this.BackOfficeApiEndPoint1}/api/User/AddNewBankAccount`;
  static PostRequestToWithdraw: any = `${this.BackOfficeApiEndPoint1}/api/User/RequestToWithdraw`;
  static GetUserWithdrawInfo: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserWithdrawInfo`;
  static GetSystemBankAccountTypeList: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/SystemBankAccountTypes?`;
  static GetUserDepositInfo: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserDepositInfo`;
  static GetLastestUserRequestDecimalDeposit: any = `${this.BackOfficeApiEndPoint1}/api/User/GetLastestUserRequestDecimalDeposit`;
  static PostDataUploadSlipQrDeposit: any = `${this.BackOfficeApiEndPoint1}/api/User/QrDeposit`;

  // Login Auth API by external provider
  static PostAuthExternalProvider: any = `${this.AuthenApiEndPoint}/connect/token`;
  static AuthenUserProfile: any = `${this.AuthenApiEndPoint}/connect/userinfo`;
  static CheckUsernameIsAvailable: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/CheckUsernameIsAvailable`;

  // wallet-bank
  static GetUserInfomation: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserInfomation`;
  static GetDecimalDepositRequest: any = `${this.BackOfficeApiEndPoint1}/api/User/GetDecimalDepositRequest?`;
  static GetLastestUserRequestDecimalDepositTimeout: any = `${this.BackOfficeApiEndPoint1}/api/User/GetLastestUserRequestDecimalDepositTimeout?`;
  static PostUploadSlipDecimalDepositTimeout: any = `${this.BackOfficeApiEndPoint1}/api/User/UploadSlipDecimalDepositTimeout`;
  static GetUserProfileImage: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserProfileImage`;

  // history
  static GetDepositWithdrawAllHistory: any = `${this.BackOfficeApiEndPoint1}/api/User/DepositWithdrawalHistory?`;
  static GetMemberWinLoseHistoryByUsername: any = `${this.BackOfficeApiEndPoint1}/api/User/GetBetHistories?`;
  static GetUserNotifications: any = `${this.BackOfficeApiEndPoint1}/api/User/Notifications?`;

  //Lock all user
  static GetClientStatus: any = `${this.AuthenApiEndPoint}/system/GetClientStatus?clientId=lsm-frontend`;

  // Promotion & rebate
  static GetAllPromotion: any = `${this.BackOfficeApiEndPoint1}/api/User/GetAllPromotions`;
  static GetPromotionDisplayCategorie: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/PromotionDisplayCategories`;
  static PostSelectPromotion: any = `${this.BackOfficeApiEndPoint1}/api/User/SelectPromotion`;
  static DeleteUserPromotion: any = `${this.BackOfficeApiEndPoint1}/api/User/DeleteUserPromotion`;
  static GetUserRebateInfo: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserRebateInfo`;
  static GetUserBalance: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserBalance`;
  static PostRewardUserRebate: any = `${this.BackOfficeApiEndPoint1}/api/User/RewardUserRebate`;
  static GetUserAvailablePromotion: any = `${this.BackOfficeApiEndPoint1}/api/User/GetUserAvailablePromotions`;

  //Game
  static GetAllProducts: any = `${this.BackOfficeApiEndPoint1}/api/Game/GetAllProducts`;
  static GetProductUrlForPlay: any = `${this.BackOfficeApiEndPoint1}/api/Game/Launch`;
  static GetAllProductsV3: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/GetAllProviderProducts`;

  // Affliate
  static GetAffliateInfo: any = `${this.BackOfficeApiEndPoint1}/api/User/GetAffliateInfo`;
  static SetRegisterAffliateCount: any = `${this.BackOfficeApiEndPoint1}/api/Onboarding/RegisterAffliateCount`;
  static GetAffliateRegisterType: any = `${this.BackOfficeApiEndPoint1}/api/User/GetAffliateRegisterType`;
  static SetRegisterUserAffliate: any = `${this.BackOfficeApiEndPoint1}/api/User/RegisterAffliate`;
  static SetWithdrawAffliateCommission: any = `${this.BackOfficeApiEndPoint1}/api/user/WithdrawAffliateCommission`;
  static GetAffliateOverviewInfo: any = `${this.BackOfficeApiEndPoint1}/api/User/GetAffliateOverviewInfo?`;
  static GetAffiliateShortenLink: string = `${this.ShortenLinkEndpoint}/api/links`;

  // System
  static GetWebInfo: any = `${this.BackOfficeApiEndPoint1}/api/Dictionaries/GetWebinfo`;

  // Migration
  static checkClientCheckloginV2: any = `${this.BackOfficeApiEndPoint1}/api/AgentMigration/CheckV2MemberLogin`;
  static memberVerify: any = `${this.BackOfficeApiEndPoint1}/api/AgentMigration/MemberVerify`;

  // Minigame
  static miniGameMenu: any = `${this.BackOfficeApiEndPoint1}/api/User/GetPromotionMiniGamesMenus`;
  static checkPromitionLuckyAvailable: any = `${this.BackOfficeApiEndPoint1}/api/User/CheckPromotionLuckyBonusAvailableById`;
  static userPromotionLuckyBonus: any = `${this.BackOfficeApiEndPoint1}/api/User/UsePromotionLuckyBonus`;
  static getPromotionLuckyBonus: any = `${this.BackOfficeApiEndPoint1}/api/User/GetPromotionLuckyBonus`;
  static getPromotionGameResult: any = `${this.BackOfficeApiEndPoint1}/api/User/GetPromotionGameResult`;
  static PostDepositRequestPgwPayment: any = `${this.BackOfficeApiEndPoint1}/api/User/RequestPgwPayment`;
}
