import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';
import { WalletBankService } from './services/wallet-bank.service';
import { SetDisplayPromotion } from '../../shared/services/setDisplayPromotion';
import { Router } from '@angular/router';
import { GameService } from '../game/services/game.service';
import { timer, Subscription } from 'rxjs';
import { miniGameService } from '../../shared/services/minigame.service';
import { ProfileService } from '../profile/services/profile.service';
import { GameConfigConstant } from '../../core/game.config.constant';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements OnInit {
  creditBalance!: number;
  bonusWallet!: number;
  turnoverRemain!: number;
  availablePromotions!: any;
  totalAvailablePromotions!: number;
  userInfo!: any;
  displayName!: string;
  memberGroupImage!: string;
  memberRole!: string;
  gameList: any = [];
  gameLastPlayList: any = [];
  gameHitPlayList: any = [];
  showModal: boolean = false;
  promotionDisplayCategory!: any[];
  sportCategoryData: any;
  casinoCategoryData: any;
  slotCategoryData: any;
  lottoCategoryData: any;
  allPromotions: any;
  activedPromotionId!: number;
  activedPromotion: any;
  richText: any;
  isActiveRichText: boolean = false;
  allowedToRewardPromotion!: boolean;
  imgUrlDesktop: string = '';
  promotionContents: string = '';
  alertMsgContents: string = '';
  showModalAlertMessage: boolean = false;
  showModalPromotion: boolean = false;
  showModalPromotionCompleted: boolean = false;
  isClickCloseModal: boolean = false;
  IsActiveBonusWallet!: boolean;
  promotionForSelect: any;
  deviceInfo!: any;
  isActiveTurnoverRemain!: boolean;
  isActiveWinAmountRemain!: boolean;
  winAmountRemain!: number;
  rewardedBonusAmount: number = 0;
  runningMessageId!: number;
  alertMessageId!: number;
  countDown: Subscription = new Subscription();
  timeLeft: number = 0;
  timeDisplay: string = '';
  minigamemenu!: any;
  checkuseravailable!: any;

  bankList: any;
  withdrawBankList: any[] | undefined;
  telephoneNumber: any;
  username: string = '';

  promoteList: any = [
    'sportOnly',
    'muaypukyok',
    'lottoexit',
    'ab',
    'pg',
    'sexy',
    'ps',
    'sa',
    'hg',
    'dg',
    'pp',
  ];

  private readonly _miniGamePromotionId: string = 'appMiniGamePromotionId';
  newTabGames: string[] = GameConfigConstant.GameInNewTab;

  constructor(
    private _walletBankService: WalletBankService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public setPromotion: SetDisplayPromotion,
    private router: Router,
    private _gameService: GameService,
    private _minigameService: miniGameService,
    private _profileService: ProfileService,
    private _deviceService: DeviceDetectorService
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('appUserInfo') || '{}');
    this.deviceInfo = JSON.parse(
      localStorage.getItem('deviceInfo') || JSON.stringify(this._deviceService)
    );
    this.alertMsgContents = JSON.parse(
      localStorage.getItem('alertMessage') || '""'
    );
  }

  ngOnInit(): void {
    if (
      localStorage.getItem('alertMessage') !=
      localStorage.getItem('lastAlertMessage') &&
      this.alertMsgContents.length > 0
    ) {
      this.showModalAlertMessage = true;
      document.body.style.overflow = 'hidden';
    }

    this.gameLastPlayList = [];
    this.sportCategoryData = [];
    this.casinoCategoryData = [];
    this.slotCategoryData = [];
    this.lottoCategoryData = [];

    this.bankList = JSON.parse(localStorage.getItem('bankList') || '{}');

    this.getMinigame();

    this.onGetDataUserAvailablePromotion();
    this.getDataUserInfomation();
    this.onGetDataUserBalance();
    this.onGetDataGameHitList();
    this.getDataAllPromotion();
    this.onGetLastGamePlay();
    this.getMemberWithdrawBankList();
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
  }

  getMinigame() {
    this.spinner.show();
    this._minigameService.getGameMenu().subscribe({
      next: (data) => {
        this.minigamemenu = data;
        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  getDataUserInfomation() {
    this.spinner.show();
    this._walletBankService.getDataUserInfomation().subscribe({
      next: (data) => {
        this.setDataToFront(data);
        this.getUrlUserProfileImage();
        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataToFront(data: any) {
    this.activedPromotionId = data.activedPromotionId;
    this.activedPromotion = data.activedPromotion;
    this.allowedToRewardPromotion = data.allowedToRewardPromotion;

    if (this.activedPromotion !== null) {
      this.richText = this.activedPromotion.promotionRichText;
      this.timeLeft = Math.floor(
        new Date(this.activedPromotion.expiredDate).getTime() / 1000 -
        (new Date().getTime() / 1000 + new Date().getTimezoneOffset() * 60)
      );

      if (this.timeLeft > 0) {
        this.countDown = timer(0, 1000).subscribe(() => {
          if (this.timeLeft == 0) {
            this.timeDisplay = 'หมดเวลา';
            this.countDown.unsubscribe();
            setTimeout(() => {
              this.onGetDataUserBalance();
            }, 3000);
          } else {
            this.timeDisplay = '';
            let hours = Math.floor(this.timeLeft / 3600);
            let minutes = Math.floor((this.timeLeft / 60) % 60);
            let seconds = Math.floor(this.timeLeft % 60);
            let unit;

            if (hours > 0) {
              this.timeDisplay += hours + ':';
              unit = unit ?? ' ชั่วโมง';
            }
            if (minutes > 0 || hours > 0) {
              this.timeDisplay += ('0' + minutes).slice(-2) + ':';
              unit = unit ?? ' นาที';
            }
            this.timeDisplay += ('0' + seconds).slice(-2) + (unit ?? ' วินาที');

            this.timeLeft--;
          }
        });
      }
    }

    this.displayName = this.userInfo.name;
    this.memberRole = this.userInfo.role;
  }

  onGetDataUserAvailablePromotion() {
    this.spinner.show();
    this._walletBankService.getDataUserAvailablePromotion().subscribe({
      next: (data) => {
        if (data) {
          const promotionData = data.filter((item: any) => {
            return item.promotionBasicTypeCode != 'manual';
          });
          this.promotionForSelect = promotionData;
        } else {
          this.promotionForSelect = [];
        }
        this.availablePromotions =
          this.promotionForSelect.length > 0 ? true : false;
        this.totalAvailablePromotions =
          this.availablePromotions == true ? this.promotionForSelect.length : 0;

        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  onRefreshDataUserInfo() {
    this.getDataUserInfomation();
  }

  onRefreshDataUserBalance() {
    this.onGetDataUserBalance();
  }

  onGetDataGameHitList() {
    let allGameList = localStorage.getItem('allGameList');
    if (allGameList) this.gameList = JSON.parse(allGameList);
    else {
      this._gameService.getDataAllProductsV3().subscribe({
        next: async (games) => {
          const product = await games.data.filter((item: any) => {
            return item.status == 'Active';
          });

          await product.sort((a: any, b: any) => {
            return a.displayOrder - b.displayOrder;
          });

          this.gameList = await product;
          localStorage.setItem('allGameList', JSON.stringify(this.gameList));
        },
        error: (e) => {
          console.error(e);
          this.spinner.hide();
        },
      });
    }

    this.gameHitPlayList = this.gameList.filter((item: any) => {
      item.imageUrl = 'assets/img/brand/' + item.code + '.png';
      item.category = item.tags[0].slug;
      switch (item.category) {
        case 'game':
          item.targetLink = '/game/gameListing';
          break;
        case 'casino':
          item.targetLink = '/game/casinoListing';
          break;
        case 'sport':
          item.targetLink = '/game/sportListing';
          break;
        case 'lotto':
          item.targetLink = '/game/lottoListing';
          break;
      }
      return this.promoteList.find((x: any) => x == item.code);
    });
  }

  onCleanBonusWallet() {
    this.spinner.show();
    this._walletBankService.getDataUserBalance().subscribe({
      next: (data) => {
        this.handleDataUserBalance(data);
        this.deleteUserPromotion();
      },
      error: (e) => {
        console.error(e);
        this.deleteUserPromotion();
      },
    });
  }

  deleteUserPromotion() {
    this._walletBankService.deleteUserPromotion().subscribe({
      next: async (data) => {
        this.showModal = !this.showModal;
        this.toastr.clear();

        await this.onGetDataUserBalance();
        await this.getDataUserInfomation();
        this.spinner.hide();
      },
      error: (e) => {
        this.toastr.clear();
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  getDataAllPromotion() {
    let cacheApiDataAllPromotion: any = localStorage.getItem('apiDataAllPromotion');
    cacheApiDataAllPromotion = cacheApiDataAllPromotion ? JSON.parse(cacheApiDataAllPromotion) : null;

    let cacheDataPromotionDisplayCategory: any = localStorage.getItem('apiDataPromotionDisplayCategory');
    cacheDataPromotionDisplayCategory = cacheDataPromotionDisplayCategory ? JSON.parse(cacheDataPromotionDisplayCategory) : null;

    if (cacheDataPromotionDisplayCategory === null) {
      this._walletBankService.getDataPromotionDisplayCategory().subscribe({
        next: (data) => {
          this.promotionDisplayCategory = data;
          localStorage.setItem('apiDataPromotionDisplayCategory', JSON.stringify(data))
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.promotionDisplayCategory = cacheDataPromotionDisplayCategory;
    }

    if (cacheApiDataAllPromotion === null) {
      this._walletBankService.getDataAllPromotion().subscribe({
        next: (data) => {
          let newData = this.setPromotion.canDisplayPromotion(data);
          localStorage.setItem('apiDataAllPromotion', JSON.stringify(newData))
          this.onSetDataToGroup(newData);
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.onSetDataToGroup(cacheApiDataAllPromotion);
    }
  }

  onSetDataToGroup(dataSource: any) {
    let sportCategoryData: any[] = [];
    let casinoCategoryData: any[] = [];
    let slotCategoryData: any[] = [];
    let lottoCategoryData: any[] = [];

    dataSource.forEach((e: any) => {
      switch (e.promotionDisplayCategoryId) {
        case 1:
          if (sportCategoryData.length < 2) {
            sportCategoryData.push(e);
          }
          break;

        case 2:
          if (casinoCategoryData.length < 2) {
            casinoCategoryData.push(e);
          }
          break;

        case 3:
          if (slotCategoryData.length < 2) {
            slotCategoryData.push(e);
          }
          break;

        case 4:
          if (lottoCategoryData.length < 2) {
            lottoCategoryData.push(e);
          }
          break;

        default:
          break;
      }
    });

    this.sportCategoryData = sportCategoryData;
    this.casinoCategoryData = casinoCategoryData;
    this.slotCategoryData = slotCategoryData;
    this.lottoCategoryData = lottoCategoryData;
    this.allPromotions = dataSource;
  }

  onToggleRichText() {
    this.isActiveRichText = !this.isActiveRichText;
  }

  onGetDataUserBalance() {
    this.spinner.show();
    this._walletBankService.getDataUserBalance().subscribe({
      next: (data) => {
        this.handleDataUserBalance(data);
        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  handleDataUserBalance(data: any) {
    this.creditBalance = data.creditBalance;
    this.bonusWallet = data.bonusWallet;
    console.log('bonus', data);
    this.IsActiveBonusWallet = data.isActiveBonusWallet;

    this.isActiveTurnoverRemain = data.isActiveTurnoverRemain;
    this.turnoverRemain = data.turnoverRemain;
    this.isActiveWinAmountRemain = data.isActiveWinAmountRemain;
    this.winAmountRemain = data.winAmountRemain;

    this.showModalPromotionCompleted = data.isPromotionCompleted;
    this.rewardedBonusAmount = data.rewardedBonusAmount;
  }

  onClickMiniGameWheel(miniGameItem: any) {
    this.spinner.show();
    this._minigameService
      .checkPromitionLuckyAvailable(miniGameItem.id)
      .subscribe({
        next: (data) => {
          this.checkuseravailable = data;
          if (this.checkuseravailable.isAvailable) {
            localStorage.setItem(this._miniGamePromotionId, miniGameItem.id);
            this.router
              .navigateByUrl('/luckywheel')
              .then(() => this.spinner.hide());
          } else {
            this.alertMsgContents = this.checkuseravailable.reason;
            this.showModalAlertMessage = true;
            this.spinner.hide();
          }
        },
        error: (e) => {
          console.error(e);
        },
      });

    // this.promotionContents = dataPromotion.promotionRichText;
    // document.body.style.overflow = 'hidden';
  }

  onClickMiniGameSafe(miniGameItem: any) {
    this.spinner.show();
    this._minigameService
      .checkPromitionLuckyAvailable(miniGameItem.id)
      .subscribe({
        next: (data) => {
          this.checkuseravailable = data;
          if (this.checkuseravailable.isAvailable) {
            localStorage.setItem(this._miniGamePromotionId, miniGameItem.id);
            this.spinner.hide();
            this.router.navigateByUrl('/safe-list');
          } else {
            this.alertMsgContents = this.checkuseravailable.reason;
            this.showModalAlertMessage = true;
            this.spinner.hide();
          }
        },
        error: (e) => {
          console.error(e);
        },
      });

    // this.promotionContents = dataPromotion.promotionRichText;
    // document.body.style.overflow = 'hidden';
  }

  onClickPromotion(dataPromotion: any) {
    this.showModalPromotion = true;
    this.imgUrlDesktop = dataPromotion.imgUrlDesktop;
    this.promotionContents = dataPromotion.promotionRichText;
    document.body.style.overflow = 'hidden';
  }

  onClickCloseModal(event: any) {
    this.showModalPromotion = !event;
    this.showModalAlertMessage = !event;
    this.showModalPromotionCompleted = !event;
    document.body.style.overflow = '';
  }

  onClickSelectPromotion() {
    localStorage.setItem(
      'promotionForSelect',
      JSON.stringify(this.promotionForSelect)
    );
  }

  onGetLastGamePlay() {
    let gameSportPlayLasted: any = localStorage.getItem('gameSportPlayLasted');
    if (gameSportPlayLasted) {
      gameSportPlayLasted = JSON.parse(gameSportPlayLasted);
      this.onSetLastGamePlay(gameSportPlayLasted);
    }

    let gameCasinoPlayLasted: any = localStorage.getItem(
      'gameCasinoPlayLasted'
    );
    if (gameCasinoPlayLasted) {
      gameCasinoPlayLasted = JSON.parse(gameCasinoPlayLasted);
      this.onSetLastGamePlay(gameCasinoPlayLasted);
    }

    let gameLottoPlayLasted: any = localStorage.getItem('gameLottoPlayLasted');
    if (gameLottoPlayLasted) {
      gameLottoPlayLasted = JSON.parse(gameLottoPlayLasted);
      this.onSetLastGamePlay(gameLottoPlayLasted);
    }

    let gameSlotPlayLasted: any = localStorage.getItem('gameSlotPlayLasted');
    if (gameSlotPlayLasted) {
      gameSlotPlayLasted = JSON.parse(gameSlotPlayLasted);
      this.onSetLastGamePlay(gameSlotPlayLasted);
    }
  }

  onSetLastGamePlay(list: any) {
    list.slice(0, 4).forEach((game: any) => {
      this.gameLastPlayList.push(game);
    });
  }

  onClickGameLaunch(item: any) {
    if (GameConfigConstant.GenNewToken.includes(item.code)) {
      this._gameService
        .getDataProductUrlForPlayGameList(
          item.brand,
          item.category,
          item.code,
          this.deviceInfo.deviceType
        )
        .subscribe({
          next: (data) => {
            this.spinner.hide();
            this.openGame(data.value, this.newTabGames.includes(item.code));
          },
          error: (e) => {
            console.error(e);
            this.spinner.hide();
          },
        });
    } else if (item.linkPlayGame == null) {
      this.spinner.show();
      this._gameService
        .getDataProductUrlForPlay(
          item.brand,
          item.category,
          this.deviceInfo.deviceType
        )
        .subscribe({
          next: (data) => {
            this.spinner.hide();
            this.openGame(data.value, this.newTabGames.includes(item.code));
          },
          error: (e) => {
            console.error(e);
            this.spinner.hide();
          },
        });
    } else {
      this.openGame(item.linkPlayGame, this.newTabGames.includes(item.code));
    }
  }

  openGame(url: string, isNewTab: boolean = false) {
    const isSafari =
      this.deviceInfo?.browser?.toLowerCase()?.indexOf('safari') > -1;
    if (isNewTab) {
      if (isSafari) {
        setTimeout(() => {
          window.open(
            url,
            '_blank',
            'toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes'
          );
        });
        return;
      }
      window.open(url, '_blank');
      return;
    }

    if (url.indexOf('http:') != 0 && url.indexOf('hg.lsmapi.com') == -1)
      this.router.navigate(['game/player'], { queryParams: { url: url } });
    else if (!isSafari) window.open(url, '_blank');
    else
      setTimeout(() => {
        window.open(
          url,
          '_blank',
          'toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes'
        );
      });
  }

  getUrlUserProfileImage() {
    this._walletBankService.getUrlUserProfileImage().subscribe({
      next: (data) => {
        this.memberGroupImage = data.value
          ? data.value
          : 'assets/img/icons/img-profile.png';
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  getMemberWithdrawBankList() {
    this._profileService.getDataWithdrawBankList().subscribe({
      next: (data) => {
        this.setDataMemberWithdrawBankList(data.collection);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataMemberWithdrawBankList(sourceData: any) {
    if (sourceData) {
      let packData: any[] = [];
      sourceData.forEach((e: any) => {
        let bankId = Number(e.registBankId) - 1;
        e.bankAccountName = this.bankList[bankId].name;
        let bCode = this.bankList[bankId].code;
        e.bankAccountCode = bCode.toUpperCase();
        e.registBankLogoUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${e.bankAccountCode}.png`;

        // bankAccountStatusClass
        if (e.isApproved == true) {
          e.bankAccountStatusClass = 'use';
          e.bankAccountStatusName = 'ตรวจสอบบัญชีสำเร็จแล้ว';
        } else if (e.isApproved == false && e.isRejected == false) {
          e.bankAccountStatusClass = 'wait';
          e.bankAccountStatusName = 'กรุณารอตรวจสอบบัญชีสักครู่ค่ะ';
        } else if (e.isRejected == true) {
          e.bankAccountStatusClass = 'wait';
          e.bankAccountStatusName = 'บัญชีที่ไม่ได้ใช้งาน';
        } else {
          e.bankAccountStatusClass = 'wait';
          e.bankAccountStatusName = 'ไม่ทราบ ติดต่อผู้ดูแลระบบ';
        }

        packData.push(e);
      });
      this.withdrawBankList = packData;
      this.displayName = this.userInfo.name;
      this.memberRole = this.userInfo.role;
      this.telephoneNumber = this.userInfo.phoneNumber
        ? this.userInfo.phoneNumber
        : this.telephoneNumber;
      this.username = this.userInfo.preferred_username;
    }
  }
}
