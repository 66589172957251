import { Component, OnInit } from '@angular/core';
// import { SafeService } from 'src/app/shared/services/safe.services';
import "./safe-list.component.scss";
import { miniGameService } from 'src/app/shared/services/minigame.service';

declare function animateGameCard(): any;
declare function randomReward(): any;
declare function GameResultSafety(): any;

@Component({
  selector: 'app-game',
  templateUrl: './safe-list.component.html',
  styleUrls: ['./safe-list.component.scss'] 
})


export class SafeListComponent implements OnInit {
  websiteName: string = '';
  result: any;
  bonus: string = '';
  resultBonus: string = '';

  constructor() { }

  ngOnInit(): void {
    
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }

    // setTimeout(()=>{
    //   animateGameCard();
    // }, 2000);
  }
}
