import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, retry, throwError } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthenInterceptor implements HttpInterceptor {
  private readonly _userKey: string = "appUser";
  isShowMobileMenu: boolean | undefined;
  constructor(
    private readonly _authService: AuthService,
    private readonly _router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // return next.handle(request);
    let _userKey = localStorage.getItem(this._userKey)
   
    return next.handle(request.clone({      
      setHeaders: {
        Authorization: `Bearer ${_userKey}`,
        //Authorization: `Bearer 3Hv2ZA==`,
        // Authorization: `Bearer 22F272CFA3E4A315C4C8E4ACD6438491DFAEA7E41B78651A4AE4F60B2D0ECC7B`,
      },
    })).pipe(
      // retry(1),
      catchError((returnedError) => {
        let errorMessage = null;

        if (returnedError.error instanceof ErrorEvent) {
          errorMessage = `Error: ${returnedError.error.message}`;
        } else if (returnedError instanceof HttpErrorResponse) {
          errorMessage = `Error Status ${returnedError.status}: ${returnedError.error.error} - ${returnedError.error.message}`;
          this.handleServerSideError(returnedError);
        } 

        return of(returnedError);
      })
    );
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled: boolean = false;

    switch (error.status) {
      case 400:
        throw error;
      case 401:
        console.log("401");
        this._authService.logout$().subscribe(x => {
          this.isShowMobileMenu = false;
          this._router.navigate(['login']);
        });
        break;
      case 403:
        this._authService.logout$().subscribe(x => {
          this.isShowMobileMenu = false;
          this._router.navigate(['login']);
        });
        break;
    }

    return handled;
  }
}
