<div class="mt-4">
  <main id="main-content" [ngClass]="{ 'w-sm': this.size === 'w-sm' }">
    <app-frame-bg [type]="'contact-box'">
      <div class="-content-playing p-3">
        <div class="container">
          <div class="ml-3">
            <app-theme-label [label]="'ติดต่อเรา'"></app-theme-label>
          </div>

          <div class="block">
            <div class="mt-4 px-6 md:px-2 text-center">
              <p class="font-bold">แสกน QR Code หรือกดปุ่มแอดไลน์</p>
              <p class="text-sm text-[#B5B5B5]">
                พวกเราเพียบพร้อมไปด้วยทีมงานมืออาชีพที่ทำงานกัน <br />
                อย่างไม่หยุดพักติดต่อเราได้เลยตามช่องทางด้านล่างนี้
              </p>
            </div>

            <div class="mt-2">
              <div class="px-4 space-x-4 flex justify-around">
                <div class="flex-none">
                  <img
                    appImgLazyLoad
                    src="https://fs.cdnxn.com/lsm99king/king/img/qrcode.webp"
                    class="w-[100px]"
                  />
                </div>
                <div class="flex-1 flex flex-col justify-center items-center">
                  <a href="{{ this.linkLine }}" target="_blank">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/line.webp"
                      class="w-[271px]"
                    />
                  </a>
                  <div
                    class="flex flex-col justify-center items-center w-full"
                    *ngIf="this.phoneNumbers && this.phoneNumbers.length > 0"
                  >
                    <p class="py-2">หรือโทร</p>
                    <div class="w-full text-center">
                      <app-frame-bg [type]="'contact-tel'">
                        <a
                          href="tel:{{ this.phoneNumbers[0] }}"
                          target="_blank"
                          class="font-bold"
                          >{{ this.phoneNumbers[0] }}</a
                        >
                      </app-frame-bg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-frame-bg>
  </main>
</div>
