import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-contact-box",
  templateUrl: "./contact-box.component.html",
  styleUrls: ["./contact-box.component.css"],
})
export class ContactBoxComponent implements OnInit {
  lineId!: string;
  linkLine!: string;
  phoneNumbers: any = [];
  @Input() size = "";

  constructor() {}

  ngOnInit(): void {
    this.lineId = JSON.parse(localStorage.getItem("lineId") ?? '""');
    this.linkLine = JSON.parse(localStorage.getItem("linkLine") ?? '""');
    let phoneList = JSON.parse(localStorage.getItem("phoneNumbers") ?? '""');
    if (phoneList !== "") this.phoneNumbers = phoneList.split(",");
  }
}
