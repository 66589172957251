import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';
import { UserAddBankModel } from 'src/app/models/user-add-bank.model';


const setMemberNewBankAccountBaseUrl = ApiEndpointConstant.SetMemberNewBankAccount;


@Injectable({
  providedIn: 'root'
})

export class AddBankService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  addMemberNewBankAccount(setData: UserAddBankModel) {
    return this._httpClient.post<UserAddBankModel>(`${setMemberNewBankAccountBaseUrl}`, setData);
  } 

}
