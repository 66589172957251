import { Component, OnInit } from '@angular/core';
import { HistoryService } from '../services/history.service';
import { data } from 'autoprefixer';
import { HistoryWinLostModel } from '../../../models/history-win-lost.model';

@Component({
  selector: 'app-history-player',
  templateUrl: './history-player.component.html',
  styleUrls: ['./history-player.component.css'],
})
export class HistoryPlayerComponent implements OnInit {
  take: number = 10;
  skip: number = 0;
  dataHistoryList: any = [];
  username: string = '';
  colorPlus: string = 'green';

  total!: number;
  limitPerPage: number = 10;
  totalPage: number = 0;
  totalPageList: any = [];
  currentPage: number = 0;
  userInfo!: any;

  constructor(private _historyService: HistoryService) {}

  ngOnInit(): void {
    let userInfo!: any;
    userInfo = localStorage.getItem('appUserInfo');
    this.userInfo = JSON.parse(userInfo);

    this.getDataWinLostHistory();
  }

  getDataWinLostHistory() {
    this._historyService
      .getDataMemberWinLoseHistoryByUsername(this.take, this.skip)
      .subscribe({
        next: (data) => {
          console.log('data', data);
          this.dataHistoryList = data.collection;
          this.total = data.total;

          // this.dataHistoryList = [
          //   {
          //     createdDate: new Date(),
          //     round: "5345141",
          //     brand: "WM Casino",
          //     gameName: "pg",
          //     betStatus: "Success",
          //     betAmount: 100,
          //     winlose: 0.5,
          //   },
          //   {
          //     createdDate: new Date(),
          //     round: "5345142",
          //     brand: "WM Casino",
          //     gameName: "pg",
          //     betStatus: "Success",
          //     betAmount: 100,
          //     winlose: 0.5,
          //   },
          //   {
          //     createdDate: new Date(),
          //     round: "5345143",
          //     brand: "WM Casino",
          //     gameName: "pg",
          //     betStatus: "Fail",
          //     betAmount: 100,
          //     winlose: 0.5,
          //   },
          //   {
          //     createdDate: new Date(),
          //     round: "5345144",
          //     brand: "WM Casino",
          //     gameName: "pg",
          //     betStatus: "Fail",
          //     betAmount: 100,
          //     winlose: 0.5,
          //   },
          // ];

          // console.log(this.dataHistoryList);

          this.onPaginator(this.currentPage);
        },
        error: (e) => {
          console.error(e);
        },
      });
    /* 
    let collection = [];
    collection = [
      {
        no: 0,
        betId: 1,
        betTime: '2022-02-26T07:40:20',
        betDetail: {
          ref: 'asdzz3333xxxdafd',
          roundId: 1,
          game: 'mario'
        },
        amount: 2,
        winLose: 10,
        total: 4
      },
      {
        no: 0,
        betId: 1,
        betTime: '2022-02-26T07:40:20',
        betDetail: {
          ref: 'asdzz3333xxxdafd',
          roundId: 1,
          game: 'mario'
        },
        amount: 2,
        winLose: 10,
        total: 4
      },
      {
        no: 0,
        betId: 1,
        betTime: '2022-02-26T07:40:20',
        betDetail: {
          ref: 'asdzz3333xxxdafd',
          roundId: 1,
          game: 'mario'
        },
        amount: 2,
        winLose: 10,
        total: 4
      },
      {
        no: 0,
        betId: 1,
        betTime: '2022-02-26T07:40:20',
        betDetail: {
          ref: 'asdzz3333xxxdafd',
          roundId: 1,
          game: 'mario'
        },
        amount: 2,
        winLose: 10,
        total: 4
      }
    ]

    this.dataHistoryList = collection
 */
  }

  onPaginator(index: number) {
    this.totalPage = Math.ceil(this.total / this.limitPerPage);
    this.totalPageList = [];

    if (index == 0) {
      this.currentPage = 0;
    }

    let beforeCurrentPage = 0;
    let afterCurrentPage = 0;
    if (this.currentPage == 0) {
      beforeCurrentPage = this.currentPage;
      afterCurrentPage = this.currentPage + 2;
    } else {
      beforeCurrentPage = this.currentPage - 1;
      afterCurrentPage = this.currentPage + 2;
      if (afterCurrentPage > this.totalPage) {
        afterCurrentPage = this.totalPage;
      }
    }

    for (let i = beforeCurrentPage; i < afterCurrentPage; i++) {
      this.totalPageList.push(i);
    }
  }

  paginateEvent(page: any) {
    if (page == 0) {
      this.currentPage = 0;
      this.skip = 0;
      this.take = 10;
    } else {
      this.currentPage = page - 1;
      this.skip = this.currentPage * this.take;

      // this.getDataDepositWithdrawAllHistory();
      // if (this.keyword!=undefined && this.keyword!="") {
      //   this.onSearchData();
      // } else {
      //   this.getTransactionBank();
      // }
    }

    this.getDataWinLostHistory();
  }
}
