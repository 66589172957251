import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  lineId!: string;
  linkLine!: string;
  phoneNumbers: any = [];

  constructor() {}

  ngOnInit(): void {
    this.lineId = JSON.parse(localStorage.getItem("lineId") ?? '""');
    this.linkLine = "https://line.me/R/ti/p/~@99KING";
    let phoneList = JSON.parse(localStorage.getItem("phoneNumbers") ?? '""');
    if (phoneList != "") this.phoneNumbers = phoneList.split(",");
  }
}
