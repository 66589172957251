<app-modal-with-rich-text *ngIf="showModalPromotion" [imgUrl]="imgUrlDesktop" [promotionContents]="promotionContents"
  (isClickCloseModal)="onClickCloseModal($event)" [isReadOnly]="true">
</app-modal-with-rich-text>

<!-- <div class="-title text-left">
  <h2>โปรโมชั่น</h2>
</div> -->

<div class="flex-cols">
  <div *ngFor="let item of promotions">
    <div class="-list-img my-6">
      <a>
        <img appImgLazyLoad (click)="onClickPromotion(item)" [src]="
            item.imgUrlDesktop
              ? item.imgUrlDesktop
              : 'assets/img/uploads/promotion.jpg'
          " alt="{{ item.name }}" />
      </a>
    </div>
  </div>

  <div class="-box-btn -group-promotion-btn-see-all">
    <a routerLink="/promotionList" class="">
      <span>ดูโปรโมชั่นทั้งหมด</span>
    </a>
  </div>
</div>