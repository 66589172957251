import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import {WebSocketService} from "../../shared/services/web-socket.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService,
    private readonly _webSocketService: WebSocketService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // return this.checkLogin(state.url);
    let isLogin = this.checkLogin(state.url);
    console.log(`Login auth: ${isLogin}`);
    if (isLogin){
      // connect web socket here
      this._webSocketService.connectWebSocket();
    }
    return isLogin;
  }

  checkLogin(redirectUrl?: string): boolean {
    if (this._authService.isLoggedIn) {
      return true;
    }

    this._authService.redirectUrl = redirectUrl;
    this._router.navigate(['login']);

    return false
  }
  
}
