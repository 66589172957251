import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-label',
  templateUrl: './theme-label.component.html',
  styleUrls: ['./theme-label.component.css'],
})
export class ThemeLabelComponent implements OnInit {
  @Input() label = '';

  constructor() {}

  ngOnInit(): void {}
}
