<div class="fixed top-0 z-20 inset-0 overflow-y-auto">
    <div class="flex justify-center">
        <div class="fixed inset-0 opacity" (click)="onClickCloseModal()">
            <div class="absolute inset-0"></div>
        </div>
        <div
                class="-box-rebate overflow-hidden sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
        >
            <div class="px-4 py-3">
                <div class="-box-btn">
                    <a class="-btn-02" (click)="onClickCloseModal()">
                        <span>ปิด</span>
                    </a>
                </div>
            </div>

            <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:text-left">
                        <div class="mt-2">
                            <div id="filter-all" class="-list-game -filter-item">
                                <div *ngFor="let item of gameAllList">
                                    <a (click)="onClickGameLaunch(item)" class="-new" target="_blank">
                                        <img appImgLazyLoad [src]="item.properties['imageURL']" alt="" style="width:230px">
                                        <span>
                                          <em>
                                            <img appImgLazyLoad src="assets/img/icons/play.svg" alt="">
                                              {{item.name}}
                                          </em>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4 py-3">
                <div class="-box-btn">
                    <a class="-btn-02" (click)="onClickCloseModal()">
                        <span>ปิด</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>