<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>
<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- <div class="-desktop">
  <div class="deposit-withdraw-bg2 mt-[5rem]">
    <a class="text-deposit-withdraw1" routerLink="/deposit">
      <div class="flex items-center space-x-1">
        <video
          autoplay
          loop
          poster="https://fs.cdnxn.com/lsm99king/king/img/withdraw.gif"
          class="w-[30px]"
        >
          <source
            type="video/webm"
            src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.webm"
            class="w-[30px]"
          />
        </video>
        <span class="span-deposit-withdraw"> ฝากเงิน </span>
      </div>
    </a>
    <a class="text-deposit-withdraw0" routerLink="/withdraw">
      <div class="flex items-center space-x-1">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/deposit.webp"
          class="w-[30px]"
        />
        <span class="span-deposit-withdraw"> ถอนเงิน </span>
      </div>
    </a>
  </div>
</div> -->
<div class="group-deposit--">
  <div class="--mobile -mb-deposit-section">
    <div class="mx-2rem">
      <div class="deposit-withdraw-bg2-mb">
        <a class="text-deposit-withdraw1" routerLink="/deposit">
          <div class="flex items-center space-x-1">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.gif"
              class="w-[30px]"
            />
            <span class="span-deposit-withdraw"> ฝากเงิน </span>
          </div>
        </a>
        <a class="text-deposit-withdraw0" routerLink="/withdraw">
          <div class="flex items-center space-x-1">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/deposit.webp"
              class="w-[30px]"
            />
            <span class="span-deposit-withdraw"> ถอนเงิน </span>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="running-message--">
    <app-snackbar-running-message
      class="-deposit-snackbar-running"
    ></app-snackbar-running-message>
  </div>
</div>

<div class="">
  <div
    class="mt-7 mx-auto -is-upload-slip-box"
    *ngIf="this.isUpload && this.isMemberBankAccountVerified"
  >
    <!-- <div class="mx-auto -is-upload-slip-box"> -->
    <div
      [ngClass]="this.preview ? 'block-is-upload-slip' : 'flex-is-upload-slip'"
    >
      <div class="p-[1rem] mx-auto -upload-slip-left">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/text-uploadslip.webp"
          class="w-[100%]"
          alt=""
        />
        <div>
          <h4 class="text-center">
            อัพโหลดภาพสลิปหลักฐานการโอนเงินจากธนาคารที่มีรูป QR Code
            เพื่อแจ้งยอดฝาก ยอดเงินจะเข้าสู่ระบบภายในเวลา 1 นาทีค่ะ
          </h4>
        </div>
      </div>

      <form class="-form-style p-[1rem] mx-auto -upload-slip-right">
        <div class="-row -required">
          <div id="reader"></div>
          <label
            for="fileSlip"
            class="-form-input-mb"
            style="cursor: pointer"
            >{{ fileImageName }}</label
          >

          <div class="-slip-backdrop">
            <label for="fileSlip" class="previewSlip">
              <div class="-errorMsgPriview" *ngIf="this.errorMessage">
                {{ this.errorMessage }}
              </div>
              <img src="{{ this.preview }}" />
            </label>
            <input
              type="file"
              (change)="onFilechange($event)"
              class="hidden"
              id="fileSlip"
              accept="image/jpeg,image/jpg,image/gif,image/png"
            />
            <div class="-slip-backdrop-2" *ngIf="this.errorMessage"></div>
          </div>
        </div>
        <button
          (click)="confirmDepositAndUploadSlip()"
          [disabled]="this.isDisabled"
        >
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/btn-uploadslip.webp"
            alt=""
            class="mx-auto"
          />
        </button>
      </form>
    </div>
  </div>
</div>
<!-- 
<div class="-desktop pb-[5rem]">
  <div class="flex justify-center mt-4">
    <div style="width: 40%">
      <div class="-content-bank">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/text-depositBankAccount.webp"
          class="w-[80%] mx-auto"
        />
        <div class="py-4 text-center text-gray-400">
          <p>คัดลอกเลขบัญชี เพื่อใช้โอนเงินผ่านบัญชีของคุณ</p>
          <p>ระบุหมายเหตุ “ใช้สำหรับเล่น LSM99King เท่านั้น”</p>
        </div>
        <div class="-inner my-2 w-sm mx-auto">
          <div class="-your-account mt-0 text-lg">
            <div class="-items mt-2">
              <div
                class="-item"
                *ngFor="let item of systemBankAccountTypeDepositExpressList"
              >
                <div class="-inner items-center">
                  <div class="-img">
                    <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                  </div>
                  <div class="-text">
                    <p class="text-sm">
                      {{ item.bankName }}
                    </p>
                    <p class="text-xl font-bold">
                      <strong>{{ item.bankAccount }}</strong>
                    </p>
                    <p class="text-sm whitespace-nowrap text-truncate">
                      {{ item.accountName ?? item.bankAccountStatusName }}
                    </p>
                  </div>
                  <div class="flex-none w-[100px] pl-4">
                    <a
                      (click)="copyBankAccountNo(item.bankAccount)"
                      class="cursor-pointer"
                    >
                      <img
                        appImgLazyLoad
                        src="https://fs.cdnxn.com/lsm99king/king/img/btn-addbank.webp"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-theme-line></app-theme-line>
      <div class="my-3">
        <div class="flex justify-center">
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/label-for-deposit.webp"
            class="w-[250px]"
          />
        </div>
      </div>
      <app-theme-line></app-theme-line>
    </div>

    <div class="mt-[1rem]">
      <app-bank-list [frame]="'bank-list-resp'"></app-bank-list>
    </div>
  </div>
</div> -->
<div class="group-deposit--">
  <div class="group-deposit---">
    <div class="group-ss-deposit">
      <div
        class="group-from-payment--"
        *ngIf="
          this.isActiveQrPgw &&
          this.minPgwQrPayDepositAmount &&
          this.maxPgwQrPayDepositAmount &&
          this.isMemberBankAccountVerified
        "
      >
        <div class="bg-tab-text--">
          <app-theme-label [label]="'ฝากโดย QR Payment'"></app-theme-label>
        </div>
        <div class="py-4 text-start text-gray-400">
          <h4>เลือกจำนวนเงินที่ต้องการฝาก</h4>
          <p>
            ยอดเงินขั้นต่ำในการฝาก
            {{ this.minPgwQrPayDepositAmount | number }} บาท สูงสุดไม่เกิน
            {{ this.maxPgwQrPayDepositAmount | number }} บาท
          </p>
        </div>
        <div class="group-from-Payment">
          <div class="-form-style -form-money">
            <form [formGroup]="pgwDepositForm">
              <div class="Deposit-from-num">
                <div
                  class="-form-radio"
                  *ngFor="let item of pgwdepositAmountList; index as i"
                >
                  <input
                    type="radio"
                    name="money"
                    id="money-{{ i + 1 }}"
                    (click)="selectPgwDepositAmount(item)"
                  />
                  <label
                    for="money-{{ i + 1 }}"
                    class="--form-input"
                    style="cursor: pointer"
                    >{{ item | number }}</label
                  >
                </div>
              </div>
              <div class="-row">
                <app-theme-text-input>
                  <input
                    type="number"
                    class="-form-input"
                    [(ngModel)]="pgwDepositAmount"
                    formControlName="pgwDepositAmountForm"
                    placeholder="หรือระบุจำนวนเงิน"
                  />
                </app-theme-text-input>
              </div>
            </form>
            <div class="w-[240px] mt-4 mx-auto">
              <app-theme-button>
                <button
                  class="-btn-01"
                  (click)="confirmPgwDeposit()"
                  [disabled]="this.isDisabled"
                >
                  ยืนยันการฝากเงิน
                </button>
              </app-theme-button>
            </div>
            <p class="py-4 text-center text-gray-400">
              หากเงินไม่เข้าภายใน 5 นาที
              <a routerLink="/contact">โปรดติดต่อแอดมิน</a>
            </p>
          </div>
        </div>
      </div>
      <div
        class="bg-tab-text--"
        style="display: flex; align-items: center; column-gap: 1rem"
      >
        <app-theme-label [label]="'ฝากเงินอัตโนมัติ'"></app-theme-label>
        <p
          class="text-center text-gray-400"
          style="font-size: 10px; padding: 0rem"
        >
          รวดเร็ว ปลอดภัย เข้าบัญชีไว ภายใน 1 นาที
        </p>
      </div>
      <div class="automatic-deposit--">
        <div class="py-4 text-center text-gray-400">
          <p>คัดลอกเลขบัญชี เพื่อใช้โอนเงินผ่านบัญชีของคุณ</p>
          <p>ระบุหมายเหตุ “ใช้สำหรับเล่น LSM99King เท่านั้น”</p>
        </div>
        <div class="-inner my-2 w-sm mx-auto">
          <div class="-your-account mt-0 text-lg">
            <div class="-items mt-2">
              <div
                class="-item"
                *ngFor="let item of systemBankAccountTypeDepositExpressList"
              >
                <div class="-inner items-center">
                  <div class="-img">
                    <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                  </div>
                  <div class="-text">
                    <p class="text-sm">
                      {{ item.bankName }}
                    </p>
                    <p class="text-xl font-bold">
                      <strong>{{ item.bankAccount }}</strong>
                    </p>
                    <p class="text-sm whitespace-nowrap text-truncate">
                      {{ item.accountName ?? item.bankAccountStatusName }}
                    </p>
                  </div>
                  <div class="flex-none w-[100px] pl-4">
                    <a
                      (click)="copyBankAccountNo(item.bankAccount)"
                      class="cursor-pointer"
                    >
                      <img
                        appImgLazyLoad
                        src="https://fs.cdnxn.com/lsm99king/king/img/btn-addbank.webp"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-3">
          <div class="flex justify-center">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/label-for-deposit.webp"
              class="w-[250px]"
            />
          </div>
        </div>
      </div>
      <app-theme-line></app-theme-line>
    </div>

    <div class="box-deposit-bank--">
      <app-bank-list [frame]="'bank-list-resp'"></app-bank-list>
    </div>
  </div>
</div>

<!-- HIDE this section -->

<div class="hidden">
  <!-- <div class="-desktop pb-[5rem] -deposit-withdarw-section-main">
    <app-snackbar-running-message></app-snackbar-running-message>
    <div class="deposit-withdraw-bg2 mt-[5rem]">
      <a class="text-deposit-withdraw1" routerLink="/deposit">
        <div class="flex items-center space-x-1">
          <video
            autoplay
            loop
            poster="https://fs.cdnxn.com/lsm99king/king/img/withdraw.gif"
            class="w-[30px]"
          >
            <source
              type="video/webm"
              src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.webm"
              class="w-[30px]"
            />
          </video>
          <span class="span-deposit-withdraw"> ฝากเงิน </span>
        </div>
      </a>
      <a class="text-deposit-withdraw0" routerLink="/withdraw">
        <div class="flex items-center space-x-1">
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/deposit.webp"
            class="w-[30px]"
          />
          <span class="span-deposit-withdraw"> ถอนเงิน </span>
        </div>
      </a>
    </div>
    <div class="container">
      <div
        class="mt-7"
        *ngIf="this.isUpload && this.isMemberBankAccountVerified"
      >
        <div class="mx-auto w-[60%]">
          <div [ngClass]="this.preview ? 'block' : 'flex'">
            <div class="p-[1rem] mx-auto" style="width: 50%">
              <img
                appImgLazyLoad
                src="https://fs.cdnxn.com/lsm99king/king/img/text-uploadslip.webp"
                alt=""
              />
              <div>
                <h4 class="text-center">
                  อัพโหลดภาพสลิปหลักฐานการโอนเงินจากธนาคารที่มีรูป QR Code
                  เพื่อแจ้งยอดฝาก ยอดเงินจะเข้าสู่ระบบภายในเวลา 1 นาทีค่ะ
                </h4>
              </div>
            </div>

            <form class="-form-style p-[1rem] mx-auto" style="width: 40%">
              <div class="-row -required">
                <div id="reader"></div>
                <label
                  for="fileSlip"
                  class="-form-input-mb"
                  style="cursor: pointer"
                  >{{ fileImageName }}</label
                >

                <div class="-slip-backdrop">
                  <label for="fileSlip" class="previewSlip">
                    <div class="-errorMsgPriview" *ngIf="this.errorMessage">
                      {{ this.errorMessage }}
                    </div>
                    <img src="{{ this.preview }}" />
                  </label>
                  <input
                    type="file"
                    (change)="onFilechange($event)"
                    class="hidden"
                    id="fileSlip"
                    accept="image/jpeg,image/jpg,image/gif,image/png"
                  />
                  <div class="-slip-backdrop-2" *ngIf="this.errorMessage"></div>
                </div>
              </div>
              <button
                (click)="confirmDepositAndUploadSlip()"
                [disabled]="this.isDisabled"
              >
                <img
                  appImgLazyLoad
                  src="https://fs.cdnxn.com/lsm99king/king/img/btn-uploadslip.webp"
                  alt=""
                  class="mx-auto"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="flex justify-center mt-4">
        <div style="width: 40%">
          <div class="-content-bank">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/text-depositBankAccount.webp"
              class="w-[80%] mx-auto"
            />
            <div class="py-4 text-center text-gray-400">
              <p>คัดลอกเลขบัญชี เพื่อใช้โอนเงินผ่านบัญชีของคุณ</p>
              <p>ระบุหมายเหตุ “ใช้สำหรับเล่น LSM99King เท่านั้น”</p>
            </div>
            <div class="-inner my-2 w-sm mx-auto">
              <div class="-your-account mt-0 text-lg">
                <div class="-items mt-2">
                  <div
                    class="-item"
                    *ngFor="let item of systemBankAccountTypeDepositExpressList"
                  >
                    <div class="-inner items-center">
                      <div class="-img">
                        <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                      </div>
                      <div class="-text">
                        <p class="text-sm">
                          {{ item.bankName }}
                        </p>
                        <p class="text-xl font-bold">
                          <strong>{{ item.bankAccount }}</strong>
                        </p>
                        <p class="text-sm whitespace-nowrap text-truncate">
                          {{ item.accountName ?? item.bankAccountStatusName }}
                        </p>
                      </div>
                      <div class="flex-none w-[100px] pl-4">
                        <a
                          (click)="copyBankAccountNo(item.bankAccount)"
                          class="cursor-pointer"
                        >
                          <img
                            appImgLazyLoad
                            src="https://fs.cdnxn.com/lsm99king/king/img/btn-addbank.webp"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <app-theme-line></app-theme-line>
          <div class="my-3">
            <div class="flex justify-center">
              <img
                appImgLazyLoad
                src="https://fs.cdnxn.com/lsm99king/king/img/label-for-deposit.webp"
                class="w-[250px]"
              />
            </div>
          </div>
          <app-theme-line></app-theme-line>
        </div>

        <div class="mt-[1rem]">
          <app-bank-list [frame]="'bank-list-resp'"></app-bank-list>
        </div>
      </div>
    </div>
  </div> -->

  <div class="-mobile pb-[12rem] -mb-deposit-section">
    <div class="mx-2rem">
      <div class="deposit-withdraw-bg2-mb">
        <a class="text-deposit-withdraw1" routerLink="/deposit">
          <div class="flex items-center space-x-1">
            <!-- เปลี่ยนจาก video เป็น img -->
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.gif"
              class="w-[30px]"
            />
            <span class="span-deposit-withdraw"> ฝากเงิน </span>
          </div>
        </a>
        <a class="text-deposit-withdraw0" routerLink="/withdraw">
          <div class="flex items-center space-x-1">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/deposit.webp"
              class="w-[30px]"
            />
            <span class="span-deposit-withdraw"> ถอนเงิน </span>
          </div>
        </a>
      </div>
    </div>

    <div class="-content-bank">
      <div
        class="mt-7 w-sm m-auto -is-upload-slip"
        *ngIf="this.isUpload && this.isMemberBankAccountVerified"
      >
        <div>
          <img
            appImgLazyLoad
            src="https://fs.cdnxn.com/lsm99king/king/img/text-uploadslip.webp"
            alt=""
          />
          <div>
            <form class="-form-style m-4">
              <div class="text-center">
                <h4>
                  อัพโหลดภาพสลิปหลักฐานการโอนเงินจากธนาคารที่มีรูป QR Code
                  เพื่อแจ้งยอดฝาก ยอดเงินจะเข้าสู่ระบบภายในเวลา 1 นาทีค่ะ
                </h4>
              </div>
              <div class="-row -required">
                <div id="reader"></div>
                <label
                  for="fileSlip"
                  class="-form-input-mb your-text-container"
                  style="cursor: pointer"
                  >{{ fileImageName }}</label
                >
                <label for="fileSlip" class="previewSlip">
                  <div *ngIf="this.errorMessage">{{ this.errorMessage }}</div>
                  <img src="{{ this.preview }}" />
                </label>
                <input
                  type="file"
                  hidden
                  (change)="onFilechange($event)"
                  id="fileSlip"
                  accept="image/jpeg,image/jpg,image/gif,image/png"
                />
              </div>
              <button
                (click)="confirmDepositAndUploadSlip()"
                [disabled]="this.isDisabled"
              >
                <img
                  appImgLazyLoad
                  src="https://fs.cdnxn.com/lsm99king/king/img/btn-uploadslip.webp"
                  alt=""
                  class="mx-auto"
                />
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="py-4 mt-[9rem] text-center text-gray-400">
        <p>คัดลอกเลขบัญชี เพื่อใช้โอนเงินผ่านบัญชีของคุณ</p>
        <p>ระบุหมายเหตุ “ใช้สำหรับเล่น LSM99King เท่านั้น”</p>
      </div>
      <div class="-inner my-2 w-sm mx-auto">
        <div class="-your-account mt-0 text-lg">
          <div class="-items mt-2">
            <div
              class="-item"
              *ngFor="let item of systemBankAccountTypeDepositExpressList"
            >
              <div class="-inner items-center">
                <div class="-img">
                  <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                </div>
                <div class="-text">
                  <p class="text-sm">
                    {{ item.bankName }}
                  </p>
                  <p class="text-xl font-bold">
                    <strong>{{ item.bankAccount }}</strong>
                  </p>
                  <p class="text-sm whitespace-nowrap text-truncate">
                    {{ item.accountName ?? item.bankAccountStatusName }}
                  </p>
                </div>
                <div class="flex-none w-[100px] pl-4">
                  <a
                    (click)="copyBankAccountNo(item.bankAccount)"
                    class="cursor-pointer"
                  >
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-addbank.webp"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-theme-line></app-theme-line>
    <div class="my-3">
      <div class="flex justify-center">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/label-for-deposit.webp"
          class="w-[250px]"
        />
      </div>
    </div>
    <app-theme-line></app-theme-line>
    <div class="test"></div>
  </div>
</div>

<div class="fixed z-20 inset-0 overflow-y-auto" *ngIf="this.showModal">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              กรุณาอ่านเงื่อนไข
            </h3>
            <div class="mt-2">
              <p
                class="text-sm leading-5 text-gray-500"
                style="font-size: 16px"
              >
                ขณะนี้คุณยังมีโปรโมชั่นที่คงค้างอยู่
                และไม่อนุญาตให้ฝากเงินเพิ่มระหว่างรับโปรโมชั่นได้
                ถ้าคุณทำการฝากเงินเข้ามาในระบบ
                <span class="warning"
                  >โปรโมชั่นนี้จะถูกยกเลิกโดยอัตโนมัติ
                  และเครดิตที่อยู่ในกระเป๋าโบนัสทั้งหมดจะถูกริบคืนทันที</span
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
          <button
            (click)="toggleModal()"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            รับทราบ
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
