import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RebateService } from './services/rebate.service';
import {SetDisplayPromotion} from "../../shared/services/setDisplayPromotion";

@Component({
  selector: 'app-rebate',
  templateUrl: './rebate.component.html',
  styleUrls: ['./rebate.component.scss']
})
export class RebateComponent implements OnInit {
  promotionDisplayCategory: any;
  sportCategoryData!: any[];
  casinoCategoryData!: any[];
  slotCategoryData!: any[];
  lottoCategoryData!: any[];
  amount!: number;
  isAllowedToRebate!: boolean;
  fromDateFormatted: any;
  toDateFormatted: any;

  promotionContents: string = '';
  showModalPromotion: boolean = false;
  isClickCloseModal: boolean = false;
  lastUpdate!: Date


  promotionAllList!: any;
  promotionList: any;
  onSelectCategoryName!: string;
  imgUrlDesktop: string = '';

  constructor(
    private _rebateService : RebateService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    public setPromotion: SetDisplayPromotion
  ) { }

  ngOnInit(): void {
    this.sportCategoryData = [];
    this.casinoCategoryData = [];
    this.slotCategoryData = [];
    this.lottoCategoryData = [];
    
    this.onSelectCategoryName = "all";
    this.spinner.show()
    this.onGetDataUserRebateInfo();
    this.getDataAllPromotion();
  }

  
  onSelectCategory(categoryName: string){
    this.onSelectCategoryName = categoryName;
    
    switch (categoryName) {
      case 'all':
        this.promotionList = this.promotionAllList;
        break;
    
      case 'casino':
        this.promotionList = this.casinoCategoryData;
        break;
    
      case 'sport':
        this.promotionList = this.sportCategoryData;
        break;
    
      case 'slot':
        this.promotionList = this.slotCategoryData;
        break;
    
      case 'lotto':
        this.promotionList = this.lottoCategoryData;
        break;
    
      default:
        break;
    }

  }

  getDataAllPromotion(){
    let cacheApiDataAllPromotion: any = localStorage.getItem('apiDataAllPromotion');
    cacheApiDataAllPromotion = cacheApiDataAllPromotion ? JSON.parse(cacheApiDataAllPromotion) : null;

    let cacheDataPromotionDisplayCategory: any = localStorage.getItem('apiDataPromotionDisplayCategory');
    cacheDataPromotionDisplayCategory = cacheDataPromotionDisplayCategory ? JSON.parse(cacheDataPromotionDisplayCategory) : null;

    if (cacheDataPromotionDisplayCategory === null) {
      this._rebateService.getDataPromotionDisplayCategory().subscribe({
        next: (data) => {
          this.promotionDisplayCategory = data;
          localStorage.setItem('apiDataPromotionDisplayCategory', JSON.stringify(data))
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.promotionDisplayCategory = cacheDataPromotionDisplayCategory;
    }

    if (cacheApiDataAllPromotion === null) {
      this._rebateService.getDataAllPromotion().subscribe({
        next: (data) => {
          let newData = this.setPromotion.canDisplayPromotion(data);
          localStorage.setItem('apiDataAllPromotion', JSON.stringify(newData))
          this.onSetDataToGroup(newData);
          this.promotionAllList = newData;
          this.promotionList = newData;
        },
        error: (e) => {
          console.error(e);
        },
      });
    } else {
      this.onSetDataToGroup(cacheApiDataAllPromotion);
      this.promotionAllList = cacheApiDataAllPromotion;
      this.promotionList = cacheApiDataAllPromotion;
    }
  }

  onSetDataToGroup(dataSource: any){

    let sportCategoryData: any[] = [];
    let casinoCategoryData: any[] = [];
    let slotCategoryData: any[] = [];
    let lottoCategoryData: any[] = [];

    dataSource.forEach((e: any) => {
      switch (e.promotionDisplayCategoryId) {
        case 1:
          if (sportCategoryData.length<2) {
            sportCategoryData.push(e);
          }
          break;
          
        case 2:
          if (casinoCategoryData.length<2) {
            casinoCategoryData.push(e);
          }
          break;
      
        case 3:
          if (slotCategoryData.length<2) {
            slotCategoryData.push(e);
          }
          break;
      
        case 4:
          if (lottoCategoryData.length<2) {
            lottoCategoryData.push(e);
          }
          break;
      
        default:
          break;
      }
    });

    this.sportCategoryData = sportCategoryData;
    this.casinoCategoryData = casinoCategoryData;
    this.slotCategoryData = slotCategoryData;
    this.lottoCategoryData = lottoCategoryData;

    
  }

  onGetDataUserRebateInfo(){
    this._rebateService.getDataUserRebateInfoBaseUrl()
    .subscribe({
      next: data => {
        this.amount = data.amount;
        this.isAllowedToRebate = data.isAllowedToRebate;
        this.fromDateFormatted = data.fromDateFormatted;
        this.toDateFormatted = data.toDateFormatted;
        this.spinner.hide();
        this.lastUpdate = new Date();

      },
      error: e => {
        console.error(e);
        this.spinner.hide();
      }
    })
  }

  refreshRebateInfo(){
    this.onGetDataUserRebateInfo();
  }

  confirmRebateAmount(){
    this.spinner.show();
    if (!this.isAllowedToRebate) {
      // not allow to rebate
    } else {
      // confirm rebate amount

      this._rebateService.postDataRewardUserRebate()
      .subscribe({
        next: data => {
          this.onGetDataUserRebateInfo();
          this.toastr.success('ทำรายการสำเร็จ', 'สำเร็จ');
          this.spinner.hide();
        },
        error: e => {
          console.error(e);
          this.toastr.error('กรุณาติดต่อแอดมิน', 'เกิดข้อผิดพลาด');
          this.spinner.hide();
        }
      })
      
    }
  }

  onClickPromotion(dataPromotion: any) {
    this.showModalPromotion = true;
    this.promotionContents = dataPromotion.promotionRichText;
    this.imgUrlDesktop = dataPromotion.imgUrlDesktop;
    document.body.style.overflow = 'hidden';
  }

  onClickCloseModal(event: any) {
    this.showModalPromotion = !event;
    document.body.style.overflow = '';
  }


}
