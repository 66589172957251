<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>

<!-- start content this page -->
<main id="main-content" class="p-2 flex justify-center mx-auto">
  <iframe
    name="iframeGame"
    [src]="url"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    scrolling="auto"
  ></iframe>
</main>
<!-- end content this page -->
