import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserWithdrawBankModel } from 'src/app/models/user-withdraw-bank.model';
import { ApiEndpointConstant } from '../../../core/api.endpoint.constant';

const getWithdrawBankListBaseUrl = ApiEndpointConstant.GetWithdrawBankList;
const postChangeMemberUserPassowrdBaseUrl = ApiEndpointConstant.PostChangeMemberUserPassowrd;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getDataWithdrawBankList() {
    return this._httpClient.get<UserWithdrawBankModel>(`${getWithdrawBankListBaseUrl}`);
  } 
  
  setDataChangeMemberUserPassowrd(dataSet: any) {
    return this._httpClient.post<any>(`${postChangeMemberUserPassowrdBaseUrl}`, dataSet);
  } 

}
