<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<!-- End Set Header -->

<!-- Call Modal promotion completed-->
<app-modal-promotion-completed
  *ngIf="showModalPromotionCompleted"
  [rewardedBonusAmount]="rewardedBonusAmount"
  (isClickCloseModal)="onClickCloseModal($event)"
></app-modal-promotion-completed>

<!-- start content this page -->
<!-- <main id="main-content">
  <div class="-content-playing">
    <div class="container">

      <div class="-bag-balance">
        <div class="-inner">
          <h2>ยอดเงินในกระเป๋า</h2>
          <div>
            <strong>฿  {{this.creditAmount | number}}</strong>
            <a class="-btn-refresh btn-refresh" (click)="onRefreshData()">
              <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt=""/>
            </a>
            <p>จำนวนครั้งที่ถอนได้ {{this.displayWithdrawTime}}</p>
          </div>

          <div class="-withdraw-icon">
            <img appImgLazyLoad src="assets/pink/img/withdraw.png" alt=""/>
          </div>
        </div>
      </div>
      

      <div class="-box-inner-content mt-10">
        

        <div class="-inner" *ngIf="this.isActiveWithdraw">
          <h4>เลือกจำนวนเงินที่ต้องการถอน</h4>
          <p>ยอดเงินขั้นต่ำในการถอน {{this.minWithdrawAmount | number}} บาท สูงสุดไม่เกิน {{this.maxWithdrawAmount | number}} บาท</p>

          <div class="-form-style -form-money">
            <form [formGroup]="withdrawForm">
              <div class="-row">

                <div class="-form-radio" *ngFor="let item of withdrawAmountList; index as i" >
                  <input type="radio" name="money" id="money-{{i+1}}" (click)="checkDataConditonStatus(item)">
                  <label for="money-{{i+1}}" class="-form-input" style="cursor: pointer;">{{item | number}}</label>
                </div>

              </div>
              <div class="-row">
                <input type="number" 
                  class="-form-input" 
                  [(ngModel)]="this.withdrawAmount"
                  formControlName="withdrawAmountForm" 
                  placeholder="หรือระบุจำนวนเงิน"/>
              </div>
            </form>
          </div>
          <div class="-content-bank">
            <div class="-your-account text-left my-8">
              <h2 class="text-base font-medium">เลือกบัญชีทีต้องการรับเงิน</h2>
        
              <div class="-items">

                <splide [options]="{ type: 'loop', perPage: 1, keyboard: false }"
                  (onActive)="onSplideActive($event)"
                >
                  <splide-slide *ngFor="let item of userWithdrawBankList">
                    <div class="-item">
                      <div class="-inner">            
                        <div class="-img">
                          <img appImgLazyLoad [src]="item.bankImageUrl" alt="">
                        </div>
                        <div class="-text">
                          <p>{{item.bankName}}</p>
                          <p><strong>{{item.bankAccountNo}}</strong></p>
                          <p><small>{{item.bankAccountName}}</small></p>
                        </div>
                      </div>
                    </div>
                  </splide-slide>
                </splide>
                
              </div>
            
              <button class="-btn-01 mt-6"
                (click)="confirmWithdraw(this.withdrawAmount)" 
                [disabled]="this.isDisabled"
              >ยืนยันการถอนเงิน</button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>  -->
<div class="-desktop">
  <div class="deposit-withdraw-bg mt-[5rem]">
    <a class="text-deposit-withdraw0" routerLink="/deposit">
      <div class="flex items-center space-x-1">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.webp"
          class="w-[30px]"
        />
        <span class="span-deposit-withdraw"> ฝากเงิน </span>
      </div>
    </a>
    <a class="text-deposit-withdraw1" routerLink="/withdraw">
      <div class="flex items-center space-x-1">
        <video
          autoplay
          loop
          poster="https://fs.cdnxn.com/lsm99king/king/img/deposit.gif"
          class="w-[30px]"
        >
          <source
            type="video/webm"
            src="https://fs.cdnxn.com/lsm99king/king/img/deposit.webm"
            class="w-[30px]"
          />
        </video>
        <span class="span-deposit-withdraw"> ถอนเงิน </span>
      </div>
    </a>
  </div>
  <app-snackbar-running-message></app-snackbar-running-message>
</div>

<div class="-mobile">
  <div class="deposit-withdraw-bg-mb mt-[5.5rem] mb-4">
    <a class="text-deposit-withdraw0" routerLink="/deposit">
      <div class="flex items-center space-x-1">
        <img
          appImgLazyLoad
          src="https://fs.cdnxn.com/lsm99king/king/img/withdraw.webp"
          class="w-[30px]"
        />
        <span class="span-deposit-withdraw"> ฝากเงิน </span>
      </div>
    </a>
    <a class="text-deposit-withdraw1" routerLink="/withdraw">
      <div class="flex items-center space-x-1">
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/deposit.gif"
          class="w-[30px]"
        />
        <span class="span-deposit-withdraw"> ถอนเงิน </span>
      </div>
    </a>
  </div>
</div>
<!-- end content this page -->

<div class="mb-[10rem] mt-top-">
  <div class="container md:w-xl">
    <div
      class="flex flex-col justify-center items-center px-6 lg:flex-row lg:justify-center lg:items-start lg:space-x-10"
    >
      <div class="px-6 max-w-sm">
        <!-- <app-theme-line></app-theme-line> -->
        <!-- <div class="flex justify-between items-center space-x-1 my-3">
          <div class="flex-1">
            <app-frame-bg [type]="'label'">
              <p class="h-[20px] pt-[.1rem] pl-[3rem] whitespace-nowrap">
                ถอนเงิน
              </p>
            </app-frame-bg>
          </div>
          <span class="text-sm text-gray-400 whitespace-nowrap pt-1">รวดเร็ว ปลอดภัย เข้าบัญชีไว ภายใน 1 นาที</span>
        </div> -->
        <!-- <app-theme-line></app-theme-line> -->

        <p>ยอดเงินในบัญชี</p>
        <app-frame-bg [type]="'withdraw-wallet'">
          <div class="pt-1 mx-4 flex justify-between items-center">
            <div class="w-[30px] flex-none">
              <img
                appImgLazyLoad
                src="https://fs.cdnxn.com/lsm99king/king/img/icon-refresh.webp"
                class="w-[30px] cursor-pointer"
                (click)="onGetDataUserBalance()"
              />
            </div>
            <span class="text-black text-xl font-bold">
              ฿ {{ this.creditAmount | number : "1.2-2" }}
            </span>
          </div>
        </app-frame-bg>
        <p class="text-right py-1 text-gray-400">
          อัพเดทยอดล่าสุด {{ this.lastUpdate | date : "dd/MM HH:mm" }}
        </p>
        <app-theme-line></app-theme-line>

        <div class="mt-4">
          <app-theme-label
            [label]="'เลือกจำนวนเงินที่ต้องการถอน'"
          ></app-theme-label>

          <p class="text-center mt-3 py-1 text-gray-400">
            ถอนเงินขั้นต่ำ 50 บาท สูงสุดไม่เกิน 100,000 บาท
          </p>
          <div class="-desktop">
            <div class="mt-4">
              <div class="-form-style -form-money">
                <form [formGroup]="withdrawForm">
                  <div class="grid grid-cols-4 gap-2">
                    <div
                      class="-form-radio"
                      *ngFor="let item of withdrawAmountList; index as i"
                    >
                      <input
                        type="radio"
                        name="money"
                        id="money-{{ i + 1 }}"
                        (click)="checkDataConditonStatus(item)"
                      />
                      <label
                        for="money-{{ i + 1 }}"
                        class="-form-input"
                        style="cursor: pointer"
                        >{{ item | number }}</label
                      >
                    </div>
                  </div>
                  <div class="-row">
                    <app-theme-text-input>
                      <input
                        type="number"
                        class="-form-input"
                        [(ngModel)]="this.withdrawAmount"
                        formControlName="withdrawAmountForm"
                        placeholder="หรือระบุจำนวนเงิน"
                      />
                    </app-theme-text-input>
                  </div>
                </form>

                <div class="w-[240px] mt-4 mx-auto">
                  <app-theme-button>
                    <button
                      class="-btn-01"
                      (click)="confirmWithdraw(this.withdrawAmount)"
                      [disabled]="this.isDisabled"
                    >
                      ยืนยันการถอนเงิน
                    </button>
                  </app-theme-button>
                </div>
              </div>
            </div>
          </div>
          <div class="-mobile">
            <div class="mt-4">
              <div class="-form-style -form-money">
                <form [formGroup]="withdrawForm">
                  <div class="grid grid-cols-4 gap-2">
                    <div
                      class="-form-radio"
                      *ngFor="let item of withdrawAmountListM; index as i"
                    >
                      <input
                        type="radio"
                        name="money"
                        id="money-{{ i + 1 }}"
                        (click)="checkDataConditonStatus(item)"
                      />
                      <label
                        for="money-{{ i + 1 }}"
                        class="-form-input"
                        style="cursor: pointer"
                        >{{ item | number }}</label
                      >
                    </div>
                  </div>
                  <div class="-row">
                    <app-theme-text-input>
                      <input
                        type="number"
                        class="-form-input"
                        [(ngModel)]="this.withdrawAmount"
                        formControlName="withdrawAmountForm"
                        placeholder="หรือระบุจำนวนเงิน"
                      />
                    </app-theme-text-input>
                  </div>
                </form>

                <div class="w-[240px] mt-4 mx-auto">
                  <app-theme-button>
                    <button
                      class="-btn-01"
                      (click)="confirmWithdraw(this.withdrawAmount)"
                      [disabled]="this.isDisabled"
                    >
                      ยืนยันการถอนเงิน
                    </button>
                  </app-theme-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-[360px] xl:w-full">
        <app-frame-bg [type]="'withdraw-bank'">
          <div
            class="relative -your-account text-left my-8 pt-[4rem] flex justify-center"
          >
            <div class="absolute top-[23px] right-[13px]">
              <a routerLink="/addBank"
                ><img
                  appImgLazyLoad
                  src="https://fs.cdnxn.com/lsm99king/king/img/btn-bank-account-add.webp"
                  class="mr-[1rem] mt-[.2rem] w-28 cursor-pointer xl:mt-[1rem] xl:mr-[2rem]"
              /></a>
            </div>
            <!-- <div class="-items w-sm">
              <splide
                [options]="{
                  type: 'slide',
                  perPage: 1,
                  keyboard: false,
                  pagination: false
                }"
                (onActive)="onSplideActive($event)"
              >
                <splide-slide *ngFor="let item of userWithdrawBankList">
                  <div class="-item">
                    <div class="-inner">
                      <div class="-img">
                        <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                      </div>
                      <div class="-text">
                        <p>{{ item.bankName }}</p>
                        <p>
                          <strong>{{ item.bankAccountNo }}</strong>
                        </p>
                        <p>
                          <small>{{ item.bankAccountName }}</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </splide-slide>
              </splide>
            </div> -->
            <div
              class="-items m-2 px-6 overflow-y-scroll no-scrollbar max-h-[110px] w-full xl:mt-[2rem] xl:px-[3rem] xl:max-h-[170px]"
            >
              <div *ngFor="let item of userWithdrawBankList; let i = index">
                <div class="flex justify-start items-center space-x-8">
                  <div class="">
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-choose-bank.webp"
                      class="w-[107px]"
                      (click)="onSplideActive(i)"
                      *ngIf="i !== selectBankWithdrawIndex"
                    />
                    <img
                      appImgLazyLoad
                      src="https://fs.cdnxn.com/lsm99king/king/img/btn-choose-bank-active.webp"
                      class="w-[107px]"
                      (click)="onSplideActive(i)"
                      *ngIf="i === selectBankWithdrawIndex"
                    />
                  </div>
                  <div class="flex justify-start items-center space-x-2">
                    <div class="-img flex-none w-[32px]">
                      <img appImgLazyLoad [src]="item.bankImageUrl" alt="" />
                    </div>
                    <div class="-text whitespace-nowrap">
                      <p class="text-sm leading-[14px]">
                        {{ item.bankName }}
                      </p>
                      <p class="font-bold">
                        {{ item.bankAccountNo }}
                      </p>
                      <p class="text-sm leading-[14px]">
                        {{ item.bankAccountName }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-frame-bg>
      </div>
    </div>
  </div>
</div>
