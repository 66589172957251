import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {LoginService} from "../../shared/services/login.service";
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";
import {MemberService} from "../../shared/services/member.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { TourService } from 'src/app/shared/services/tour.services';

import {MigrationService} from "../../shared/services/migration.service";

const loginWithFacebookBaseUrl = ApiEndpointConstant.GetUrlLoginWithFacebook;
const loginWithLineBaseUrl = ApiEndpointConstant.GetUrlLoginWithLine;
const userProfileBaseUrl = ApiEndpointConstant.AuthenUserProfile;

@Component({
  selector: 'app-login-migration',
  templateUrl: './loginmigration.component.html',
  styleUrls: ['./loginmigration.component.css']
})
export class LoginMigrationComponent implements OnInit {
  loginMigratiomForm!: FormGroup;
  isLoginFormNotValid: boolean = false;
  deviceInfo: any = null;
  pathImage: string = '';
  websiteName: string = '';
  welcomeMessage: string = '';
  private readonly _userKey: string = "appUser";
  private readonly _userInfoKey: string = "appUserInfo";

  private readonly _userName: string = "appUserName";
  private readonly _userAgent: string = "appUserAgent";
  private readonly _userCredit: string = "appUserCredit";
  private readonly _userType: string = "appUserType";
  accountLogin: any;
  usernameForLogin: any;
  passwordForLogin: any;
  isAutoLogin: boolean = false;
  UserType: string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder,
    private _urlRoute: ActivatedRoute,
    private toastr: ToastrService,
    private _loginService: LoginService,
    private _migrationService : MigrationService,
    private _httpClient: HttpClient,
    private _router: Router,
    private _memberService: MemberService,
    private _deviceService: DeviceDetectorService,
    private readonly _tourService: TourService
  ) { 
    const navigation = this._router.getCurrentNavigation();

    const state = navigation?.extras.state as {
      username: string,
      password: string,
    };
    
    if (navigation?.extras.state != undefined  && navigation?.extras.state != null) {
      this.usernameForLogin = state.username;
      this.passwordForLogin = state.password;
      if ((this.usernameForLogin!="") && (this.passwordForLogin!="")) {
        this.isAutoLogin = true;
      } else {
        this.isAutoLogin = false;
      }
    } else {
      this.isAutoLogin = false;
    }

    //this.startTour();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.setImage();
    this.setName();
    this.setUserType();
    this.setWelcomeMessage();
    this.setGamePlayLasted()
    setTimeout(() => {
      this.spinner.hide();
    }, 800);
    
    this.callGetClientStatus();

    setTimeout(() => {
      document.body.classList.remove('-open-menu-hamburger');
      document.body.style.overflow = '';
    }, 100)

    this.initLoginForm();
/* 
    this.urlRoute.params.subscribe((params: any) => {
      this.accountLogin = params['account'];
    }) 
 */
      

    this.onInitDataInLocalStorage();
  }

  callGetClientStatus() {
    this._loginService.getClientStatusBaseUrl().subscribe({
      next: (data) => {
          if (data == false) {
              window.location.href = 'http://125.26.170.3/';
          }
      },
      error: e => {
          console.error(e);
      }
    })
  }

  initLoginForm() {
    this.loginMigratiomForm = this._formBuilder.group({
      userForm: ['', Validators.required],
      passwordForm: ['', Validators.required]
    })
  }

  setUserType() {
    //get marketing channel from url link
    this._urlRoute.queryParams
    .subscribe(params => {

      if (params['type']) {
        this.UserType = params['type'];
      }
    });
  }

  onClickBtnLogin() {
    this.spinner.show();
    this.isLoginFormNotValid = this.loginMigratiomForm.invalid;
    if (this.isLoginFormNotValid) {
      this.spinner.hide();
      return;
    }

    let dataSet = {
      User:  this.loginMigratiomForm.controls['userForm'].value,
      Password:  this.loginMigratiomForm.controls['passwordForm'].value,
    }

    this._migrationService.memberVerify(dataSet).subscribe({
      next: (data: any) => {
        if(data.isMember){
          localStorage.setItem(this._userName, data.username);
          localStorage.setItem(this._userAgent, data.agent);
          localStorage.setItem(this._userCredit, data.credit);
          localStorage.setItem(this._userType, this.UserType);
          this._router.navigateByUrl('/register-migration').then(() => this.spinner.hide());
        }else{
          this.toastr.error('', data.message);
          this.spinner.hide();
          return
        }
        
      },
      error: e => {
        console.error(e);
        let errorDescription = e.error.error_description;
        let errorMsg = `User: ${this.loginMigratiomForm.controls['userForm'].value} has been locked 30 Minutes. (3 BadAttempts)`;
        if (errorDescription == errorMsg) {
          this.toastr.error('', 'ยูเซอร์นี้ถูกล็อค กรุณาติดต่อแอดมินค่ะ');
        } else {
          this.toastr.error('', 'เบอร์โทรหรือรหัสผ่านไม่ถูกต้อง');
        }
        this.spinner.hide();
      }
    })
  }


  getUserProfile$() {
    this.setDatatoLocalStorage();
    this._httpClient.get<any>(`${userProfileBaseUrl}`).subscribe({
      next: (data) => {
        if (data){
          let obj = JSON.stringify(data);
          localStorage.setItem(this._userInfoKey, obj);
          this._router.navigateByUrl('/index').then(() => this.spinner.hide());
        }
      },
      error: e => {
        console.error(e)
      }
    })
  }

  setDatatoLocalStorage() {
    this._memberService.getBankList()
        .subscribe({
          next: (data) => {
            localStorage.setItem('bankList', JSON.stringify(data));
          },
          error: e => {
            console.error(e)
          }
        })

    this._memberService.getSystemBankAccountTypeList()
        .subscribe({
          next: (data) => {
            localStorage.setItem('systemBankAccountTypeList', JSON.stringify(data));
          },
          error: e => {
            console.error(e)
          }
        })

  }

    onClickLoginFaceBook() {
        localStorage.setItem('isLoginFacebook', JSON.stringify(true));
        localStorage.setItem('isLoginLine', JSON.stringify(false));
    }

    onClickLoginLine() {
        localStorage.setItem('isLoginFacebook', JSON.stringify(false));
        localStorage.setItem('isLoginLine', JSON.stringify(true));
    }


    onRemoveLocalStorage(){
      localStorage.removeItem("isLoginFacebook");
      localStorage.removeItem("isLoginLine");
      this.setGamePlayLasted();
    }

    onInitDataInLocalStorage(){
      localStorage.removeItem('deviceInfo');
      localStorage.setItem('deviceInfo', JSON.stringify(this._deviceService));
    }

    setGamePlayLasted() {
      localStorage.setItem('gameCasinoPlayLasted', '');
      localStorage.setItem('gameSlotPlayLasted', '');
      localStorage.setItem('gameSportPlayLasted', '');
      localStorage.setItem('gameLottoPlayLasted', '');
    }

    setWelcomeMessage() {
      let welcomeMessage = localStorage.getItem('welcomeMessage');
      if (welcomeMessage) {
        this.welcomeMessage = JSON.parse(localStorage.getItem('welcomeMessage') || '');
      }
    }

  setImage() {
    let webImage = localStorage.getItem('webImage');
    if (webImage) {
      this.pathImage = JSON.parse(webImage || '');
    }
  }

  setName() {
    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }
  }

  startTour(){
    this._tourService.tourLoginFeature();
  }
}
