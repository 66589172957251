import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DepositService } from '../services/deposit.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-deposit-upload-slip',
  templateUrl: './deposit-upload-slip.component.html',
  styleUrls: ['./deposit-upload-slip.component.css']
})
export class DepositUploadSlipComponent implements OnInit {

  fileSlip: any;
  params: any;
  fileImageName: string = "เลือกไฟล์ภาพสลิปที่นี่";
  fileImageType: string | undefined;
  imgBase64: any;
  decimalDepositUserId!: number;
  depositAmountRequired!: number;
  bankName: string | undefined;
  bankAccount: string | undefined;
  accountName: string | undefined;
  isDisabled: boolean = false;

  constructor(
    private readonly _router: Router,
    private _activateRoute: ActivatedRoute,
    private _depositService: DepositService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private location: Location,
  ) { }

  ngOnInit(): void {
      this._activateRoute.queryParams
      .subscribe(params => {
        let data: any = params;
        this.decimalDepositUserId = Number(data.requestDecimalDepositId);
      });
  }

  onFilechange(event: Event){
    let evt: any = event.target;
    if (evt.files.length > 0) {
      if (evt.files[0].size > 3072000)
        this.toastr.error('ขนาดไฟล์ภาพใหญ่เกินไป', 'ข้อมูลไม่ถูกต้อง');
      else {
        this.fileSlip = evt.files[0];
        this.onEndcodeBase64File();
      }
    }
  }

  onSubmit(){
    if (this.fileSlip) {
      this.spinner.show();
      this.isDisabled = true;

      let payload = {
        decimalDepositUserId: this.decimalDepositUserId,
        fileImage: {
          imageBase64: this.imgBase64,
          imageType: this.fileImageType,
          fileName: this.fileImageName
        }
      }
      
      this._depositService.postDataUploadSlipDecimalDepositTimeout(payload)
      .subscribe({
        next: (data) => {
          this.spinner.hide();
          this.toastr.success('ยอดเงินจะเข้าสู่ระบบภายในเวลา 3 นาที', 'ทำรายการสำเร็จ');
          this._router.navigate(['/index']);
        },
        error: (e) => {
          console.error(e)
          this.spinner.hide();
          this.isDisabled = false;
          this.toastr.error('กรุณาติดต่อแอดมิน', 'ทำรายการไม่สำเร็จ');
        }
      })

    }else{
      this.toastr.error('กรุณาเลือกไฟล์อัพโหลด', 'ข้อมูลไม่ถูกต้อง');
    }
  }

  onEndcodeBase64File(){
    this.spinner.show();
    let file: File;
    file = this.fileSlip;
    const reader = new FileReader();
    let base64String: any;

    this.fileImageName = file.name;
    this.fileImageType = file.type.split('/')[1];

    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      base64String = _event.target.result;
      this.imgBase64 = base64String.split(',')[1];
    }
    this.spinner.hide();
  }

}
