import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SystemService } from './shared/services/system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  themeName!: string;
  welcomeMessage: string = '';
  registerMessage: string = '';
  runningMessage: string = '';
  runningMessageId: number = 0;
  alertMessage: string = '';
  alertMessageId: number = 0;
  urlWebsite!: string;
  webImage!: string;
  webLogo!: string;
  websiteName!: string;
  lineId!: string;
  linkLine!: string;
  phoneNumbers!: string;
  logoLink!: string;
  registerLink!: string;

  constructor(
    private titleService: Title,
    private _systemService: SystemService
  ) {
    // let themeName = localStorage.getItem('cssThemeName');
    // if (themeName) {
    //   this.themeName = JSON.parse(themeName || '');
    // }

    let webLogo = localStorage.getItem('webLogo');
    if (webLogo) {
      this.webLogo = JSON.parse(webLogo || '');
    }

    let websiteName = localStorage.getItem('websiteName');
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '');
    }

    let welcomeMessage = localStorage.getItem('welcomeMessage');
    if (welcomeMessage) {
      this.welcomeMessage = JSON.parse(welcomeMessage || '');
    }
  }

  ngOnInit(): void {
    this.onGetWebInfo();

    this.titleService.setTitle(
      'ยินดีต้อนรับเข้าสู่ ' + this.websiteName + ' - ' + this.welcomeMessage
    );

    let favicon1 = document.getElementById('favicon1');
    favicon1!.setAttribute('href', this.webLogo);

    let favicon2 = document.getElementById('favicon2');
    favicon2!.setAttribute('href', this.webLogo);

    // let cssThemeName = document.getElementById('cssThemeName');
    // cssThemeName!.setAttribute('href', `assets/${this.themeName}/css/main.css`);
  }

  // TODO wait service backEnd and map value here
  setTheme() {
    // localStorage.setItem('cssThemeName', JSON.stringify(this.themeName));
    localStorage.setItem('webImage', JSON.stringify(this.webImage));
    localStorage.setItem('webLogo', JSON.stringify(this.webLogo));
    localStorage.setItem('websiteName', JSON.stringify(this.websiteName));
    localStorage.setItem('welcomeMessage', JSON.stringify(this.welcomeMessage));
    localStorage.setItem(
      'registerMessage',
      JSON.stringify(this.registerMessage)
    );
    localStorage.setItem('lineId', JSON.stringify(this.lineId));
    localStorage.setItem('linkLine', JSON.stringify(this.linkLine));
    localStorage.setItem('phoneNumbers', JSON.stringify(this.phoneNumbers));

    localStorage.setItem('alertMessage', JSON.stringify(this.alertMessage));
    localStorage.setItem('alertMessageId', JSON.stringify(this.alertMessageId));
    localStorage.setItem('runningMessage', JSON.stringify(this.runningMessage));
    localStorage.setItem(
      'runningMessageId',
      JSON.stringify(this.runningMessageId)
    );
    localStorage.setItem('urlWebsite', JSON.stringify(this.urlWebsite));
    localStorage.setItem('logoLink', JSON.stringify(this.logoLink));
    localStorage.setItem('registerLink', JSON.stringify(this.registerLink));
  }

  onGetWebInfo() {
    this._systemService.getWebInfo().subscribe({
      next: (data) => {
        let reload = false;
        //data.theme != this.themeName;
        this.themeName = 'Blue';
        this.welcomeMessage = data.welcomeMessage;
        this.registerMessage = data.registerMessage;
        this.runningMessage = data.runningMessage;
        this.alertMessage = data.alertMessage;
        this.runningMessageId = data.runningMessageId;
        this.alertMessageId = data.alertMessageId;
        this.urlWebsite = data.urlWebsite;
        this.webImage = data.webImage;
        this.webLogo = data.webLogo;
        this.websiteName = data.websiteName;
        this.lineId = data.lineId;
        this.linkLine = data.linkLine;
        this.phoneNumbers = data.phoneNumbers;
        this.logoLink = JSON.parse(data.customSetting).headerLink;
        this.registerLink = JSON.parse(data.customSetting).isRegister;
        this.setTheme();
        if (reload) location.reload();

        document.body.className = 'loaded';
      },
      error: (e) => {
        console.error(e);
      },
    });
  }
}
