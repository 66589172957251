import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WalletBankService } from 'src/app/pages/wallet/services/wallet-bank.service';
import { SetDisplayPromotion } from '../../services/setDisplayPromotion';

@Component({
  selector: 'app-group-promotions',
  templateUrl: './group-promotions.component.html',
  styleUrls: ['./group-promotions.component.css'],
})
export class GroupPromotionsComponent implements OnInit {
  @Input() promotionCategoryId: number = 0;

  promotions: any[] = [];
  promotionContents: string = '';
  showModalPromotion: boolean = false;
  imgUrlDesktop: string = '';

  constructor(
    private _walletBankService: WalletBankService,
    public setPromotion: SetDisplayPromotion
  ) {}

  ngOnInit(): void {
    this.onGetDataPromotion();
    console.log(this.promotions);
  }

  onGetDataPromotion() {
    this._walletBankService.getDataAllPromotion().subscribe({
      next: (data) => {
        let newData = this.setPromotion.canDisplayPromotion(data);
        this.promotions = newData.filter(
          (promotion: any) =>
            promotion.promotionDisplayCategoryId === this.promotionCategoryId
        );
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onClickPromotion(dataPromotion: any) {
    this.showModalPromotion = true;
    this.promotionContents = dataPromotion.promotionRichText;
    this.imgUrlDesktop = dataPromotion.imgUrlDesktop;
    document.body.style.overflow = 'hidden';
  }

  onClickCloseModal(event: any) {
    this.showModalPromotion = !event;
    document.body.style.overflow = '';
  }
}
