

<!-- Set Header -->
<div class="-header">
  <a routerLink="/register" class="-btn-back">
    <img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="">
  </a>
  
  <h2 class="-text-header">ยืนยันตัวตน</h2>

  <a routerLink="/" class="-btn-close">
    <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
  </a>
</div>

<app-main-menu></app-main-menu>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-register">
    <div class="container">
      <div class="-section-top">
        <img appImgLazyLoad src="assets/img/theme/otp.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
        <h2 class="-title leading-8 mb-1">ระบบได้ทำการส่งรหัส
          <br>OTP 4 หลัก</h2>
        <p class="mb-0">ไปยังหมายเลขโทรศัพท์ที่คุณระบุไว้เรียบร้อยแล้ว</p>
        <p class="-ref-code">Ref code : <span class="-code">srfh</span></p>
        
        <div class="-form-style mt-5">
          <form>
            <div class="-row -required">
              <div class="-form-otp">
                <input type="tel" class="-form-input" maxlength="1">
                <input type="tel" class="-form-input" maxlength="1">
                <input type="tel" class="-form-input" maxlength="1">
                <input type="tel" class="-form-input" maxlength="1">
              </div>
              <p class="-text-validate">Validate</p>
            </div>
            <div class="-row">
              <!-- <button type="submit" class="-btn-01"><span>ยืนยันตัวตน</span></button> -->
              <!-- Mock Up Next Step -->
              <a routerLink="/register-step02" class="-btn-01 mt-8"><span>ยืนยันตัวตน</span></a><!-- End Mock Up Next Step -->
            </div>
          </form>
        </div>
        <div class="flex justify-between mt-4 mb-20">
          <a routerLink="/register">แก้ไขหมายเลขโทรศัพท์</a>
          <a>ขอรหัสใหม่อีกครั้ง</a>
        </div>
      </div>
    </div>
  </div>
</main> <!-- end content this page -->


<script>
  var container = document.getElementsByClassName("-form-otp")[0];
  container.onkeyup = function(e) {
    var target = e.srcElement || e.target;
    var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    var myLength = target.value.length;
    if (myLength >= maxLength) {
      var next = target;
      while (next = next.nextElementSibling) {
        if (next == null)
          break;
        if (next.tagName.toLowerCase() === "input") {
          next.focus();
          break;
        }
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      var previous = target;
      while (previous = previous.previousElementSibling) {
        if (previous == null)
            break;
        if (previous.tagName.toLowerCase() === "input") {
            previous.focus();
            break;
        }
      }
    }
  }
</script>
