<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>

<!-- Set Header -->
<div class="-header">
	<a routerLink="/deposit" class="-btn-back">
		<img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="">
	</a>
	<h2 class="-text-header">อัพโหลดสลิป</h2>
</div>
<app-main-menu></app-main-menu>
<!-- End Set Header -->

<!-- start content this page -->
<main id="main-content">
	<div class="container">
		<div class="-section-top">
			<img appImgLazyLoad src="assets/img/theme/new-bank.svg" alt="" class="mx-auto block mt-16 mb-6 -shadow-icon">
			<h2 class="-title">แจ้งการฝากเงิน</h2>
			
			<div class="-form-style mt-4">
				<form>
                    <div class="-row">
                        <span>อัพโหลดภาพสลิปหลักฐานการโอนเงินเพื่อแจ้งยอดฝาก ยอดเงินจะเข้าสู่ระบบภายในเวลา 3 นาทีค่ะ</span>
                    </div>
                    <br>
					<div class="-row -required">
						<label for="fileSlip" class="-form-input">{{fileImageName}}</label>
                        <input type="file" (change)="onFilechange($event)" id="fileSlip" accept="image/jpeg,image/gif,image/png">
					</div>
					<div class="-row">
						<button class="-btn-01"
							(click)="onSubmit()"
							[disabled]="this.isDisabled"
						>อัพโหลดไฟล์</button>
					</div>
				</form>
			</div>

		</div>
	</div>
</main> 
<!-- end content this page -->
