import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AffliateService } from '../services/affliate.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register-affliate',
  templateUrl: './register-affliate.component.html',
  styleUrls: ['./register-affliate.component.css']
})
export class RegisterAffliateComponent implements OnInit {
  affliateRegisterTypeList!: any;
  registerAffliateFormGroup!: FormGroup;
  isInvalidselectAffliateRegisterType!: boolean;
  isAffliateApproved: any;
  affliateStatus!: string;
  registerStatus!: string;

  constructor(
    private readonly _router: Router,
    private _affliateService: AffliateService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.onGetDataAffliateInfo();
    this.onInitRegisterAffliateFormGroup();
  }

  onSubmit(){
    this.spinner.show();

    let selectAffliateRegisterTypeId: any = this.registerAffliateFormGroup.controls["selectAffliateRegisterTypeForm"].value;
    selectAffliateRegisterTypeId = Number(selectAffliateRegisterTypeId)>0 ? selectAffliateRegisterTypeId : 1;
    this.registerAffliateFormGroup.controls["selectAffliateRegisterTypeForm"].setValue(selectAffliateRegisterTypeId);

    if (this.registerAffliateFormGroup.status=="VALID") {
      let payload = {
        affliateCalculateTypeId: this.registerAffliateFormGroup.controls["selectAffliateRegisterTypeForm"].value
      }

      this._affliateService.setRegisterUserAffliate(payload)
      .subscribe({
        next: data => {
          setTimeout(()=>{
            this.spinner.hide();
            this.toastr.clear();
            this.toastr.success('ทำรายการสำเร็จ', 'สำเร็จ');
            this.isAffliateApproved = true;
            this.onGetDataAffliateInfo();
/* 
            this._router.navigate(
              ['/affiliates'],
              { queryParams: { 
                  registerStatus: 'success'
                } 
              }
            );
 */            
          }, 800)
        },
        error: e => {
          console.error(e);
          this.spinner.hide();
          this.toastr.error('ทำรายการไม่สำเร็จ', 'ไม่สำเร็จ');
        }
      })
      
    } else {
      this.isInvalidselectAffliateRegisterType = (this.registerAffliateFormGroup.controls["selectAffliateRegisterTypeForm"].status=="INVALID") ? true : false;
    }
    
  }

  onInitRegisterAffliateFormGroup(){
    this.registerAffliateFormGroup = this.formBuilder.group({
      selectAffliateRegisterTypeForm: ['', [Validators.required]]
    });
  }

  onGetDataAffliateRegisterType(){
    this._affliateService.getDataAffliateRegisterType()
    .subscribe({
      next: data => {
        this.affliateRegisterTypeList = data.reverse();
        setTimeout(()=>{
          this.spinner.hide();
        }, 500);
      },
      error: e => {
        console.error(e);
        setTimeout(()=>{
          this.spinner.hide();
        }, 500);
      }
    })
  }

  onGetDataAffliateInfo(){
    this._affliateService.getDataAffliateInfo()
    .subscribe({
      next: data => {

        this.affliateStatus = data.affliateStatus;

        switch (this.affliateStatus) {
          case "approved":
            this.registerStatus = "approved";
            break;
        
          case "pending":
            this.registerStatus = "pending";
            break;
        
          case "rejected":
            this.registerStatus = "rejected";
            break;
        
          default:
            this.registerStatus = "empty";
            this.onGetDataAffliateRegisterType();
            break;
        }
/* 
        if (Number(data.affliateGroupId)>0 && data.affliateId==null) {
          this.isAffliateApproved = true;
        } else {
          this.onGetDataAffliateRegisterType();
        }
 */
        setTimeout(()=>{
          this.spinner.hide();
        }, 500);
      },
      error: e => {
        console.error(e);
        setTimeout(()=>{
          this.spinner.hide();
        }, 500);
      }
    })
  }

}
