import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { WalletBankService } from "src/app/pages/wallet/services/wallet-bank.service";
import { SetDisplayPromotion } from "../../services/setDisplayPromotion";

@Component({
  selector: "app-recommend-promotions",
  templateUrl: "./recommend-promotions.component.html",
  styleUrls: ["./recommend-promotions.component.css"],
})
export class RecommendPromotionsComponent implements OnInit {
  @Input() promotions!: any[];
  @Output() handleClickPromotion = new EventEmitter<any>();
  onSelectCategoryId = 0;
  allPromotions!: any[];
  @Input() single: boolean = true;

  sportCategoryData: any;
  casinoCategoryData: any;
  slotCategoryData: any;
  lottoCategoryData: any;

  constructor(
    private _walletBankService: WalletBankService,
    public setPromotion: SetDisplayPromotion
  ) {}

  ngOnInit(): void {
    if (this.promotions && this.promotions.length > 0) {
      this.allPromotions = this.promotions;
    } else {
      this.getDataAllPromotion();
    }
  }

  onSelectCategory(id: number) {
    this.onSelectCategoryId = id;
    if (id === 0) this.allPromotions = this.promotions;
    else {
      const filter = this.promotions.filter(
        (promotion: any) => promotion.promotionDisplayCategoryId === id
      );

      this.allPromotions = filter;
    }
  }

  onClickPromotion(dataPromotion: any) {
    this.handleClickPromotion.emit(dataPromotion);
  }

  getDataAllPromotion() {
    this._walletBankService.getDataAllPromotion().subscribe({
      next: (data) => {
        let newData = this.setPromotion.canDisplayPromotion(data);
        this.onSetDataToGroup(newData);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onSetDataToGroup(dataSource: any) {
    let sportCategoryData: any[] = [];
    let casinoCategoryData: any[] = [];
    let slotCategoryData: any[] = [];
    let lottoCategoryData: any[] = [];

    dataSource.forEach((e: any) => {
      switch (e.promotionDisplayCategoryId) {
        case 1:
          if (sportCategoryData.length < 2) {
            sportCategoryData.push(e);
          }
          break;

        case 2:
          if (casinoCategoryData.length < 2) {
            casinoCategoryData.push(e);
          }
          break;

        case 3:
          if (slotCategoryData.length < 2) {
            slotCategoryData.push(e);
          }
          break;

        case 4:
          if (lottoCategoryData.length < 2) {
            lottoCategoryData.push(e);
          }
          break;

        default:
          break;
      }
    });

    this.sportCategoryData = sportCategoryData;
    this.casinoCategoryData = casinoCategoryData;
    this.slotCategoryData = slotCategoryData;
    this.lottoCategoryData = lottoCategoryData;
    this.allPromotions = dataSource;
    this.promotions = this.allPromotions;
  }
}
