<div
  class="fixed top-0 w-full border-b-2 border-gray-300 z-20 inset-0 overflow-y-auto z-[51]"
>
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 relative">
        <div class="absolute top-0 right-0">
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
            class="w-[25px] cursor-pointer"
            (click)="onClickCloseModal()"
          />
        </div>
        <div class="sm:flex sm:items-start">
          <div class="mt-3" *ngIf="alertMsgContents.length > 0">
            <p
              class="text-sm leading-5 text-gray-500"
              [innerHtml]="alertMsgContents | safeHtml"
              style="font-size: 16px"
            ></p>
          </div>
          <div class="w-full" *ngIf="alertMsgContents.length === 0">
            <app-frame-bg [type]="'new-member'">
              <div class="-row w-[200px] mx-auto pt-[6.5rem]">
                <app-theme-button [type]="'secondary'">
                  <a
                    (click)="onClickCloseModal()"
                    class="-btn-02 text-white cursor-pointer"
                    type="button"
                  >
                    <span class="mb-2 text-xl text-black font-bold">ตกลง</span>
                  </a>
                </app-theme-button>
              </div>
            </app-frame-bg>
          </div>
        </div>
      </div>
      <div class="px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <!-- <div class="-row w-[200px] mx-auto">
          <app-theme-button>
            <a
              (click)="onClickCloseModal()"
              class="-btn-02 text-white cursor-pointer"
              type="button"
            >
              <span>ตกลง</span>
            </a>
          </app-theme-button>
        </div> -->
      </div>
    </div>
  </div>
</div>
