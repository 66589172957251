<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>
<!-- Set Header -->
<!-- <div class="-header">
  <a routerLink="/profile" class="-btn-back">
    <img appImgLazyLoad src="assets/img/icons/angle-left-white.svg" alt="" />
  </a>
  <h2 class="-text-header">ข้อมูลส่วนตัว</h2>
</div> -->

<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- End Set Header -->

<!-- start content this page -->
<div class="mt-4 top-mgt">
  <app-frame-bg [type]="'add-bank'">
    <main id="main-content">
      <div class="-content-register">
        <div class="container w-sm">
          <div class="-section-top">
            <div class="-form-style pt-[1rem] px-[3rem]">
              <form [formGroup]="addBankForm">
                <div class="-form-style">
                  <div class="-required -error">
                    <div class="justify-center -required -error">
                      <div class="dropdownWrapper">
                        <app-theme-text-input>
                          <button id="dropdownUsersButton" data-dropdown-toggle="dropdownUsers"
                            data-dropdown-placement="bottom"
                            class="-form-style -form-input bg-white-700 hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-2"
                            type="button" (click)="hideBankContainer = !hideBankContainer">
                            <div class="button-dropdown w-full flex justify-between items-center">
                              <div>
                                <span class="text-base text-[#4f4f4f] -text-placeholder">{{
                                  bankName === ""
                                  ? "เลือกธนาคาร"
                                  : "ธนาคาร" + bankName
                                  }}</span>
                              </div>
                              <div class="w-3">
                                <img appImgLazyLoad src="https://fs.cdnxn.com/lsm99king/king/img/icon-dropdown.webp" />
                              </div>
                            </div>
                          </button></app-theme-text-input>
                      </div>
                    </div>

                    <div id="dropdownBanks" [ngClass]="{ hidden: hideBankContainer }"
                      class="hidden z-10 mt-2 w-100 bg-white rounded shadow dark:bg-gray-700">
                      <ul class="overflow-y-auto w-100 py-1 h-48 text-black absolute bg-white w-[232px] cursor-pointer"
                        aria-labelledby="dropdownUsersButton">
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(3)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KBANK.png"
                                alt="ธนาคารกสิกรไทย" width="30" />
                            </div>
                            <span>ธนาคารกสิกรไทย</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(2)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/SCB.png"
                                alt="ธนาคารไทยพาณิชย์" width="30" />
                            </div>
                            <span>ธนาคารไทยพาณิชย์</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(4)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KTB.png"
                                alt="ธนาคารกรุงไทย" width="30" />
                            </div>
                            <span>ธนาคารกรุงไทย</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(1)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BBL.png"
                                alt="ธนาคารกรุงเทพ" width="30" />
                            </div>
                            <span>ธนาคารกรุงเทพ</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(11)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/TTB.png"
                                alt="ธนาคารทหารไทยธนชาต" width="30" />
                            </div>
                            <span>ธนาคารทหารไทยธนชาต</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(5)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BAY.png"
                                alt="ธนาคารกรุงศรีอยุธยา" width="30" />
                            </div>
                            <span>ธนาคารกรุงศรีอยุธยา</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(10)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/GSB.png"
                                alt="ธนาคารออมสิน" width="30" />
                            </div>
                            <span>ธนาคารออมสิน</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(9)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/GHB.png"
                                alt="ธนาคารอาคารสงเคราะห์" width="30" />
                            </div>
                            <span>ธนาคารอาคารสงเคราะห์</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(6)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/BAAC.png"
                                alt="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร" width="30" />
                            </div>
                            <span>ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(12)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/ISBT.png"
                                alt="ธนาคารอิสลามแห่งประเทศไทย" width="30" />
                            </div>
                            <span>ธนาคารอิสลามแห่งประเทศไทย</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(13)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/KKP.png"
                                alt="ธนาคารเกียรตินาคิน" width="30" />
                            </div>
                            <span>ธนาคารเกียรตินาคิน</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(7)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/CIMB.png"
                                alt="ธนาคารซีไอเอ็มบี" width="30" />
                            </div>
                            <span>ธนาคารซีไอเอ็มบี</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(14)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/LHBANK.png"
                                alt="ธนาคารแลนด์ แอนด์ เฮ้าส์" width="30" />
                            </div>
                            <span>ธนาคารแลนด์ แอนด์ เฮ้าส์</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(8)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/TISCO.png"
                                alt="ธนาคารทิสโก้" width="30" />
                            </div>
                            <span>ธนาคารทิสโก้</span>
                          </div>
                        </li>
                        <li>
                          <div
                            class="bank-item flex items-center py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            (click)="onChooseBank(15)">
                            <div class="pr-2">
                              <img class="rounded-full" src="https://fs.cdnxn.com/lsm99king/img/logo/UOB.png"
                                alt="ธนาคารยูโอบี" width="30" />
                            </div>
                            <span>ธนาคารยูโอบี</span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <p *ngIf="!bankIdValidate" class="-text-validate">
                      โปรดเลือกธนาคาร
                    </p>
                  </div>
                </div>

                <div class="-row -required mt-2">
                  <app-theme-text-input>
                    <input type="text" class="-form-input" formControlName="bankAccountNoForm"
                      [(ngModel)]="this.bankAccountNo" placeholder="หมายเลขบัญชีธนาคาร{{ bankName }}" />
                  </app-theme-text-input>
                </div>

                <div class="-row w-[200px] mx-auto">
                  <!-- Mock Up Next Step -->
                  <app-theme-button>
                    <a (click)="onSubmit()" class="-btn-01 is-link pb-2">
                      <span>เพิ่มบัญชี</span>
                    </a>
                  </app-theme-button>

                  <!-- End Mock Up Next Step -->
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  </app-frame-bg>
</div>
<!-- end content this page -->