import { Component, OnInit } from '@angular/core';
import { GameService } from '../services/game.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { GameConfigConstant } from '../../../core/game.config.constant';

@Component({
  selector: 'app-sport-list',
  templateUrl: './sport-list.component.html',
  styleUrls: ['./sport-list.component.css'],
})
export class SportListComponent implements OnInit {
  gameList: any = [];
  gameDisplayList: any = [];
  gameHitsList: any = [];
  gameHistoryList: any = [];
  gameGroupList: any = [
    {
      name: 'คาสิโน',
      key: 'casino',
      imageUrl: 'assets/img/uploads/game-category-01.png',
      targetLink: '/game/casinoListing',
      detail: '',
    },
    {
      name: 'เกมส์',
      key: 'game',
      imageUrl: 'assets/img/uploads/game-category-02.png',
      targetLink: '/game/gameListing',
      detail: '',
    },
    {
      name: 'กีฬา',
      key: 'sport',
      imageUrl: 'assets/img/uploads/game-category-03.png',
      targetLink: '/game/sportListing',
      detail: '',
    },
    {
      name: 'หวย',
      key: 'lotto',
      imageUrl: 'assets/img/uploads/game-category-04.png',
      targetLink: '/game/lottoListing',
      detail: '',
    },
  ];
  promoteList: any = [
    'sportOnly',
    'muaypukyok',
    'lottoexit',
    'ab',
    'pg',
    'sexy',
    'ps',
    'sa',
    'hg',
    'dg',
    'pp',
  ];
  isActiveGameBtn: string = 'all';
  deviceInfo!: any;

  newTabGames: string[] = GameConfigConstant.GameInNewTab;

  constructor(
    private _gameService: GameService,
    private spinner: NgxSpinnerService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.deviceInfo = JSON.parse(localStorage.getItem('deviceInfo') || '{}');

    this.onGetGameList();
    this.onGetHistory();
  }

  onGetGameList() {
    this.spinner.show();
    let allGameList = localStorage.getItem('allGameList');
    if (allGameList) {
      this.gameList = JSON.parse(allGameList);
      this.onGetGameAllList();
      this.onGetDataGameHitList();
    } else {
      this._gameService.getDataAllProductsV3().subscribe({
        next: async (games) => {
          const product = await games.data.filter((item: any) => {
            return item.status == 'Active';
          });

          await product.sort((a: any, b: any) => {
            return a.displayOrder - b.displayOrder;
          });

          this.gameList = await product;
          this.onGetGameAllList();
          this.onGetDataGameHitList();
          localStorage.setItem('allGameList', JSON.stringify(this.gameList));
        },
        error: (e) => {
          console.error(e);
          this.spinner.hide();
        },
      });
    }
    this.spinner.hide();
  }

  onGetHistory() {
    let gameSportPlayLasted = localStorage.getItem('gameSportPlayLasted');
    if (gameSportPlayLasted)
      this.gameHistoryList = JSON.parse(gameSportPlayLasted);
  }

  onGetGameAllList() {
    this.gameDisplayList = this.gameList.filter((item: any) => {
      item.imageUrl = 'assets/img/brand/' + item.code + '.png';
      item.brand = item.code;
      item.category = 'sport';
      return item.tags.find((x: any) => x.slug == 'sport');
    });
  }

  onGetDataGameHitList() {
    this.gameHitsList = this.gameList.filter((item: any) => {
      switch (item.tags[0].slug) {
        case 'game':
          item.targetLink = '/game/gameListing';
          break;
        case 'casino':
          item.targetLink = '/game/casinoListing';
          break;
        case 'sport':
          item.targetLink = '/game/sportListing';
          break;
        case 'lotto':
          item.targetLink = '/game/lottoListing';
          break;
      }
      return this.promoteList.find((x: any) => x == item.code);
    });
  }

  onGetGameNewList() {
    this.gameDisplayList = this.gameList
      .filter((item: any) => {
        return item.tags.find((x: any) => x.slug == 'sport');
      })
      .sort((a: any, b: any) => {
        return b.id - a.id;
      })
      .splice(0, 6);
  }

  onGetGamePopularList() {
    this.gameDisplayList = this.gameList
      .filter((item: any) => {
        return (
          item.tags.find((x: any) => x.slug == 'sport') &&
          this.promoteList.find((x: any) => x == item.code)
        );
      })
      .splice(0, 6);
  }

  onGetGameLastList() {
    this.gameDisplayList = this.gameHistoryList;
  }

  onActiveGameBtn(key: string) {
    this.isActiveGameBtn = key;

    switch (key) {
      case 'all':
        this.onGetGameAllList();
        break;
      case 'new':
        this.onGetGameNewList();
        break;
      case 'popular':
        this.onGetGamePopularList();
        break;
      case 'last':
        this.onGetGameLastList();
        break;
      default:
        this.onGetGameAllList();
        break;
    }
  }

  onClickGameLaunch(item: any) {
    this.spinner.show();
    this._gameService
      .getDataProductUrlForPlay(
        item.brand,
        item.category,
        this.deviceInfo.deviceType
      )
      .subscribe({
        next: async (data) => {
          this.setHistory(item);
          this.spinner.hide();
          this.openGame(await data.value, this.newTabGames.includes(item.code));
        },
        error: (e) => {
          console.error(e);
          this.spinner.hide();
        },
      });
  }

  setHistory(item: any) {
    this.gameHistoryList = this.gameHistoryList.filter((x: any) => {
      return x.id != item.id;
    });
    this.gameHistoryList.unshift(item);
    if (this.gameHistoryList.length > 10) this.gameHistoryList.pop();
    localStorage.setItem(
      'gameSportPlayLasted',
      JSON.stringify(this.gameHistoryList)
    );
  }

  openGame(url: string, isNewTab: boolean = false) {

    const isSafari = this.deviceInfo?.browser?.toLowerCase()?.indexOf('safari') > -1;

    if (isNewTab) {
      if (isSafari) {
        setTimeout(() => {
          var newTab = window.open(url, '_blank','toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }
        });
        return;
      }
      var newTab = window.open(url, '_blank');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }
      return;
    }

    if (url.indexOf('http:') != 0 && url.indexOf('hg.lsmapi.com') == -1) {
      this._router.navigate(['game/player'], { queryParams: { url: url } });
    } else if (!isSafari) {
      
      var newTab = window.open(url, '_blank');
          if (newTab === null) {
            // New tab was blocked
            var link = document.createElement('a');
            link.target = '_blank';
            link.href = url;
            document.body.appendChild(link);
            link.click();
          }

    } else {
      setTimeout(() => {
        var newTab = window.open(url, '_blank','toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes');
        if (newTab === null) {
          // New tab was blocked
          var link = document.createElement('a');
          link.target = '_blank';
          link.href = url;
          document.body.appendChild(link);
          link.click();
        }
      });
    }
      
  }
}
