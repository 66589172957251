
<div class="fixed top-0 w-full border-b-2 border-gray-300 z-20 inset-0 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
        <div
                class="-box-rebate inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
        >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline" style="text-align: center">
                            ยินดีด้วยคุณ
                        </h3>
                        <div class="mt-2">
                            <p
                                class="text-sm leading-5 text-gray-500"
                                style="font-size: 16px; text-align: center">
                                คุณได้ทำโปรโมชั่นสำเร็จ
                            </p>
                            <p
                                *ngIf="rewardedBonusAmount > 0"
                                class="text-sm leading-5 text-gray-500"
                                style="font-size: 16px; text-align: center">
                                ได้โบนัส {{rewardedBonusAmount}} บาท
                            </p>
                            <img appImgLazyLoad src="assets/img/icons/winner.gif">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
                  <button
                          (click)="onClickCloseModal()"
                          type="button"
                          class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  >ปิด</button>
                </span>
            </div>
        </div>
    </div>
</div>