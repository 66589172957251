import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InSessionGuard implements CanActivate {

  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin(state.url);
  }

  checkLogin(redirectUrl?: string): boolean {
    if (!this._authService.isLoggedIn) {
      return true;
    }

    this._authService.redirectUrl = redirectUrl;
    this._router.navigate(['index']);

    return false
  }
}
