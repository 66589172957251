<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing">
    <div class="container w-sm md:w-md xl:w-xl">
      <div class="-box-history-data md:hidden">
        <div class="my-3">
          <app-theme-line></app-theme-line>
          <div class="py-2">
            <app-theme-label [label]="'ประวัติการทำรายการ'"></app-theme-label>
          </div>
          <app-theme-line></app-theme-line>
        </div>

        <div>
          <app-theme-line></app-theme-line>
          <div class="-nav-data mt-0">
            <a
              class="-nav-01 is-link"
              [ngClass]="this.isActiveTab == 1 ? 'active' : ''"
              (click)="onActiveHistory(1)"
              ><span>รายการฝาก - ถอน</span></a
            >
            <a
              class="-nav-02 is-link"
              [ngClass]="this.isActiveTab == 2 ? 'active' : ''"
              (click)="onActiveHistory(2)"
              ><span>ประวัติการเข้าเล่น</span></a
            >
          </div>
          <app-theme-line></app-theme-line>
        </div>

        <div *ngIf="this.isActiveTab == 1">
          <app-history-deposit-withdraw></app-history-deposit-withdraw>
        </div>
        <div *ngIf="this.isActiveTab == 2">
          <app-history-player></app-history-player>
        </div>
      </div>

      <div class="hidden md:block -box-history-data flex justify-center">
        <div class="my-3">
          <app-theme-label [label]="'ประวัติการทำรายการ'"></app-theme-label>
        </div>
        <div class="flex justify-between">
          <div class="">
            <div class="flex justify-center w-full">
              <img
                src="https://fs.cdnxn.com/lsm99king/king/img/label-for-history-deposit-withdraw.webp"
              />
            </div>
            <app-history-player></app-history-player>
          </div>
          <div class="">
            <div class="flex justify-center w-full">
              <img
                appImgLazyLoad
                src="https://fs.cdnxn.com/lsm99king/king/img/label-for-history-player.webp"
              />
            </div>
            <app-history-deposit-withdraw></app-history-deposit-withdraw>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
