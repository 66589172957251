<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>
<div toastContainer></div>
<!-- Set Header -->
<div class="-header">
  <img appImgLazyLoad [src]="this.pathImage" alt="" class="-logo">
</div>
<!-- End Set Header -->

<div class="-content-register -step-01">
  <div class="container">
    <div class="-section-top">
      <div style="padding-top: 30px"></div>
      <h2 class="-title">ระบบย้ายสมาชิก</h2>
      <p>ข้อมูลปัจจุบัน</p>
      <p>ชื่อยูสเซอร์ : {{this.userName}} </p>
      <p>เครติดคงเหลือ : {{this.userCredit}} </p>
      <h1 style="color: red;">ต้องทำการถอนเงินออกจาก "กระเป๋าเกมส์" ทั้งหมดก่อนทำการย้าย เพื่อป้องกันยอดเงินมาไม่ครบ
      </h1>
      <div class="-form-style">
        <form [formGroup]="registermigrationForm" action="" id="form-register">
          <div class="-row -required -error">
            <label>หมายเลขโทรศัพท์</label>
            <input type="tel" maxlength="10" formControlName="phoneNumberForm" (keypress)="numberOnly($event)"
              class="-form-input">
            <p *ngIf="!phoneNumberValidate" class="-text-validate">โปรดใส่หมายเลขโทรศัพท์</p>
          </div>
          <div class="-row justify-center -required -error">
            <label>กรุณาเลือกธนาคาร</label>
            <div class="block rounded-lg max-w-sm text-center">
              <div class="p-1 bank-container">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(3)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KBANK.png" alt="ธนาคารกสิกรไทย"
                  [ngClass]="this.bankId == 3 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(2)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/SCB.png" alt="ธนาคารไทยพาณิชย์"
                  [ngClass]="this.bankId == 2 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(4)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KTB.png" alt="ธนาคารกรุงไทย"
                  [ngClass]="this.bankId == 4 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(1)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BBL.png" alt="ธนาคารกรุงเทพ"
                  [ngClass]="this.bankId == 1 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(11)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/TTB.png" alt="ธนาคารทหารไทยธนชาต"
                  [ngClass]="this.bankId == 11 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(5)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BAY.png" alt="ธนาคารกรุงศรีอยุธยา"
                  [ngClass]="this.bankId == 5 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(10)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/GSB.png" alt="ธนาคารออมสิน"
                  [ngClass]="this.bankId == 10 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(9)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/GHB.png" alt="ธนาคารอาคารสงเคราะห์"
                  [ngClass]="this.bankId == 9 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(6)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/BAAC.png" alt="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
                  [ngClass]="this.bankId == 6 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(12)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/ISBT.png" alt="ธนาคารอิสลามแห่งประเทศไทย"
                  [ngClass]="this.bankId == 12 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(13)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/KKP.png" alt="ธนาคารเกียรตินาคิน"
                  [ngClass]="this.bankId == 13 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(7)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/CIMB.png" alt="ธนาคารซีไอเอ็มบี"
                  [ngClass]="this.bankId == 7 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(14)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/LHBANK.png" alt="ธนาคารแลนด์ แอนด์ เฮ้าส์"
                  [ngClass]="this.bankId == 14 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(8)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/TISCO.png" alt="ธนาคารทิสโก้"
                  [ngClass]="this.bankId == 8 ? 'selected' : ''">
                <img appImgLazyLoad class="rounded-full" (click)="onChooseBank(15)"
                  src="https://fs.cdnxn.com/lsm99king/img/logo/UOB.png" alt="ธนาคารยูโอบี"
                  [ngClass]="this.bankId == 15 ? 'selected' : ''">
              </div>
            </div>
            <p *ngIf="!bankIdValidate" class="-text-validate">โปรดเลือกธนาคาร</p>
          </div>
          <div class="-row -required -error">
            <label>หมายเลขบัญชีธนาคาร{{bankName}}</label>
            <input type="tel" maxlength="20" formControlName="accountNoForm" (keypress)="numberOnly($event)"
              class="-form-input">
            <p *ngIf="!accountNoValidate" class="-text-validate">กรุณากรอกหมายเลขบัญชีธนาคาร</p>
          </div>
          <div class="-row">
            <a style="cursor: pointer" (click)="onSubmit()" class="-btn-01">
              <span>ยืนยันย้ายระบบ</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="fixed z-20 inset-0 overflow-y-auto" *ngIf="this.showModal">
  <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              กรุณาจดจำรหัสผ่าน
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500" style="font-size: 16px;">
                รหัสผ่านของท่านคือ <span
                  style="font-size: 20; font-weight: bold; color: red;">{{this.passwordForLogin}}</span>
                โดยท่านสามารถเข้าไปเปลี่ยน รหัสผ่านใหม่ ได้ที่เมนูข้อมูลส่วนตัวค่ะ
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
          <button (click)="toggleModal()" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base
                leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700
                focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5">ปิด</button>
        </span>
      </div>
    </div>
  </div>
</div>