import { Component, OnInit } from '@angular/core';
import { AffliateService } from './services/affliate.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.component.html',
  styleUrls: ['./affiliates.component.scss']
})
export class AffiliatesComponent implements OnInit {
  affliateFormGroup!: FormGroup;
  isUserAffliate: boolean = false;
  registerAffliateLink!: string;
  shortenAffliateLink!: string;
  credit!: number;
  totalMembers!: number;
  clickCount!: number;
  newMembers!: number;
  newDeposit!: number;
  totalPlayer!: number;
  isAffliateApproved!: boolean;
  
  totalBenefit!: number;
  totalCompanyWinlose!: number;
  totalTurnOver!: number;
  affliateInfoSummaryMemberBets!: any;
  affliateInfoSummaryMemberDepositWithdrawal!: any;
  lastUpdate!: Date;

  monthList: any = [
    {
      code: 0,
      nameTh: "มกราคม",
      nameEn: "january",
    },
    {
      code: 1,
      nameTh: "กุมภาพันธ์",
      nameEn: "february",
    },
    {
      code: 2,
      nameTh: "มีนาคม",
      nameEn: "march",
    },
    {
      code: 3,
      nameTh: "เมษายน",
      nameEn: "april",
    },
    {
      code: 4,
      nameTh: "พฤษภาคม",
      nameEn: "may",
    },
    {
      code: 5,
      nameTh: "มิถุนายน",
      nameEn: "june",
    },
    {
      code: 6,
      nameTh: "กรกฎาคม",
      nameEn: "july",
    },
    {
      code: 7,
      nameTh: "สิงหาคม",
      nameEn: "august",
    },
    {
      code: 8,
      nameTh: "กันยายน",
      nameEn: "september",
    },
    {
      code: 9,
      nameTh: "ตุลาคม",
      nameEn: "october",
    },
    {
      code: 10,
      nameTh: "พฤศจิกายน",
      nameEn: "november",
    },
    {
      code: 11,
      nameTh: "ธันวาคม",
      nameEn: "december",
    },
  ];
  currentYear!: number;
  currentMonth!: number;
  selectMonth!: number;
  formBuilder: any;
  urlWebsite: string = "";

  constructor(
    private _affliateService: AffliateService,
    private _authService: AuthService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private _formBuilder: FormBuilder,
  ) { 
    let urlWebsite: any = localStorage.getItem('urlWebsite');
    if (urlWebsite) {
      this.urlWebsite = JSON.parse(urlWebsite);
    }else{
      let url = new URL(window.location.href);
      this.urlWebsite = url.origin;
    }
  }

  ngOnInit(): void {
    this.spinner.show();
    this.onGetDataAffliateInfo();

    this.onGetDate();
    this.onInitAffliateFormGroup();
    this.calMonthList();
  }

  calMonthList() {
    let d = new Date();
    let e = new Date();

    let newDate = d.getMonth();
    let oldDate = 0;
    let dateNow = e.getMonth() + 1;
    let monthListNew = []
    d.setMonth(d.getMonth() - 12);
    let lastYear = d.getFullYear();    

    for (let i = 0; i < 13; i++) {
      if (newDate < 12) {
        monthListNew.push({
          code: newDate,
          nameTh: this.monthList[newDate].nameTh,
          nameEn: this.monthList[newDate].nameEn,
          year: lastYear
        })
        newDate = newDate + 1
      } else if (oldDate <= dateNow) {
        monthListNew.push({
          code: oldDate,
          nameTh: this.monthList[oldDate].nameTh,
          nameEn: this.monthList[oldDate].nameEn,
          year: e.getFullYear()
        })
        oldDate = oldDate + 1
      }
    }
    this.monthList = monthListNew.reverse();

    this.onInitSelectMonthYear(this.monthList[0]);
  }

  onGetDataAffliateInfo(){
    this._affliateService.getDataAffliateInfo()
    .subscribe({
      next: data => {
        if (Number(data.affliateGroupId)>0 && data.affliateId!=null) {
          this.isUserAffliate = true;
          this.registerAffliateLink = `${this.urlWebsite}${data.registerAffliateLink}`;
          this.credit = data.credit;
          this.totalMembers = data.totalMembers;
          this.clickCount = data.clickCount;
          this.newMembers = data.newMembers;
          this.newDeposit = data.newDeposit;
          this.totalPlayer = data.totalPlayer;
          this.isAffliateApproved = data.isAffliateApproved;

          this.totalBenefit = data.totalBenefit;
          this.totalCompanyWinlose = data.totalCompanyWinlose;
          this.totalTurnOver = data.totalTurnOver;
          this.affliateInfoSummaryMemberBets = data.affliateInfoSummaryMemberBets;
          this.affliateInfoSummaryMemberDepositWithdrawal = data.affliateInfoSummaryMemberDepositWithdrawal;

          this.lastUpdate = new Date();

          const affiliateUrl = `${this.urlWebsite}${data.registerAffliateLink}`;
          const userInfo = this._authService.getUserInfo();
          const tags: string[] = [];

          if (userInfo && userInfo.phone_number) {
            tags.push(userInfo.phone_number);
          }

          if (environment.shortenLinkEnable) {
            this._affliateService.getShortenUrl(affiliateUrl, tags).subscribe({
              next: (result) => {
                if (result && result.shortURL) {
                  this.shortenAffliateLink =
                    result.secureShortURL || result.shortURL;
                }
                this.registerAffliateLink = affiliateUrl;
              },
              error: () => {
                this.registerAffliateLink = affiliateUrl;
              },
              complete: () => {
                setTimeout(() => {
                  this.spinner.hide();
                }, 800);
              },
            });
          } else {
            this.registerAffliateLink = affiliateUrl;
            setTimeout(() => {
              this.spinner.hide();
            }, 800);
          }
          
          setTimeout(()=>{
            this.spinner.hide();
          }, 800);
        } else {
          this.isUserAffliate = false;
          setTimeout(()=>{
            this.spinner.hide();
          }, 800);
        }
      },
      error: e => {
        console.error(e);
      }
    })
    
  }

  copyText(text: string){
    navigator.clipboard
      .writeText(text)
      .then(() => {
        this.toastr.success('คัดลอกลิ้งสำเร็จ', 'สำเร็จ');
      })
      .catch(() => {
        this.toastr.error(
          'ไม่สามารถคัดลอกได้ โปรดกดคัดลอกเลขที่บัญชีด้วยตัวเองค่ะ',
          'ไม่สำเร็จ'
        );
      });
  }

  onClickRefresh(){
    this.onGetDataAffliateInfo();
  }

  onClickWithdraw(){
    this.spinner.show();

    this._affliateService.setDataWithdrawAffliateCommission()
    .subscribe({
      next: data => {
        setTimeout(()=>{
          this.onGetDataAffliateInfo();
          this.spinner.hide();
          this.toastr.clear();
          this.toastr.success('ทำรายการสำเร็จ', 'สำเร็จ');
        }, 800);
      },
      error: e => {
        setTimeout(()=>{
          console.error(e);
          this.toastr.clear();
          this.toastr.error('ทำรายการไม่สำเร็จ', 'ไม่สำเร็จ');
        }, 800);
      }
    })
  }

  onChangeMonthYear(event: Event){ 

    let dataInfo = this.monthList[this.affliateFormGroup.controls['selectMonthForm'].value];
    let year = dataInfo.year;
    let month = dataInfo.code+1;
    month = String(month).padStart(2,"0");
    

    this._affliateService.getDataAffliateOverviewInfo(year, month)
    .subscribe({
      next: data => {
        this.totalMembers = data.totalMembers;
        this.clickCount = data.clickCount;
        this.newMembers = data.newMembers;
        this.newDeposit = data.newDeposit;
        this.totalPlayer = data.totalPlayer;
      },
      error: e => {
        console.error(e);
      }
    })

  }

  onInitSelectMonthYear(dataInfo: any){
    let year = dataInfo.year;
    let month = dataInfo.code+1;
    month = String(month).padStart(2,"0");
    

    this._affliateService.getDataAffliateOverviewInfo(year, month)
    .subscribe({
      next: data => {
        this.totalMembers = data.totalMembers;
        this.clickCount = data.clickCount;
        this.newMembers = data.newMembers;
        this.newDeposit = data.newDeposit;
        this.totalPlayer = data.totalPlayer;
      },
      error: e => {
        console.error(e);
      }
    })
  }

  onGetDate(){
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    this.currentMonth = today.getMonth();
    this.currentYear = today.getFullYear()+543;

    
  }

  onInitAffliateFormGroup(){
    this.affliateFormGroup = this._formBuilder.group({
      selectMonthForm: [0, [Validators.required]]
    });
  }

}
