<div
  id="toast-container"
  style="visibility: hidden"
  class="-btn-toast toast-success"
>
  โอนเงินสำเร็จ
</div>

<div
  id="toast-container-deposit-fail"
  style="visibility: hidden"
  class="-btn-toast-fail"
>
  โอนเงินไม่สำเร็จ
</div>

<div
  id="toast-container-verification-bank"
  style="visibility: hidden"
  class="-btn-toast toast-success"
>
  ระบบอนุมัติบัญชีสำเร็จแล้วค่ะ
</div>

<div
  id="toast-container-verification-reject"
  style="visibility: hidden"
  class="-btn-toast-fail"
>
  ระบบอนุมัติบัญชีไม่สำเร็จค่ะ
</div>

<div class="hidden md:block">
  <app-frame-bg [type]="'header'">
    <div class="-header">
      <a
        id="hamburger"
        (click)="toggleMenuHamburger()"
        [ngClass]="isHidden ? '-hamburger -active' : '-hamburger'"
        style="cursor: pointer"
      >
      </a>

      <img
        [src]="this.pathImage"
        alt=""
        class="-logo btn-link"
        routerLink="/wallet"
      />
      <!-- <h2 class="-text-header">เมนูหลัก</h2> -->

      <div class="-profile">
        <p>{{ this.displayName }}</p>
        <p class="-wallet">
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/icon-coin.webp"
            width="24px"
            class="inline"
          />
          {{ this.creditBalance | number }}
        </p>
      </div>

      <a style="cursor: pointer" class="-noti" onclick="openPopup();">
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti.webp"
          alt=""
          width="54"
          height="56"
        />
      </a>
    </div>
  </app-frame-bg>
</div>

<div class="block md:hidden">
  <app-frame-bg
    [type]="'header'"
    [ngStyle]="{ display: this.hasGameUrl ? 'none' : '' }"
  >
    <div class="-header">
      <a
        id="hamburger"
        (click)="toggleMenuHamburger()"
        [ngClass]="isHidden ? '-hamburger -active' : '-hamburger'"
        style="cursor: pointer"
      >
      </a>

      <img
        [src]="this.pathImage"
        alt=""
        class="-logo btn-link"
        routerLink="/wallet"
      />
      <!-- <h2 class="-text-header">เมนูหลัก</h2> -->

      <div class="-profile">
        <p>{{ this.displayName }}</p>
        <p class="-wallet">
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/icon-coin.webp"
            width="24px"
            class="inline"
          />
          {{ this.creditBalance | number }}
        </p>
      </div>

      <a style="cursor: pointer" class="-noti" onclick="openPopup();">
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti.webp"
          alt=""
          width="54"
          height="56"
        />
      </a>
    </div>
  </app-frame-bg>
</div>

<div class="md:hidden">
  <div
    class="absolute bg-transparent top-0 left-0 h-[100vh] w-[100%]"
    [ngStyle]="{ display: isDragging ? 'block' : 'none' }"
    [cdkDropListAutoScrollStep]="10000"
    (cdkDropListDropped)="onDragEnd($event)"
    cdkDropList
    #dropMove
  ></div>

  <div
    [ngStyle]="{ display: this.hasGameUrl ? '' : 'none' }"
    (cdkDragStarted)="onDragStart($event)"
    (cdkDragReleased)="onDragEnd($event)"
    [cdkDragDisabled]="isDisabledDrag"
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    cdkDrag
  >
    <button
      id="hamburger-move"
      [disabled]="isDragging"
      (click)="toggleMenuHamburger()"
      class="-hamburger-move"
    ></button>
  </div>
</div>

<div class="-content-popup w-sm mr-[1rem]">
  <div class="-header">
    <a href="javascript:void(0);" class="-btn-close" onclick="closePopup();">
      <!-- <img appImgLazyLoad src="assets/img/icons/close.svg" alt="" /> -->
      <img
        appImgLazyLoad
        src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
        class="w-[40px]"
      />
    </a>
  </div>

  <div class="container w-sm">
    <div class="my-3">
      <app-theme-line></app-theme-line>
      <div class="py-2">
        <app-theme-label [label]="'แจ้งเตือน'"></app-theme-label>
      </div>
      <app-theme-line></app-theme-line>
    </div>

    <app-theme-line></app-theme-line>
    <div class="-btn-filter m-0 py-2">
      <a
        (click)="onSelectNoticategory('')"
        class="-btn-all is-link"
        [ngClass]="this.activeNotiTab == '' ? 'active' : ''"
      >
        <span class="-text cursor-pointer">ทั้งหมด</span>
      </a>
      <a
        (click)="onSelectNoticategory('deposit')"
        class="-btn-new is-link"
        [ngClass]="this.activeNotiTab == 'deposit' ? 'active' : ''"
      >
        <span class="-text cursor-pointer">ฝากเงิน</span>
      </a>
      <a
        (click)="onSelectNoticategory('withdraw')"
        class="-btn-popular is-link"
        [ngClass]="this.activeNotiTab == 'withdraw' ? 'active' : ''"
      >
        <span class="-text cursor-pointer">ถอนเงิน</span>
      </a>
      <a
        (click)="onSelectNoticategory('bonus')"
        class="-btn-latest is-link"
        [ngClass]="this.activeNotiTab == 'bonus' ? 'active' : ''"
      >
        <span class="-text cursor-pointer">โบนัส</span>
      </a>
    </div>
    <app-theme-line></app-theme-line>

    <!--   TODO comment for future has been enhance inbox     -->
    <!--        <p class="-title-noti">คุณมีการแจ้งเตือนใหม่ 2 รายการ</p>-->
    <p class="-title-noti" *ngIf="dataCollection.length == 0">
      คุณไม่มีข้อความ
    </p>

    <div class="-list-noti">
      <app-frame-bg [type]="item.frameType" *ngFor="let item of dataCollection">
        <div class="relative h-[105px] w-full">
          <div class="absolute w-full h-full top-[1.5rem] left-[1.75rem]">
            <!-- <h3>{{ item.transactionName }} {{ item.remark }}</h3> -->
            <div class="relative">
              <p class="text-gray-400 mt-4 ml-4">
                {{ item.createdDate | date : "dd/MM/yyyy HH:mm" : "+0000" }}
              </p>
              <div class="absolute w-[20px] top-[-20px] right-[40px]">
                <img
                  src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti-success.webp"
                  *ngIf="item.transactionStatusType === 'success'"
                />
                <img
                  src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti-cancel.webp"
                  *ngIf="item.transactionStatusType === 'cancel'"
                />
                <img
                  src="https://fs.cdnxn.com/lsm99king/king/img/icon-noti-info.webp"
                  *ngIf="item.transactionStatusType == ''"
                />
              </div>
              <div class="group-noti-">
                <div class="right-[40px] bottom-[-35px]">
                  <span> {{ item.transactionName }} {{ item.remark }} </span>
                </div>
                <div class="right-[40px] bottom-[-35px]">
                  <span
                    *ngIf="item.transactionType != 'info'"
                    [ngStyle]="{
                      color:
                        item.transactionType === 'deposit'
                          ? '#45FFA6'
                          : '#EB4B45'
                    }"
                    >{{ item.transactionTypeName }}
                    {{ item.transactionType === "deposit" ? "+" : "-"
                    }}{{ item.amount | number : "1.2-2" }}</span
                  >
                </div>
              </div>
            </div>
            <!-- <p>
              {{ item.transactionTypeName }}
              <span *ngIf="item.transactionType != 'info'">{{
                item.amount | number : "1.2-2"
              }}</span>
            </p> -->
          </div>
        </div>
      </app-frame-bg>

      <!--     TODO  comment for future has been enhance inbox        -->
      <!--        <div class="-item -unread">-->
      <!--            <h3>ถอนเงินสำเร็จ</h3>-->
      <!--            <p>รายการการถอนเงินของคุณสำเร็จแล้วระบบจะโอนเงินยอดเงินจะเข้าบัญชีของคุณภายใน 3 นาที</p>-->
      <!--            <p><small>23:17 28-01-2022</small></p>-->
      <!--        </div>-->
      <!--        <div class="-item -unread">-->
      <!--            <h3>ยอดฝากเงินจะเข้าสู่กระเป๋าเงินคุณแล้ว</h3>-->
      <!--            <p>คุณสามารถตรวจสอบได้ในเมนูกระเป๋าเงิน</p>-->
      <!--            <p><small>23:17 28-01-2022</small></p>-->
      <!--        </div>-->
      <!--        <div class="-item">-->
      <!--            <h3>แจ้งเตือนจากระบบ</h3>-->
      <!--            <p>ธนาคาร TTB ขัดข้องชั่วคราวเนื่องจากการควบรวม คุณจะทำรายการผ่านธนาคารนรี้อีกครั้งวันที่ 29-01-2022 เวลา 06:00</p>-->
      <!--            <p><small>23:17 28-01-2022</small></p>-->
      <!--        </div>-->
      <!--        <div class="-item">-->
      <!--            <h3>คุณมี 2 โปรโมชั่นที่สามารถรับได้</h3>-->
      <!--            <p>คุณสามารถกดรับ พร้อมระบุจำนวนเงินที่ต้องการใช้กับโปรโมชั่นได้ทันที</p>-->
      <!--            <p><small>23:17 28-01-2022</small></p>-->
      <!--        </div>-->
    </div>
  </div>

  <br />

  <div class="flex justify-center" *ngIf="this.totalPage > 1">
    <nav aria-label="Page navigation example">
      <ul class="flex list-style-none">
        <!-- <li class="page-item disabled">
            <a class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-500 pointer-events-none focus:shadow-none"
            tabindex="-1" (click)="onPaginator();" ><</a>
        </li> -->
        <li class="page-item is-link">
          <a
            class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
            (click)="paginateEvent(0)"
            ><</a
          >
        </li>

        <li
          *ngFor="let page of totalPageList; let i = index"
          class="page-item is-link"
        >
          <a
            [ngClass]="
              page == currentPage
                ? 'border-0 bg-gray-200 page-link relative block py-1.5\n' +
                  '               px-3 border-0 bg-transparent\n' +
                  '               outline-none transition-all\n' +
                  '               duration-300 rounded-full text-gray-800\n' +
                  '               hover:text-gray-800 hover:bg-gray-200\n' +
                  '               focus:shadow-none'
                : 'page-link relative block py-1.5\n' +
                  '               px-3 border-0 bg-transparent\n' +
                  '               outline-none transition-all\n' +
                  '               duration-300 rounded-full text-gray-800\n' +
                  '               hover:text-gray-800 hover:bg-gray-200\n' +
                  '               focus:shadow-none'
            "
            (click)="paginateEvent(page + 1)"
            >{{ page + 1 }}</a
          >
        </li>

        <li class="page-item is-link">
          <a
            class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
            (click)="paginateEvent(this.totalPage)"
            >></a
          >
        </li>
      </ul>
    </nav>
  </div>
</div>
