import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiEndpointConstant} from "../../core/api.endpoint.constant";

const miniGameMenuUrl = ApiEndpointConstant.miniGameMenu;
const checkPromitionLuckyAvailableUrl = ApiEndpointConstant.checkPromitionLuckyAvailable;

const userPromotionLuckyBonusBonusUrl = ApiEndpointConstant.userPromotionLuckyBonus; 
const getPromotionLuckyBonusBonusUrl = ApiEndpointConstant.getPromotionLuckyBonus; 
const getPromotionGameResultUrl = ApiEndpointConstant.getPromotionGameResult;

@Injectable({
  providedIn: 'root'
})
export class miniGameService {
  constructor(
    private _httpClient: HttpClient
  ) { }

  getGameMenu() {
    return this._httpClient.get(`${miniGameMenuUrl}`)
  }

  checkPromitionLuckyAvailable(promotionId : number) {
    return this._httpClient.get(`${checkPromitionLuckyAvailableUrl}/${promotionId}`);
  }

  userPromotionLuckyBonus(promotionId : any) {
    return this._httpClient.post(`${userPromotionLuckyBonusBonusUrl}/${promotionId}`, promotionId);
  }

  getPromotionLuckyBonus(promotionId : any) {
    return this._httpClient.post(`${getPromotionLuckyBonusBonusUrl}/${promotionId}`, promotionId);
  }

  getPromotionGameResult(gameId : number) {
    return this._httpClient.get(`${getPromotionGameResultUrl}/${gameId}`);
  }
  
}
