<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>

<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- Alert message -->
<app-modal-alert-message
        *ngIf="showModalAlertMessage"
        [alertMsgContents]="alertMsgContents"
        (isClickCloseModal)="onClickCloseModal($event)">
</app-modal-alert-message>

<!-- start content this page -->
<main id="main-content" class="min-h-screen">
  <div class="-content-playing -game-play" >
    <div class="container">
      <div class="-box-title">
        <h1 class="text-black">คุณเลือกตู้เซฟลุ้นรางวัล</h1>
        <p class="bg-[#AF010B] inline-block text-[22px] px-3 py-2 uppercase mt-3">BERLIN777</p>
      </div>

      <div class="-box-safety" (click)="safestart()">
        <div class="-safety">
          <img appImgLazyLoad src="assets/img/game-safety/{{safeType}}-body.png" alt="safety-body" class="-body">

          <div class="-group-door-close">
            <img appImgLazyLoad src="assets/img/game-safety/{{safeType}}-door-close.png" alt="safety-door-close" class="-door-close">
            <img appImgLazyLoad src="assets/img/game-safety/wheel.png" alt="wheel" class="-wheel">
            <img appImgLazyLoad src="assets/img/game-safety/text-berlin777.png" alt="berlin777" class="-text-berlin777">
            <img appImgLazyLoad src="assets/img/game-safety/text-open.png" alt="text-open" class="-text-open">
          </div>

          <img appImgLazyLoad src="assets/img/game-safety/{{safeType}}-door-open.png" alt="safety-door-open" class="-door-open">
        </div>

        <div class="-light">
          <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/light.png" alt="light"></div>
        </div>
        <div class="-ribbon">
          <div class="-inner"><img appImgLazyLoad src="assets/img/game-safety/ribbon.png" alt="ribbon"></div>
        </div>

        <div class="-detail">
          <p class="text-[22px] mt-5">ยินดีด้วยคุณได้รับ</p>
          <p class="text-[32px] font-medium mt-3">{{resultBonus}}</p>
          <p class="text-reward text-[42px] font-medium text-white mt-5 bg-[#AF010B] py-4 px-3 inline-flex">{{bonus}}</p>
        </div>
      </div>

      <p class="-remark -before-click text-center text-2xl leading-8">กดที่ตู้เซฟ<br>เพื่อทำการเปิดรับของรางวัลพิเศษ</p>

      <p class="-remark -after-click text-center"><strong>Remark</strong> (สำหรับของรางวัลเพื่ออธิบายรายละเอียด<br>ข้อจำกัด วิธีการใช้รางวัล หรือเงื่อนไขเพิ่มเติมต่างๆ)</p>

      <a href="javascript:void(0);" class="-btn-01" onclick="openPopupReward();">รับรางวัล</a>
    </div>
  </div>
</main> <!-- end content this page -->

<div class="-content-popup-reward bg-white">
  <div class="-header">
    <h2 class="-text-header text-[#AF010B]">รับรางวัลจากตู้เซฟ</h2>
    
    <a href="javascript:void(0);" class="-btn-close" onclick="closePopupReward();">
      <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
    </a>
  </div>

  <div class="container">
    <div class="-box-img w-[110px]">
      <img appImgLazyLoad src="assets/img/game-safety/{{safeType}}-open-light.png" alt="safety">
    </div>

    <div class="-detail text-black">
      <p class="text-2xl font-medium">คุณได้รับรางวัล</p>
      <p class="font-medium text-[32px] text-[#AF010B] mt-5">{{resultBonus}}</p>
      <p class="text-reward font-medium text-[54px] text-[#AF010B] mt-5">{{bonus}}</p>
      <p class="mt-10 text-lg"><strong>จากการเปิดตู้เซฟ กับ BERLIN777 เรียบร้อยแล้ว</strong></p>
      <!-- <p class="font-light">ระบบจะโอนรางวัลเข้าสู่ยอดในบัญชีของคุณภาย<br>ใน 30 นาที</p> -->
      <a href="/safe-result" class="-btn-01">ตกลง</a>
      <!-- <a href="game-safety-play-again.php" class="block text-center mt-5 font-medium text-[#AF010B]">เปิดตู้เซฟลุ้นรางวัลอีกครั้ง</a> -->
    </div>
  </div>
</div>


