// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: "Prod",
  backendApi: "https://api.ppnes.com",
  authApi: "https://auth.ppnes.com",
  webSocket: "wss://266bmfatdk.execute-api.ap-southeast-1.amazonaws.com/prod",
  // luckywheel_centerCircleImageUrl: "https://resource-luckydraw.s3.ap-southeast-1.amazonaws.com/logo-berlin.png",
  luckywheel_centerCircleImageUrl:
    "https://fs.cdnxn.com/lsm99king/king/img/King-HNY1.gif",
  luckywheel_wheelStrokeColor: "#ffe889",
  luckywheel_segmentStrokeColor: "#E2E2E2",
  luckywheel_colorArray: "#000000",
  shortenLinkEnable: true,
  shortenLinkApi: "https://shorten-url.azurewebsites.net",
  shortenLinkApiKey: "f8d5ce81-8163-46d1-a5c7-8aa46dfc71ec",
  shortenLinkTitle: "รับสิทธิพิเศษ",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
