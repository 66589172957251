import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { ProfileService } from "src/app/pages/profile/services/profile.service";

@Component({
  selector: "app-bank-list",
  templateUrl: "./bank-list.component.html",
  styleUrls: ["./bank-list.component.css"],
})
export class BankListComponent implements OnInit {
  @Input() size: string | undefined;
  @Input() frame: string = "bank-list";
  @Output() onSplide = new EventEmitter<any>();

  userInfo!: any;
  bankList!: any[];
  withdrawBankList: any[] | undefined;
  telephoneNumber: any;
  displayName: any;
  memberRole: any;
  username: any;

  constructor(private _profileService: ProfileService) { }

  ngOnInit(): void {
    this.userInfo = JSON.parse(localStorage.getItem("appUserInfo") || "{}");
    this.bankList = JSON.parse(localStorage.getItem("bankList") || "{}");
    console.log(this.frame);
    this.getMemberWithdrawBankList();
  }

  getMemberWithdrawBankList() {
    this._profileService.getDataWithdrawBankList().subscribe({
      next: (data) => {
        this.setDataMemberWithdrawBankList(data.collection);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataMemberWithdrawBankList(sourceData: any) {
    if (sourceData) {
      let packData: any[] = [];

      sourceData.forEach((e: any) => {
        let bankId = Number(e.registBankId) - 1;
        e.bankAccountName = this.bankList[bankId].name;
        let bCode = this.bankList[bankId].code;
        e.bankAccountCode = bCode.toUpperCase();
        e.registBankLogoUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${e.bankAccountCode}.png`;

        // bankAccountStatusClass
        if (e.isApproved == true) {
          e.bankAccountStatusClass = "use";
          e.bankAccountStatusName = "ตรวจสอบบัญชีสำเร็จแล้ว";
        } else if (e.isApproved == false && e.isRejected == false) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "กรุณารอตรวจสอบบัญชีสักครู่ค่ะ";
        } else if (e.isRejected == true) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "บัญชีที่ไม่ได้ใช้งาน";
        } else {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "ไม่ทราบ ติดต่อผู้ดูแลระบบ";
        }

        packData.push(e);
      });

      this.withdrawBankList = packData;
    }
  }

  onSplideActive(evt: any) {
    this.onSplide.emit(evt[0].index);
  }
}
