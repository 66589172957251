import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointConstant } from 'src/app/core/api.endpoint.constant';
import { HistoryAllDepositWithdrawModel } from 'src/app/models/history-all-deposit-withdraw.model';

const getDepositWithdrawAllHistoryBaseUrl = ApiEndpointConstant.GetDepositWithdrawAllHistory;
const getMemberWinLoseHistoryByUsernameBaseUrl = ApiEndpointConstant.GetMemberWinLoseHistoryByUsername;
const getUserNotificationsBaseUrl = ApiEndpointConstant.GetUserNotifications;

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getDataDepositWithdrawAllHistory(take:number, skip:number) {
    return this._httpClient.get<HistoryAllDepositWithdrawModel>(`${getDepositWithdrawAllHistoryBaseUrl}Take=${take}&Skip=${skip}&Asc=false&Orderby=createdDate`);
  } 

  getDataMemberWinLoseHistoryByUsername(take:number, skip:number) {
    return this._httpClient.get<any>(`${getMemberWinLoseHistoryByUsernameBaseUrl}Take=${take}&Skip=${skip}`);
  }

  getUserNotifications(take:number, skip:number, category: String){
    return this._httpClient.get<any>(`${getUserNotificationsBaseUrl}Take=${take}&Skip=${skip}&category=${category}`);
  }
}
