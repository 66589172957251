<div *ngIf="isShowRunningMsg">
  <!-- <div class="container">

  </div> -->

  <app-frame-bg [type]="'snackbar'">
    <div class="-desktop">
      <div class="container">
        <div
          class="mt-4 flex space-x-4 justify-start items-center pt-1 top-head-a pta-t"
          style="z-index: 99"
        >
          <marquee direction="left" behavior="scroll">{{ runningMsg }}</marquee>
          <a (click)="onCloseRunningMsg()">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
              class="w-[30px]"
            />
          </a>
        </div>
      </div>
    </div>
    <div class="-mobile">
      <div class="container">
        <div
          class="mt-9a flex space-x-4 justify-start items-center px-[1rem] pt-1"
        >
          <marquee direction="left" behavior="scroll">{{ runningMsg }}</marquee>
          <a (click)="onCloseRunningMsg()">
            <img
              appImgLazyLoad
              src="https://fs.cdnxn.com/lsm99king/king/img/icon-close-modal.webp"
              class="w-[30px]"
            />
          </a>
        </div>
      </div>
    </div>
  </app-frame-bg>
</div>
