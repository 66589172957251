import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UserInfomationModel } from 'src/app/models/user-infomation.model';
import { PromotionService } from './services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-get-promotion',
  templateUrl: './get-promotion.component.html',
  styleUrls: ['./get-promotion.component.scss']
})
export class GetPromotionComponent implements OnInit {
  gameLastPlayList!: any;
  promotionList!: any;
  dataInfo!: UserInfomationModel;
  creditAmount!: number;
  creditAmountControl: any;
  totalUserPromotion!: number;
  deviceInfo!: any;
  usePromotionId!: number;
  creditBalance!: number;
  usePromotionInfo: any;
  showModal: boolean = false;
  showModalPromotion: boolean = false;
  showModalPromotionCompleted: boolean = false;
  promotionForSelect: any;
  promotionContents: string = '';
  rewardedBonusAmount: number = 0;
  isReadOnly: boolean = true;
  isDisabled: boolean = false;
  indexOfPromotion: number = 0;

  constructor(
    private _promotionService : PromotionService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    let promotionForSelect: any = localStorage.getItem("promotionForSelect");
    this.promotionList = JSON.parse(promotionForSelect);

    let info: any;
    info = localStorage.getItem('deviceInfo');
    this.deviceInfo = JSON.parse(info);

    this.creditAmountControl = new FormControl("" , [
      Validators.required, 
      Validators.minLength(1), 
      Validators.maxLength(9), 
      Validators.pattern(/^\d+(\.\d{0,2})?$/),
      Validators.min(0)
    ]);

    this.onGetDatagetDataUserInfomation();
    this.onGetDataUserBalance();
    this.setPromotion();
  }

  onSplideActive(evt: any){
    this.indexOfPromotion = evt[0].index;
    this.setPromotion();
  }

  onGetDatagetDataUserInfomation(){
    this._promotionService.getDataUserInfomation()
    .subscribe({
      next: data => {
        this.dataInfo = data;

        if (this.dataInfo.activedPromotionId>0 || !this.promotionList) {
          this.router.navigate(['/wallet']);
        }else{
          this.onGetDataUserAvailablePromotion();
        }
      },
      error: e => {
        console.error(e);
      }
    })
  }

  onGetDataUserBalance(){
    this._promotionService.getDataUserBalance()
    .subscribe({
      next: data => {
        this.creditAmount = data.creditBalance;
        this.creditBalance = data.creditBalance;

        this.showModalPromotionCompleted = data.isPromotionCompleted;
        this.rewardedBonusAmount = data.rewardedBonusAmount;
      },
      error: e => {
        console.error(e);
      }
    })
  }

  onGetDataUserAvailablePromotion(){
    this.totalUserPromotion = this.promotionList.length;

    if (this.totalUserPromotion==0 || this.totalUserPromotion==undefined) {
      this._promotionService.getDataUserAvailablePromotion()
      .subscribe({
        next: data => {
          if (data) {
            this.promotionList = data;
            this.totalUserPromotion = data.length;
          }
        },
        error: e => {
          console.error(e);
        }
      })
    }

    this.usePromotionId = this.promotionList[0].id;
    this.usePromotionInfo = this.promotionList[0];
  }

  onSubmit(){
    this.toastr.clear();
    this.spinner.show();
    let creditAmount = Number(this.creditAmount);

    if (creditAmount>0) {
      if (creditAmount>this.creditBalance) {
        this.spinner.hide();
        this.toastr.error('คุณใส่จำนวนเงินมากกว่าเงินในกระเป๋า', 'ข้อมูลไม่ถูกต้อง');
      } else {
        if (this.usePromotionInfo.promotionTypeId==1) {
          creditAmount = this.creditBalance;
          this.creditAmount = this.creditBalance;
        }

        let payload = {
          amount: creditAmount,
          promotionId: this.usePromotionId
        }
   
        this._promotionService.postDataSelectPromotion(payload)
        .subscribe({
          next: data => {
            this.toastr.clear();
            setTimeout(()=>{
              localStorage.removeItem("promotionForSelect");
              this.spinner.hide();
              this.toastr.success('ทำรายการสำเร็จ', 'สำเร็จ');
              this.router.navigate(['/wallet']);
            }, 200)
          },
          error: e => {
            console.error('e:::::::', e.error.messageHint);
            setTimeout(() => {
              localStorage.removeItem("promotionForSelect");
              this.spinner.hide();
              this.toastr.error(e.error.messageHint, 'ไม่สำเร็จ');
            }, 200)
          }
        })
        
      }

    } else {
      this.toastr.clear();
      setTimeout(()=>{
        this.spinner.hide();
        this.toastr.error('ใส่จำนวนเงินไม่ถูกต้อง', 'ข้อมูลไม่ถูกต้อง');
      }, 300)
    }
  }

  toggleModal(){
    this.showModal = !this.showModal;
  }

  onClickCloseModal(event: any) {
    this.showModalPromotion = !event;
    this.showModalPromotionCompleted = !event;
    document.body.style.overflow = '';
  }

  onClickSubmitPromotion(event: any) {
    this.showModalPromotion = !event;
    document.body.style.overflow = '';
    this.onSubmit()
  }

  onClickPromotion(dataPromotion: any) {
    this.showModalPromotion = true;
    this.promotionContents = dataPromotion.promotionRichText;
    document.body.style.overflow = 'hidden';
  }

  onCheckPromotion() {
    this.showModalPromotion = true;
  }

  setPromotion() {
    this.usePromotionId = this.promotionList[this.indexOfPromotion].id;
    this.usePromotionInfo = this.promotionList[this.indexOfPromotion];
    this.promotionContents = this.promotionList[this.indexOfPromotion].promotionRichText;
    
    if (this.usePromotionInfo.promotionTypeCode == "welcome" ||
        this.usePromotionInfo.promotionTypeCode == "reload" ||
        this.usePromotionInfo.promotionCalTypeCode == "winloss-win") {
      this.isDisabled = true;
      this.creditAmount = this.creditBalance;
      this.creditAmountControl.disable();
    }
    else {
      this.isDisabled = false;
      this.creditAmountControl.enable();
    }
  }
}
