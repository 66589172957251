<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<!-- End Set Header -->

<app-modal-with-rich-text
  *ngIf="showModalPromotion"
  (isClickCloseModal)="onClickCloseModal($event)"
  [imgUrl]="imgUrlDesktop"
  [promotionContents]="promotionContents"
  [isReadOnly]="true"
>
</app-modal-with-rich-text>

<!-- start content this page -->
<!-- <main id="main-content">
    <div class="-content-playing">
        <div class="container">


            <div class="category">
                <a (click)="onSelectCategory('all')" [ngClass]="this.onSelectCategoryName=='all' ? 'active' : ''">
                    <span>ทั้งหมด</span>
                </a>
                <a (click)="onSelectCategory('sport')" [ngClass]="this.onSelectCategoryName=='sport' ? 'active' : ''">
                    <span>กีฬา</span>
                </a>
                <a (click)="onSelectCategory('casino')" [ngClass]="this.onSelectCategoryName=='casino' ? 'active' : ''">
                    <span>คาสิโน</span>
                </a>
                <a (click)="onSelectCategory('slot')" [ngClass]="this.onSelectCategoryName=='slot' ? 'active' : ''">
                    <span>สล็อต</span>
                </a>
                <a (click)="onSelectCategory('lotto')" [ngClass]="this.onSelectCategoryName=='lotto' ? 'active' : ''">
                    <span>หวย</span>
                </a>
			</div>
            <div class="-section-recommended-promotion">
                <div class="-list-img" *ngFor="let item of promotionList" style="margin-bottom: 10px;">
                    <a>
                      <img appImgLazyLoad (click)="onClickPromotion(item)" [src]="item.imgUrlDesktop ? item.imgUrlDesktop : 'assets/img/uploads/promotion.jpg'" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</main>  -->
<!-- end content this page -->

<app-recommend-promotions
  *ngIf="this.promotionList.length > 0"
  [promotions]="this.promotionList"
  (handleClickPromotion)="this.onClickPromotion($event)"
  [single]="false"
></app-recommend-promotions>
