<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<app-snackbar-running-message></app-snackbar-running-message>
<!-- End Set Header -->

<!-- Alert message -->
<app-modal-alert-message
  *ngIf="showModalAlertMessage"
  [alertMsgContents]="alertMsgContents"
  class="wallet-alert-msgs"
  (isClickCloseModal)="onClickCloseModal($event)"
>
</app-modal-alert-message>

<app-modal-with-rich-text
  *ngIf="showModalPromotion"
  [imgUrl]="imgUrlDesktop"
  [promotionContents]="promotionContents"
  (isClickCloseModal)="onClickCloseModal($event)"
  [isReadOnly]="true"
>
</app-modal-with-rich-text>

<!-- Call Modal promotion completed-->
<app-modal-promotion-completed
  *ngIf="showModalPromotionCompleted"
  [rewardedBonusAmount]="rewardedBonusAmount"
  (isClickCloseModal)="onClickCloseModal($event)"
>
</app-modal-promotion-completed>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-playing mt-0">
    <div
      class="container md:w-xl md:flex md:justify-start md:space-x-4 xl:space-x-4"
    >
      <div class="md:w-sm">
        <app-theme-wallet-group [title]="'wallet'"></app-theme-wallet-group>
        <!-- *ngIf="this.IsActiveBonusWallet" -->
        <div class="mt-4" *ngIf="this.IsActiveBonusWallet">
          <div class="flex justify-start items-center space-x-10 mt-2">
            <div class="flex-none space-y-2">
              <span class="flex justify-start items-center">
                <img
                  appImgLazyLoad
                  src="assets/img/icons/trophy.png"
                  alt=""
                  class="w-[1.5rem] mr-1"
                />
                เทิร์นโอเวอร์คงเหลือ ฿ {{ this.turnoverRemain | number }}
                <a
                  class="cursor-pointer ml-4"
                  (click)="onRefreshDataUserBalance()"
                >
                  <img
                    appImgLazyLoad
                    src="https://fs.cdnxn.com/lsm99king/king/img/btn-refresh.webp"
                    alt=""
                    class="w-[1.5rem] mb-1"
                  />
                </a>
              </span>
              <span class="flex justify-start items-center">
                <img
                  appImgLazyLoad
                  src="assets/img/icons/thunder.png"
                  alt=""
                  class="w-[1.5rem] mr-1" />

                กระเป๋าโบนัส ฿ {{ this.bonusWallet | number }}
                <a
                  class="cursor-pointer ml-4"
                  (click)="toggleModal()"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <img
                    appImgLazyLoad
                    src="https://fs.cdnxn.com/lsm99king/king/img/btn-refresh.webp"
                    alt=""
                    class="w-[1.5rem] mb-1"
                  /> </a
              ></span>
              <span class="flex justify-start items-center">
                <img
                  appImgLazyLoad
                  src="assets/img/icons/clock.png"
                  alt=""
                  class="w-[1.5rem] mr-1"
                />
                เหลือเวลาอีก {{ this.timeDisplay ? this.timeDisplay : 0 }}</span
              >
            </div>
          </div>
        </div>

        <div class="mt-4">
          <app-bank-list></app-bank-list>
        </div>

        <!-- HIDE This Section -->
        <div class="-section-wallet mb-2">
          <div class="-group-btn" *ngIf="this.minigamemenu">
            <ng-container *ngFor="let item of this.minigamemenu">
              <div *ngIf="item.promotionCalValueTypeCode == 'luckybonus-wheel'">
                <a (click)="onClickMiniGameWheel(item)" style="cursor: pointer">
                  <span class="w-[9rem]">
                    <img
                      appImgLazyLoad
                      src="assets/img/theme/game-wheel.png"
                      alt=""
                    />
                  </span>
                  <!-- <span class="-text">{{ item.name }}</span> -->
                </a>
              </div>

              <div *ngIf="item.promotionCalValueTypeCode == 'luckybonus-chest'">
                <a (click)="onClickMiniGameSafe(item)">
                  <span class="w-[9rem]">
                    <img
                      appImgLazyLoad
                      src="assets/img/theme/game-wheel.png"
                      alt=""
                    />
                  </span>
                  <!-- <span class="-text">{{ item.name }}</span> -->
                </a>
              </div>
            </ng-container>
            <a routerLink="/affiliates">
              <span class="w-[9rem]">
                <img
                  appImgLazyLoad
                  src="assets/img/theme/achievement.png"
                  alt=""
                />
              </span>
              <!-- <span class="-text">พันธมิตร</span> -->
            </a>
          </div>
        </div>

        <div class="mt-4 md:hidden">
          <app-game-popular-list
            [games]="gameHitPlayList"
          ></app-game-popular-list>
        </div>

        <div class="mt-4 md:hidden">
          <app-recommend-promotions
            [promotions]="this.allPromotions"
            (handleClickPromotion)="this.onClickPromotion($event)"
          ></app-recommend-promotions>
        </div>

        <div class="mt-4">
          <app-contact-box></app-contact-box>
        </div>
      </div>

      <!-- FOR DESKTOP -->
      <div class="hidden md:block md:w-[45%] xl:w-[55%] 2xl:w-[75%]">
        <div class="">
          <app-game-popular-list
            [games]="gameHitPlayList"
            [size]="'md'"
          ></app-game-popular-list>
        </div>

        <div class="mt-4">
          <app-recommend-promotions
            [promotions]="this.allPromotions"
            (handleClickPromotion)="this.onClickPromotion($event)"
          ></app-recommend-promotions>
        </div>
      </div>

      <!-- <div class="-section-wallet">
        <div class="-profile-wallet">
          <div class="-inner">
            <div class="-profile">
              <a
                routerLink="/profile"
                class="-btn-edit"
                style="margin-top: 42px"
              >
                <img appImgLazyLoad src="assets/img/icons/edit.svg" alt="" />
              </a>

              <div class="-img">
                <img
                  [src]="this.memberGroupImage"
                  alt=""
                  style="min-height: 50px"
                />
              </div>
              <p>ยินดีต้อนรับสมาชิก {{ this.memberRole }}</p>
              <p>
                <strong>{{ this.displayName }}</strong>
              </p>
            </div>

            <div class="-wallet black">
              <img appImgLazyLoad src="assets/img/icons/coin.png" alt="" class="-icon" />
              <p>ยอดเงินในกระเป๋า</p>
              <p>
                <span class="text-credit-balance">{{
                  this.creditBalance | number
                }}</span>
              </p>
              <a
                class="-btn-refresh"
                style="cursor: pointer"
                (click)="onRefreshDataUserBalance()"
              >
                <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt="" />
              </a>
            </div>
            <div class="-wallet bonus-wallet">
              <img appImgLazyLoad src="assets/img/icons/thunder.png" alt="" class="-icon">
              <p>กระเป๋าโบนัส</p>
              <p><span>฿ 1,000</span></p>

              <a class="-btn-refresh"
                style="cursor: pointer; z-index: 9; background-color: firebrick;" 
                (click)="toggleModal()" 
                data-bs-toggle="modal" data-bs-target="#exampleModal">
                <img appImgLazyLoad src="assets/img/icons/close.svg" alt="" style="width: 10px;">
              </a>   
            </div>   
            <div class="-wallet bonus-wallet">
              <img appImgLazyLoad src="assets/img/icons/clock.png" style="left:9px" alt="" class="-icon">
              <p>เหลือเวลาอีก</p>
              <p><span>10 นาที</span></p>  
            </div>        
          </div>
        </div>

        <div
        class="-list-btn"
        *ngIf="
          (!this.activedPromotionId && this.availablePromotions) ||
          (!this.IsActiveBonusWallet && this.availablePromotions)
        "
      >
          <a
            routerLink="/getPromotion"
            class="-active mt-2"
            (click)="onClickSelectPromotion()"
          >
            <span>
              <img appImgLazyLoad src="assets/img/icons/star.png" alt="" class="-icon" />
              <em>โปรโมชั่นที่คุณรับได้</em>
              <strong
                >{{ this.totalAvailablePromotions | number }} โปรโมชั่น</strong
              >
              <img
                src="assets/img/icons/angle-right-white.svg"
                alt=""
                class="-arrow"
              />
            </span>
          </a>
        </div>

        <div
          class="-profile-wallet"
          *ngIf="
            this.isActiveWinAmountRemain ||
            this.isActiveTurnoverRemain ||
            this.IsActiveBonusWallet
          "
        >
          <div>
            <div class="-wallet tern-over" *ngIf="this.isActiveTurnoverRemain">
              <img
                src="assets/img/icons/trophy.png"
                style="left: 12px"
                alt=""
                class="-icon"
              />
              <p>เทิร์นโอเวอร์คงเหลือ</p>
              <p>
                <span>฿ {{ this.turnoverRemain | number }}</span>
              </p>
              <a
                class="-btn-refresh"
                style="cursor: pointer; z-index: 9"
                (click)="onRefreshDataUserBalance()"
              >
                <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt="" />
              </a>
            </div>

            <div class="-wallet tern-over" *ngIf="this.isActiveWinAmountRemain">
              <img
                src="assets/img/icons/trophy.png"
                style="left: 12px"
                alt=""
                class="-icon"
              />
              <p>ยอดได้เป้าหมาย</p>
              <p>
                <span>฿ {{ this.winAmountRemain | number }}</span>
              </p>
              <a
                class="-btn-refresh"
                style="cursor: pointer; z-index: 9"
                (click)="onRefreshDataUserBalance()"
              >
                <img appImgLazyLoad src="assets/img/icons/refresh.svg" alt="" />
              </a>
            </div>

            <div class="-wallet bonus-wallet" *ngIf="this.IsActiveBonusWallet">
              <img appImgLazyLoad src="assets/img/icons/thunder.png" alt="" class="-icon" />
              <p>กระเป๋าโบนัส</p>
              <p>
                <span>฿ {{ this.bonusWallet | number }}</span>
              </p>

              <a
                class="-btn-refresh"
                style="cursor: pointer; z-index: 9; background-color: firebrick"
                (click)="toggleModal()"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <img
                  src="assets/img/icons/close.svg"
                  alt=""
                  style="width: 10px"
                />
              </a>
            </div>
            <div class="-wallet bonus-wallet" *ngIf="this.IsActiveBonusWallet">
              <img
                src="assets/img/icons/clock.png"
                style="left: 9px"
                alt=""
                class="-icon"
              />
              <p>เหลือเวลาอีก</p>
              <p>
                <span>{{ this.timeDisplay }}</span>
              </p>
            </div>
          </div>
        </div>

        <div
          class="-profile-wallet"
          *ngIf="
            this.isActiveWinAmountRemain ||
            this.isActiveTurnoverRemain ||
            this.IsActiveBonusWallet
          "
        >
          <div class="card-rich-text">
            <div
              class="-wallet bonus-wallet black m-0"
              (click)="onToggleRichText()"
            >
              <img appImgLazyLoad src="assets/img/icons/star.png" alt="" class="-icon" />
              <p>อ่านเงื่อนไขการทำโปรโมชั่นที่คุณเลือก</p>
              <p><span></span></p>
              <a
                class="-btn-refresh"
                style="cursor: pointer"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                *ngIf="!this.isActiveRichText"
              >
                <img
                  src="assets/img/icons/angle-down-white.svg"
                  alt=""
                  style="width: 10px"
                />
              </a>
              <a
                class="-btn-refresh"
                style="cursor: pointer; background-color: #fff"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                *ngIf="this.isActiveRichText"
              >
                <img
                  src="assets/img/icons/angle-top-black.svg"
                  alt=""
                  style="width: 10px"
                />
              </a>
            </div>

            <div *ngIf="this.isActiveRichText">
              <div
                [innerHtml]="this.richText | safeHtml"
                class="promotion-detail"
              ></div>
              <br />
            </div>
          </div>
        </div>

        

     <div
        class="-section-lates-play-game"
        *ngIf="this.gameLastPlayList.length > 0"
      >
        <div class="flex items-center justify-between">
          <h2>เกมส์ที่เล่นล่าสุด</h2>
          <a routerLink="/game/gameListing" class="!no-underline">ดูทั้งหมด</a>
        </div>

        <splide
          class="mt-3 -list-game-category"
          [options]="{
            type: 'loop',
            gap: 15,
            autoplay: true,
            perPage: 2,
            keyboard: false
          }"
        >
          <splide-slide *ngFor="let item of gameLastPlayList">
            <div
              id="filter-all"
              style="display: unset"
              class="-list-game -filter-item"
            >
              <a (click)="onClickGameLaunch(item)" class="-new">
                <img
                  class="rounded-lg"
                  style="height: 156px; width: 156px"
                  [src]="item.imageUrl"
                  alt=""
                />
                <span>
                  <em>
                    <img appImgLazyLoad src="assets/img/icons/play.svg" alt="" />
                    {{ item.name }}
                  </em>
                </span>
              </a>
            </div>
          </splide-slide>
        </splide>
      </div> -->

      <!-- <div class="-section-games-popular">
        <div class="flex items-center justify-between">
          <h2>ค่ายเกมส์ยอดนิยม</h2>
          <a routerLink="/game/gameListing" class="!no-underline">ดูทั้งหมด</a>
        </div>

        <splide
          class="mt-3 -list-game-category"
          [options]="{
            type: 'loop',
            gap: 15,
            autoplay: true,
            perPage: 2,
            keyboard: false
          }"
        >
          <splide-slide *ngFor="let item of gameHitPlayList">
            <div class="flex justify-center">
              <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a
                  [routerLink]="item.targetLink"
                  data-mdb-ripple="true"
                  data-mdb-ripple-color="light"
                >
                  <img
                    class="rounded-lg"
                    style="height: 156px; width: 156px"
                    [src]="item.imageUrl"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </splide-slide>
        </splide>
      </div> -->

      <!-- <div class="-section-recommended-promotion">
        <h2>โปรโมชั่นแนะนำ</h2>
        <div *ngFor="let item of promotionDisplayCategory">
          <div *ngIf="item.id === 1 && sportCategoryData.length > 0">
            <div class="flex items-center justify-between">
              <h2>{{ item.name }}</h2>
            </div>
            <div
              class="-list-img"
              *ngFor="let c of sportCategoryData"
              style="margin-bottom: 10px"
            >
              <a>
                <img
                  (click)="onClickPromotion(c)"
                  [src]="
                    c.imgUrlDesktop
                      ? c.imgUrlDesktop
                      : 'assets/img/uploads/promotion.jpg'
                  "
                  alt="{{ c.name }}"
                />
              </a>
            </div>
          </div>

          <div *ngIf="item.id === 2 && casinoCategoryData.length > 0">
            <div class="flex items-center justify-between">
              <h2>{{ item.name }}</h2>
            </div>
            <div
              class="-list-img"
              *ngFor="let c of casinoCategoryData"
              style="margin-bottom: 10px"
            >
              <a>
                <img
                  (click)="onClickPromotion(c)"
                  [src]="
                    c.imgUrlDesktop
                      ? c.imgUrlDesktop
                      : 'assets/img/uploads/promotion.jpg'
                  "
                  alt="{{ c.name }}"
                />
              </a>
            </div>
          </div>

          <div *ngIf="item.id === 3 && slotCategoryData.length > 0">
            <div class="flex items-center justify-between">
              <h2>{{ item.name }}</h2>
            </div>
            <div
              class="-list-img"
              *ngFor="let c of slotCategoryData"
              style="margin-bottom: 10px"
            >
              <a>
                <img
                  (click)="onClickPromotion(c)"
                  [src]="
                    c.imgUrlDesktop
                      ? c.imgUrlDesktop
                      : 'assets/img/uploads/promotion.jpg'
                  "
                  alt="{{ c.name }}"
                />
              </a>
            </div>
          </div>

          <div *ngIf="item.id === 4 && lottoCategoryData.length > 0">
            <div class="flex items-center justify-between">
              <h2>{{ item.name }}</h2>
            </div>
            <div
              class="-list-img"
              *ngFor="let c of lottoCategoryData"
              style="margin-bottom: 10px"
            >
              <a>
                <img
                  (click)="onClickPromotion(c)"
                  [src]="
                    c.imgUrlDesktop
                      ? c.imgUrlDesktop
                      : 'assets/img/uploads/promotion.jpg'
                  "
                  alt="{{ c.name }}"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="-box-btn">
          <a routerLink="/promotionList" class="-btn-02">
            <span>ดูโปรโมชั่นทั้งหมด</span>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</main>
<!-- end content this page -->

<div class="fixed z-20 inset-0 overflow-y-auto" *ngIf="this.showModal">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
  >
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
    </div>
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
    <div
      class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-start">
          <div
            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-red-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              กรุณาอ่านเงื่อนไข
            </h3>
            <div class="mt-2">
              <p
                class="text-sm leading-5 text-gray-500"
                style="font-size: 16px"
              >
                คุณสามารถขอยกเลิกโปรโมชั่นปัจจุบัน
                และขอรับเงินต้นคืนได้ก็ต่อเมื่อ
                คุณยังไม่ได้ใช้เครดิตในกระเป๋าโบนัสหลังจากกดรับโปรโมชั่นเท่านั้น
                <span class="warning"
                  >หากคุณขอยกเลิกโปรโมชั่นปัจจุบันหลังจากได้ใช้เครดิตในกระเป๋าโบนัสไปแล้ว
                  เครดิตที่เหลืออยู่ในกระเป๋าโบนัสทั้งหมดจะถูกริบคืนทันที</span
                >
                คุณยืนยันที่จะขอยกเลิกโปรโมชั่นปัจจุบันหรือไม่?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-4">
          <button
            (click)="onCleanBonusWallet()"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-green-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            ยืนยัน
          </button>
        </span>

        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0">
          <button
            (click)="toggleModal()"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >
            ยกเลิก
          </button>
        </span>
      </div>
    </div>
  </div>
</div>
