<!-- Set Header -->
<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>
<app-snackbar-running-message></app-snackbar-running-message>
<!-- start content this page -->
<div class="box-frame-top">
  <div class="box-frame">
    <div class="tab-bar-ac">
      <a routerLink="/affiliates">
        <span class="act1">ภาพรวม</span>
      </a>
      <a routerLink="/referFriends">
        <span class="act2" style="color: #eeaf4a">วิธีแนะนำเพื่อน</span>
      </a>
    </div>
    <div class="group-refer-fri">
      <div class="group-refer-left">
        <div class="text-head-refer">
          <div class="text-t1">
            <img src="https://fs.cdnxn.com/lsm99king/king/img/dollar.png" />
            <span style="font-size: 18px">แค่แชร์ลิงค์ก็สร้างรายได้</span>
          </div>
          <span style="font-size: 10px"
            >แนะนำเพื่อนสร้างรายได้ 100,000 / เดือน</span
          >
        </div>
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/imaget1.png"
          class="img-t1"
        />
        <div class="title-text-fri">
          <span style="color: #eeaf4a; font-weight: 700"
            >แนะนำเพื่อนสร้างรายได้ 100,000 / เดือน </span
          ><br />
          <span style="color: #eeaf4a; font-weight: 700"
            >(แค่คัดลอกลิงก์ไปแชร์ก็ได้เงินแล้ว) ยิ่งแชร์มากยิ่งได้มาก</span
          >
          <div style="padding: 0.5rem 1rem">
            <span
              >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ท่านสามารถนำลิงก์ไปโปรโมท
              โดยแชร์ให้เพื่อนผ่านช่องทาง Social Media ต่างๆ เช่น
              Facebook,Live,Line Group, Twitter, Instagram ไม่ว่าจะอยู่ที่ไหน
              จะเป็นใคร ก็สามาถทำได้ง่ายๆ ยิ่ง
              ชวนได้มากเท่าไหร่รายได้ของท่านก็จะเพิ่มขึ้นอย่างไร้ขีดจำกัด</span
            >
          </div>
        </div>
      </div>
      <div class="group-refer-right">
        <div class="box-group-step">
          <div class="group-step">
            <div class="step-number">1</div>
            <div>
              <div class="sp00">
                <span class="sp1"
                  >Step 1<span class="sp2"
                    >&nbsp;&nbsp;สมัครเข้าสู่ระบบ</span
                  ></span
                >
              </div>
              <div class="text-line"></div>
              <span class="sp01"
                >เลือกไอคอน “แนะนำเพื่อน” <br />ในหน้ากระเป๋า หรือ
                เมนูเบอร์เกอร์</span
              >
            </div>
          </div>
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/chevron.png"
            class="img-s1"
          />
          <div class="group-step">
            <div class="step-number">2</div>
            <div>
              <div class="sp00">
                <span class="sp1"
                  >Step 2<span class="sp2"
                    >&nbsp;&nbsp;เลือก Tab ภาพรวม</span
                  ></span
                >
              </div>
              <div class="text-line"></div>
              <span class="sp01">คัดลอกลิงค์เพื่อชวนเพื่อนของคุณ</span>
            </div>
          </div>
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/chevron.png"
            class="img-s1"
          />
          <div class="group-step">
            <div class="step-number">3</div>
            <div>
              <div class="sp00">
                <span class="sp1"
                  >Step 3<span class="sp2"
                    >&nbsp;&nbsp;ส่งลิงค์ให้เพื่อนคุณได้เลย</span
                  ></span
                >
              </div>
              <div class="text-line"></div>
              <span class="sp01"
                >เมื่อเพื่อนคุณสมัครสมาชิกและมียอดเล่น
                <br />จะได้รับรายได้ทันที</span
              >
            </div>
          </div>
          <img
            src="https://fs.cdnxn.com/lsm99king/king/img/chevron.png"
            class="img-s1"
          />
        </div>
        <img
          src="https://fs.cdnxn.com/lsm99king/king/img/imaget2.png"
          class="img-t2"
        />
      </div>
    </div>
    <div class="gruop-questions">
      <div style="display: flex; justify-content: center">
        <span style="font-size: 18px; font-weight: 700"
          >Q&A คำถามที่พบบ่อย</span
        >
      </div>
      <div class="group-questions-box" (click)="onSelectQuestiona()">
        <div class="questions-box">
          <div style="display: flex; align-items: center; column-gap: 0.5rem">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/mark.png"
              class="questions-t1"
            />
            <span class="qus-t1">ระบบแนะนำเพื่อนคืออะไร ?</span>
          </div>
          <div class="img-drow-up" [class.activeQuestionTabup]="tab1"></div>
        </div>
        <div [class.activeQuestionTab]="tab1" style="display: none">
          <div class="questions-box-answer">
            <span
              >ระบบแนะนำเพื่อน
              เป็นการส่งลิงก์แนะนำเพื่อนให้กับเพื่อนของท่านเพื่อรับค่าคอมมิชชั่น
              โดยท่านจะได้รับค่าคอมมิชชั่นจากยอดเล่นของเพื่อนที่แนะนำสูงสุดถึง
              0.5%</span
            >
            <span style="font-weight: 700; margin-top: 0.5rem"
              >โดยค่าคอมมิชชั่นแนะนำเพื่อนมีดังนี้</span
            >
            <span>• สมาชิกขั้นที่ 1 รับ 0.07%</span>
            <span>• สมาชิกขั้นที่ 2 รับ 0.05%</span>
            <span>• สมาชิกขั้นที่ 3 รับ 0.04%</span>
            <span>• สมาชิกขั้นที่ 4 รับ 0.03%</span>
            <span>• สมาชิกขั้นที่ 5 รับ 0.01%</span>
          </div>
        </div>
      </div>
      <div class="group-questions-box" (click)="onSelectQuestionb()">
        <div class="questions-box">
          <div style="display: flex; align-items: center; column-gap: 0.5rem">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/mark.png"
              class="questions-t1"
            />
            <span class="qus-t1"
              >ค่าคอมมิชชั่นจากยอดเล่นของเพื่อนนับอย่างไร ?</span
            >
          </div>
          <div class="img-drow-up" [class.activeQuestionTabup]="tab2"></div>
        </div>
        <div [class.activeQuestionTab]="tab2" style="display: none">
          <div class="questions-box-answer">
            <span
              >ค่าคอมมิชชั่นจากยอดเล่นของเพื่อนนับตามเกมต่างๆ
              โดยมีวิธีการนับดังนี้</span
            >
            <span style="font-weight: 700; margin-top: 0.5rem">ตัวอย่าง</span>
            <span
              >• สมาชิกขั้นที่ 1 : หากมีสมาชิก 1 ท่านลงเดิมพัน 100,000 บาท
              ท่านจะได้รับค่าคอมมิชชั่น 70 บาท</span
            >
            <span
              >• สมาชิกขั้นที่ 2 : หากมีสมาชิก 1 ท่านลงเดิมพัน 100,000 บาท
              ท่านจะได้รับค่าคอมมิทชั่น 50 บาท</span
            >
          </div>
        </div>
      </div>
      <div class="group-questions-box" (click)="onSelectQuestionc()">
        <div class="questions-box">
          <div style="display: flex; align-items: center; column-gap: 0.5rem">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/mark.png"
              class="questions-t1"
            />
            <span class="qus-t1">มีวิธีการจ่ายค่าคอมมิชชั่นอย่างไร ?</span>
          </div>
          <div class="img-drow-up" [class.activeQuestionTabup]="tab3"></div>
        </div>
        <div [class.activeQuestionTab]="tab3" style="display: none">
          <div class="questions-box-answer">
            <span style="font-weight: 700"
              >ค่าคอมมิชชั่นจะจ่ายไปยังบัญชีของท่านโดยอัตโนมัติในทุกๆวัน</span
            >
            <span
              >• เมื่อลูกข่ายของท่านมียอดเล่นเสีย
              ท่านจะได้รับโบนัสจากยอดเล่นเสียนั้นโดยอัตโนมัติทันที
              ซึ่งหากท่านไม่กดรับ เงินโบนัสที่ได้นั้นก็จะยังคงค้างอยู่
              โดยไม่หายไปแน่นอน (แต่หากมีการกดรับโปรโมชั่น
              ท่านจะไม่สามารถรับโบนัสได้เพื่อป้องกันการโกง)</span
            >
            <span
              >• หากลูกข่ายของท่านมีการเดิมพัน บาคาร่า/สล็อต/กีฬา
              เมื่อมียอดเดิมพันไม่ว่าจะได้หรือเสีย ระบบจะคิดค่าคอมมิชชั่นให้ท่าน
              0.5% โดยอัตโนมัติทันที แต่ท่านต้องกดรับค่าคอมมิชชั่นก่อน
              จึงจะสามารถรับโบนัสได้</span
            >
          </div>
        </div>
      </div>
      <div class="group-questions-box" (click)="onSelectQuestiond()">
        <div class="questions-box">
          <div style="display: flex; align-items: center; column-gap: 0.5rem">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/mark.png"
              class="questions-t1"
            />
            <span class="qus-t1">มีวิธีการจ่ายค่าคอมมิชชั่นอย่างไร ?</span>
          </div>
          <div class="img-drow-up" [class.activeQuestionTabup]="tab4"></div>
        </div>
        <div [class.activeQuestionTab]="tab4" style="display: none">
          <div class="questions-box-answer">
            <span style="font-weight: 700"
              >การแนะนำเพื่อนมีความเสี่ยงหรือไม่ ?</span
            >
            <span
              >การแนะนำเพื่อนนั้นไม่มีความเสี่ยงใดๆ
              โดยยอดได้หรือเสียทั้งหมดของเพื่อนที่ท่านแนะนำ
              ทางบริษัทเป็นผู้รับผิดชอบ ไม่ว่าสมาชิกที่ท่านแนะนำจะชนะหรือแพ้
              ท่านจะได้รับค่าคอมมิชชั่นแนะนำเพื่อนโดยอัตโนมัติทันที</span
            >
          </div>
        </div>
      </div>
      <div class="group-questions-box" (click)="onSelectQuestione()">
        <div class="questions-box">
          <div style="display: flex; align-items: center; column-gap: 0.5rem">
            <img
              src="https://fs.cdnxn.com/lsm99king/king/img/mark.png"
              class="questions-t1"
            />
            <span class="qus-t1">มีวิธีการจ่ายค่าคอมมิชชั่นอย่างไร ?</span>
          </div>
          <div class="img-drow-up" [class.activeQuestionTabup]="tab5"></div>
        </div>
        <div [class.activeQuestionTab]="tab5" style="display: none">
          <div class="questions-box-answer">
            <span style="font-weight: 700"
              >การแนะนำเพื่อนมีความเสี่ยงหรือไม่ ?</span
            >
            <span
              >• ใช้ Social Media ออนไลน์ของท่าน เช่น LINE, Facebook, Instagram,
              Twitter เป็นต้น เพื่อใช้ในการโปรโมท GSM99
              โดยการนำลิงก์แนะนำเพื่อนไปแชร์หรือส่งต่อให้เพื่อนของท่านได้ไม่จำกัด</span
            >
            <span
              >• แนะนำเว็บไซต์ ชื่อเว็บ ให้กับเพื่อน ๆ ของท่านที่ชอบเล่นเกม /
              กีฬาต่างๆ
              จากนั้นส่งต่อให้พวกเขาทราบเกี่ยวกับเกม/โปรโมชั่น/กิจกรรมสนุกๆที่สามารถสร้างรายได้
              เพื่อให้เพื่อนของท่านมาสมัครเป็นสมาชิกกับทาง ชื่อเว็บ</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end content this page -->
