<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- start content this page -->
<main id="main-content" class="bg-black min-h-screen">
  <div class="-content-playing -game-play">
    <div class="container">
      <div class="-box-title">
        <h1 class="text-white">คุณเลือกไพ่ลุ้นรางวัล</h1>
        <p class="bg-[#AF010B] inline-block text-[22px] px-3 py-2 uppercase mt-3">BERLIN777</p>
      </div>

      <div class="-box-card">
        <div class="-card">
          <div class="-back">
            <img appImgLazyLoad src="assets/img/game-card/card-back.png" alt="card">
          </div>

          <div class="-front">
            <img appImgLazyLoad src="assets/img/game-card/card-front.png" alt="card">

            <div class="-detail">
              <img appImgLazyLoad src="assets/img/game-card/icon-{{cardType}}.svg" alt="icon">
              <p class="text-[17px] mt-5">ยินดีด้วยคุณได้รับ</p>
              <p class="text-[25px] font-medium">เทิร์นโอเวอร์</p>
              <p class="text-reward text-[30px] font-medium text-[#AF010B] mt-3.5">฿30,000</p>
            </div>

            <div class="-light">
              <div class="-inner"><img appImgLazyLoad src="assets/img/game-card/light.png" alt="light"></div>
            </div>
            <div class="-ribbon">
              <div class="-inner"><img appImgLazyLoad src="assets/img/game-card/ribbon.png" alt="ribbon"></div>
            </div>
          </div>
        </div>
      </div>

      <p class="-remark mt-4 text-white text-center"><strong>Remark</strong> (สำหรับของรางวัลเพื่ออธิบายรายละเอียด<br>ข้อจำกัด วิธีการใช้รางวัล หรือเงื่อนไขเพิ่มเติมต่างๆ)</p>

      <a href="javascript:void(0);" class="-btn-01" onclick="openPopupReward();">รับรางวัล</a>
    </div>
  </div>
</main> <!-- end content this page -->

<div class="-content-popup-reward">
  <div class="-header">
    <h2 class="-text-header text-[#AF010B]">รับรางวัลจากไพ่</h2>
    
    <a href="javascript:void(0);" class="-btn-close" onclick="closePopupReward();">
      <img appImgLazyLoad src="assets/img/icons/close.svg" alt="">
    </a>
  </div>

  <div class="container">
    <div class="-box-img">
      <img appImgLazyLoad src="assets/img/game-card/light-red.png" alt="light" class="-light">
      <img appImgLazyLoad src="assets/img/game-card/card-{{cardType}}.png" alt="card">
    </div>

    <div class="-detail">
      <p class="text-2xl font-medium">คุณได้รับรางวัล</p>
      <p class="font-medium text-[32px] text-[#E50211] mt-5">เทิร์นโอเวอร์</p>
      <p class="text-reward font-medium text-[54px] text-[#E50211] mt-5">฿30,000</p>
      <p class="mt-10 text-lg"><strong>จากการเปิดไพ่ กับ BERLIN777 เรียบร้อยแล้ว</strong></p>
      <p class="font-light">ระบบจะโอนรางวัลเข้าสู่ยอดในบัญชีของคุณภาย<br>ใน 30 นาที</p>
      <a href="/" class="-btn-01">ตกลง</a>
      <a href="game-card-play-again.php" class="block text-center mt-5 font-medium text-[#E50211]">เปิดไพ่ลุ้นรางวัลอีกครั้ง</a>
    </div>
  </div>
</div>


