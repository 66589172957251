<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"></div>
  </div>
</ngx-spinner>

<app-notification-bar></app-notification-bar>
<app-main-menu></app-main-menu>
<app-menu-bottom></app-menu-bottom>

<!-- Call Modal-->
<app-modal-with-rich-text *ngIf="showModalPromotion" (isClickCloseModal)="onClickCloseModal($event)"
  (isClickSubmitPromotion)="onClickSubmitPromotion($event)" [promotionContents]="promotionContents"
  [isReadOnly]="false">
</app-modal-with-rich-text>

<!-- Call Modal promotion completed-->
<app-modal-promotion-completed *ngIf="showModalPromotionCompleted" [rewardedBonusAmount]="rewardedBonusAmount"
  (isClickCloseModal)="onClickCloseModal($event)">
</app-modal-promotion-completed>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-register">
    <div class="">
      <div class="-section-top">
        <h2 class="-title mt-10">
          คุณมี {{ this.totalUserPromotion | number }} โปรโมชั่นที่สามารถรับได้
        </h2>
        <p>
          กรุณากดเลือกโปรโมชั่นที่ต้องการรับ พร้อมระบุจำนวนเงิน<br />ที่ต้องการใช้กับโปรโมชั่นได้
        </p>
        <br />

        <splide [options]="{ type: 'loop', perPage: 1, keyboard: false }" (onActive)="onSplideActive($event)">
          <splide-slide *ngFor="let item of promotionList">
            <div class="flex justify-center">
              <div class="rounded-lg shadow-lg bg-white max-w-sm">
                <a data-mdb-ripple="true" data-mdb-ripple-color="light" class="pointer">
                  <img appImgLazyLoad class="rounded-t-lg" (click)="onClickPromotion(item)" [src]="
                      item.imgUrlDesktop
                        ? item.imgUrlDesktop
                        : 'assets/img/uploads/promotion.jpg'
                    " alt="{{ item.name }}" />
                </a>

                <div class="p-4 card-game">
                  <h5 class="text-gray-900 text-xl font-medium text-card-game">
                    {{ item.name }}
                  </h5>
                </div>
              </div>
            </div>
          </splide-slide>
        </splide>

        <div class="-form-style mt-4">
          <form>
            <div class="-row -required">
              <label>{{
                this.isDisabled
                ? 'โปรโมชั่นนี้อนุญาตให้รับด้วยเงินทั้งหมดเท่านั้น'
                : 'ระบุจำนวนเงินที่ต้องการใช้กับโปรโมชั่น'
                }}</label>
              <input type="number" class="-form-input" [(ngModel)]="this.creditAmount"
                [formControl]="creditAmountControl" />
            </div>

            <div class="-row">
              <button type="submit" class="-btn-01" (click)="onCheckPromotion()">
                <span>ตรวจสอบโปรโมชั่น</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- end content this page -->