import { Component, Input, EventEmitter, OnInit, Output } from "@angular/core";
import { DepositService } from "./services/deposit.service";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { timer, Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Html5Qrcode } from "html5-qrcode";
import { SystemService } from "../../shared/services/system.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-deposit",
  templateUrl: "./deposit.component.html",
  styleUrls: ["./deposit.component.scss"],
})
export class DepositComponent implements OnInit {
  bankList: any = [];
  withdrawBankList: any = [];
  systemBankAccountTypeDepositList: any = [];
  systemBankAccountTypeDepositExpressList: any = [];
  depositAmountList: any = [10, 50, 100, 300, 500, 1000, 3000, 5000, 10000];
  depositAmount: any;
  isActiveBtnCalculateDepositAmount: boolean = false;
  multiSystemBankAccountTypeDeposit: boolean | undefined;
  multiSystemBankAccountTypeDepositExpress: boolean | undefined;
  isActiveTimer: boolean = false;

  countDown: Subscription = new Subscription();
  counter: number = 0;
  tick = 1000;

  systemBankId: number | undefined;
  depositAmountRequired: number = 0;
  isActiveSelectDepositAmount: boolean = true;
  iSActiveUploadSlip: boolean = false;
  isDepositTimeOut: boolean = false;
  isActiveRenewDeposit: boolean = false;
  requestDecimalDepositId: number = 0;
  selectSystemBankId: number = 0;
  selectSystemBankInfo!: any;
  iSActiveTabDepositExpress: boolean = true;
  iSActiveTabDepositDecimal: boolean = true;
  isActiveSelectAmount: boolean = true;
  isToggleById: number = 0;
  decimalDepositUserId!: number;
  systemBankIdIsNotExpire: number = 0;
  isFirstTimeDeposit!: boolean;
  isMemberBankAccountVerified: boolean = false;
  minDepositAmount!: number;
  minDepositFirstTimeAmount!: number;
  maxDepositFirstTimeAmount!: number;
  maxDepositNextTimeAmount!: number;
  isSystembankRunTimeId: number = 0;
  isActivePromotion: boolean = false;
  showModal: boolean = false;
  isDuringPromotionCanDeposit!: boolean;
  isDepositLimit!: boolean;
  websiteName: string = "";
  isDisabled: boolean = false;
  isUpload: boolean = false;
  fileImageName: string = "อัพโหลดสลิป";
  fileImageType: string = "";
  imgBase64: string = "";
  preview: string = "";
  qrCode: string = "";
  errorMessage: string = "";

  pgwDepositAmount: number = 0;
  pgwDepositForm!: FormGroup;
  pgwdepositAmountList: number[] = [300, 500, 1000, 2000, 3000, 5000, 10000];
  minPgwQrPayDepositAmount: number = 300;
  maxPgwQrPayDepositAmount: number = 100000;
  path: string = "";
  isActiveQrPgw!: boolean;

  constructor(
    private _depositService: DepositService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private readonly _router: Router,
    private _systemService: SystemService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.bankList = JSON.parse(localStorage.getItem("bankList") || "{}");

    this.isSystembankRunTimeId =
      this.selectSystemBankId =
      this.isToggleById =
        Number(localStorage.getItem("decimalBank"));
    if (this.selectSystemBankId != 0) {
      this.isActiveSelectDepositAmount = false;
      this.onGetDataLastestUserRequestDecimalDeposit(this.selectSystemBankId);
    }

    this.setName();
    this.onGetUserInfo();
    this.onGetBankForDeposit();
    this.getMemberWithdrawBankList();
    this._systemService.updateGetWebInfo();

    this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter != 0) {
        --this.counter;
      } else {
        if (this.counter < 0) {
          this.isSystembankRunTimeId = 0;
          this.countDown.unsubscribe();
        }

        if (this.isActiveTimer == true && this.iSActiveUploadSlip == false) {
          this.iSActiveUploadSlip = true;
          this.isDepositTimeOut = true;
        }
      }
    });

    this.pgwDepositForm = this.formBuilder.group({
      pgwDepositAmountForm: [
        "",
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(15),
          Validators.pattern(/^\d+(\.\d{0,2})?$/),
          Validators.min(this.minPgwQrPayDepositAmount),
        ],
      ],
    });
    this.pgwDepositForm
      .get("pgwDepositAmountForm")
      ?.valueChanges.subscribe((value) => {
        if (value === "0" || value === 0) {
          this.pgwDepositForm
            .get("pgwDepositAmountForm")
            ?.setValue("", { emitEvent: false });
        }
      });
    this.path = this._router.url.substring(1);
  }

  ngOnDestroy() {
    this.countDown.unsubscribe();
  }

  onGetBankForDeposit() {
    this._depositService.getDataUserDepositInfo().subscribe({
      next: (data) => {
        if (data.systemsBankAccounts) {
          this.mapGroupSystemsBankAccountType(data.systemsBankAccounts);
        }

        this.isDepositLimit = data.isDepositLimit;
        this.isFirstTimeDeposit = data.isFirstTimeDeposit;
        this.isUpload = data.isUpload && this._systemService.isUpload;
        this.isMemberBankAccountVerified = data.isMemberBankAccountVerified;
        this.maxDepositFirstTimeAmount = data.maxDepositFirstTimeAmount;
        this.minDepositFirstTimeAmount = data.minDepositFirstTimeAmount;
        this.maxDepositNextTimeAmount = data.maxDepositNextTimeAmount;
        this.minDepositAmount = data.minDepositAmount;

        this.isActiveQrPgw = data.isActivePgwQrPay;
        this.pgwDepositAmount = this.minPgwQrPayDepositAmount =
          data.minPgwQrPayDepositAmount;
        this.maxPgwQrPayDepositAmount = data.maxPgwQrPayDepositAmount;
        this.filterDeposit();
        console.log("this.isUpload", this.isUpload);
        console.log("data.isUpload", data.isUpload);
        console.log(
          "this._systemService.isUpload",
          this._systemService.isUpload
        );
        console.log(
          "this.isMemberBankAccountVerified",
          this.isMemberBankAccountVerified
        );
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  filterDeposit() {
    this.pgwdepositAmountList = this.pgwdepositAmountList.filter(
      (x) =>
        x >= this.minPgwQrPayDepositAmount && x <= this.maxPgwQrPayDepositAmount
    );
  }

  getMemberWithdrawBankList() {
    this._depositService.getDataWithdrawBankList().subscribe({
      next: (data) => {
        this.setDataMemberWithdrawBankList(data.collection);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setDataMemberWithdrawBankList(sourceData: any) {
    if (sourceData) {
      let packData: any[] = [];
      sourceData.forEach((e: any) => {
        let bankId = Number(e.registBankId) - 1;
        e.bankAccountName = this.bankList[bankId].name;
        let bCode = this.bankList[bankId].code;
        e.bankAccountCode = bCode.toUpperCase();
        e.registBankLogoUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${e.bankAccountCode}.png`;

        // bankAccountStatusClass
        if (e.isApproved == true) {
          e.bankAccountStatusClass = "use";
          e.bankAccountStatusName = "ตรวจสอบบัญชีสำเร็จแล้ว";
        } else if (e.isApproved == false && e.isRejected == false) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "กรุณารอตรวจสอบบัญชีสักครู่ค่ะ";
        } else if (e.isRejected == true) {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "บัญชีที่ไม่ได้ใช้งาน";
        } else {
          e.bankAccountStatusClass = "wait";
          e.bankAccountStatusName = "ไม่ทราบ ติดต่อผู้ดูแลระบบ";
        }

        packData.push(e);
      });
      this.withdrawBankList = packData;
    }
  }

  mapGroupSystemsBankAccountType(sourceData: any) {
    if (sourceData) {
      let typeDeposit: any = [];
      let typeDepositExpress: any = [];
      sourceData.forEach((e: any) => {
        let bankId = Number(e.bankId) - 1;
        let bankCode = this.bankList[bankId].code;
        let bCodeUpper = bankCode.toUpperCase();
        e.bankImageUrl = `https://fs.cdnxn.com/lsm99king/img/logo/${bCodeUpper}.png`;
        e.bankAccountQrCodeImageUrl = `assets/img/qrcode/${bCodeUpper}.png`;

        switch (e.systemBankAccountTypeId) {
          case 1:
            typeDeposit.push(e);
            break;
          case 2:
          case 5:
          case 6:
            typeDepositExpress.push(e);
            break;
          default:
            break;
        }
      });
      this.systemBankAccountTypeDepositList = typeDeposit;
      this.systemBankAccountTypeDepositExpressList = typeDepositExpress;

      this.multiSystemBankAccountTypeDeposit =
        typeDeposit.length > 1 ? true : false;
      this.multiSystemBankAccountTypeDepositExpress =
        typeDepositExpress.length > 1 ? true : false;

      if (typeDepositExpress.length == 0) {
        this.iSActiveTabDepositExpress = false;
      }

      if (typeDeposit.length == 0) {
        this.iSActiveTabDepositDecimal = false;
      } else {
        if (this.multiSystemBankAccountTypeDeposit == false) {
          this.selectSystemBankId = this.systemBankAccountTypeDepositList[0].id;
        }
      }
    }
  }

  copyBankAccountNo(bankAccountNo: any) {
    navigator.clipboard
      .writeText(bankAccountNo)
      .then(() => {
        this.toastr.success(
          "คัดลอกเลขที่บัญชีธนาคาร " + bankAccountNo,
          "สำเร็จ"
        );
      })
      .catch(() => {
        try {
          var input = document.createElement("input");
          input.className = "bankNo";
          input.value = bankAccountNo;
          document.body.appendChild(input);
          input.focus();
          input.select();
          document.execCommand("copy");
          document.body.removeChild(input);
          this.toastr.success(
            "คัดลอกเลขที่บัญชี " + bankAccountNo + " แล้วค่ะ",
            "สำเร็จ"
          );
        } catch (err) {
          this.toastr.error(
            "ไม่สามารถคัดลอกได้ โปรดกดคัดลอกเลขที่บัญชีด้วยตัวเองค่ะ",
            "ไม่สำเร็จ"
          );
        }
      });
  }

  selectDepositAmount(amount: number) {
    this.spinner.show();
    this.depositAmount = amount;

    if (this.isDepositLimit == true) {
      this.isActiveBtnCalculateDepositAmount = false;
      if (this.isFirstTimeDeposit == true) {
        this.isActiveBtnCalculateDepositAmount = false;
        // if (amount > this.maxDepositFirstTimeAmount) {
        //   this.toastr.clear();
        //   this.toastr.error('ยอดเงินมากกว่าเงื่อนไขการฝากครั้งแรก', 'ฝากไม่สำเร็จ');
        // } else if(amount < this.minDepositFirstTimeAmount){
        //   this.toastr.clear();
        //   this.toastr.error('ยอดเงินน้อยกว่าเงื่อนไขการฝากครั้งแรก', 'ฝากไม่สำเร็จ');
        // } else {
        //   this.isActiveBtnCalculateDepositAmount = true;
        // }
        this.isActiveBtnCalculateDepositAmount = true;
      } else {
        this.isActiveBtnCalculateDepositAmount = false;
        // if(amount < this.minDepositAmount){
        //   this.toastr.clear();
        //   this.toastr.error('ยอดเงินน้อยกว่าเงื่อนไขการฝากขั้นต่ำ', 'ฝากไม่สำเร็จ');
        // } else if(amount > this.maxDepositNextTimeAmount){
        //   this.toastr.clear();
        //   this.toastr.error('ยอดเงินมากกว่าเงื่อนไขการฝาก', 'ฝากไม่สำเร็จ');
        // } else {
        //   this.isActiveBtnCalculateDepositAmount = true;
        // }
        this.isActiveBtnCalculateDepositAmount = true;
      }
      setTimeout(() => {
        this.spinner.hide();
      }, 300);
    } else {
      this.isActiveBtnCalculateDepositAmount = true;
      setTimeout(() => {
        this.spinner.hide();
      }, 300);
    }
  }

  calculateDepositAmount() {
    this.isDisabled = true;
    this.isDepositTimeOut = false;

    let approveData = false;

    approveData = true;
    this.isActiveSelectAmount = false;

    if (approveData == true) {
      let payload = {
        systemBankId: this.selectSystemBankId,
        amount: this.depositAmount,
      };

      this.isActiveBtnCalculateDepositAmount = false;
      this.isActiveSelectDepositAmount = false;
      this.isActiveTimer = true;
      this.isSystembankRunTimeId = this.selectSystemBankId;

      this._depositService.getDataDecimalDepositRequest(payload).subscribe({
        next: (data) => {
          this.isActiveBtnCalculateDepositAmount = false;
          this.isActiveSelectDepositAmount = false;
          this.isActiveTimer = true;

          this.depositAmountRequired = data.depositAmountRequired;
          this.counter = data.timeRemainSecond;
          this.decimalDepositUserId = data.decimalDepositUserId;

          this.onGetDataLastestUserRequestDecimalDeposit(
            this.selectSystemBankId
          );
          this.spinner.hide();
        },
        error: (e) => {
          console.error(e);
          if (e.status == 400) {
            this.toastr.error(
              "ฝากไม่สำเร็จเนื่องจากไม่ตรงเงื่อนไข",
              "ฝากไม่สำเร็จ"
            );

            this.iSActiveUploadSlip = false;
            this.isActiveBtnCalculateDepositAmount = true;
            this.isActiveSelectDepositAmount = true;
            this.isActiveTimer = false;
            this.isActiveSelectAmount = true;
            this.isSystembankRunTimeId = 0;
            this.isDisabled = false;
          } else {
            this.isDisabled = false;
            this.toastr.error(
              "ทำรายการฝากไม่สำเร็จ กรุณาติดต่อแอดมิน",
              "ฝากไม่สำเร็จ"
            );
          }
        },
      });
    }
  }

  selectSystemBank(sBankInfo: any) {
    this.spinner.show();

    this.depositAmount = 0;
    this.isActiveBtnCalculateDepositAmount = false;

    this.selectSystemBankId = sBankInfo.id;
    this.selectSystemBankInfo = sBankInfo;

    this.onGetDataLastestUserRequestDecimalDeposit(this.selectSystemBankId);

    if (this.isSystembankRunTimeId != sBankInfo.id) {
      this.isActiveSelectAmount = true;
    } else {
      this.isActiveSelectAmount = false;
    }

    this.spinner.hide();
  }

  createNewDepositAmount() {
    this.isActiveSelectDepositAmount = true;
    this.isActiveTimer = false;
    this.iSActiveUploadSlip = false;
    this.isActiveRenewDeposit = false;
    this.isActiveSelectAmount = true;
    this.isSystembankRunTimeId = 0;
  }

  onManageStateDepositQueue(dataInfo: any) {
    this.depositAmountRequired = dataInfo.depositAmountRequired;
    this.requestDecimalDepositId = dataInfo.requestDecimalDepositId;
    this.systemBankIdIsNotExpire = dataInfo.systemBankId;

    if (this.systemBankIdIsNotExpire == this.selectSystemBankId) {
      this.isActiveRenewDeposit = true;
      this.isActiveBtnCalculateDepositAmount = false;
      this.isActiveTimer = false;
      this.isActiveSelectAmount = false;
    } else {
      this.isActiveRenewDeposit = false;
      this.isActiveBtnCalculateDepositAmount = false;
      this.isActiveTimer = false;
      this.isActiveSelectAmount = true;
    }
  }

  onFilechange(event: Event) {
    let evt: any = event.target;
    if (evt.files.length > 0) {
      this.errorMessage = "";
      if (evt.files[0].size > 3072000) {
        this.toastr.error("ขนาดไฟล์ภาพใหญ่เกินไป", "ข้อมูลไม่ถูกต้อง");
        this.fileImageName = "ขนาดไฟล์ภาพใหญ่เกินไป โปรดลองใหม่อีกครั้ง";
        this.qrCode = "";
        this.preview = "";
      } else {
        new Html5Qrcode("reader")
          .scanFile(evt.files[0], false)
          .then((qrCode: any) => {
            this.qrCode = qrCode;
            this.onEndcodeBase64File(evt.files[0]);
          })
          .catch((e: any) => {
            this.toastr.error(
              "รูปภาพสลิปโอนเงินไม่ถูกต้อง โปรดติดต่อแอดมิน",
              "ข้อมูลไม่ถูกต้อง"
            );
            this.fileImageName = "รูปภาพไม่ถูกต้อง โปรดลองใหม่อีกครั้ง";
            this.qrCode = "";
            this.preview = "";
          });
      }
    }
  }

  confirmDepositAndUploadSlip() {
    if (this.qrCode) {
      this.spinner.show();
      this.isDisabled = true;

      let payload = {
        qrCode: this.qrCode,
        fileImage: {
          imageBase64: this.imgBase64,
          imageType: this.fileImageType,
          fileName: this.fileImageName,
        },
      };

      this._depositService.postDataUploadQrDeposit(payload).subscribe({
        next: (data) => {
          this.spinner.hide();
          this.toastr.success(
            "ยอดเงินจะเข้าสู่ระบบภายในเวลา 1 นาที",
            "ทำรายการสำเร็จ"
          );
          this._router.navigate(["/"]);
        },
        error: (e) => {
          this.spinner.hide();
          if (e.error.code == "3001")
            this.errorMessage = "รูปภาพสลิปนี้ได้ถูกเติมเงินไปเรียบร้อยแล้ว";
          else if (e.error.code == "3002")
            this.errorMessage = "รูปภาพสลิปโอนเงินไม่ถูกต้อง กรุณาติดต่อแอดมิน";
          else if (e.error.code == "3003")
            this.errorMessage =
              "รูปภาพสลิปมีอายุ 1 วันหลังจากโอน กรุณาติดต่อแอดมิน";
          else
            this.errorMessage = "สลิปนี้มีข้อมูลไม่ถูกต้อง กรุณาติดต่อแอดมิน";
          this.isDisabled = false;
          this.toastr.error(
            "ข้อมูลสลิปโอนเงินไม่ถูกต้อง กรุณาติดต่อแอดมิน",
            "ทำรายการไม่สำเร็จ"
          );
        },
      });
    } else {
      this.toastr.error("กรุณาเลือกไฟล์สลิปเพื่ออัพโหลด", "ข้อมูลไม่ถูกต้อง");
    }
  }

  onEndcodeBase64File(file: File) {
    this.spinner.show();
    const reader = new FileReader();

    this.fileImageName = file.name;
    this.fileImageType = file.type.split("/")[1];

    reader.readAsDataURL(file);
    reader.onload = (_event: any) => {
      this.preview = _event.target.result;
      this.imgBase64 = this.preview.split(",")[1];
    };
    this.spinner.hide();
  }

  confirmDepositDecimalAndUploadSlip() {
    let approveData = false;

    let systemBankId = 0;
    let systemBankInfo: any;
    if (this.systemBankAccountTypeDepositList.length == 1) {
      systemBankId = this.systemBankAccountTypeDepositList[0].id;
      systemBankInfo = this.systemBankAccountTypeDepositList[0];
      approveData = true;
    } else {
      systemBankId = Number(this.selectSystemBankId);

      if (systemBankId == 0) {
        approveData = false;
        this.toastr.error("กรุณาเลือกธนาคาร", "ข้อมูลไม่ครบ");
      } else {
        systemBankInfo = this.selectSystemBankInfo;
        approveData = true;
      }
    }

    if (approveData == true) {
      let requestDecimalDepositId =
        this.requestDecimalDepositId > 0
          ? this.requestDecimalDepositId
          : this.decimalDepositUserId;

      if (requestDecimalDepositId == 0) {
        this.toastr.error("กรุณาติดต่อแอดมิน", "เกิดข้อผิดพลาด");
      } else {
        this._router.navigate(["/depositUploadSlip"], {
          queryParams: { requestDecimalDepositId: requestDecimalDepositId },
        });
      }
    }
  }

  onToggleTabAmountDecimal(id: number) {
    return;
    if (this.isSystembankRunTimeId > 0) {
    } else {
      this.isToggleById = id;
    }
  }

  onGetDataLastestUserRequestDecimalDeposit(systemBankId: number) {
    this._depositService.getDataLastestUserRequestDecimalDeposit().subscribe({
      next: (data) => {
        if (data) {
          if (data.timeRemainSecond > 0 && data.systemBankId == systemBankId) {
            localStorage.setItem("decimalBank", data.systemBankId);
            this.isSystembankRunTimeId = data.systemBankId;
            this.isDepositTimeOut = false;
            this.isActiveSelectAmount = false;

            this.isActiveBtnCalculateDepositAmount = false;
            this.isActiveSelectDepositAmount = false;
            this.isActiveTimer = true;
            this.iSActiveUploadSlip = false;

            this.depositAmountRequired = data.depositAmountRequired;
            this.counter = data.timeRemainSecond;
            this.requestDecimalDepositId = data.requestDecimalDepositId;
          } else if (
            data.timeRemainSecond < 0 &&
            data.systemBankId == systemBankId
          ) {
            this.onManageStateDepositQueue(data);
          } else {
            if (this.isActiveTimer == true) {
              this.isActiveSelectAmount = true;
            } else {
              this.isActiveSelectAmount = true;
              this.isActiveSelectDepositAmount = true;
              this.isActiveTimer = false;
              this.isActiveBtnCalculateDepositAmount = false;
            }
          }
        } else if (Number(localStorage.getItem("decimalBank"))) {
          localStorage.setItem("decimalBank", "0");
          this.isActiveSelectDepositAmount = true;
          this.isSystembankRunTimeId =
            this.isToggleById =
            this.selectSystemBankId =
              0;
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  onGetUserInfo() {
    this._depositService.getDataUserInfomation().subscribe({
      next: (data) => {
        if (data.activedPromotionId) {
          this.isActivePromotion = true;
          this.isDuringPromotionCanDeposit =
            data.activedPromotion.isDuringPromotionCanDeposit;
          this.showModal =
            this.isDuringPromotionCanDeposit == true ? false : true;
        } else {
          this.isActivePromotion = false;
        }
        this.spinner.hide();
      },
      error: (e) => {
        console.error(e);
        this.spinner.hide();
      },
    });
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }

  setName() {
    let websiteName = localStorage.getItem("websiteName");
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName || '""');
    }
  }
  selectPgwDepositAmount(amount: number) {
    this.pgwDepositAmount = amount;
  }

  confirmPgwDeposit() {
    if (
      this.pgwDepositAmount < this.minPgwQrPayDepositAmount ||
      this.pgwDepositAmount > this.maxPgwQrPayDepositAmount
    ) {
      console.log("ยอดฝากเงิน:", this.pgwDepositAmount);
      console.log("ยอดฝากเงินขั้นต่ำ:", this.minPgwQrPayDepositAmount);
      console.log("ยอดฝากเงินสูงสุด:", this.maxPgwQrPayDepositAmount);
      this.toastr.error("ทำรายการฝากไม่ถูกต้องตามเงื่อนไข", "ฝากไม่สำเร็จ");
      return;
    }
    this.isDisabled = true;
    this.spinner.show();
    this._depositService
      .postRequestDepositQrPaymentGateway({ amount: this.pgwDepositAmount })
      .subscribe({
        next: (data) => {
          if (data) {
            if (data.remark) this.toastr.success(data.remark, "สำเร็จ");
            else this.toastr.success("กำลังพาไปหน้าจ่ายเงิน", "สำเร็จ");

            window.location.href = data.payUrl;
          }
          this.isDisabled = false;
          this.spinner.hide();
        },
        error: (err) => {
          this.isDisabled = false;
          this.spinner.hide();
          this.toastr.error(
            "ทำรายการฝากไม่สำเร็จ กรุณาติดต่อแอดมิน",
            "ฝากไม่สำเร็จ"
          );
        },
      });
  }
}
