<div class="-content-data w-sm mx-auto">
  <div class="-box-content -content-01 active">
    <div class="-list-history">
      <app-frame-bg
        [type]="item.frameType"
        *ngFor="let item of dataHistoryList"
      >
        <!-- <div class="-item -card"
                    [ngClass]="{'-deposit':item.transactionType === 'deposit',
                                '-withdraw':item.transactionType === 'withdraw',
                                '-correct':item.transactionStatusType === 'success',
                                '-incorrect':item.transactionStatusType === 'cancel'
                            }"
                >
                    <header>
                        <p style="font-size: 10px;">เวลา {{item.createdDate | date:'dd/MM/yyyy : HH:mm:ss'}}</p>
                        <div class="-group-status">
                            <p>
                                <span>{{item.transactionTypeName}}</span>
                            </p>
                            <p>
                                <span *ngIf="item.transactionStatusType=='success'">
                                    <img appImgLazyLoad src="assets/img/icons/correct.svg" alt="">
                                    {{item.transactionStatusName}}
                                </span>
                                <span *ngIf="item.transactionStatusType=='pending' || item.transactionStatusType=='processing'">
                                    {{item.transactionStatusName}}
                                </span>
                                <span *ngIf="item.transactionStatusType=='cancel'">
                                    <img appImgLazyLoad src="assets/img/icons/incorrect.svg" alt="">
                                    {{item.transactionStatusName}}
                                </span>
                            </p>
                        </div>
                    </header>
                    <div class="-box-data">
                        <div class="-col">
                            <p>ยอดก่อนทำ</p>
                            <p *ngIf="(item.beforeAmount>=0) && (item.transactionStatusType!='pending')"><strong>{{item.beforeAmountTxt}}</strong></p>
                        </div>
                        <div class="-col">
                            <p>จำนวนที่ทำ</p>
                            <p><strong>{{item.amount | number}}</strong></p>
                        </div>
                        <div class="-col">
                            <p>ยอดหลังทำ</p>
                            <p *ngIf="item.afterAmount>0"><strong>{{item.afterAmountTxt}}</strong></p>
                        </div>
                    </div>
                </div>-->
        <div class="relative h-[105px] w-[90%]">
          <div class="absolute w-full h-full top-[1.75rem] left-[1.75rem]">
            <!-- <h3>{{ item.transactionName }} {{ item.remark }}</h3> -->
            <div class="relative">
              <p class="text-gray-400 text-sm mt-2 ml-[1.5rem]">
                {{ item.createdDate | date : "dd/MM/yyyy HH:mm" : "+0000" }}
              </p>
              <div class="mt-3 flex justify-start space-x-3">
                <div class="flex-1 flex justify-between">
                  <span class="text-gray-400">ยอดก่อนทำ</span>
                  <span *ngIf="item.afterAmount > 0">
                    <strong>{{
                      item.afterAmountTxt | number : "1.2-2"
                    }}</strong></span
                  >
                </div>
                <div class="flex-1">
                  <div class="flex justify-between">
                    <span class="text-gray-400">จำนวนที่ทำ</span>
                    <span
                      [ngStyle]="{
                        color:
                          item.transactionType === 'deposit'
                            ? '#45FFA6'
                            : '#EB4B45'
                      }"
                      ><strong>
                        {{ item.transactionType === "deposit" ? "+" : "-"
                        }}{{ item.amount | number : "1.2-2" }}</strong
                      ></span
                    >
                  </div>
                  <div class="flex justify-between">
                    <span class="text-gray-400">ยอดหลังทำ</span>
                    <span *ngIf="item.afterAmount > 0"
                      ><strong>{{
                        item.afterAmountTxt | number : "1.2-2"
                      }}</strong></span
                    >
                  </div>
                </div>
              </div>
              <!-- <div class="absolute right-[40px] bottom-[-35px]">
                <span
                  *ngIf="item.transactionType != 'info'"
                  [ngStyle]="{
                    color:
                      item.transactionType === 'deposit' ? '#45FFA6' : '#EB4B45'
                  }"
                  >{{ item.transactionTypeName }}
                  {{ item.transactionType === "deposit" ? "+" : "-"
                  }}{{ item.amount | number : "1.2-2" }}</span
                >
              </div> -->
            </div>
            <!-- <p>
                        {{ item.transactionTypeName }}
                        <span *ngIf="item.transactionType != 'info'">{{
                          item.amount | number : "1.2-2"
                        }}</span>
                      </p> -->
          </div>
        </div>
      </app-frame-bg>
    </div>
  </div>
</div>

<br />

<div class="flex justify-center" *ngIf="this.totalPage > 1">
  <nav aria-label="Page navigation example">
    <ul class="flex list-style-none">
      <!-- <li class="page-item disabled">
            <a class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-500 pointer-events-none focus:shadow-none"
            tabindex="-1" (click)="onPaginator();" ><</a>
        </li> -->
      <li class="page-item is-link">
        <a
          class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          (click)="paginateEvent(0)"
          ><</a
        >
      </li>

      <li
        *ngFor="let page of totalPageList; let i = index"
        class="page-item is-link"
      >
        <a
          [ngClass]="
            page == currentPage
              ? 'border-0 bg-gray-200 page-link relative block py-1.5\n' +
                '               px-3 border-0 bg-transparent\n' +
                '               outline-none transition-all\n' +
                '               duration-300 rounded-full text-gray-800\n' +
                '               hover:text-gray-800 hover:bg-gray-200\n' +
                '               focus:shadow-none'
              : 'page-link relative block py-1.5\n' +
                '               px-3 border-0 bg-transparent\n' +
                '               outline-none transition-all\n' +
                '               duration-300 rounded-full text-gray-800\n' +
                '               hover:text-gray-800 hover:bg-gray-200\n' +
                '               focus:shadow-none'
          "
          (click)="paginateEvent(page + 1)"
          >{{ page + 1 }}</a
        >
      </li>

      <li class="page-item is-link">
        <a
          class="page-link relative block py-1.5 px-3 border-0 bg-transparent outline-none transition-all duration-300 rounded-full text-gray-800 hover:text-gray-800 hover:bg-gray-200 focus:shadow-none"
          (click)="paginateEvent(this.totalPage)"
          >></a
        >
      </li>
    </ul>
  </nav>
</div>
