import { Component, Input, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MemberService } from "../../../shared/services/member.service";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { LoginService } from "../../../shared/services/login.service";
import { SystemService } from "../../../shared/services/system.service";

@Component({
  selector: "app-register-easy",
  templateUrl: "./register-easy.component.html",
  styleUrls: ["./register-easy.component.css"],
})
export class RegisterEasyComponent implements OnInit {
  @Input() marketingChannelCode: string = "";

  registerEasyForm!: FormGroup;
  bankId: number = 0;
  bankName: string = "";
  phoneNumberValidate: boolean = true;
  accountNoValidate: boolean = true;
  bankIdValidate: boolean = true;
  otpReference!: string;
  phoneNumber!: string;
  accountNumber!: string;
  marketingCode: string = "";
  marketingChannelId: number = 0;
  affCode: string = "0";
  isLoginFacebook: any;
  isLoginLine: any;
  externalAuthReference: any;
  payload: any;
  registerMessage: string = "";
  pathImage: string = "";
  websiteName: string = "";
  showModal: boolean = false;
  usernameForLogin!: string;
  passwordForLogin!: string;
  isDisabled: boolean = false;
  private readonly _marketingChanelId: string = "appMarketingChanelId";
  private readonly _affCode: string = "appAffCode";
  hideBankContainer: boolean = true;

  isLinkLine: any = null;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder,
    private _memberService: MemberService,
    private readonly _router: Router,
    private _urlRoute: ActivatedRoute,
    private _loginService: LoginService,
    private _systemService: SystemService
  ) {}

  ngOnInit(): void {
    this.setName();
    this.setImage();
    this.setRegister();
    this.setRegisterMessage();
    this.initRegisterEasyForm();
    this.getMarketingChannels();

    this.isLinkLine = localStorage.getItem("linkLine");
    this.isLinkLine = JSON.parse(this.isLinkLine);
  }

  getMarketingChannels() {
    this._memberService.getMarketingChanels().subscribe({
      next: (data) => {
        this.setMarketingChannel(data);
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  setMarketingChannel(data: any[]) {
    //get marketing channel from url link
    this._urlRoute.queryParams.subscribe((params) => {
      if (params["channel"]) {
        this.marketingCode = params["channel"];
        let items = data.filter(
          (x) => x.code.toLowerCase() == this.marketingCode.toLowerCase()
        );
        if (items.length > 0) {
          this.marketingChannelId = items[0].id;
          localStorage.setItem(this._marketingChanelId, items[0].id);
        }
      } else {
        if (localStorage.getItem("appMarketingChanelId")) {
          let appMarketingChanelId: any = localStorage.getItem(
            "appMarketingChanelId"
          );
          this.marketingChannelId = appMarketingChanelId;
        }
      }

      if (params["affiliatecode"]) {
        this.affCode = params["affiliatecode"];
        localStorage.setItem(this._affCode, this.affCode);
        this.onSetAffliateCountFromAffliateCode(this.affCode);
      } else {
        if (localStorage.getItem("appAffCode")) {
          let appAffCode: any = localStorage.getItem("appAffCode");
          this.affCode = appAffCode;
        }
      }
    });
  }

  setRegister() {
    this._systemService.getWebInfo().subscribe({
      next: (data) => {
        let isRegister = JSON.parse(data.customSetting).isRegister ?? true;
        let isInvite = JSON.parse(data.customSetting).isInvite ?? true;

        if (!isRegister) {
          if (isInvite) {
            this._urlRoute.queryParams.subscribe((params) => {
              if (!params["affiliatecode"])
                this._router.navigateByUrl("/login");
              console.log("if1");
              console.log(this._router.navigateByUrl("/login"));
            });
          } else this._router.navigateByUrl("/login");
          console.log("if2");
          console.log(this._router.navigateByUrl("/login"));
        } else if (!isInvite) {
          localStorage.setItem(this._affCode, "");
          this._router.navigateByUrl("/register");
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  }

  initRegisterEasyForm() {
    this.registerEasyForm = this._formBuilder.group({
      phoneNumberForm: ["", [Validators.required, Validators.minLength(10)]],
      accountNoForm: ["", [Validators.required, Validators.minLength(10)]],
    });
  }

  numberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
  }

  onChooseBank(bankId: number) {
    this.bankId = bankId;
    switch (bankId) {
      case 1:
        this.bankName = "กรุงเทพ";
        break;
      case 2:
        this.bankName = "ไทยพาณิชย์";
        break;
      case 3:
        this.bankName = "กสิกรไทย";
        break;
      case 4:
        this.bankName = "กรุงไทย";
        break;
      case 5:
        this.bankName = "กรุงศรีอยุธยา";
        break;
      case 6:
        this.bankName = "เพื่อการเกษตรและสหกรณ์การเกษตร";
        break;
      case 7:
        this.bankName = "ซีไอเอ็มบีไทย";
        break;
      case 8:
        this.bankName = "ทิสโก้";
        break;
      case 9:
        this.bankName = "อาคารสงเคราะห์";
        break;
      case 10:
        this.bankName = "ออมสิน";
        break;
      case 11:
        this.bankName = "ทหารไทยธนชาต";
        break;
      case 12:
        this.bankName = "อิสลามแห่งประเทศไทย";
        break;
      case 13:
        this.bankName = "เกียรตินาคินภัทร";
        break;
      case 14:
        this.bankName = "แลนด์ แอนด์ เฮ้าส์";
        break;
      case 15:
        this.bankName = "ยูโอบี";
        break;
      default:
        this.bankName = "";
        break;
    }
    this.hideBankContainer = !this.hideBankContainer;
  }

  onSubmit() {
    this.phoneNumberValidate =
      this.registerEasyForm.controls["phoneNumberForm"].valid;
    this.accountNoValidate =
      this.registerEasyForm.controls["accountNoForm"].valid;
    this.bankIdValidate = this.bankId > 0;

    if (this.registerEasyForm.valid && this.bankIdValidate) {
      this.isDisabled = true;
      this.spinner.show();

      this.phoneNumber =
        this.registerEasyForm.controls["phoneNumberForm"].value;
      this.accountNumber =
        this.registerEasyForm.controls["accountNoForm"].value;

      this.externalAuthReference = localStorage.getItem(
        "externalAuthReference"
      );
      this.externalAuthReference = JSON.parse(this.externalAuthReference);

      this.isLoginFacebook = localStorage.getItem("isLoginFacebook");
      this.isLoginFacebook = JSON.parse(this.isLoginFacebook);

      this.isLoginLine = localStorage.getItem("isLoginLine");
      this.isLoginLine = JSON.parse(this.isLoginLine);

      this.payload = {
        phoneNumber: this.phoneNumber,
        bankAccountNo: this.accountNumber,
        registBankId: this.bankId,
        fromMarketingChannelId: this.marketingChannelId,
        externalAuthReference: this.externalAuthReference,
        registAffliateCode: this.affCode,
      };

      if (this.isLoginFacebook || this.isLoginLine) {
        this._loginService.postMemberRegister3rd(this.payload).subscribe({
          next: (data) => {
            this.spinner.hide();
            // this._router.navigate(['registerSuccess']);
            // this.toastr.success('', 'การสมัครสมาชิกสำเร็จ โปรด Login เพื่อใช้งาน');

            this.usernameForLogin = this.phoneNumber;
            this.passwordForLogin = data.code;

            this.showModal = true;

            setInterval(() => {
              this.showModal = false;
              this.onNextToLogin();
            }, 20000);
          },
          error: (e) => {
            console.error("postMemberRegister3rd fail::::", e);
            this.spinner.hide();
            this.toastr.error("", "การสมัครสมาชิกไม่สำเร็จ");
            this.isDisabled = false;
          },
        });
      } else {
        this._memberService
          .postCheckUsernameIsAvailable(this.phoneNumber)
          .subscribe({
            next: (data) => {
              this.spinner.hide();
              this.register();
            },
            error: (e) => {
              console.error(e);
              this.spinner.hide();
              this.toastr.error(
                "",
                "เบอร์โทรศัพท์ไม่ถูกต้อง หรือ มีผู้ใช้งานเบอร์นี้แล้ว"
              );
              this.isDisabled = false;
            },
          });
      }
    }
  }

  register() {
    this.spinner.show();

    let dataSet = {
      phoneNumber: this.phoneNumber,
      bankAccountNo: this.accountNumber,
      registBankId: this.bankId,
      fromMarketingChannelId: this.marketingChannelId,
      registAffliateCode: this.affCode,
    };
    this._memberService.postMemberRegister(dataSet).subscribe({
      next: (data: any) => {
        this.usernameForLogin = this.phoneNumber;
        this.passwordForLogin = data.code;

        this.spinner.hide();
        // this._router.navigate(['registerSuccess']);
        // this.toastr.success('', 'การสมัครสมาชิกสำเร็จ โปรด Login เพื่อใช้งาน');

        this.showModal = true;
        setInterval(() => {
          // this.spinner.show();
          this.showModal = false;
          this.onNextToLogin();
        }, 20000);
      },
      error: (e: any) => {
        console.error(e);
        this.toastr.clear();
        let errorCode = e.error.code;
        switch (errorCode) {
          case "105":
            this.toastr.error(
              "เลขที่บัญชีนี้มีในระบบแล้ว กรุณาใช้เลขที่บัญชีใหม่ค่ะ",
              "ทำรายการไม่สำเร็จ"
            );
            break;

          default:
            this.toastr.error(
              "สมาชิกในระบบเต็มแล้ว กรุณาสมัครใหม่ในภายหลังค่ะ",
              "ทำรายการไม่สำเร็จ"
            );
            break;
        }
        this.spinner.hide();
        this.isDisabled = false;
      },
    });
  }

  onSetAffliateCountFromAffliateCode(
    registAffliateCode: string = this.affCode
  ) {
    let payload = {
      value: registAffliateCode,
    };

    this._memberService.setDataRegisterAffliateCount(payload).subscribe({
      next: (data) => {},
      error: (e) => {
        console.error(e);
      },
    });
  }

  setRegisterMessage() {
    let registerMessage = localStorage.getItem("registerMessage");
    if (registerMessage) {
      this.registerMessage = JSON.parse(registerMessage);
    }
  }

  setImage() {
    // let webImage = localStorage.getItem('webImage');
    // if (webImage) {
    //   this.pathImage = JSON.parse(webImage);
    // }
    this.pathImage =
      "https://fs.cdnxn.com/lsm99king/king/img/bg-logo/header-logo.webp";
    // this.pathImage = 'https://fs.cdnxn.com/lsm99king/king/img/logo.gif';
  }

  setName() {
    let websiteName = localStorage.getItem("websiteName");
    if (websiteName) {
      this.websiteName = JSON.parse(websiteName);
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
    this.onNextToLogin();
  }

  onNextToLogin() {
    this.spinner.hide();

    const navigationExtras: NavigationExtras = {
      state: {
        username: this.usernameForLogin,
        password: this.passwordForLogin,
      },
    };
    this._router.navigate(["login"], navigationExtras);
  }
}
