<div toastContainer></div>
<ngx-spinner type="ball-scale-multiple">
  <div class="flex justify-center items-center space-x-1 text-sm text-gray-700">
    <div
      class="w-28 h-28 border-t-4 border-b-4 border-white rounded-full animate-spin"
    ></div>
  </div>
</ngx-spinner>

<!-- start content this page -->
<main id="main-content">
  <div class="-content-register w-sm mx-auto">
    <div class="container">
      <div
        class="-section-top"
        *ngIf="
          this.registerStatus == 'empty' &&
          this.affliateRegisterTypeList.length > 0
        "
      >
        <img
          src="assets/img/theme/new-bank.svg"
          alt=""
          class="mx-auto block mt-16 mb-6 -shadow-icon"
        />
        <h2 class="-title">สมัครเข้าร่วมพันธมิตร</h2>
        <p>
          การสมัครเข้าร่วมพันธมิตร
          ท่านสามารถรับผลประโยชน์เพิ่มเติมมากมายได้จากช่องทางนี้
        </p>

        <div class="-form-style mt-4">
          <form [formGroup]="this.registerAffliateFormGroup">
            <div class="-row -required">
              <label>เลือกวิธีการคำนวณผลประโยชน์</label>
              <select
                class="-form-input"
                formControlName="selectAffliateRegisterTypeForm"
                [ngClass]="
                  this.isInvalidselectAffliateRegisterType
                    ? 'invalid-dropdown'
                    : ''
                "
              >
                <option
                  *ngFor="let item of affliateRegisterTypeList; index as i"
                  [value]="item.id"
                  [selected]="true"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <br />
            <div class="-row">
              <a (click)="onSubmit()" class="-btn-01 btn-link">
                <span>สมัคร</span>
              </a>
            </div>
          </form>
        </div>
      </div>

      <div
        class="-section-top"
        *ngIf="
          this.registerStatus == 'empty' &&
          this.affliateRegisterTypeList.length == 0
        "
      >
        <img
          src="assets/img/theme/new-bank.svg"
          alt=""
          class="mx-auto block mt-16 mb-6 -shadow-icon"
        />
        <h2 class="-title">สมัครเข้าร่วมพันธมิตร</h2>
        <p>
          ขออภัยในความไม่สะดวก ขณะนี้ยังไม่เปิดรับสมัครพันธมิตรเพิ่มเติม
          ทางเราจะรีบแจ้งให้ทราบทันทีเมื่อระบบเปิดรับสมัครแล้วค่ะ
        </p>
      </div>

      <div class="-section-top" *ngIf="this.registerStatus == 'pending'">
        <img
          src="assets/img/theme/new-bank.svg"
          alt=""
          class="mx-auto block mt-16 mb-6 -shadow-icon"
        />
        <h2 class="-title">คุณทำการสมัครเข้ามาที่ระบบแล้ว</h2>
        <p>ทางแอดมินกำลังตรวจสอบข้อมูลการสมัครและจะดำเนินการให้เร็วที่สุดค่ะ</p>
      </div>

      <div class="-section-top" *ngIf="this.registerStatus == 'rejected'">
        <img
          src="assets/img/theme/new-bank.svg"
          alt=""
          class="mx-auto block mt-16 mb-6 -shadow-icon"
        />
        <h2 class="-title">การสมัครของคุณไม่ได้รับการอนุมัติ</h2>
        <p>
          หากท่านมีข้อสงสัยในขั้นตอนการตรวจสอบข้อมูลกรุณาติดต่อแอดมินได้เลยค่ะ
        </p>
        <div class="-your-account mt-4">
          <a class="center btn-link" routerLink="/contact">
            <img appImgLazyLoad src="assets/img/icons/call-center.jpg" />
          </a>
          <h2>ติดต่อแอดมิน</h2>
        </div>
      </div>
    </div>
  </div>
</main>
<!-- end content this page -->
